import { BANK_TYPES, BANKS_LIST_MIXED_TYPE } from '../consts';

import { AU_APP, MY_APP } from 'constants/localeConfigs';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

const getPurposeParam = (isSpending, isWithdrawal) => {
  if (isSpending) {
    return 'spending';
  }

  if (isWithdrawal) {
    return 'withdrawal';
  }

  return 'funding';
};

const getBankListRequestParams = (requestData) => {
  const {
    isMoreBanks,
    name,
    limit,
    isOffset,
    isSpending,
    isWithdrawal,
    isYodleeFastlinkEnabled,
  } = requestData;

  const params = {};

  if (limit) {
    params.limit = limit;
  }

  if (name) {
    params.name = name;
  }

  if (MY_APP) {
    params.purpose = getPurposeParam(isSpending, isWithdrawal);
  } else if (AU_APP) {
    params.promoted = true;

    if (!isSpending) {
      params.type = BANK_TYPES.funding;
    }
  }

  if (isMoreBanks) {
    params.offset = 1;

    params.limit = 25;

    delete params.promoted;

    if (isOffset) {
      const lastOffset = Number(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.bankSearchOffset),
      );

      if (!lastOffset) {
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.bankSearchOffset,
          params.offset,
        );
      } else {
        params.offset = lastOffset + 1;

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.bankSearchOffset,
          params.offset,
        );
      }
    } else {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.bankSearchOffset, 1);
    }
  }

  if (isYodleeFastlinkEnabled) {
    params.mixed_for = BANKS_LIST_MIXED_TYPE.cdr;
  } else {
    params.mixed_for = BANKS_LIST_MIXED_TYPE.scraping;
  }

  return new URLSearchParams(params).toString();
};

export default getBankListRequestParams;
