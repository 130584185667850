import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation } from 'react-i18next';

import Loader from '../../../components/elements/Loader/Loader';
import ChangeLocationModal from '../../../components/layout/Modals/ChangeLocationModal';
import { FAVOURITE_CATEGORY_ID } from '../consts';

import RewardsMap from './RewardsMap';
import CategoriesView from './CategoriesView';
import InstoreOfferItem from './InstoreOfferItem';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

export default function InStoreRewardsView({
  isMap,
  toggleMapList,
  instoreOffers,
  categories,
  filterCategory,
  toggleLikeOffer,
  showMainLoader,
  markers,
  address,
  isShowChangeLocation,
  openChangeLocation,
  categoryName,
  categoryId,
  favourites,
}) {
  const { t } = useTranslation();
  const isFavouriteCategory = categoryId === FAVOURITE_CATEGORY_ID;

  const rewards = isFavouriteCategory
    ? favourites?.instore
    : instoreOffers?.records;

  return (
    <div className={isMap ? 'in-store map-block' : 'in-store'}>
      <div className="profile-content settings__content-default-container">
        <div className="profile-content__header -with-navigation ">
          <div className="profile-content__title">
            {t('inStoreRewardsPage.title')}
          </div>

          <div className="profile-content__navigation">
            <DeprecatedButton
              title={t('inStoreRewardsPage.list')}
              buttonClass={!isMap ? 'active' : ''}
              handleClick={() => toggleMapList(false)}
            />

            <DeprecatedButton
              title={t('inStoreRewardsPage.map')}
              buttonClass={isMap ? 'active' : ''}
              handleClick={() => toggleMapList(true)}
            />
          </div>
        </div>

        {isShowChangeLocation && (
          <ChangeLocationModal
            isShowChangeLocation={isShowChangeLocation}
            close={openChangeLocation}
            addressSave={address}
            isMap={isMap}
          />
        )}

        {isMap && (
          <div
            className="profile-content__container"
            style={{ height: 'calc(100vh - 150px)', width: '100%' }}
          >
            <div className="change-location">
              <div className="panel">
                <div className="label">
                  {t('inStoreRewardsPage.offersNear')}
                </div>

                <div className="address">{address}</div>

                <div
                  onClick={() => openChangeLocation(true)}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                  className="change"
                >
                  {t('inStoreRewardsPage.change')}
                </div>
              </div>
            </div>

            <RewardsMap markers={markers} />
          </div>
        )}

        {!isMap && (
          <div className="profile-content__container">
            <div className="change-location">
              <div className="panel">
                <div className="label">
                  {t('inStoreRewardsPage.offersNear')}
                </div>

                <div className="address">{address}</div>

                <div
                  onClick={() => openChangeLocation(true)}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                  className="change"
                >
                  {t('inStoreRewardsPage.change')}
                </div>
              </div>
            </div>

            <div className="grid">
              <div className="grid__categories">
                {categories && categories.categories && (
                  <CategoriesView
                    categories={categories.categories}
                    filterCategory={filterCategory}
                    categoryId={categoryId}
                  />
                )}

                <div className="name">{categoryName}</div>

                <div className="grid__container" id="rewards-instore-items">
                  {!showMainLoader &&
                    rewards?.map((brand) => (
                      <InstoreOfferItem
                        key={brand.offerId}
                        offer={brand}
                        toggleLikeOffer={toggleLikeOffer}
                        categoryId={categoryId}
                      />
                    ))}

                  {showMainLoader && (
                    <Loader additionalClass="-absolute -top" />
                  )}

                  {!showMainLoader && rewards?.length === 0 && (
                    <div className="found-block">
                      <div className="icon-not-found" />

                      <div className="not-found">
                        <div>{t('inStoreRewardsPage.noResultsFound')}</div>

                        <div>{t('inStoreRewardsPage.switchingToOnline')}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

InStoreRewardsView.propTypes = {
  isMap: PropTypes.bool.isRequired,
  toggleMapList: PropTypes.func.isRequired,
  instoreOffers: PropTypes.shape({
    records: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  categories: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  filterCategory: PropTypes.func.isRequired,
  toggleLikeOffer: PropTypes.func.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  markers: PropTypes.shape({}).isRequired,
  address: PropTypes.string.isRequired,
  isShowChangeLocation: PropTypes.bool.isRequired,
  openChangeLocation: PropTypes.func.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryId: PropTypes.number.isRequired,
  favourites: PropTypes.shape({
    instore: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
