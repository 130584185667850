import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/pages/invest.scss';
import '../../../styles/layout/statements.scss';
import { DEFAULT_FAVOURITE_CATEGORY } from '../consts';
import checkIsFavouriteCategory from '../utils/checkIsFavouriteCategory';

export default function CategoryCard({
  category,
  filterCategory,
  isSearchFromMainPage,
  categoryId,
}) {
  const isActive =
    categoryId === category.id ||
    checkIsFavouriteCategory(categoryId, category.id);

  return (
    <div
      className={`rewards__carousel-item ${isActive ? '-active' : ''}`}
      onClick={() =>
        filterCategory({
          name: category.name,
          id: category.id.toString(),
          isSearchFromMainPage,
        })
      }
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
    >
      <div className="rewards__carousel-logo">
        {category.id === DEFAULT_FAVOURITE_CATEGORY.id ? (
          <div className="favorite" />
        ) : (
          <img
            className="rewards__carousel-img"
            src={category.image}
            alt={category.name}
          />
        )}
      </div>

      <div className="rewards__carousel__lablel">{category.name}</div>
    </div>
  );
}

CategoryCard.defaultProps = {
  categoryId: null,
  isSearchFromMainPage: false,
};

CategoryCard.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  filterCategory: PropTypes.func.isRequired,
  isSearchFromMainPage: PropTypes.bool,
  categoryId: PropTypes.number,
};
