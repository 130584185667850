import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DATE_FORMAT_DAY_NAME, DATE_FORMAT_DAY_NUMBER } from 'config/date';
import { IRecurring } from 'store/dependentUser/types';
import { FREQUENCIES } from 'constants/comonConstants';

interface IUseInvestmentFrequencyTextProps {
  recurring?: IRecurring;
}

export const useInvestmentFrequencyText = ({
  recurring,
}: IUseInvestmentFrequencyTextProps) => {
  const { t } = useTranslation();

  const { day, frequency_identifier, frequency } = recurring || {};

  switch (frequency_identifier) {
    case FREQUENCIES.weekly:
    case FREQUENCIES.fortnightly:
      return t('raizKidsEditPage.recurringInvestmentPreview.frequency.day', {
        frequency,
        dayName:
          day &&
          dayjs()
            .day(day - 1)
            .format(DATE_FORMAT_DAY_NAME),
      });
    case FREQUENCIES.monthly:
      return t(
        'raizKidsEditPage.recurringInvestmentPreview.frequency.monthlyDay',
        {
          frequency,
          monthlyDay: day && dayjs().date(day).format(DATE_FORMAT_DAY_NUMBER),
        },
      );
    default:
      return frequency;
  }
};
