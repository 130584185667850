import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import Firebase from 'firebase/app';

import * as fundingAccountAction from '../../../store/fundingAccount/actions';
import * as actionsUser from '../../../store/user/actions';
import { MY_APP } from '../../../constants/localeConfigs';
import { REQUIRED } from '../../../constants/errors';
import { YODLEE_FASTLINK_STATUSES } from '../consts';

import SpendingAccountView from './SpendingAccountView';

import {
  selectIsSpendingInstitutionsEnabled,
  selectIsVisaEnabled,
  selectIsYodleeFastlinkEnabled,
} from 'store/firebaseConfig/selectors';
import { selectIsBankAccountLinkingStep } from 'store/fundingAccount/selectors';
import { FASTLINK_FLOWS } from 'components/modals/Fastlink/consts';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { checkIsEligibleForFastlink } from 'utils/checkIsEligibleForFastlink';

class SpendingAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowModalError: false,
      isShowBankModal: false,
      isShowBankModalError: false,
      isRoundUpsModalOpen: false,
      showMainLoader: true,
      bank: {},
      bankCurrentTarget: 'LOGIN',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
      isShowPassword: true,
      cardNumber: '',
      cardNumberError: '',
      isConfirmUnlinkSpending: false,
      currentUnlinkIdCart: '',
      isFirstStep2factor: true,
      isShowAddCardModal: false,
      isShowAllVisaBanks: false,
      isUnlinkCard: false,
      cardNumberSuffix: '',
      currentCardId: null,
      isShowConfirmation: false,
      isReConnectAccount: false,
      isFastlinkModalOpen: false,
      searchValue: null,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleStopBankAccountLinking =
      this.handleStopBankAccountLinking.bind(this);

    this.bankLoginProcessWait = this.bankLoginProcessWait.bind(this);

    this.handleCloseModalError = this.handleCloseModalError.bind(this);

    this.deleteAccount = this.deleteAccount.bind(this);

    this.fixAccount = this.fixAccount.bind(this);

    this.showMoreBanks = this.showMoreBanks.bind(this);

    this.searchBanks = this.searchBanks.bind(this);

    this.trackScrolling = this.trackScrolling.bind(this);

    this.roundUpModalOpen = this.roundUpModalOpen.bind(this);

    this.changeDate = this.changeDate.bind(this);

    this.toggleShowPassword = this.toggleShowPassword.bind(this);

    this.handleChangeCardNumber = this.handleChangeCardNumber.bind(this);

    this.linkCard = this.linkCard.bind(this);

    // this.accountChangeBankCard = this.accountChangeBankCard.bind(this);
    this.unlinkSpendingCard = this.unlinkSpendingCard.bind(this);

    this.toggleShowPassword = this.toggleShowPassword.bind(this);

    this.checkMfa = this.checkMfa.bind(this);

    this.showAddCardModal = this.showAddCardModal.bind(this);

    this.addCard = this.addCard.bind(this);

    this.deleteVisaCard = this.deleteVisaCard.bind(this);

    this.showVisaAllBanks = this.showVisaAllBanks.bind(this);

    this.toggleFastlinkModal = this.toggleFastlinkModal.bind(this);

    this.changeSearchValue = this.changeSearchValue.bind(this);
  }

  componentDidMount() {
    Firebase.analytics().logEvent('Spending_Account');

    const { actions } = this.props;

    if (MY_APP) {
      actions.getVisaPaymentCard();

      actions.getPaymentCard('spending').then(() => {
        const { isInstitutionsApiEnabled } = this.props;

        if (isInstitutionsApiEnabled) {
          actions
            .getBanks({
              isSpending: true,
              isShowBank: false,
            })
            .then(() => {
              this.setState({ showMainLoader: false });
            });
        } else {
          this.setState({ showMainLoader: false });
        }
      });
    } else {
      actions
        .getBanks({
          isSpending: true,
          isShowBank: false,
        })
        .then(() => {
          this.setState({ showMainLoader: false });
        });
    }

    window.scrollTo(0, 0);
  }

  // TODO: Check new "UNSAFE_componentWillReceiveProps" => "componentDidUpdate + getDerivedStateFromProps"
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const newData = {};
  //   if (nextProps.paymentCard !== prevState.paymentCard) {
  //     newData.paymentCard = nextProps.paymentCard;
  //   }
  //   if (nextProps.isShowCreditCardError !== prevState.isShowCreditCardError) {
  //     newData.isShowCreditCardError = nextProps.isShowCreditCardError;
  //   }
  //   if (nextProps.skipBankLoginProps !== prevState.skipBankLoginProps) {
  //     newData.skipBankLoginProps = nextProps.skipBankLoginProps;
  //   }
  //   if (nextProps.errorMessageProps !== prevState.errorMessageProps) {
  //     newData.errorMessageProps = nextProps.errorMessageProps;
  //   }
  //   if (nextProps.addListener !== prevState.addListener) {
  //     newData.addListener = nextProps.addListener;
  //   }
  //   return newData;
  // }
  //
  // componentDidUpdate(prevProps, prevState) {

  //   const { skipBankLogin, errorMessage } = this.props;
  //   if (paymentCard && paymentCard.request_url) {
  //     setTimeout(() => {
  //       document.infinitium.submit();
  //     }, 2000);
  //   }
  //   if (isShowCreditCardError) {
  //     this.setState({
  //       isShowBankModalError: true
  //     });
  //   }
  //   if (skipBankLoginProps && prevState.skipBankLoginProps !== skipBankLoginProps
  //     && skipBankLoginProps !== skipBankLogin) {
  //     this.setState({
  //       isShowBankModal: false
  //     });
  //   }
  //   const stopBankLoginRequest = parseInt(sessionStorage.getItem('stopBankLoginRequest'));
  //   if (errorMessageProps && (prevState.errorMessageProps !== errorMessageProps && errorMessageProps !== errorMessage
  //     && !stopBankLoginRequest)) {
  //     this.setState({
  //       isShowBankModalError: true
  //     });
  //     sessionStorage.setItem('stopBankLoginRequest', 1);
  //   }
  //   if (errorMessageProps && (prevState.errorMessage !== errorMessageProps && errorMessageProps !== errorMessage)) {
  //     this.setState({
  //       isShowModalError: true
  //     });
  //   }
  //   if (addListener && prevState.addListener !== addListener) {
  //     document.addEventListener('scroll', this.trackScrolling);
  //   }
  // }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { skipBankLogin, errorMessage } = this.props;

    if (newProps.paymentCard && newProps.paymentCard.request_url) {
      setTimeout(() => {
        document.infinitium.submit();
      }, 2000);
    }

    if (newProps.isShowCreditCardError) {
      this.setState({
        isShowBankModalError: true,
      });
    }

    if (newProps.skipBankLogin && newProps.skipBankLogin !== skipBankLogin) {
      this.setState({
        isShowBankModal: false,
      });
    }

    const stopBankLoginRequest = parseInt(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.stopBankLoginRequest),
    );

    if (
      newProps.errorMessage &&
      newProps.errorMessage !== errorMessage &&
      !stopBankLoginRequest
    ) {
      this.setState({
        isShowBankModalError: true,
      });

      sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLoginRequest, 1);
    }

    if (newProps.errorMessage && newProps.errorMessage !== errorMessage) {
      this.setState({
        isShowModalError: true,
      });
    }

    if (newProps.addListener) {
      document.addEventListener('scroll', this.trackScrolling);
    }
  }

  componentDidUpdate(_, prevState) {
    const { searchValue } = this.state;

    const { actions } = this.props;

    if (
      typeof searchValue === 'string' &&
      prevState.searchValue !== searchValue
    ) {
      actions.waitingInProcess();

      this.searchBanks();
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    document.removeEventListener('scroll', this.trackScrolling);

    actions.resetProps();
  }

  handleCloseModal() {
    this.setState({
      isShowBankModal: false,
      isShowAddCardModal: false,
      isShowModalError: false,
      isShowBankCardModal: false,
      cardNumberError: '',
    });
  }

  handleCloseModalError() {
    const { actions } = this.props;

    actions.stopLoginRequest();

    this.setState({
      isShowBankModalError: false,
      isConfirmUnlinkSpending: false,
      isShowConfirmation: false,
      isUnlinkCard: false,
      cardNumberSuffix: '',
    });
  }

  handleStopBankAccountLinking() {
    const { actions } = this.props;

    actions.showWaitPopUpBankLogin(false);

    sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLogin, 1);
  }

  connectAccount = () => {
    const { actions } = this.props;

    actions.connectAccount();
  };

  showBanks = () => {
    const { actions } = this.props;

    if (MY_APP) {
      actions.getBanksList({ isSpending: true });
    } else {
      actions.getBanks({ isSpending: true });
    }
  };

  showBanksFromSubAccount = () => {
    const { actions } = this.props;

    actions.getBanksList({ isSpending: true });
  };

  signInBank = (bankId) => {
    const { state, isYodleeFastlinkEnabled, actions } = this.props;

    state.fundingAccount.credentials.LOGIN = '';

    state.fundingAccount.credentials.PASSWORD = '';

    let bank = [];

    if (
      state.fundingAccount.moreBanks &&
      state.fundingAccount.moreBanks.length !== 0
    ) {
      bank = state.fundingAccount.moreBanks.filter((banks) =>
        banks.institution_id.includes(bankId),
      );
    } else if (MY_APP) {
      bank = state.fundingAccount.banksInstitutions.institutions.filter(
        (banks) => banks.institution_id.includes(bankId),
      );
    } else {
      bank = state.fundingAccount.banks.institutions.filter((banks) =>
        banks.institution_id.includes(bankId),
      );
    }

    if (bank) {
      this.setState({
        bank: bank[0],
      });
    }

    const isEligibleForFastlink = checkIsEligibleForFastlink({
      provider: bank[0].provider,
      isYodleeFastlinkEnabled,
    });

    if (isEligibleForFastlink) {
      actions.setCurrentBank(bank[0]);

      actions.setFastlinkFlow(FASTLINK_FLOWS.add);

      this.setState({
        isFastlinkModalOpen: true,
      });

      return;
    }

    this.setState({
      isShowBankModal: true,
    });
  };

  handleChangeBank = (e) => {
    const { state } = this.props;

    this.setState({ bankCurrentTarget: e.target.name });

    state.form.BANK_SIGN_IN.initial[e.target.name] = e.target.value;
  };

  checkMfa = (bank) => {
    if (bank && bank.login_form && bank.login_form.length !== 0) {
      let isMfa = false;

      bank.login_form.map((form) => {
        if (form.mfa) {
          isMfa = true;
        }

        return false;
      });

      return isMfa;
    }

    return false;
  };

  bankInSubmit = (e, values, form, hasValidationErrors, isResend = false) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        const { actions, userActions, refreshSessionId, isRoundUpRefreshing } =
          this.props;

        const { bank, isReConnectAccount } = this.state;

        if (!isRoundUpRefreshing) {
          userActions.showLoader();
        }

        const { otpData, isShowMfaField } = this.props;
        let isMfa = false;

        if (!isShowMfaField) {
          isMfa = this.checkMfa(bank);
        }

        const bankData = {
          formData: values,
          bank,
          isSpending: true,
          isRegistration: false,
          isFromInvestments: false,
          autoRoundUps: false,
          isMfa,
          isKidsRegistrationPortfolio: false,
          isKidsRegistrationHome: false,
          isReConnectAccount,
        };

        if (isResend) {
          bankData.formData = Object.fromEntries(
            Object.entries(values).filter(([, v]) => v !== null && v !== ''),
          );

          actions.bankSignIn(bankData);

          return;
        }

        if (otpData && isShowMfaField) {
          bankData.formData = Object.assign(otpData, values);

          bankData.isMfa = false;

          actions.bankSignIn(bankData);

          return;
        }

        if (isRoundUpRefreshing) {
          const refreshData = {
            otp_token: values.otp_token,
            session_id: refreshSessionId,
          };

          actions.refreshRoundUp(refreshData, bank.id).then((response) => {
            if (!response.error) {
              this.setState({ isShowBankModal: false });

              actions
                .getBanks({ isSpending: true, isShowBank: false })
                .then(() => {
                  this.setState({ showMainLoader: false });
                });
            }
          });

          return;
        }

        actions.bankSignIn(bankData);
      }
    }
  };

  handleSubAccount = ({ target, accountId, monitoredId, linked }) => {
    const { actions } = this.props;

    target.parentElement.classList.toggle('checkbox__check');

    actions.subAccountLinkUnlink(accountId, monitoredId, linked);
  };

  trackScrolling = () => {
    const wrappedElement = document.getElementById('bankSearch');
    const { actions, addListener } = this.props;

    if (addListener && this.isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this.trackScrolling);

      actions.getBanksList({
        isMoreBanks: true,
        isOffset: true,
      });
    }
  };

  searchBanks = () => {
    const { actions } = this.props;
    const { searchValue } = this.state;

    if (searchValue) {
      actions.getBanksList({
        isMoreBanks: true,
        name: searchValue,
      });
    } else {
      actions.getBanksList({ isMoreBanks: true });
    }
  };

  handleChangeCardNumber = (e) => {
    this.setState({ cardNumber: e.target.value, cardNumberError: '' });
  };

  linkCard = (e, values, form, hasValidationErrors) => {
    const { cardNumber, bank } = this.state;
    const { actions, userActions, t } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        if (cardNumber === '') {
          this.setState({
            cardNumberError: REQUIRED,
          });
        }

        if (cardNumber.replace(/\s/g, '').length !== 16) {
          this.setState({
            cardNumberError: t(
              'settingsPage.registrationBankAccount.wrongCardNumber',
            ),
          });
        } else {
          let expirationYear = 0;
          let expirationMonth = 0;

          if (
            values.cardYear &&
            values.cardYear[0] &&
            values.cardMonth &&
            values.cardMonth[0]
          ) {
            expirationYear = values.cardYear[0].value;

            expirationMonth = values.cardMonth[0].value;
          }

          const data = {
            number: cardNumber.replace(/\s/g, ''),
            cvv: values.cvvCode,
            expiration_year: expirationYear,
            expiration_month: expirationMonth,
            spending: true,
            funding: false,
            bank_id: bank.id,
            holder_name: values.holderName,
          };

          const redirectData = {
            failed_url: `${window.location.href}-failed`,
            success_url: `${window.location.href}-success`,
          };

          userActions.showLoader();

          actions.addPaymentCard(data, redirectData);
        }
      }
    }
  };

  openBankCardModal = (bankId) => {
    const { state } = this.props;

    const bank = state.fundingAccount.banks.banks.filter(
      (banks) => banks.id === bankId,
    );

    if (bank) {
      this.setState({
        bank: bank[0],
      });
    }

    this.setState({
      isShowBankCardModal: true,
    });
  };

  fixAccount(e, account) {
    const { actions, isYodleeFastlinkEnabled, monitoredInstitutions } =
      this.props;

    const { updateEligibility } = monitoredInstitutions[0].additional_status;

    const isEligibleForFastlink = checkIsEligibleForFastlink({
      provider: account.provider,
      isYodleeFastlinkEnabled,
    });

    if (
      isEligibleForFastlink &&
      [
        YODLEE_FASTLINK_STATUSES.allowUpdateWithCredentials,
        YODLEE_FASTLINK_STATUSES.allowUpdate,
      ].includes(updateEligibility)
    ) {
      if (
        updateEligibility ===
        YODLEE_FASTLINK_STATUSES.allowUpdateWithCredentials
      ) {
        actions.setFastlinkFlow(FASTLINK_FLOWS.edit);
      } else if (updateEligibility === YODLEE_FASTLINK_STATUSES.allowUpdate) {
        actions.setFastlinkFlow(FASTLINK_FLOWS.refresh);
      }

      this.setState({
        isFastlinkModalOpen: true,
      });

      return;
    }

    if (account?.error_info?.error_code === 'ACCOUNT_LOCKED') {
      actions.initiateRefreshRoundUp(account.id).then((response) => {
        if (!response.error) {
          this.setState({
            bank: account,
            isShowBankModal: true,
          });
        }
      });
    } else {
      this.setState({
        bank: account,
        isShowBankModal: true,
        isReConnectAccount: true,
      });
    }
  }

  isBottom(el) {
    if (el) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    return false;
  }

  roundUpModalOpen(bool) {
    this.setState({
      isRoundUpsModalOpen: bool,
    });
  }

  changeDate(value, type) {
    if (type === 'month') {
      this.setState({
        cardMonth: value,
      });
    } else {
      this.setState({
        cardYear: value,
      });
    }
  }

  showMoreBanks() {
    const { actions } = this.props;

    actions.getBanksList({
      isMoreBanks: true,
      isSpending: true,
    });
  }

  deleteAccount(e, accountId) {
    Firebase.analytics().logEvent('Spending_Account', {
      eventKey: 'Bank Account Removed',
    });

    if (accountId) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.deleteAccountId, accountId);

      this.setState({
        isShowModalError: true,
      });

      this.setState({
        isShowConfirmation: true,
      });
    } else {
      const { actions } = this.props;

      this.setState({ showMainLoader: true });

      actions
        .deleteAccount(
          sessionStorage.getItem(SESSION_STORAGE_KEYS.deleteAccountId),
        )
        .then(() => {
          this.setState({ showMainLoader: false });
        });

      this.setState({
        isShowModalError: false,
      });

      this.setState({
        isShowConfirmation: false,
      });
    }
  }

  bankLoginProcessWait() {
    const { actions } = this.props;

    actions.waitingInProcess();
  }

  toggleShowPassword() {
    const { isShowPassword } = this.state;

    this.setState({ isShowPassword: !isShowPassword });
  }

  unlinkSpendingCard(cardId = '', cardNumberSuffix) {
    const { isConfirmUnlinkSpending, currentUnlinkIdCart } = this.state;

    if (!isConfirmUnlinkSpending) {
      this.setState({
        isShowConfirmation: true,
        isConfirmUnlinkSpending: true,
        currentUnlinkIdCart: cardId,
        cardNumberSuffix,
      });
    } else if (currentUnlinkIdCart) {
      const { actions } = this.props;

      actions.unlinkSpendingCard(cardId).then(() => {
        const { isShowCreditCardError } = this.props;

        this.setState({
          isShowConfirmation: false,
          isConfirmUnlinkSpending: true,
          currentUnlinkIdCart: '',
        });

        if (!isShowCreditCardError) {
          actions.getPaymentCard('spending');
        }
      });
    }
  }

  showAddCardModal(bankId) {
    const { state } = this.props;

    this.setState({ isShowAddCardModal: true });

    const bank = state.fundingAccount.banks.banks.find(
      (banks) => banks.id === bankId,
    );

    if (bank) {
      this.setState({ bank });
    }
  }

  addCard({ e, form, hasValidationErrors }) {
    const { cardNumber } = this.state;
    const { actions, userActions, t } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        if (cardNumber === '') {
          this.setState({
            cardNumberError: REQUIRED,
          });
        }

        if (cardNumber.replace(/\s/g, '').length !== 16) {
          this.setState({
            cardNumberError: t(
              'settingsPage.registrationBankAccount.wrongCardNumber',
            ),
          });
        } else {
          const data = {
            number: cardNumber.replace(/\s/g, ''),
          };

          userActions.showLoader();

          actions.addVisaCard({ data }).then(() => {
            const { isShowAddCardModalError } = this.props;

            if (!isShowAddCardModalError) {
              this.setState({
                isShowAddCardModal: false,
                showMainLoader: false,
                cardNumber: '',
                cardNumberError: '',
              });

              actions.closeModal();

              actions.getVisaPaymentCard();
            }
          });
        }
      }
    }
  }

  deleteVisaCard(cardId, cardNumberSuffix) {
    const { actions } = this.props;
    const { isShowConfirmation } = this.state;

    if (isShowConfirmation) {
      actions.deleteVisaCard(cardId).then(() => {
        actions.getVisaPaymentCard();

        this.setState({ isShowConfirmation: false, isUnlinkCard: false });
      });
    } else {
      this.setState({
        isShowConfirmation: true,
        isUnlinkCard: true,
        cardNumberSuffix,
        currentCardId: cardId,
      });
    }
  }

  showVisaAllBanks(e) {
    e.persist();

    e.preventDefault();

    this.setState({
      isShowAllVisaBanks: true,
    });
  }

  toggleFastlinkModal() {
    const { isFastlinkModalOpen } = this.state;
    const { actions } = this.props;

    actions.setFastlinkFlow(FASTLINK_FLOWS.add);

    this.setState({
      isFastlinkModalOpen: !isFastlinkModalOpen,
    });
  }

  changeSearchValue(searchValue) {
    this.setState({
      searchValue,
    });
  }

  render() {
    const {
      isShowBankModal,
      isShowBankModalError,
      bank,
      isShowModalError,
      isShowConfirmation,
      isRoundUpsModalOpen,
      showMainLoader,
      bankCurrentTarget,
      isShowBankCardModal,
      cardMonth,
      cardYear,
      cardCvv,
      isShowPassword,
      cardNumberError,
      isFirstStep2factor,
      isShowAddCardModal,
      isShowAllVisaBanks,
      isUnlinkCard,
      cardNumberSuffix,
      currentCardId,
      isFastlinkModalOpen,
      searchValue,
    } = this.state;

    const {
      state,
      isShowBankModalAlert,
      errorMessage,
      errorTitle,
      monitoredInstitutions,
      isShowConnect,
      isShowBanks,
      moreBanks,
      isShowMoreBanks,
      showLoaderFunding,
      paymentCard,
      spendingPaymentCards,
      isShowMfaField,
      visaPaymentCards,
      isShowAddCardModalError,
      isFundingErrorVisible,
      isRoundUpRefreshing,
      visaEnabled,
      isInstitutionsApiEnabled,
      isBankAccountLinkingStep,
    } = this.props;

    return (
      <SpendingAccountView
        showLoader={showLoaderFunding}
        showMainLoader={showMainLoader}
        showBanks={this.showBanks}
        isShowBanks={isShowBanks}
        signInBank={this.signInBank}
        isShowBankModal={isShowBankModal}
        handleCloseModal={this.handleCloseModal}
        bank={bank}
        bankInSubmit={this.bankInSubmit}
        handleChangeBank={this.handleChangeBank}
        credentials={state.fundingAccount.credentials}
        handleStopBankAccountLinking={this.handleStopBankAccountLinking}
        bankLoginProcessWait={this.bankLoginProcessWait}
        isShowBankModalAlert={isShowBankModalAlert}
        errorMessage={errorMessage}
        errorTitle={errorTitle}
        handleCloseModalError={this.handleCloseModalError}
        isShowBankModalError={isShowBankModalError}
        monitoredInstitutions={monitoredInstitutions}
        handleSubAccount={this.handleSubAccount}
        isShowModalError={isShowModalError}
        showBanksFromSubAccount={this.showBanksFromSubAccount}
        deleteAccount={this.deleteAccount}
        isShowConfirmation={isShowConfirmation}
        fixAccount={this.fixAccount}
        isShowConnect={isShowConnect}
        connectAccount={this.connectAccount}
        showMoreBanks={this.showMoreBanks}
        moreBanks={moreBanks}
        isShowMoreBanks={isShowMoreBanks}
        isRoundUpsModalOpen={isRoundUpsModalOpen}
        roundUpModalOpen={this.roundUpModalOpen}
        bankCurrentTarget={bankCurrentTarget}
        isShowBankCardModal={isShowBankCardModal}
        openBankCardModal={this.openBankCardModal}
        cardMonth={cardMonth}
        cardYear={cardYear}
        cardCvv={cardCvv}
        changeDate={this.changeDate}
        linkCard={this.linkCard}
        toggleShowPassword={this.toggleShowPassword}
        isShowPassword={isShowPassword}
        handleChangeCardNumber={this.handleChangeCardNumber}
        cardNumberError={cardNumberError}
        paymentCard={paymentCard}
        // accountChangeBankCard={this.accountChangeBankCard}
        spendingPaymentCards={spendingPaymentCards}
        unlinkSpendingCard={this.unlinkSpendingCard}
        isFirstStep2factor={isFirstStep2factor}
        isShowMfaField={isShowMfaField}
        checkMfa={this.checkMfa}
        showAddCardModal={this.showAddCardModal}
        isShowAddCardModal={isShowAddCardModal}
        addCard={this.addCard}
        visaPaymentCards={visaPaymentCards}
        deleteVisaCard={this.deleteVisaCard}
        showVisaAllBanks={this.showVisaAllBanks}
        isShowAllVisaBanks={isShowAllVisaBanks}
        isShowAddCardModalError={isShowAddCardModalError}
        isUnlinkCard={isUnlinkCard}
        cardNumberSuffix={cardNumberSuffix}
        currentCardId={currentCardId}
        visaEnabled={visaEnabled}
        isRoundUpRefreshing={isRoundUpRefreshing}
        isFundingErrorVisible={isFundingErrorVisible}
        isInstitutionsApiEnabled={isInstitutionsApiEnabled}
        toggleFastlinkModal={this.toggleFastlinkModal}
        isFastlinkModalOpen={isFastlinkModalOpen}
        isBankAccountLinkingStep={isBankAccountLinkingStep}
        changeSearchValue={this.changeSearchValue}
        searchValue={searchValue}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  // accountData: state.fundingAccount.accountData,
  errorMessage: state.fundingAccount.errorMessage,
  errorTitle: state.fundingAccount.errorTitle,
  credentials: state.fundingAccount.credentials,
  isShowBankModalAlert: state.fundingAccount.isShowBankModalAlert,
  skipBankLogin: state.fundingAccount.skipBankLogin,
  monitoredInstitutions: state.fundingAccount.monitoredInstitutions,
  isShowConnect: state.fundingAccount.isShowConnect,
  isShowBanks: state.fundingAccount.isShowBanks,
  moreBanks: state.fundingAccount.moreBanks,
  isShowMoreBanks: state.fundingAccount.isShowMoreBanks,
  addListener: state.fundingAccount.addListener,
  showLoaderFunding: state.user.showLoaderFunding,
  isShowCreditCardError: state.fundingAccount.isShowCreditCardError,
  paymentCard: state.fundingAccount.paymentCard,
  spendingPaymentCards: state.fundingAccount.spendingPaymentCards,
  switchCardSuccess: state.fundingAccount.switchCardSuccess,
  isShowMfaField: state.fundingAccount.isShowMfaField,
  otpData: state.fundingAccount.otpData,
  user: state.user.user,
  visaPaymentCards: state.fundingAccount.visaPaymentCards,
  isShowAddCardModalError: state.fundingAccount.isShowAddCardModalError,
  refreshSessionId: state.fundingAccount.refreshSessionId,
  isFundingErrorVisible: state.fundingAccount.isFundingErrorVisible,
  isRoundUpRefreshing: state.fundingAccount.isRoundUpRefreshing,
  banksInstitutions: state.fundingAccount.banksInstitutions,
  visaEnabled: selectIsVisaEnabled(state),
  isInstitutionsApiEnabled: selectIsSpendingInstitutionsEnabled(state),
  isBankAccountLinkingStep: selectIsBankAccountLinkingStep(state),
  isYodleeFastlinkEnabled: selectIsYodleeFastlinkEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(fundingAccountAction, dispatch),
  userActions: bindActionCreators(actionsUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpendingAccount);

SpendingAccount.defaultProps = {
  // accountData: {},
  state: {},
  isShowBankModalAlert: false,
  skipBankLogin: false,
  errorMessage: '',
  errorTitle: '',
  monitoredInstitutions: [],
  isShowConnect: false,
  isShowBanks: false,
  moreBanks: [{}],
  isShowMoreBanks: false,
  addListener: false,
  showLoaderFunding: false,
  isShowCreditCardError: false,
  paymentCard: {},
  spendingPaymentCards: {},
  isShowMfaField: false,
  otpData: {},
  user: {},
  visaPaymentCards: {},
  isShowAddCardModalError: false,
  refreshSessionId: '',
  isFundingErrorVisible: false,
  isRoundUpRefreshing: false,
  banksInstitutions: {},
  visaEnabled: false,
  isInstitutionsApiEnabled: false,
  isYodleeFastlinkEnabled: false,
};

SpendingAccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  actions: PropTypes.shape({
    addPaymentCard: PropTypes.func,
    getPaymentCard: PropTypes.func,
    getBanks: PropTypes.func,
    resetProps: PropTypes.func,
    connectAccount: PropTypes.func,
    bankSignIn: PropTypes.func,
    getBanksList: PropTypes.func,
    showWaitPopUpBankLogin: PropTypes.func,
    resetMfaField: PropTypes.func,
    subAccountLinkUnlink: PropTypes.func,
    waitingInProcess: PropTypes.func,
    deleteAccount: PropTypes.func,
    unlinkSpendingCard: PropTypes.func,
    stopLoginRequest: PropTypes.func,
    getVisaPaymentCard: PropTypes.func,
    addVisaCard: PropTypes.func,
    closeModal: PropTypes.func,
    deleteVisaCard: PropTypes.func,
    initiateRefreshRoundUp: PropTypes.func,
    refreshRoundUp: PropTypes.func,
    setCurrentBank: PropTypes.func,
    setFastlinkFlow: PropTypes.func,
  }).isRequired,
  state: PropTypes.shape({
    form: PropTypes.shape({
      BANK_SIGN_IN: PropTypes.string,
    }),
    fundingAccount: PropTypes.shape({
      credentials: PropTypes.shape({
        LOGIN: PropTypes.string,
        PASSWORD: PropTypes.string,
      }),
      banks: PropTypes.shape({
        banks: PropTypes.arrayOf(PropTypes.shape({})),
        institutions: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      banksInstitutions: PropTypes.shape({
        institutions: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      institutions: PropTypes.arrayOf(PropTypes.shape({})),
      moreBanks: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  isShowBankModalAlert: PropTypes.bool,
  skipBankLogin: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorTitle: PropTypes.string,
  monitoredInstitutions: PropTypes.arrayOf(
    PropTypes.shape({
      additional_status: PropTypes.shape({
        additionalStatus: PropTypes.string,
        updateEligibility: PropTypes.string,
      }),
    }),
  ),
  isShowConnect: PropTypes.bool,
  isShowBanks: PropTypes.bool,
  moreBanks: PropTypes.arrayOf(PropTypes.shape({})),
  isShowMoreBanks: PropTypes.bool,
  addListener: PropTypes.bool,
  showLoaderFunding: PropTypes.bool,
  userActions: PropTypes.shape({
    showLoader: PropTypes.func,
  }).isRequired,
  isShowCreditCardError: PropTypes.bool,
  paymentCard: PropTypes.shape({}),
  spendingPaymentCards: PropTypes.shape({}),
  isShowMfaField: PropTypes.bool,
  otpData: PropTypes.shape({}),
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
  visaPaymentCards: PropTypes.shape({}),
  isShowAddCardModalError: PropTypes.bool,
  refreshSessionId: PropTypes.string,
  isFundingErrorVisible: PropTypes.bool,
  isRoundUpRefreshing: PropTypes.bool,
  banksInstitutions: PropTypes.shape({
    institutions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  visaEnabled: PropTypes.bool,
  isInstitutionsApiEnabled: PropTypes.bool,
  isBankAccountLinkingStep: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isYodleeFastlinkEnabled: PropTypes.bool,
};
