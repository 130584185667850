import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import { AU_APP, MY_APP } from '../../../constants/localeConfigs';
import { checkIsDependentUser } from '../../../utils/user';
import { checkIsUserWithInvestAccess } from '../utils';

import { DataSharingIcon, RoundUpsIcon } from './styles';

import RoundUpsModal from 'components/layout/Modals/RoundUpsModal/RoundUpsModal';
import { PATHS } from 'constants/paths';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsYodleeFastlinkEnabled } from 'store/firebaseConfig/selectors';
import { FastlinkModal } from 'components/modals/Fastlink/FastlinkModal';
import { PROVIDER_TYPES } from 'store/yodlee/consts';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setFastlinkFlow } from 'store/fundingAccount/actions';
import { FASTLINK_FLOWS } from 'components/modals/Fastlink/consts';
import { selectIsUserHasFastlink } from 'store/user/selectors';

export default function ProfileMenu({ checkToggle, user }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const activeSettings =
    window.location.pathname.indexOf('/settings') !== -1 ||
    window.location.pathname === '/statements';

  const isMalaysiaNotKidUser = MY_APP && !checkIsDependentUser(user);
  const [isRoundUpsModalOpen, setIsRoundUpsModalOpen] = useState(false);
  const [isFastlinkModalOpen, setIsFastlinkModalOpen] = useState(false);
  const isYodleeFastlinkEnabled = useAppSelector(selectIsYodleeFastlinkEnabled);
  // For a new user we display the fastlink flow if it is enabled in the remote config, but if the user has connected the account via fastlink once, we should always display the manage consent tab for him, we get this value through the API.
  const isUserHasFastlink = useAppSelector(selectIsUserHasFastlink);

  const handleOpenRoundUpsModal = (isOpen) => {
    setIsRoundUpsModalOpen(isOpen);
  };

  return (
    <div
      className={`page-menu -sub-menu -green ${
        activeSettings ? '-active' : ''
      }`}
    >
      {((AU_APP && !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser)) ||
        !AU_APP) && (
        <NavLink
          to={generatePath(PATHS.settings.profile)}
          className={({ isActive }) =>
            isActive ? 'page-menu__item -active' : 'page-menu__item'
          }
          end
          onClick={checkToggle}
        >
          <i className="page-menu__item-icon -profile" />

          {t('settingsPage.profileMenu.title')}
        </NavLink>
      )}

      {AU_APP && !checkIsDependentUser(user) && (
        <NavLink
          to="/settings/funding-account"
          className={({ isActive }) =>
            isActive ? 'page-menu__item -active' : 'page-menu__item'
          }
          onClick={checkToggle}
        >
          <i className="page-menu__item-icon -funding" />

          {t('settingsPage.fundingAccount.title')}
        </NavLink>
      )}

      {checkIsUserWithInvestAccess({ user }) && (
        <NavLink
          to="/settings/spending-account"
          className={({ isActive }) =>
            isActive ? 'page-menu__item -active' : 'page-menu__item'
          }
          onClick={checkToggle}
        >
          <i className="page-menu__item-icon -spending" />

          {t('settingsPage.profileMenu.buttons.roundUpAccount')}
        </NavLink>
      )}

      {AU_APP && checkIsUserWithInvestAccess({ user }) && (
        <NavLink
          className="page-menu__item"
          onClick={() => handleOpenRoundUpsModal(true)}
        >
          <RoundUpsIcon icon="roundUps" size={18} />

          {t('settingsPage.profileMenu.roundUpSettings')}
        </NavLink>
      )}

      {(isYodleeFastlinkEnabled || isUserHasFastlink) && (
        <NavLink
          className="page-menu__item"
          onClick={() => {
            dispatch(setFastlinkFlow(FASTLINK_FLOWS.manageConsent));

            setIsFastlinkModalOpen(true);
          }}
        >
          <DataSharingIcon icon="dataSharing" size={20} />

          {t('settingsPage.profileMenu.buttons.dataSharing')}
        </NavLink>
      )}

      {AU_APP && sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) && (
        <NavLink
          to="/settings/smsf-account"
          className={({ isActive }) =>
            isActive ? 'page-menu__item -active' : 'page-menu__item'
          }
          onClick={checkToggle}
        >
          <i className="page-menu__item-icon -smsf" />

          {t('settingsPage.profileMenu.buttons.smsfAccount')}
        </NavLink>
      )}

      {isMalaysiaNotKidUser && (
        <NavLink
          to="/settings/funding-account"
          className={({ isActive }) =>
            isActive ? 'page-menu__item -active' : 'page-menu__item'
          }
          onClick={checkToggle}
        >
          <i className="page-menu__item-icon -funding" />

          {t('settingsPage.profileMenu.buttons.fundingAccount')}
        </NavLink>
      )}

      {isMalaysiaNotKidUser && (
        <NavLink
          to="/settings/withdrawal-account"
          className={({ isActive }) =>
            isActive ? 'page-menu__item -active' : 'page-menu__item'
          }
          onClick={checkToggle}
        >
          <i className="page-menu__item-icon -withdrawal" />

          {t('settingsPage.profileMenu.buttons.withdrawalAccount')}
        </NavLink>
      )}

      <NavLink
        to="/settings/change-password"
        className={({ isActive }) =>
          isActive ? 'page-menu__item -active' : 'page-menu__item'
        }
        onClick={checkToggle}
      >
        <i className="page-menu__item-icon -pass-change" />

        {t('settingsPage.profileMenu.buttons.changePassword')}
      </NavLink>

      <NavLink
        to="/statements"
        className={({ isActive }) =>
          isActive ? 'page-menu__item -active' : 'page-menu__item'
        }
        onClick={checkToggle}
      >
        <i className="page-menu__item-icon -statements" />

        {t('settingsPage.profileMenu.statements')}
      </NavLink>

      <NavLink
        to="/settings/support"
        className={({ isActive }) =>
          isActive ? 'page-menu__item -active' : 'page-menu__item'
        }
        onClick={checkToggle}
      >
        <i className="page-menu__item-icon -support" />

        {t('settingsPage.profileMenu.buttons.support')}
      </NavLink>

      {isRoundUpsModalOpen && (
        <RoundUpsModal
          isRoundUpsModalOpen={isRoundUpsModalOpen}
          handleOpenRoundUpsModal={handleOpenRoundUpsModal}
        />
      )}

      {isFastlinkModalOpen && (
        <FastlinkModal
          isFastlinkModalOpen={isFastlinkModalOpen}
          toggleFastlinkModal={() =>
            setIsFastlinkModalOpen(!isFastlinkModalOpen)
          }
          type={PROVIDER_TYPES.spending}
        />
      )}
    </div>
  );
}

ProfileMenu.propTypes = {
  user: PropTypes.shape({
    user: PropTypes.shape({
      user_type: PropTypes.string,
    }),
    user_type: PropTypes.string,
  }).isRequired,
  checkToggle: PropTypes.func.isRequired,
};
