import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChangeBasePortfolioFooter from './components/ChangeBasePortfolioFooter';

import { useIsSuperRegistration } from 'store/user/hooks/useIsSuperRegistration';
import HelpCardsModalAU from 'components/layout/Modals/HelpCardsModalAU';
import ChangePortfolio from 'pages/ChangePortfolio/ChangePortfolio';
import { usePortfolioColor } from 'pages/Portfolio/hooks';
import {
  PortfolioHelpCardButton,
  PortfolioTitle,
  PortfolioTitleWrapper,
  PortfolioWrapper,
} from 'pages/Portfolio/styles';
import { PORTFOLIO } from 'constants/comonConstants';
import PortfolioBackButton from 'pages/Portfolio/components/PortfolioBackButton/PortfolioBackButton';
import { IconButton } from 'components/elements/IconButton';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectPortfolioLocation,
  selectPortfolioParams,
} from 'store/portfolio/selectors';

export default function ChangeBasePortfolio() {
  const { t } = useTranslation();
  const { childId, isSuper } = useAppSelector(selectPortfolioParams);

  const isSuperRegistration = useIsSuperRegistration();

  const { isRegistration } = useAppSelector(selectPortfolioLocation);
  const { portfolioColor } = usePortfolioColor();
  const [isHelpCardOpen, setIsHelpCardOpen] = useState(false);

  return (
    <>
      <PortfolioWrapper portfolioColor={portfolioColor}>
        <PortfolioTitleWrapper>
          <PortfolioBackButton />

          <PortfolioTitle isRegistration={isRegistration}>
            {t('portfolioCustomization.tabs.basePortfolioPage.title')}
          </PortfolioTitle>
        </PortfolioTitleWrapper>

        {!isRegistration && (
          <PortfolioHelpCardButton>
            <IconButton
              icon="helpWhite"
              onClick={() => setIsHelpCardOpen(true)}
              size={28}
            />
          </PortfolioHelpCardButton>
        )}

        <ChangePortfolio
          isEditKids={!!childId}
          isRegistration={isRegistration}
          isChangeBasePortfolioPage
          isSuper={isSuper}
          isSuperRegistration={isSuperRegistration}
        />
      </PortfolioWrapper>

      <ChangeBasePortfolioFooter />

      {!isRegistration && (
        <HelpCardsModalAU
          isOpenHelpCards={isHelpCardOpen}
          currentPage={PORTFOLIO}
          toggleHelpCard={setIsHelpCardOpen}
        />
      )}
    </>
  );
}
