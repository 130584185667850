import { CUSTOM_HEADER, USER_TYPE } from 'services/api/consts';

interface IGetSuperPortfolioHeadersProps {
  isSuper?: boolean;
}

export const getSuperPortfolioHeaders = ({
  isSuper,
}: IGetSuperPortfolioHeadersProps) => {
  if (!isSuper) {
    return {};
  }

  return {
    [CUSTOM_HEADER.userType]: USER_TYPE.superAnnuation,
  };
};
