import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import getTransactionNameObject from '../utils/getTransactionNameObject';
import {
  CategoryDescription,
  CategoryStatus,
  CategoryTitle,
  CategoryWrapper,
  EditIcon,
  HistoryDateWrapper,
} from '../styles';
import {
  IAllCategoryDetails,
  IToggleCategorisationInfo,
  ITransactionCategorisation,
} from '../types';

import { CREDIT } from 'constants/comonConstants';
import { formatNumber } from 'utils/formatters/formatNumber';
import { DATE_FORMAT } from 'config/date';

interface ITransactionInformationProps {
  transaction: ITransactionCategorisation;
  previous: ITransactionCategorisation;
  toggleCategorisationInfo: IToggleCategorisationInfo;
  types: IAllCategoryDetails[];
}

const TransactionInformation = ({
  transaction,
  previous,
  toggleCategorisationInfo,
  types,
}: ITransactionInformationProps) => {
  const { t } = useTranslation();

  const customDescription = 'custom_description';

  const transactionNameField = transaction.custom_fields.find(
    (field) => field.name === customDescription,
  );

  const transactionNameObject = transactionNameField
    ? getTransactionNameObject({ types, value: transactionNameField.value })
    : null;

  const transactionName =
    transactionNameObject?.name ||
    t('transactionCategorisationPage.uncategorised');

  const transactionAmount = transaction.amount;
  const previousDate = previous?.date || '';

  const handleToggleCategorisationInfo = useCallback(
    () =>
      toggleCategorisationInfo({
        condition: true,
        transactionAmount,
        transactionDescription: transaction.description,
        transactionMovement: transaction.movement,
        transactionName,
        transactionCategory: transactionNameObject?.parentCategory || '',
        transaction,
      }),
    [
      toggleCategorisationInfo,
      transactionAmount,
      transaction,
      transactionName,
      transactionNameObject,
    ],
  );

  return (
    <div key={window.crypto.randomUUID()}>
      {previousDate !== transaction.date && (
        <HistoryDateWrapper>
          {dayjs(transaction.date).format(DATE_FORMAT)}
        </HistoryDateWrapper>
      )}

      <CategoryWrapper
        onClick={handleToggleCategorisationInfo}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        <CategoryTitle>
          <CategoryStatus>
            <EditIcon icon="edit" size={16} />

            {transactionName}
          </CategoryStatus>

          <div>
            {transaction.movement === CREDIT ? '+' : '-'}

            {formatNumber({ value: transactionAmount })}
          </div>
        </CategoryTitle>

        <CategoryDescription>{transaction.description}</CategoryDescription>
      </CategoryWrapper>
    </div>
  );
};

export default TransactionInformation;
