import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IBankAccountConnectionStatusProps } from '../types';
import { BANK_LINKING_STATUS } from '../consts';

import {
  useInstitutionByIdQuery,
  useGetLinkStatesQuery,
} from 'store/fundingAccount/fundingAccount';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getBanks,
  resetProps,
  setIsBankAccountLinkingStep,
  setIsShowConnectModal,
  setIsShowConnectStep,
} from 'store/fundingAccount/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { selectBankAccountData } from 'store/fundingAccount/selectors';
import { IBankAccountData } from 'store/fundingAccount/types';
import { ACCOUNT_CONNECTION_INTERVAL } from 'components/modals/Fastlink/consts';
import { toggleInfoModal } from 'store/modals/actions';

export const useBankAccountLinkingStatus = ({
  onUnmount,
  linkAccountLater,
  isSpending,
  isRegistration,
}: Pick<
  IBankAccountConnectionStatusProps,
  'onUnmount' | 'linkAccountLater' | 'isSpending' | 'isRegistration'
>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data: institutionsLinkStates = [] } = useGetLinkStatesQuery();

  const bankAccountData = useAppSelector(
    selectBankAccountData,
  ) as IBankAccountData;

  const params = isSpending
    ? bankAccountData
    : {
        ...bankAccountData,
        additionalParams: {
          funding_source: 1,
        },
      };

  const { data } = useInstitutionByIdQuery(params, {
    pollingInterval: ACCOUNT_CONNECTION_INTERVAL,
  });

  const currentStatusData = data?.monitored_institution?.link_state;

  const indexOfCurrentState = useMemo(
    () =>
      institutionsLinkStates.findIndex(
        ({ state }) => state === currentStatusData?.status,
      ),
    [currentStatusData?.status, institutionsLinkStates],
  );

  const isLinked = currentStatusData?.status === BANK_LINKING_STATUS.completed;

  useEffect(() => {
    if (isLinked) {
      dispatch(setIsBankAccountLinkingStep(false));

      if (
        !isSpending &&
        data?.monitored_institution?.subaccounts?.length === 0
      ) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              title: t(
                'registrationPage.bankAccountConnectionStatus.errorModal.title',
              ),
              description: t(
                'registrationPage.bankAccountConnectionStatus.errorModal.description',
                {
                  bankName: data?.monitored_institution?.name,
                },
              ),
            },
          }),
        );
      } else if (isRegistration) {
        dispatch(
          setIsShowConnectModal({ isShowConnectModal: true, isSpending }),
        );
      } else {
        dispatch(setIsShowConnectStep(true));

        dispatch(resetProps());
      }
    }
  }, [
    onUnmount,
    linkAccountLater,
    dispatch,
    isLinked,
    isSpending,
    isRegistration,
    data?.monitored_institution,
    t,
  ]);

  useEffect(
    () => () => {
      if (!isRegistration) {
        dispatch(getBanks());
      }

      onUnmount();
    },
    [dispatch, isRegistration, onUnmount],
  );

  return {
    indexOfCurrentState,
    institutionsLinkStates,
    isLinked,
  };
};
