import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InView } from 'react-intersection-observer';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation, Trans } from 'react-i18next';

import Input from '../../../components/elements/Input/Input';
import Loader from '../../../components/elements/Loader/Loader';
import ChangeLocationModal from '../../../components/layout/Modals/ChangeLocationModal';

import CategoriesView from './CategoriesView';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function offerRender(offerData, t) {
  const {
    offer,
    isInstore,
    toggleLikeOffer,
    isShowFavourite,
    isLastOffer,
    setInView,
  } = offerData;

  let distance = '';

  if (
    isInstore &&
    offer.locations &&
    offer.locations[0] &&
    offer.locations[0].distance
  ) {
    if (offer.locations[0].distance < 1) {
      distance = (
        <span>
          {parseInt(offer.locations[0].distance * 1000)}

          {t('common.metric.meter')}
        </span>
      );
    } else if (isInstore) {
      distance = (
        <span>
          {parseFloat(offer.locations[0].distance).toFixed(1)}

          {t('common.metric.kilometerShort')}
        </span>
      );
    }
  }

  return (
    <div className="rewards-block" key={isInstore ? offer.offerId : offer.id}>
      <Link
        to={`/rewards/${!isInstore || offer.isOnline ? 'online' : 'in-store'}/${
          isInstore ? offer.offerId : offer.id
        }`}
      >
        <div className="grid__container-item">
          <div className="grid__container-image">
            <div
              className="grid__container-logo"
              style={{
                background: `url(${
                  isInstore ? offer.coverShotUrl : offer.coverArt
                }) no-repeat`,
                backgroundSize: 'cover',
              }}
            />
          </div>

          <div className="grid__container-bottom-part">
            <div className="brand-icon-placeholder" />

            <div
              className="brand-icon"
              style={{
                background: `url(${
                  isInstore ? offer.logoUrl : offer.logo
                }) no-repeat`,
                backgroundSize: 'cover',
              }}
            />

            <div className="brand-title-block">
              <div>{isInstore ? offer.advertiserName : offer.advertiser}</div>

              <div>{isInstore ? offer.offerTitle : offer.title}</div>
            </div>

            <div className="distance">
              <div className="marker-icon">
                <div>{distance}</div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div
        className={
          offer.isFavourite || isShowFavourite
            ? 'like-instore'
            : 'unlike-instore'
        }
        onClick={() => {
          toggleLikeOffer({
            isFavourite: offer.isFavourite || isShowFavourite,
            id: isInstore ? offer.offerId : offer.id,
            isInstore: isShowFavourite ? !offer.isOnline : isInstore,
            isShowFavourite,
          });
        }}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      />

      {!isLastOffer && <InView onChange={setInView} />}
    </div>
  );
}

export default function RewardsSearchView({
  isInstore,
  toggleInstoreList,
  instoreOffers,
  categories,
  searchHandler,
  clearSearch,
  searchValue,
  filterCategory,
  onlineOffers,
  categoryId,
  categoryName,
  isEmptySearch,
  toggleLikeOffer,
  favourites,
  isShowFavourite,
  showMainLoader,
  isInStoreRewards,
  address,
  openChangeLocation,
  isShowChangeLocation,
  setInView,
}) {
  const { t } = useTranslation();

  return (
    <div className="in-store rewards-search">
      <div className="profile-content settings__content-default-container">
        {!categoryId && !isInStoreRewards && (
          <div className="grid__title search-page">
            {!isShowFavourite && (
              <div className="search-content">
                <Input
                  onChange={searchHandler}
                  autoFocus={window.innerWidth > 768}
                  type="text"
                  input={{ value: searchValue }}
                  placeholder={t(
                    'newRewardsPage.rewardSearch.searchPlaceholder',
                  )}
                  classList="grid__input"
                />
              </div>
            )}

            {!isShowFavourite && (
              <div
                onClick={clearSearch}
                className="clear-search"
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              />
            )}
          </div>
        )}

        {(categoryId || !isEmptySearch) && (
          <div>
            <div className="profile-content__header -with-navigation ">
              <div className="profile-content__title">
                {categoryName !== '' && <div>{categoryName}</div>}
              </div>

              <div className="profile-content__navigation">
                <DeprecatedButton
                  title={t('newRewardsPage.rewardSearch.online')}
                  buttonClass={!isInstore ? 'active' : ''}
                  handleClick={() => toggleInstoreList(false)}
                />

                <DeprecatedButton
                  title={t('newRewardsPage.rewardSearch.inStore')}
                  buttonClass={isInstore ? 'active' : ''}
                  handleClick={() => toggleInstoreList(true)}
                />
              </div>

              {!isShowFavourite && (isInStoreRewards || categoryId) && (
                <div className="grid__title">
                  <div className="search-content">
                    <Input
                      onChange={searchHandler}
                      autoFocus={window.innerWidth > 768}
                      // defaultValue={searchValue}
                      type="text"
                      input={{ value: searchValue }}
                      placeholder={t(
                        'newRewardsPage.rewardSearch.searchPlaceholder',
                      )}
                      classList="grid__input"
                    />
                  </div>

                  <div
                    onClick={clearSearch}
                    className="clear-search"
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                  />
                </div>
              )}
            </div>

            {!isShowFavourite && instoreOffers && isInstore && categories && (
              <div className="profile-content__container">
                <div className="grid">
                  <div className="grid__categories">
                    <div className="change-location">
                      <div className="panel">
                        <div className="label">
                          {t('newRewardsPage.rewardSearch.offersNear')}
                        </div>

                        <div className="address">{address}</div>

                        <div
                          onClick={() => openChangeLocation(true)}
                          onKeyPress={() => {}}
                          role="button"
                          tabIndex="0"
                          className="change"
                        >
                          {t('newRewardsPage.rewardSearch.change')}
                        </div>
                      </div>
                    </div>

                    <div className="grid__container" id="rewards-instore-items">
                      {!showMainLoader &&
                        instoreOffers &&
                        instoreOffers.records &&
                        instoreOffers.records.length !== 0 &&
                        instoreOffers.records.map((offer, index) =>
                          offerRender(
                            {
                              offer,
                              isInstore,
                              toggleLikeOffer,
                              isLastOffer: instoreOffers.records[index + 1],
                              setInView,
                            },
                            t,
                          ),
                        )}

                      {showMainLoader && (
                        <Loader additionalClass="-absolute -top" />
                      )}

                      {!showMainLoader &&
                        instoreOffers &&
                        instoreOffers.records &&
                        instoreOffers.records.length === 0 && (
                          <div className="found-block">
                            <div className="icon-not-found" />

                            <div className="not-found">
                              <div>
                                {t(
                                  'newRewardsPage.rewardSearch.noResultsFound',
                                )}
                              </div>

                              <div>
                                {t(
                                  'newRewardsPage.rewardSearch.switchingToOnline',
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isShowFavourite && onlineOffers && !isInstore && categories && (
              <div className="profile-content__container">
                <div className="grid">
                  <div className="grid__categories">
                    <div className="grid__container" id="rewards-online-items">
                      {!showMainLoader &&
                        onlineOffers &&
                        onlineOffers.records &&
                        onlineOffers.records.length !== 0 &&
                        onlineOffers.records.map((offer, index) =>
                          offerRender(
                            {
                              offer,
                              isInstore,
                              toggleLikeOffer,
                              isLastOffer: onlineOffers.records[index + 1],
                              setInView,
                            },
                            t,
                          ),
                        )}

                      {showMainLoader && (
                        <Loader additionalClass="-absolute -top" />
                      )}

                      {!showMainLoader &&
                        onlineOffers &&
                        onlineOffers.records &&
                        onlineOffers.records.length === 0 && (
                          <div className="found-block">
                            <div className="icon-not-found" />

                            <div className="not-found">
                              <div>
                                {t(
                                  'newRewardsPage.rewardSearch.noResultsFound',
                                )}
                              </div>

                              <div>
                                {t(
                                  'newRewardsPage.rewardSearch.switchingToStore',
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isShowFavourite &&
              favourites &&
              (favourites.online || favourites.instore) && (
                <div className="profile-content__container">
                  <div className="grid">
                    <div className="grid__categories">
                      <div
                        className="grid__container"
                        id="rewards-instore-items"
                      >
                        {!showMainLoader &&
                          !isInstore &&
                          favourites.online.length !== 0 &&
                          favourites.online.map((offer) =>
                            offerRender(
                              {
                                offer,
                                isInstore: true,
                                toggleLikeOffer,
                                isShowFavourite,
                              },
                              t,
                            ),
                          )}

                        {!showMainLoader &&
                          isInstore &&
                          favourites.instore.length !== 0 &&
                          favourites.instore.map((offer) =>
                            offerRender(
                              {
                                offer,
                                isInstore: true,
                                toggleLikeOffer,
                                isShowFavourite,
                              },
                              t,
                            ),
                          )}

                        {showMainLoader && (
                          <Loader additionalClass="-absolute -top" />
                        )}

                        {!showMainLoader &&
                          ((!isInstore &&
                            favourites.online &&
                            favourites.online.length === 0) ||
                            (isInstore &&
                              favourites.instore &&
                              favourites.instore.length === 0)) && (
                            <div className="found-block">
                              <div className="icon-not-found-favorite" />

                              <div className="not-found">
                                <div>
                                  <Trans
                                    i18nKey="newRewardsPage.rewardSearch.noFavourite"
                                    t={t}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}

        {!categoryId &&
          isEmptySearch &&
          !isShowFavourite &&
          categories &&
          !showMainLoader && (
            <div className="profile-content__container empty-search">
              <div className="grid">
                <div className="grid__categories">
                  <div className="grid__title">
                    {t('newRewardsPage.rewardSearch.categories')}
                  </div>

                  <CategoriesView
                    categories={categories.categories}
                    filterCategory={filterCategory}
                    withCarusel={false}
                  />
                </div>
              </div>
            </div>
          )}

        {isShowChangeLocation && (
          <ChangeLocationModal
            isShowChangeLocation={isShowChangeLocation}
            close={openChangeLocation}
            addressSave={address}
            isMap={false}
          />
        )}
      </div>
    </div>
  );
}

RewardsSearchView.propTypes = {
  isInstore: PropTypes.bool.isRequired,
  instoreOffers: PropTypes.shape({
    records: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  categories: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  toggleInstoreList: PropTypes.func.isRequired,
  searchHandler: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  filterCategory: PropTypes.func.isRequired,
  onlineOffers: PropTypes.shape({
    records: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  categoryId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  isEmptySearch: PropTypes.bool.isRequired,
  toggleLikeOffer: PropTypes.func.isRequired,
  favourites: PropTypes.shape({
    online: PropTypes.arrayOf(PropTypes.shape({})),
    instore: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isShowFavourite: PropTypes.bool.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  isInStoreRewards: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  openChangeLocation: PropTypes.func.isRequired,
  isShowChangeLocation: PropTypes.bool.isRequired,
  setInView: PropTypes.func.isRequired,
};
