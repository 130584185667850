import styled from '@emotion/styled';

import {
  CustomizationFooterButton,
  FundsListWrapper,
} from '../FundsList/styles';

import placeholderFund from 'assets/images/icons/placeholder-fund.svg';

export const FundDetailsWrapper = styled(FundsListWrapper)`
  background: ${({ theme }) => theme.colors.wildSand};
  border-radius: ${({ isFooterVisible }) =>
    isFooterVisible ? 0 : '0 0 10px 10px'};
  padding: 16px;
  position: relative;
  margin: 0 0 100px 0;
`;

export const HeaderWrapper = styled.div`
  text-align: left;
  margin-bottom: 22px;
`;

export const HeaderImage = styled.div`
  background: ${({ theme, background }) =>
    background
      ? `url(${background}) ${theme.colors.white} no-repeat center center / contain`
      : `url(${placeholderFund}) ${theme.colors.white} no-repeat center center / auto`};
  width: 104px;
  height: 104px;
  margin-top: -68px;
  border-radius: 100%;
  box-shadow: ${({ theme }) => theme.shadows.header};
  border: 16px solid ${({ theme }) => theme.colors.white};
`;

export const HeaderTitle = styled.div`
  margin-top: 14px;
  font-size: ${({ theme }) => theme.fontSizes['6md']};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.mirage};
`;

export const HeaderDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['6sm']};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.mirage};
`;

export const FundDetailsFooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.primary};
  padding: 18px 32px;
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    padding: 16px;
  }
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};
  position: sticky;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 24px;
  text-align: left;
  right: 0;
  width: 100%;
`;

export const EditFundWrapper = styled.div`
  padding-top: 5px;
  float: left;
  margin-right: 20px;
`;

export const FooterButton = styled(CustomizationFooterButton)`
  float: right;
`;
