import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import Firebase from 'firebase/app';
import dayjs from 'dayjs';

import ChangePortfolioView from './ChangePortfolioView';
import {
  getMonthlyInvestmentIndexKey,
  getNameForCoefficients,
  getPortfolioKey,
  getPowerForCoefficientName,
  getStartingValueKey,
  mapPortfolioType,
  mapPortfolioTypeToStrategyImage,
  transformChartData,
  getMonthlyValues,
} from './utils';
import { PORTFOLIO_ETF } from './constants';
import FetchPortfolioUserData from './FetchPortfolioUserData';
import {
  ACTIVE_TAB,
  MAXIMUM_SAVINGS_RATE,
  SEARCH_PARAMS_ACTIVE_TAB,
} from './consts';

import { ALERT_TYPES } from 'constants/alerts';
import { PATHS } from 'constants/paths';
import * as userActions from 'store/user/actions';
import * as dependencyActions from 'store/dependentUser/actions';
import * as portfolioActions from 'store/portfolio/actions';
import * as spendingAnalyticsActions from 'store/spendingAnalytics/actions';
import { CURRENCY_SYMBOL, MY_APP, AU_APP } from 'constants/localeConfigs';
import { CUSTOM_PORTFOLIO } from 'constants/comonConstants';
import { checkIsDependentUser, checkIsSmsfUser } from 'utils/user';
import { api } from 'services/api';
import {
  selectActivePortfolioType,
  selectBaseFundTag,
  selectBaseUserPortfolios,
  selectIsBasicPortfolioActive,
  selectUserPortfolios,
} from 'store/portfolio/selectors';
import { selectIsUserAlertOpen } from 'store/user/selectors';
import {
  selectIsModeratePlusEnabled,
  selectIsProPortfolioEnabled,
  selectIsSuperPlusPortfolioEnabled,
  selectSupportLinks,
} from 'store/firebaseConfig/selectors';
import { PORTFOLIO_SEARCH_PARAMS } from 'pages/Portfolio/consts';
import { selectRecurringDepositSettings } from 'store/spendingAnalytics/selectors';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

const COEFFICIENT_URL = process.env.REACT_APP_PORTFOLIO_COEFFICIENTS;

// TODO: Refactor ChangePortolio https://acornsau.atlassian.net/browse/RAIZ-7235
class ChangePortfolio extends Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user));
    const monthly = `${CURRENCY_SYMBOL}5`;
    let originalBalance = 0;
    const savingsRateTmp = { savingsRate: 10 };

    const { isRegistration } = props;

    let startingValue = 0;

    if (user && user.user && user.user.current_balance) {
      originalBalance = user.user.current_balance;
    }

    if (!isRegistration && user && user.user && user.user.current_balance) {
      startingValue = user.user.current_balance;
    }

    const { savingsRate } = savingsRateTmp;

    this.state = {
      showLoader: true,
      showBtnLoader: false,
      portfolioType: 'moderate',
      savedPortfolioType: 'moderate',
      portfolioTypeFuture: '',
      coefficientData: {},
      isOpenHelpCards: false,
      isShowPortfolioStrategy: false,
      savingsRate,
      monthly,
      startingValue,
      coefficientBlocks: [],
      chartDataYears: [],
      activeEtf: '',
      ratio: null,
      portfolios: [],
      strategyImage: '',
      etfs: [],
      recommendedProfileId: false,
      isShowRecommendedPortfolio: !!MY_APP && isRegistration,
      isShowConfirm: false,
      currentPortfolioName: '',
      originalBalance,
      isCustom: false,
      activeFunds: [],
      customBalancingId: null,
      customPortfolioId: null,
      isEditCustom: false,
      customEtfs: [],
      customName: '',
      isEmptyCustomPortfolio: false,
      customAlternativeChart: null,
      activePortfolioId: null,
      isShowModalAllocation: false,
      dontShowModalAllocation: false,
      customEnabled: false,
      customBeta: false,
      balancingEtf: '',
      oldActiveFunds: [],
    };

    this.toggleHelpCard = this.toggleHelpCard.bind(this);

    this.savePortfolio = this.savePortfolio.bind(this);

    this.showPortfolioStrategyImg = this.showPortfolioStrategyImg.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.setEtfs = this.setEtfs.bind(this);

    this.clickOnFund = this.clickOnFund.bind(this);

    this.changeFund = this.changeFund.bind(this);

    this.onChangeComplete = this.onChangeComplete.bind(this);

    this.customEditToggle = this.customEditToggle.bind(this);

    this.customSave = this.customSave.bind(this);

    this.customNameChange = this.customNameChange.bind(this);

    this.changeModalAllocation = this.changeModalAllocation.bind(this);

    this.handleFetchPortfolioUserDataSuccess =
      this.handleFetchPortfolioUserDataSuccess.bind(this);

    this.getAllocationProfileId = this.getAllocationProfileId.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { portfolioType, spendingActions } = this.props;

    if (portfolioType) {
      this.setState({
        portfolioType,
      });
    }

    spendingActions.getRecurringDepositSettings().then(() => {
      const { recurringDepositSettings } = this.props;

      const currentLocation = window.location.href;
      const currentLocationUrl = new URL(currentLocation);
      const currentActiveTab = currentLocationUrl.searchParams.get(ACTIVE_TAB);

      if (
        Object.entries(recurringDepositSettings).length !== 0 &&
        recurringDepositSettings.constructor === Object &&
        currentActiveTab === SEARCH_PARAMS_ACTIVE_TAB.future
      ) {
        const { frequency_identifier, amount } = recurringDepositSettings;

        const monthlyValue = getMonthlyValues(amount, frequency_identifier);

        this.setState({
          monthly: `${CURRENCY_SYMBOL}${monthlyValue}`,
          savingsRate: monthlyValue,
        });
      }
    });

    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { activeEtf, customPortfolioId, isCustom, balancingEtf } = this.state;

    const {
      portfolioFunds,
      customPortfolio,
      isRegistration,
      isEditKids,
      proPortfolioActions,
      isProPortfolioEnabled,
      portfolios,
      superUser,
    } = this.props;

    let isNeedSetPortfolio = false;
    const user = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user));

    if (user && user.user && newProps.isSuper) {
      if (
        newProps.isSuper &&
        newProps.superUser &&
        newProps.portfolios &&
        newProps.portfolios.length !== 0
      ) {
        isNeedSetPortfolio = true;

        this.setState({ showLoader: false });
      }
    } else {
      isNeedSetPortfolio = true;
    }

    let customPortfolioIdTmp = null;

    if (
      (customPortfolioId === null &&
        newProps.customPortfolioId &&
        newProps.customPortfolioId !== '') ||
      (customPortfolioId !== newProps.customPortfolioId &&
        newProps.customPortfolioId)
    ) {
      customPortfolioIdTmp = newProps.customPortfolioId;

      this.setState({ customPortfolioId: newProps.customPortfolioId });
    }

    if (
      customPortfolio &&
      customPortfolio.portfolio &&
      customPortfolio.portfolio.projection_graph_type
    ) {
      this.getPortfolioLinesFromPortfolio(
        customPortfolio.portfolio.projection_graph_type,
      );
    }

    let customEtfs = [];

    if (
      customPortfolio &&
      customPortfolio.portfolio &&
      customPortfolio.portfolio.etfs
    ) {
      customEtfs = customPortfolio.portfolio.etfs;

      this.setState({ customEtfs });
    } else if (isCustom) {
      this.setState({ isEditCustom: true });
    }

    if (
      balancingEtf === '' &&
      newProps.portfolioFunds &&
      newProps.portfolioFunds.length !== 0
    ) {
      newProps.portfolioFunds.map((fund) => {
        if (fund.balancing) {
          this.setState({ balancingEtf: fund.category });
        }

        return false;
      });
    }

    if (
      newProps.portfolioFunds &&
      newProps.portfolioFunds.length !== 0 &&
      portfolioFunds.length === 0
    ) {
      const activeFund = [];
      let percent = 0;
      let i = 0;
      let customName = '';
      let initalBalance = 100;
      let balancingId = null;

      if (
        customPortfolio &&
        customPortfolio.portfolio &&
        customPortfolio.portfolio.id
      ) {
        customPortfolioIdTmp = customPortfolio.portfolio.id;
      }

      if (
        customPortfolio &&
        customPortfolio.portfolio &&
        customPortfolio.portfolio.name
      ) {
        customName = customPortfolio.portfolio.name;
      }

      newProps.portfolioFunds.map((fund) => {
        percent = 0;

        if (customEtfs.length !== 0) {
          customEtfs.map((etf) => {
            if (etf.id === fund.id) {
              percent = etf.percent * 100;
            }

            return true;
          });
        } else if (!fund.balancing && fund.initial_allocation) {
          initalBalance -= fund.initial_allocation;
        }

        if (fund.balancing) {
          this.setState({ customBalancingId: fund.id });

          if (customName === '') {
            balancingId = fund.id;

            activeFund[fund.id] = {
              show: false,
              percent: 100,
              max: fund.max_allocation,
              min: fund.min_allocation ? fund.min_allocation : 0,
              initialAllocation: fund.initial_allocation,
            };
          } else {
            activeFund[fund.id] = {
              show: false,
              percent: Math.round(percent),
              max: fund.max_allocation,
              min: fund.min_allocation ? fund.min_allocation : 0,
              initialAllocation: fund.initial_allocation,
            };
          }
        } else {
          activeFund[fund.id] = {
            show: false,
            percent:
              customEtfs.length !== 0
                ? Math.round(percent)
                : fund.initial_allocation,
            max: fund.max_allocation,
            min: fund.min_allocation ? fund.min_allocation : 0,
            initialAllocation: fund.initial_allocation,
          };
        }

        i += 1;

        return activeFund;
      });

      if (customEtfs.length === 0 && balancingId) {
        activeFund[balancingId].percent = initalBalance;
      }

      activeFund.length = i;

      if (
        user &&
        user.user &&
        user.user.allocation_profile_id &&
        user.user.allocation_profile_id &&
        customPortfolioIdTmp
      ) {
        const savedPortfolioCheck = newProps.portfolios.find(
          (portfolio) => portfolio.id === user.user.allocation_profile_id,
        );

        if (!savedPortfolioCheck && !newProps.isSuper && !isEditKids) {
          this.setState({
            activePortfolioId: newProps.isKidsRegistration
              ? user.user.recommended_allocation_profile_id
              : customPortfolioIdTmp,
          });
        }
      }

      this.setState({
        activeFunds: activeFund,
        customPortfolioId: customPortfolioIdTmp,
        customEtfs,
        customName,
        showLoader: false,
        isEditCustom:
          (!customPortfolio.portfolio && isCustom) ||
          (isCustom &&
            isRegistration &&
            (newProps.isKidsRegistration || checkIsDependentUser(user))),
        isEmptyCustomPortfolio: !customPortfolio.portfolio,
      });
    }

    const isPortfoliosNotSet =
      newProps.portfolios &&
      newProps.portfolios.length !== 0 &&
      isNeedSetPortfolio &&
      activeEtf === '';

    const isSuperPortfoliosNeedSet =
      newProps.isSuper &&
      newProps.portfolios.some(
        (portfolio, index) => portfolio.id !== portfolios?.[index]?.id,
      );

    if (isPortfoliosNotSet || isSuperPortfoliosNeedSet) {
      if (user && user.user) {
        const allocationProfileId = this.getAllocationProfileId({
          user,
          newProps,
        });

        let savedPortfolio = null;

        if (
          newProps.portfolios &&
          newProps.portfolios[0] &&
          newProps.portfolios[0].identifier_key
        ) {
          savedPortfolio = newProps.portfolios[0].identifier_key;
        }

        let savedPortfolioTmp = null;

        if (
          allocationProfileId &&
          newProps.portfolios &&
          newProps.portfolios[0]
        ) {
          savedPortfolioTmp = newProps.portfolios.find(
            (portfolio) => portfolio.id === allocationProfileId,
          );

          if (savedPortfolioTmp && savedPortfolioTmp.identifier_key) {
            savedPortfolio = savedPortfolioTmp.identifier_key
              .replace(' ', '_')
              .toLowerCase();
          }

          if (newProps.isChangeBasePortfolioPage) {
            savedPortfolio =
              newProps.portfolios.find(
                ({ id }) => id === newProps.basePortfolio?.id,
              )?.name_key || newProps?.portfolios[0]?.name_key;
          }

          if (isProPortfolioEnabled) {
            let activeBasicPortfolioId = newProps?.isSuper
              ? superUser?.allocation_profile_id
              : user.user.allocation_profile_id;

            if (newProps.isChangeBasePortfolioPage) {
              activeBasicPortfolioId =
                newProps.basePortfolio?.id || newProps.portfolios[0].id;
            } else if (isEditKids && savedPortfolioTmp) {
              activeBasicPortfolioId = allocationProfileId;
            } else if (!newProps.isBasicPortfolioActive) {
              activeBasicPortfolioId = newProps.portfolios[0].id;
            }

            proPortfolioActions.setActivePortfolioId(activeBasicPortfolioId);

            this.setState({
              isCustom: false,
              savedPortfolioType:
                newProps?.isSuper && !superUser?.allocation_profile_id
                  ? ''
                  : savedPortfolio,
              portfolioType: savedPortfolio,
              activePortfolioId: activeBasicPortfolioId,
            });
          } else if ((AU_APP || MY_APP) && !savedPortfolioTmp) {
            this.setState({
              isCustom: true,
              savedPortfolioType: CUSTOM_PORTFOLIO,
            });

            if (customPortfolioIdTmp) {
              this.setState({
                activePortfolioId: customPortfolioIdTmp,
              });
            }
          } else {
            this.setState({
              savedPortfolioType: savedPortfolio,
              activePortfolioId: allocationProfileId,
            });
          }

          this.getPortfolioLinesFromPortfolio(savedPortfolio);
        }

        if (
          user &&
          user.user &&
          user.user.recommended_allocation_profile_id &&
          newProps.portfolios &&
          newProps.portfolios[0]
        ) {
          this.setState({
            recommendedProfileId: user.user.recommended_allocation_profile_id,
          });
        } else {
          this.setState({ isShowRecommendedPortfolio: false });
        }

        const { actions } = this.props;
        const { portfolioTypeFuture } = this.state;

        const isSmsf =
          sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) ===
          'superFund';

        let portfolioType = null;

        if (savedPortfolio) {
          portfolioType = savedPortfolio.replace(' ', '_').toLowerCase();
        }

        if (
          (AU_APP && !savedPortfolioTmp && isSmsf && isRegistration) ||
          (!AU_APP && !MY_APP && !savedPortfolioTmp)
        ) {
          [savedPortfolioTmp] = newProps.portfolios;

          this.setState({
            activePortfolioId: savedPortfolioTmp.id,
          });
        }

        if (
          isCustom ||
          ((AU_APP || MY_APP) && !savedPortfolioTmp && !isProPortfolioEnabled)
        ) {
          portfolioType = CUSTOM_PORTFOLIO;
        }

        if (portfolioTypeFuture === '') {
          this.setState({ portfolioTypeFuture: portfolioType });

          actions.setPortfolioType(portfolioType);
        }

        this.setState({ portfolioType });

        const portfolioName = savedPortfolioTmp?.name || portfolioType;

        if (newProps.portfolios && newProps.portfolios[0]) {
          this.setEtfs(newProps.portfolios, portfolioName);
        }
      }

      this.setState({ portfolios: newProps.portfolios });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    const body = document.querySelector('body');

    body.setAttribute('class', '');

    this.setState({
      showLoader: true,
      portfolios: [],
      portfolioTypeFuture: '',
    });

    actions.resetProps();

    this._isMounted = false;
  }

  handleCloseModal() {
    const { actions } = this.props;

    actions.closeModal();

    this.setState({
      isShowPortfolioStrategy: false,
      isShowRecommendedPortfolio: false,
      isShowConfirm: false,
      isShowModalAllocation: false,
    });
  }

  async handleFetchPortfolioUserDataSuccess(allocationProfileId) {
    if (this._isMounted) {
      const {
        actions,
        isRegistration,
        isSuper,
        isEditKids,
        proPortfolioActions,
        isProPortfolioEnabled,
      } = this.props;

      await actions.getStore();

      if (AU_APP || MY_APP) {
        const currentURL = new URLSearchParams(window.location.search);
        const childId = currentURL.get(PORTFOLIO_SEARCH_PARAMS.childId) || '';

        await actions.getPortfolios(isSuper).then(() => {
          if (isProPortfolioEnabled) {
            this.setState({
              customEnabled: false,
            });

            proPortfolioActions.getProPortfolio({ childId, isSuper });
          } else {
            this.setState({
              customEnabled: true,
            });
          }
        });

        const { customEnabled } = this.state;
        const { child, portfolios, isSmsf } = this.props;

        if (portfolios.length === 0) return;

        if (customEnabled && !isSuper) {
          const hasPortfolioWithId = !!portfolios.find(
            (portfolio) => portfolio.id === allocationProfileId,
          );

          if (!hasPortfolioWithId && !(isRegistration && isSmsf)) {
            this.setState({
              isCustom: true,
            });
          }

          if (child?.dependency_user?.portfolio) {
            this.setState({
              activePortfolioId: child.dependency_user.portfolio.id,
              portfolioType: !hasPortfolioWithId
                ? CUSTOM_PORTFOLIO
                : child.dependency_user.portfolio.name
                    .replace(' ', '_')
                    .toLowerCase(),
            });
          }

          if (!hasPortfolioWithId && allocationProfileId) {
            await actions.getCustomPortfolioById(allocationProfileId);
          } else {
            await actions.getCustomPortfolio();
          }

          actions.getPortfolioFunds();
        }
      } else {
        actions.getPortfolios(isSuper, isEditKids);
      }

      if (isSuper && !isRegistration) {
        actions.getSuperUserData();
      } else {
        try {
          const coefficientData = await api.get(COEFFICIENT_URL);

          this.setState({ coefficientData });

          this.getPortfolioLinesFromPortfolio();
        } catch {
          console.error('Coefficients request failed');
        }
      }
    }
  }

  onChangeComplete({ isEditCustom }) {
    const { activeFunds, customPortfolioId } = this.state;
    const { portfolioFunds, actions, customPortfolio } = this.props;
    const previewFunds = { funds: [] };
    let balancing = {};
    let total = 0;
    let isNew = false;

    if (!customPortfolioId) {
      isNew = true;
    }

    portfolioFunds.map((fund) => {
      const allocation = isEditCustom
        ? fund.initial_allocation
        : activeFunds[fund.id].percent;

      if (allocation !== 0 && !fund.balancing) {
        total += allocation;

        previewFunds.funds.push({
          allocation,
          id: fund.id,
        });
      }

      if (fund.balancing) {
        balancing = {
          allocation,
          id: fund.id,
        };
      }

      return previewFunds;
    });

    balancing.allocation = 100 - total;

    previewFunds.funds.push(balancing);

    actions.fundsPreview(previewFunds, customPortfolio, isNew);
  }

  getAllocationProfileId({ user, newProps }) {
    const { activePortfolioId } = this.state;

    const { isEditKids, child, isChangeBasePortfolioPage, superUser } =
      this.props;

    if (newProps.isSuper) {
      return (
        newProps.superUser.allocation_profile_id ||
        superUser.allocation_profile_id ||
        newProps.portfolios[0].id
      );
    }

    if (newProps.portfolioId) {
      return newProps.portfolioId;
    }

    if (isChangeBasePortfolioPage) {
      return newProps?.basePortfolio?.id;
    }

    if (isEditKids) {
      if (child?.dependency_user?.portfolio) {
        return child.dependency_user.portfolio.id;
      }

      if (activePortfolioId) {
        return activePortfolioId;
      }
    }

    const isSmsfUser = checkIsSmsfUser(user);

    if (isSmsfUser) {
      return user.user.allocation_profile_id || newProps.portfolios[0].id;
    }

    return user.user.allocation_profile_id;
  }

  setEtfs(portfolios, currentPortfolio = '') {
    const { activeEtf } = this.state;
    let { portfolioType } = this.state;
    let activeEtfTmp = '';
    const etfs = [];
    let i = 0;
    let currentPortfolioName = '';

    if (portfolioType === 'sapphire') {
      portfolioType = 'bitcoin';
    }

    if (portfolioType === 'moderate_plus') {
      portfolioType = 'moderate';
    }

    if (portfolioType === 'sukuk') {
      portfolioType = 'moderate';
    }

    if (portfolioType === 'amanah') {
      portfolioType = 'moderately-conservative';
    }

    if (currentPortfolio !== '') {
      currentPortfolioName = currentPortfolio;
    } else {
      currentPortfolioName = portfolioType;
    }

    this.setState({
      currentPortfolioName,
    });

    if (portfolios.length !== 0) {
      let etfsArray = [];
      const portfoliosKeys = [];

      portfolios.map((portfolio) => {
        portfoliosKeys.push(portfolio.identifier_key);

        if (portfolio.etfs.length !== 0) {
          i = 0;

          etfsArray = [];

          portfolio.etfs.map((etf) => {
            if (portfolio.etfs.length !== 0) {
              etfsArray[etf.symbol] = etf;

              etfsArray[etf.symbol].position = i;

              etfsArray[etf.symbol].length = i;

              if (
                activeEtf === '' &&
                activeEtfTmp === '' &&
                portfolioType ===
                  portfolio.identifier_key.replace(' ', '_').toLowerCase()
              ) {
                activeEtfTmp = etf.symbol;

                this.setState({ activeEtf: etf.symbol });
              }
            }

            i += 1;

            return etfs;
          });

          etfs[portfolio.identifier_key.replace(' ', '_').toLowerCase()] =
            etfsArray;

          etfs[
            portfolio.identifier_key.replace(' ', '_').toLowerCase()
          ].length = i;

          etfs.length = portfolios.length;
        }

        return etfs;
      });
    }

    this.setState({ etfs });
  }

  getCoefficients(portfolioTypeSelect = null) {
    const {
      coefficientData,
      startingValue,
      savingsRate,
      customAlternativeChart,
    } = this.state;

    const { portfolioType } = this.state;

    const portfolio =
      customAlternativeChart ||
      mapPortfolioType(portfolioTypeSelect || portfolioType);

    const base = [
      getPortfolioKey(portfolio),
      getStartingValueKey(startingValue),
      getMonthlyInvestmentIndexKey(savingsRate),
      'coefficients',
    ]
      .join('-')
      .replace('_', '-');

    return coefficientData[base];
  }

  getLinesFromPortfolio(portfolioTypeSelect = null) {
    let { portfolioType } = this.state;

    if (portfolioTypeSelect) {
      portfolioType = portfolioTypeSelect;
    }

    const multipleSeparator = ':';
    const projectionLines = [];
    const lines = this.getCoefficients(portfolioType);

    if (lines) {
      for (let i = 0, lLen = lines.length; i < lLen; i += 1) {
        const row = lines[i]; // thisLine in Java app
        const blocks = [];

        Object.keys(row).forEach((columnName) => {
          if ({}.propertyIsEnumerable.call(row, columnName)) {
            const columnValue = row[columnName];
            let monthPower = 0;
            let savingsRatePower = 0;
            let startingValuePower = 0;

            // Skip non-coefficient columns
            if (!/index/gi.test(columnName) && !/type/gi.test(columnName)) {
              const multiples = columnName.split(multipleSeparator);

              for (let j = 0, mLen = multiples.length; j < mLen; j += 1) {
                const multiple = multiples[j];
                const power = getPowerForCoefficientName(multiple);
                const coeffName = getNameForCoefficients(multiple);

                if (coeffName === 'month') {
                  monthPower = power;
                } else if (coeffName === 'savingsRate') {
                  savingsRatePower = power;
                } else if (coeffName === 'startingValue') {
                  startingValuePower = power;
                }
              }

              const coeffValue = parseFloat(columnValue);

              const coeffBlock = this.coefficientBlock(
                coeffValue,
                monthPower,
                startingValuePower,
                savingsRatePower,
              );

              blocks.push(coeffBlock);
            }
          }
        });

        const intercept = row['(Intercept)'];
        const projectionType = row.type;

        projectionLines.push(
          this.projectionLine(blocks, intercept, projectionType),
        );
      }
    }

    return projectionLines;
  }

  getPortfolioLinesFromPortfolio(
    portfolioTypeSelect = null,
    savingsRateSelect = null,
  ) {
    const { startingValue } = this.state;
    const { isRegistration, t } = this.props;
    let { portfolioType, savingsRate } = this.state;

    if (portfolioTypeSelect) {
      portfolioType = portfolioTypeSelect;
    }

    if (savingsRateSelect) {
      savingsRate = savingsRateSelect;
    }

    const projectionLines = this.getLinesFromPortfolio(
      portfolioType,
      savingsRate,
    );

    let LB68;
    let UB68;
    let LB95;
    let UB95;
    let LB99;
    let UB99;
    let Mean;
    const nMonths = 241;

    for (let i = 0, pLen = projectionLines.length; i < pLen; i += 1) {
      const projectionLine = projectionLines[i];
      const floats = [];

      for (let j = 0; j < nMonths; j += 1) {
        floats[j] = this.evaluateValueAtMonth(j, startingValue, savingsRate);

        switch (projectionLine.projectionType) {
          case '68lb':
            LB68 = floats;

            break;
          case '68ub':
            UB68 = floats;

            break;
          case '95lb':
            LB95 = floats;

            break;
          case '95ub':
            UB95 = floats;

            break;
          case '99lb':
            LB99 = floats;

            break;
          case '99ub':
            UB99 = floats;

            break;
          case 'mean':
            Mean = floats;

            break;
          default:
            return [];
        }
      }

      this.updateCoefficientBlocks();
    }

    const chartData = {
      LB68,
      UB68,
      LB95,
      UB95,
      LB99,
      UB99,
      Mean,
    };

    const user = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user));
    let age = 0;

    if (!isRegistration && user && user.user && user.user.dob) {
      age = dayjs().diff(user.user.dob, 'years');
    }

    const chartDataYears = [];

    chartDataYears.Mean = transformChartData({ age, data: chartData.Mean, t });

    chartDataYears.LB68 = transformChartData({ age, data: chartData.LB68, t });

    chartDataYears.UB68 = transformChartData({ age, data: chartData.UB68, t });

    chartDataYears.LB95 = transformChartData({ age, data: chartData.LB95, t });

    chartDataYears.UB95 = transformChartData({ age, data: chartData.UB95, t });

    chartDataYears.LB99 = transformChartData({ age, data: chartData.LB99, t });

    chartDataYears.UB99 = transformChartData({ age, data: chartData.UB99, t });

    const lastData = chartDataYears.LB99[238];

    chartDataYears.LB99[239] = lastData;

    this.setState({ chartDataYears, showLoader: false });

    return chartData;
  }

  handleChangeEtf = (etf) => {
    const { activeEtf, portfolioType, etfs } = this.state;
    const currentPortfolioType = portfolioType.replace(' ', '_').toLowerCase();

    if (activeEtf !== etf) {
      this.setState({
        activeEtf: etf,
        ratio: etfs[currentPortfolioType][etf].percent,
      });
    }
  };

  handleChange = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      this.getPortfolioLinesFromPortfolio();
    } else {
      let savingsRate = [''];
      const rateReplace = e.target.value.match(/\d+/);

      if (rateReplace && rateReplace[0] && rateReplace[0] !== '0') {
        savingsRate = { ...rateReplace };
      }

      if (
        rateReplace &&
        rateReplace[0] &&
        rateReplace[0] !== '0' &&
        rateReplace[0] >= MAXIMUM_SAVINGS_RATE
      ) {
        savingsRate[0] = MAXIMUM_SAVINGS_RATE.toString();
      }

      const rate = savingsRate[0];

      this.setState({
        monthly: `${CURRENCY_SYMBOL}${savingsRate[0]}`,
        savingsRate: rate,
      });

      const { actions } = this.props;

      actions.saveSavingsRate(savingsRate[0]).then(() => {
        actions.getStore();
      });
    }
  };

  onClick = (e) => {
    const { savingsRate } = this.state;

    if (
      savingsRate &&
      savingsRate !== '0' &&
      (savingsRate === '1' ||
        savingsRate === '2' ||
        savingsRate === '3' ||
        savingsRate === '4')
    ) {
      this.setState({
        monthly: `${CURRENCY_SYMBOL}5`,
        savingsRate: 5,
      });
    }

    e.preventDefault();

    this.getPortfolioLinesFromPortfolio();
  };

  updateActiveFunds = () => {
    const { actions } = this.props;

    actions.getCustomPortfolio().then(() => {
      actions.getPortfolioFunds().then(() => {
        const { portfolioFunds } = this.props;
        const { customEtfs, activeFunds } = this.state;

        const updatedActiveFunds = portfolioFunds?.reduce((funds, { id }) => {
          const percent = customEtfs?.filter((etf) => id === etf.id)?.[0]
            ?.percent;

          if (percent) {
            return {
              ...funds,
              [id]: {
                ...activeFunds[id],
                ...{ percent: percent * 100 },
              },
            };
          }

          return {
            ...funds,
            [id]: activeFunds[id],
          };
        }, {});

        this.setState({ activeFunds: updatedActiveFunds });
      });
    });
  };

  toggleShow = (portfolioType) => {
    const {
      isSuper,
      isRegistration,
      proPortfolioActions,
      isProPortfolioEnabled,
    } = this.props;

    const {
      etfs,
      portfolios,
      isEmptyCustomPortfolio,
      customPortfolioId,
      isEditCustom,
    } = this.state;

    const newPortfolioType = portfolioType.replace(' ', '_').toLowerCase();

    let activePortfolioId = null;

    const activePortfolio = portfolios.find(
      (portfolio) => portfolio.identifier_key === portfolioType,
    );

    if (!activePortfolio) {
      activePortfolioId = customPortfolioId;
    } else {
      activePortfolioId = activePortfolio.id;
    }

    if ((AU_APP || MY_APP) && portfolioType === CUSTOM_PORTFOLIO) {
      if (isRegistration || isEmptyCustomPortfolio) {
        this.setState({
          isEditCustom: true,
        });
      }

      this.setState({
        portfolioType: newPortfolioType,
        ratio: null,
        isCustom: true,
        activePortfolioId,
      });
    } else {
      if (isProPortfolioEnabled) {
        proPortfolioActions.setActivePortfolioId(activePortfolio.id);
      }

      if (MY_APP && isEditCustom) {
        this.updateActiveFunds();
      }

      this.setState({
        portfolioType: newPortfolioType,
        activeEtf: Object.keys(etfs[newPortfolioType])[0],
        ratio: null,
        isCustom: false,
        isEditCustom: false,
        activePortfolioId: activePortfolio.id,
      });
    }

    const portfolioName = activePortfolio?.name || newPortfolioType;

    this.setEtfs(portfolios, portfolioName);

    if (!isSuper) {
      this.getPortfolioLinesFromPortfolio(newPortfolioType);
    }
  };

  updateCoefficientBlocks() {
    const { coefficientBlocks } = this.state;

    coefficientBlocks.splice(0, 1);

    this.setState({ coefficientBlocks });
  }

  coefficientBlock(
    coeffValue,
    monthPower,
    startingValuePower,
    savingsRatePower,
  ) {
    return {
      coeffValue,
      monthPower,
      startingValuePower,
      savingsRatePower,
    };
  }

  projectionLine(blocks, intercept, projectionType) {
    const { coefficientBlocks } = this.state;

    coefficientBlocks.push(blocks);

    this.setState({ coefficientBlocks });

    return { blocks, intercept, projectionType };
  }

  evaluateValueAtMonth(month, startingValue, savingsRate) {
    const { coefficientBlocks } = this.state;
    let value = 0;

    for (let i = 0, len = coefficientBlocks[0].length; i < len; i += 1) {
      const block = coefficientBlocks[0][i];

      const d = this.evaluateCoefficientBlock(
        month,
        startingValue,
        savingsRate,
        block,
      );

      value += d;
    }

    if (value < 0) {
      return 0;
    }

    return value;
  }

  evaluateCoefficientBlock(month, startingValue, savingsRate, block) {
    return parseFloat(
      block.coeffValue *
        month ** block.monthPower *
        startingValue ** block.startingValuePower *
        savingsRate ** block.savingsRatePower,
    );
  }

  toggleHelpCard(condition) {
    this.setState({ isOpenHelpCards: condition });
  }

  savePortfolio(_, isReset = false) {
    const {
      actions,
      isSuper,
      isRegistration,
      user,
      isSuperRegistration,
      isKidsRegistration,
      isEditKids,
      navigate,
      isProPortfolioEnabled,
      isInvestmentsVisible,
    } = this.props;

    const {
      portfolioType,
      isShowRecommendedPortfolio,
      recommendedProfileId,
      isShowConfirm,
      isCustom,
      customPortfolioId,
    } = this.state;

    if (!isShowConfirm && !isRegistration && !isSuperRegistration && !isReset) {
      this.setState({ isShowConfirm: true });

      return;
    }

    let searchPortflolios = [];

    this.setState({ showBtnLoader: true });

    if (isRegistration) {
      const { portfolios } = this.props;

      searchPortflolios = portfolios;
    } else {
      const { portfolios } = this.state;

      searchPortflolios = portfolios;
    }

    if (!searchPortflolios || searchPortflolios.length === 0) {
      this.setState({
        showBtnLoader: false,
        savedPortfolioType: portfolioType,
        isShowConfirm: false,
      });

      return;
    }

    Firebase.analytics().logEvent('Portfolio', {
      eventKey: 'Changed',
      type: portfolioType,
    });

    const savedPortfolio = searchPortflolios.filter(
      (portfolio) => portfolio.identifier_key === portfolioType,
    );

    let portfolioId = 0;

    if (isShowRecommendedPortfolio && isRegistration) {
      portfolioId = recommendedProfileId;
    } else if (!isCustom) {
      portfolioId = savedPortfolio[0].id;
    } else {
      portfolioId = customPortfolioId;

      Firebase.analytics().logEvent('CustomChange');
    }

    if (!portfolioId && customPortfolioId !== null) {
      portfolioId = customPortfolioId;
    }

    if (isKidsRegistration || isEditKids) {
      const { actionsDependency } = this.props;
      const currentURL = new URLSearchParams(window.location.search);
      const childId = currentURL.get(PORTFOLIO_SEARCH_PARAMS.childId) || '';

      actionsDependency.savePortfolio({ portfolioId, childId }).then(() => {
        if (isReset) {
          document.location.reload();
        } else {
          this.setState({
            showBtnLoader: false,
            savedPortfolioType: portfolioType,
            isShowConfirm: false,
            showLoader: false,
            activePortfolioId: portfolioId,
          });
        }
      });
    } else {
      actions
        .savePortfolio({
          portfolioId,
          isSuper,
          isRegistration,
          isInvestmentsVisible: checkIsDependentUser(user)
            ? false
            : isInvestmentsVisible,
        })
        .then(() => {
          if (isReset) {
            document.location.reload();
          } else {
            this.setState({
              showBtnLoader: false,
              savedPortfolioType: portfolioType,
              isShowConfirm: false,
              showLoader: false,
              activePortfolioId: portfolioId,
            });
          }

          if (isProPortfolioEnabled && isSuperRegistration) {
            navigate(PATHS.super.registration);
          }
        });
    }
  }

  showPortfolioStrategyImg() {
    const { portfolioType } = this.state;
    const strategyImage = mapPortfolioTypeToStrategyImage(portfolioType);

    this.setState({ strategyImage, isShowPortfolioStrategy: true });
  }

  clickOnFund(id) {
    const { activeFunds } = this.state;

    if (activeFunds[id].show) {
      activeFunds[id].show = false;
    } else {
      activeFunds[id].show = true;
    }

    this.setState({ activeFunds });
  }

  changeFund(percent, id) {
    const {
      activeFunds,
      customBalancingId,
      dontShowModalAllocation,
      oldActiveFunds,
    } = this.state;

    const { portfolioFunds } = this.props;
    let oldPercent = 0;

    if (percent < activeFunds[customBalancingId].min) {
      return;
    }

    if (activeFunds[id]) {
      oldPercent = Math.round(activeFunds[id].percent);

      activeFunds[id].percent = Math.round(percent);
    }

    if (
      !AU_APP &&
      oldPercent < activeFunds[id].percent &&
      activeFunds[customBalancingId].percent <=
        activeFunds[customBalancingId].min
    ) {
      oldActiveFunds[id].percent = oldPercent;

      this.setState({ activeFunds: oldActiveFunds });

      if (!dontShowModalAllocation) {
        this.setState({ isShowModalAllocation: true });
      }

      return;
    }

    let total = 0;

    portfolioFunds.map((fund) => {
      if (customBalancingId !== fund.id) {
        total += Math.round(activeFunds[fund.id].percent);
      }

      return true;
    });

    if (
      activeFunds[customBalancingId].percent <
      activeFunds[customBalancingId].min
    ) {
      activeFunds[customBalancingId].percent =
        activeFunds[customBalancingId].min;

      this.setState({ activeFunds: oldActiveFunds });

      if (!dontShowModalAllocation) {
        this.setState({ isShowModalAllocation: true });
      }

      return;
    }

    if (
      (total > 100 && !AU_APP) ||
      (100 - total < activeFunds[customBalancingId].min && !AU_APP)
    ) {
      oldActiveFunds[id].percent = oldPercent;

      this.setState({ activeFunds: oldActiveFunds });

      if (!dontShowModalAllocation) {
        this.setState({ isShowModalAllocation: true });
      }

      return;
    }

    activeFunds[customBalancingId].percent = 100 - total;

    if (
      (MY_APP && activeFunds[customBalancingId].percent <= 0) ||
      (AU_APP && activeFunds[customBalancingId].percent < 0)
    ) {
      activeFunds[id].percent = Math.round(oldPercent);

      if (!dontShowModalAllocation) {
        this.setState({ isShowModalAllocation: true });
      }

      let totalTmp = 0;

      portfolioFunds.map((fund) => {
        if (customBalancingId !== fund.id) {
          totalTmp += Math.round(activeFunds[fund.id].percent);
        }

        return true;
      });

      activeFunds[customBalancingId].percent = 100 - totalTmp;
    }

    this.setState({ oldActiveFunds: activeFunds });

    this.setState({ activeFunds });
  }

  customEditToggle() {
    const { isEditCustom, activeFunds } = this.state;

    if (isEditCustom) {
      this.updateActiveFunds();
    } else {
      const updatedActiveFunds = Object.entries(activeFunds).reduce(
        (funds, [id, fund]) => ({
          ...funds,
          [id]: { ...fund, percent: fund.initialAllocation },
        }),
        [],
      );

      this.setState({ activeFunds: updatedActiveFunds });

      this.onChangeComplete({ isEditCustom: !isEditCustom });
    }

    this.setState({ isEditCustom: !isEditCustom });
  }

  customSave() {
    const { activeFunds, customName, isEmptyCustomPortfolio } = this.state;

    const {
      portfolioFunds,
      actions,
      isRegistration,
      isSuper,
      isEditKids,
      isKidsRegistration,
      t,
    } = this.props;

    const newFunds = { funds: [] };

    portfolioFunds.map((fund) => {
      if (activeFunds[fund.id].percent !== 0) {
        newFunds.funds.push({
          allocation: activeFunds[fund.id].percent,
          id: fund.id,
        });
      }

      return true;
    });

    newFunds.name = customName;

    if (newFunds.name === '') {
      actions.customPortfolioError(t('changePortfolioPage.validPortfolio'));

      return;
    }

    actions.customPortfolioSave(newFunds).then(() => {
      Firebase.analytics().logEvent('CustomEdit');

      const { customPortfolioId } = this.state;

      if (isEmptyCustomPortfolio) {
        this.setState({
          showLoader: true,
          isEmptyCustomPortfolio: false,
          isShowConfirm: false,
        });

        this.savePortfolio({}, !isRegistration);
      }

      if (isKidsRegistration || isEditKids) {
        const { actionsDependency } = this.props;
        const currentURL = new URLSearchParams(window.location.search);
        const childId = currentURL.get(PORTFOLIO_SEARCH_PARAMS.childId) || '';

        actionsDependency.savePortfolio({
          portfolioId: customPortfolioId,
          childId,
        });
      } else {
        actions.savePortfolio({
          portfolioId: customPortfolioId,
          isSuper,
          isRegistration,
          isEditKids,
        });
      }

      this.setState({
        isEditCustom: false,
        activePortfolioId: customPortfolioId,
        customPortfolioId,
      });
    });
  }

  customNameChange(e) {
    this.setState({ customName: e.target.value });
  }

  changeModalAllocation() {
    const { dontShowModalAllocation } = this.state;

    this.setState({ dontShowModalAllocation: !dontShowModalAllocation });
  }

  render() {
    const {
      portfolioType,
      optionsChart,
      chartDataYears,
      monthly,
      activeEtf,
      ratio,
      portfolios,
      isOpenHelpCards,
      isShowPortfolioStrategy,
      strategyImage,
      showLoader,
      showBtnLoader,
      etfs,
      isShowRecommendedPortfolio,
      savedPortfolioType,
      isShowConfirm,
      currentPortfolioName,
      isCustom,
      activeFunds,
      isEditCustom,
      customEtfs,
      customName,
      isEmptyCustomPortfolio,
      customPortfolioId,
      activePortfolioId,
      isShowModalAllocation,
      dontShowModalAllocation,
      customEnabled,
      customBeta,
      balancingEtf,
      originalBalance,
    } = this.state;

    const {
      isDashboard,
      isSuper,
      isFuture,
      isRegistration,
      goBack,
      errorMessage,
      isShowErrorModal,
      portfolioFunds,
      customPortfolio,
      errorMessageCustom,
      isSmsf,
      isSuperRegistration,
      isKidsRegistration,
      isEditKids,
      user,
      t,
      moderatePlusOn,
      supportLinks,
      isProPortfolioEnabled,
      isSuperPlusPortfolioEnabled,
    } = this.props;

    return (
      <>
        <FetchPortfolioUserData
          isEditKids={isEditKids}
          isRegistration={isRegistration}
          onSuccess={this.handleFetchPortfolioUserDataSuccess}
        />

        <ChangePortfolioView
          originalBalance={originalBalance}
          user={user}
          showLoader={showLoader}
          isKidsRegistration={isKidsRegistration}
          showBtnLoader={showBtnLoader}
          title={t('changePortfolioPage.title')}
          toggleShow={this.toggleShow}
          portfolioType={portfolioType}
          optionsChart={optionsChart}
          chartDataYears={chartDataYears}
          handleChange={this.handleChange}
          onClick={this.onClick}
          monthly={monthly}
          portfolioETF={PORTFOLIO_ETF}
          activeEtf={activeEtf}
          ratio={ratio}
          portfolios={portfolios}
          isDashboard={isDashboard}
          toggleHelpCard={this.toggleHelpCard}
          isOpenHelpCards={isOpenHelpCards}
          savePortfolio={this.savePortfolio}
          isSuper={isSuper}
          isEditKids={isEditKids}
          isShowPortfolioStrategy={isShowPortfolioStrategy}
          strategyImage={strategyImage}
          showPortfolioStrategyImg={this.showPortfolioStrategyImg}
          handleCloseModal={this.handleCloseModal}
          isFuture={isFuture}
          isRegistration={isRegistration}
          goBack={goBack}
          isSmsf={isSmsf}
          etfs={etfs}
          isShowRecommendedPortfolio={isShowRecommendedPortfolio}
          savedPortfolioType={savedPortfolioType}
          errorMessage={errorMessage}
          isShowErrorModal={isShowErrorModal}
          isShowConfirm={isShowConfirm}
          currentPortfolioName={currentPortfolioName}
          portfolioFunds={portfolioFunds}
          customPortfolio={customPortfolio}
          isCustom={isCustom}
          activeFunds={activeFunds}
          clickOnFund={this.clickOnFund}
          changeFund={this.changeFund}
          onChangeComplete={this.onChangeComplete}
          isEditCustom={isEditCustom}
          customEtfs={customEtfs}
          customEditToggle={this.customEditToggle}
          customSave={this.customSave}
          customName={customName}
          customNameChange={this.customNameChange}
          isEmptyCustomPortfolio={isEmptyCustomPortfolio}
          errorMessageCustom={errorMessageCustom}
          customPortfolioId={customPortfolioId}
          activePortfolioId={activePortfolioId}
          isShowModalAllocation={isShowModalAllocation}
          changeModalAllocation={this.changeModalAllocation}
          dontShowModalAllocation={dontShowModalAllocation}
          customEnabled={customEnabled}
          customBeta={customBeta}
          balancingEtf={balancingEtf}
          isSuperRegistration={isSuperRegistration}
          supportLinks={supportLinks}
          moderatePlusOn={moderatePlusOn}
          onChangeEtf={this.handleChangeEtf}
          isProPortfolioEnabled={isProPortfolioEnabled}
          isSuperPlusPortfolioEnabled={isSuperPlusPortfolioEnabled}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user.user,
  errorMessage: state.user.errorMessage,
  recurringDepositSettings: selectRecurringDepositSettings(state),
  portfolios: ownProps?.isChangeBasePortfolioPage
    ? selectBaseUserPortfolios(state)
    : selectUserPortfolios(state),
  superUser: state.user.superUser,
  portfolioId: state.user.portfolioId,
  isShowErrorModal: state.user.isShowErrorModal,
  portfolioFunds: state.user.portfolioFunds,
  customPortfolio: state.user.customPortfolio,
  customPortfolioId: state.user.customPortfolioId,
  errorMessageCustom: state.user.errorMessageCustom,
  child: state.dependencyUsers.child,
  isBasicPortfolioActive: selectIsBasicPortfolioActive({
    isEditKids: ownProps.isEditKids,
    isSuper: ownProps.isSuper,
  })(state),
  portfolioType: selectActivePortfolioType(state),
  basePortfolio: selectBaseFundTag({ isRaizKids: ownProps.isEditKids })(state),
  isProPortfolioEnabled: selectIsProPortfolioEnabled(state),
  moderatePlusOn: selectIsModeratePlusEnabled(state),
  supportLinks: selectSupportLinks(state),
  isInvestmentsVisible: !selectIsUserAlertOpen(
    ALERT_TYPES.registration.incompleteFundingAccount,
  )(state),
  isSuperPlusPortfolioEnabled: selectIsSuperPlusPortfolioEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActions, dispatch),
  spendingActions: bindActionCreators(spendingAnalyticsActions, dispatch),
  actionsDependency: bindActionCreators(dependencyActions, dispatch),
  proPortfolioActions: bindActionCreators(portfolioActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ChangePortfolio));

ChangePortfolio.defaultProps = {
  user: {},
  errorMessage: '',
  portfolios: [],
  isDashboard: false,
  isSuper: false,
  isEditKids: false,
  isSuperRegistration: false,
  superUser: {},
  isFuture: false,
  isRegistration: false,
  goBack() {},
  isShowErrorModal: false,
  isKidsRegistration: false,
  history: {},
  isSmsf: false,
  portfolioFunds: [],
  customPortfolio: {},
  child: {},
  errorMessageCustom: '',
  portfolioType: null,
  isProPortfolioEnabled: false,
  moderatePlusOn: false,
  supportLinks: {},
  isChangeBasePortfolioPage: false,
  recurringDepositSettings: {},
  navigate() {},
  isSuperPlusPortfolioEnabled: false,
  isInvestmentsVisible: false,
};

ChangePortfolio.propTypes = {
  spendingActions: PropTypes.shape({
    getRecurringDepositSettings: PropTypes.func,
  }).isRequired,
  actions: PropTypes.shape({
    getUserData: PropTypes.func,
    resetProps: PropTypes.func,
    setPortfolioType: PropTypes.func,
    fundsPreview: PropTypes.func,
    getCustomPortfolio: PropTypes.func,
    saveSavingsRate: PropTypes.func,
    getStore: PropTypes.func,
    getPortfolioFunds: PropTypes.func,
    savePortfolio: PropTypes.func,
    closeModal: PropTypes.func,
    customPortfolioError: PropTypes.func,
    customPortfolioSave: PropTypes.func,
    getPortfolios: PropTypes.func,
    getCustomPortfolioById: PropTypes.func,
    getSuperUserData: PropTypes.func,
  }).isRequired,
  child: PropTypes.shape({
    dependency_user: PropTypes.shape({
      portfolio: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
  actionsDependency: PropTypes.shape({
    savePortfolio: PropTypes.func,
    getDependencyUser: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    user_type: PropTypes.string,
    email: PropTypes.string,
    allocation_profile_id: PropTypes.string,
    alerts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  errorMessage: PropTypes.string,
  portfolios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  recurringDepositSettings: PropTypes.shape({
    amount: PropTypes.number,
    frequency: PropTypes.string,
    frequency_identifier: PropTypes.string,
  }),
  isDashboard: PropTypes.bool,
  isSmsf: PropTypes.bool,
  isSuper: PropTypes.bool,
  isEditKids: PropTypes.bool,
  isSuperRegistration: PropTypes.bool,
  isKidsRegistration: PropTypes.bool,
  superUser: PropTypes.shape({
    allocation_profile_id: PropTypes.string,
  }),
  isFuture: PropTypes.bool,
  isRegistration: PropTypes.bool,
  goBack: PropTypes.func,
  isShowErrorModal: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
  }),
  portfolioFunds: PropTypes.arrayOf(PropTypes.shape({})),
  customPortfolio: PropTypes.shape({
    portfolio: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      projection_graph_type: PropTypes.string,
      etfs: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  errorMessageCustom: PropTypes.string,
  t: PropTypes.func.isRequired,
  proPortfolioActions: PropTypes.shape({
    getProPortfolio: PropTypes.func,
    setActivePortfolioId: PropTypes.func,
  }).isRequired,
  portfolioType: PropTypes.string,
  isChangeBasePortfolioPage: PropTypes.bool,
  isProPortfolioEnabled: PropTypes.bool,
  moderatePlusOn: PropTypes.bool,
  supportLinks: PropTypes.shape({
    supportWhatsapp: PropTypes.string,
    supportEmail: PropTypes.string,
    supportTelephone: PropTypes.string,
  }),
  navigate: PropTypes.func,
  isSuperPlusPortfolioEnabled: PropTypes.bool,
  isInvestmentsVisible: PropTypes.bool,
};
