import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { toggleInfoModal } from 'store/modals/actions';
import { updateFundsPreview } from 'store/portfolio/actions';
import { selectIsAllocationExhausted } from 'store/portfolio/selectors';
import { usePortfolioCustomizationRoute } from 'pages/Portfolio/subpages/PortfolioCustomization/hooks';

export const useUpdateFundAllocation = ({
  id,
  allocation,
  max_allocation,
  name,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isFundsLoading, isAllocationExhausted } = useSelector((state) => ({
    isFundsLoading: state.portfolio.isFundsLoading,
    isAllocationExhausted: selectIsAllocationExhausted(state),
  }));

  const [isCurrentFundLoading, setIsCurrentFundLoading] = useState(false);
  const [currentAllocation, setCurrentAllocation] = useState(allocation);

  const { isBitcoinFundPage, isResidentialPropertyFundsListPage } =
    usePortfolioCustomizationRoute();

  const onAllocationExceeded = (alignedAllocation) => {
    setCurrentAllocation(alignedAllocation);

    const translationKey =
      isBitcoinFundPage || isResidentialPropertyFundsListPage
        ? 'allocationExhausted'
        : 'etfStocksRpf';

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t(
            `portfolio.allocationExhaustedModal.${translationKey}.title`,
          ),
          description: t(
            `portfolio.allocationExhaustedModal.${translationKey}.description`,
            {
              allocation: alignedAllocation.toFixed(1),
              name,
            },
          ),
        },
      }),
    );
  };

  const updateFundAllocation = ({ updatedAllocationValue }) => {
    setIsCurrentFundLoading(true);

    dispatch(
      updateFundsPreview({
        fundId: id,
        updatedAllocationValue,
        max_allocation,
        onAllocationExceeded,
      }),
    ).then(() => {
      setIsCurrentFundLoading(false);
    });
  };

  return {
    updateFundAllocation,
    isFundsLoading,
    isCurrentFundLoading,
    currentAllocation,
    setCurrentAllocation,
    isAllocationExhausted,
  };
};
