import { SET_FIREBASE_CONFIG } from './consts';
import { IFirebaseConfigReducer } from './types';

const initialState = {
  featuresConfig: {
    zakatPayments: {
      enabled: false,
      emails: [],
      campaignIDsV2: [],
    },
    contentfulBlog: {},
    portfolio: {
      proPortfolioOn: false,
      proPortfolioUsers: [],
      moderatePlusOn: false,
      moderatePlusUsers: [],
      superPlus: {
        enabled: false,
        emails: [],
      },
    },
    paymentSubscription: {
      enabled: false,
      emails: [],
      investmentFlow: false,
    },
    raizRewards: {
      pointsIds: [],
      emails: [],
      enabled: false,
    },
    'raizRewards2.0': {
      enabled: false,
      emails: [],
    },
    'raizKids2.0': {
      savingGoalOn: false,
      savingGoalEmails: [],
    },
    homeOwnership: {
      enabled: false,
      emails: [],
    },
    showSignUpPromo: false,
    showInviteQR: false,
    bankVerificationOn: false,
    bankVerificationUsers: [],
    visaEnabled: {
      enabled: false,
      emails: [],
    },
    spendingInstitutions: {
      enabled: false,
      emails: [],
    },
    isNewFundingChangeable: false,
    roundUpsAccountMfaOn: false,
    roundUpsAccountMfaUsers: [],
    showSuperFundUser: false,
    isReferralsEnabled: false,
    dividends: {
      enabled: false,
      emails: [],
      super: {
        enabled: false,
        emails: [],
      },
      kids: {
        enabled: false,
        emails: [],
      },
    },
    insurance: {
      insuranceStatusOn: false,
      emails: [],
    },
    identityVerificationCOFA: {
      enabled: false,
      emails: [],
    },
    yodleeFastlink: {
      enabled: false,
      emails: [],
    },
    newCardBindingFlow: {
      enabled: false,
      emails: [],
    },
  },
  supportConfig: {},
  paymentsConfig: {},
};

const firebaseConfig: IFirebaseConfigReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SET_FIREBASE_CONFIG:
      return {
        ...state,
        ...action.firebaseConfig,
      };
    default:
      return state;
  }
};

export default firebaseConfig;
