import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BaseButton } from 'components/elements/Deprecated__Button';
import { DatePickerWrapper } from 'components/form/datePicker/styles';
import { RaizTheme } from 'theme';
import { BaseModalWrapper } from 'components/elements/ModalWrapper';

export const ModalWrapper = styled(BaseModalWrapper)`
  display: flex;
  justify-content: flex-start;
  min-height: 455px;
  margin: 100px auto;
  max-height: calc(100vh - 200px);
`;

export const FormStyled = styled.form`
  width: 100%;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 32px;
`;

export const ModalTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: ${({ theme }) => theme.lineHeights.length['3md']};
  text-align: center;
`;

export const ModalSubTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes['2md']};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  gap: 10px;
  justify-content: center;
  line-height: ${({ theme }) => theme.lineHeights.length['3md']};
  margin-top: 20px;
  text-align: center;
`;

export const ModalDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes['4sm']};
  line-height: ${({ theme }) => theme.lineHeights.length['3sm']};
  margin-top: 12px;
  opacity: 0.54;
  text-align: center;
`;

export const ModalButton = styled(BaseButton)`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-top: 24px;
  min-width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.niagaraHover};
  }
`;

const customCheckboxLabelStyles = css`
  label {
    margin-top: 12px;
    line-height: 22px;
  }
`;

export const CheckboxWrapper = styled.div`
  ${customCheckboxLabelStyles}
`;

interface ICustomLinkStylesProps {
  theme: RaizTheme;
}

const customLinkStyles = ({ theme }: ICustomLinkStylesProps) => css`
  a {
    color: ${theme.colors.niagara};
  }
`;

export const DisclaimerWrapper = styled.div`
  color: ${({ theme }) => theme.colors.dustyGray};
  font-size: ${({ theme }) => theme.fontSizes['5sm']};

  ${customLinkStyles}
`;

export const CustomDatePickerWrapper = styled(DatePickerWrapper)`
  margin: 16px 0 0;
`;
