import { createSelector } from '@reduxjs/toolkit';

import { checkIsFeatureEnabled } from './utils';
import { IRootFirebaseConfigState } from './types';

import {
  selectBrandCampaignId,
  selectIsXCCBSNEnabled,
  selectUser,
  selectUserEmail,
} from 'store/user/selectors';
import { selectRewardBrand } from 'store/rewards/selectors';
import { AU_APP } from 'constants/localeConfigs';
import { checkIsDependentUser } from 'utils/user';

const selectPortfolioConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.portfolio;

export const selectIsProPortfolioEnabled = createSelector(
  [selectPortfolioConfig, selectUserEmail],
  (portfolioConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: portfolioConfig?.proPortfolioOn,
      enabledForUsers: portfolioConfig?.proPortfolioUsers,
      userEmail,
    }),
);

export const selectIsSuperPlusPortfolioEnabled = createSelector(
  [selectPortfolioConfig, selectUserEmail],
  (portfolioConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: portfolioConfig?.superPlus?.enabled,
      enabledForUsers: portfolioConfig?.superPlus?.emails,
      userEmail,
    }),
);

export const selectContentfulBlogConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.contentfulBlog;

const selectZakatPaymentConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.zakatPayments;

export const selectIsZakatPaymentEnabled = createSelector(
  [selectZakatPaymentConfig, selectUserEmail, selectRewardBrand, selectUser],
  (zakatPaymentConfig, userEmail, rewardBrand, user) => {
    const isZakatPaymentFeatureEnabled = checkIsFeatureEnabled({
      isFeatureEnabled: zakatPaymentConfig?.enabled,
      enabledForUsers: zakatPaymentConfig?.emails,
      userEmail,
    });

    const isCurrentRewardBrandZakat = !!zakatPaymentConfig?.campaignIDsV2?.find(
      ({ id }) => id === rewardBrand.campaign_id,
    );

    const isDependentUser = checkIsDependentUser(user);

    return (
      isZakatPaymentFeatureEnabled &&
      isCurrentRewardBrandZakat &&
      !isDependentUser
    );
  },
);

export const selectZakatDataForCurrentBrand = createSelector(
  [selectZakatPaymentConfig, selectBrandCampaignId],
  (zakatPaymentConfig, brandCampaignId) =>
    zakatPaymentConfig?.campaignIDsV2?.find(({ id }) => id === brandCampaignId),
);

export const selectRaizRewardsConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.raizRewards;

const selectNewRewardsConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig['raizRewards2.0'];

export const selectIsNewRewardsEnabled = createSelector(
  [selectNewRewardsConfig, selectUserEmail],
  (newRewardsConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: AU_APP || newRewardsConfig?.enabled,
      enabledForUsers: newRewardsConfig?.emails,
      userEmail,
    }),
);

const selectHomeOwnershipConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.homeOwnership;

export const selectIsHomeOwnershipEnabled = createSelector(
  [selectHomeOwnershipConfig, selectUserEmail],
  (homeOwnershipConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: homeOwnershipConfig?.enabled,
      enabledForUsers: homeOwnershipConfig?.emails,
      userEmail,
    }),
);

export const selectIsSignUpPromoEnabled = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.showSignUpPromo;

export const selectIsInviteQREnabled = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.showInviteQR;

const selectSupportConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.supportConfig;

export const selectSupportLinks = createSelector(
  [selectSupportConfig],
  (supportConfig) => ({
    supportWhatsapp: supportConfig?.supportWhatsapp || '',
    supportEmail: supportConfig?.supportEmail || '',
    supportTelephone: supportConfig?.supportTelephone || '',
    raizCareUrlWhatsapp: supportConfig?.raizCareUrlWhatsapp || '',
    raizCareUrlLine: supportConfig?.raizCareUrlLine || '',
    raizCareUrlWebChat: supportConfig?.raizCareUrlWebChat || '',
  }),
);

const selectBankVerificationOn = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.bankVerificationOn;

const selectBankVerificationUsers = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.bankVerificationUsers;

export const selectIsBankVerificationEnabled = createSelector(
  [selectBankVerificationOn, selectBankVerificationUsers, selectUserEmail],
  (bankVerificationOn, bankVerificationUsers, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: bankVerificationOn,
      enabledForUsers: bankVerificationUsers,
      userEmail,
    }),
);

export const selectIsModeratePlusEnabled = createSelector(
  [selectPortfolioConfig, selectUserEmail],
  (portfolioConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: portfolioConfig?.moderatePlusOn,
      enabledForUsers: portfolioConfig?.moderatePlusUsers,
      userEmail,
    }),
);

const selectVisaEnabledConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.visaEnabled;

export const selectIsVisaEnabled = createSelector(
  [selectVisaEnabledConfig, selectUserEmail],
  (visaEnabledConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: visaEnabledConfig?.enabled,
      enabledForUsers: visaEnabledConfig?.emails,
      userEmail,
    }),
);

const selectSpendingInstitutionsConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.spendingInstitutions;

export const selectIsSpendingInstitutionsEnabled = createSelector(
  [selectSpendingInstitutionsConfig, selectUserEmail],
  (spendingInstitutionsConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: spendingInstitutionsConfig?.enabled,
      enabledForUsers: spendingInstitutionsConfig?.emails,
      userEmail,
    }),
);

export const selectIsNewFundingChangeable = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.isNewFundingChangeable;

const selectIdentityVerificationCOFAConfig = (
  state: IRootFirebaseConfigState,
) => state.firebaseConfig.featuresConfig.identityVerificationCOFA;

export const selectIsIdentityVerificationCOFA = createSelector(
  [selectIdentityVerificationCOFAConfig, selectUserEmail],
  (COFAConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: COFAConfig?.enabled,
      enabledForUsers: COFAConfig?.emails,
      userEmail,
    }),
);

const selectRoundUpsAccountMfaOn = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.roundUpsAccountMfaOn;

const selectRoundUpsAccountMfaUsers = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.roundUpsAccountMfaUsers;

export const selectIsRoundUpsAccountMfaEnabled = createSelector(
  [selectRoundUpsAccountMfaOn, selectRoundUpsAccountMfaUsers, selectUserEmail],
  (roundUpsAccountMfaOn, roundUpsAccountMfaUsers, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: roundUpsAccountMfaOn,
      enabledForUsers: roundUpsAccountMfaUsers,
      userEmail,
    }),
);

export const selectIsSuperFundUserEnabled = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.showSuperFundUser;

export const selectIsReferralsEnabled = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.isReferralsEnabled;

const selectKidsConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig['raizKids2.0'];

export const selectIsKidsInvestmentGoalEnabled = createSelector(
  [selectKidsConfig, selectUserEmail],
  (kidsConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: AU_APP || kidsConfig?.savingGoalOn,
      enabledForUsers: kidsConfig?.savingGoalEmails,
      userEmail,
    }),
);

export const selectIsRaizRewardsEnabled = createSelector(
  [selectRaizRewardsConfig],
  (raizRewardsConfig) => !!raizRewardsConfig?.enabled,
);

const selectDividendsConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.dividends;

export const selectIsDividendsEnabled = createSelector(
  [selectDividendsConfig, selectUserEmail],
  (dividendsConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: dividendsConfig?.enabled,
      enabledForUsers: dividendsConfig?.emails,
      userEmail,
    }),
);

export const selectIsSuperDividendsEnabled = createSelector(
  [selectDividendsConfig, selectUserEmail],
  (dividendsConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: dividendsConfig?.super?.enabled,
      enabledForUsers: dividendsConfig?.super?.emails,
      userEmail,
    }),
);

export const selectIsKidsDividendsEnabled = createSelector(
  [selectDividendsConfig, selectUserEmail],
  (dividendsConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: dividendsConfig?.kids?.enabled,
      enabledForUsers: dividendsConfig?.kids?.emails,
      userEmail,
    }),
);

const selectInsuranceConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.insurance;

export const selectIsInsuranceStatusEnabled = createSelector(
  [selectInsuranceConfig, selectUserEmail],
  (insuranceConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: insuranceConfig?.insuranceStatusOn,
      enabledForUsers: insuranceConfig?.emails,
      userEmail,
    }),
);

const selectYodleeConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.yodleeFastlink;

export const selectIsYodleeFastlinkEnabled = createSelector(
  [selectYodleeConfig, selectUserEmail, selectIsXCCBSNEnabled],
  (yodleeConfig, userEmail, isXCCBSNEnabled) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: yodleeConfig?.enabled,
      enabledForUsers: yodleeConfig?.emails,
      userEmail,
    }) || isXCCBSNEnabled,
);

const selectNewCardBindingFlowConfig = (state: IRootFirebaseConfigState) =>
  state.firebaseConfig.featuresConfig.newCardBindingFlow;

export const selectIsNewCardBindingFlowEnabled = createSelector(
  [selectNewCardBindingFlowConfig, selectUserEmail],
  (spendingInstitutionsConfig, userEmail) =>
    checkIsFeatureEnabled({
      isFeatureEnabled: spendingInstitutionsConfig?.enabled,
      enabledForUsers: spendingInstitutionsConfig?.emails,
      userEmail,
    }),
);
