import PropTypes from 'prop-types';

import EditFund from './FundItemActions/FundItemActions';
import {
  FundItemAmount,
  FundItemBottomWrapper,
  FundItemPeriod,
  FundItemSymbol,
  FundItemVerticalLine,
  FundItemYearToDateChanges,
} from './styles';

import { formatNumber } from 'utils/formatters/formatNumber';
import { NUMBER_FORMATS } from 'utils/formatters/consts';

export default function FundItemBottom({ fund }) {
  const {
    id,
    symbol,
    allocation,
    current_price,
    max_allocation,
    ytd_return,
    name,
  } = fund || {};

  return (
    <FundItemBottomWrapper>
      <FundItemSymbol>{symbol}</FundItemSymbol>

      <FundItemVerticalLine />

      <FundItemAmount>
        {formatNumber({
          value: current_price,
          maximumFractionDigits: 2,
        })}
      </FundItemAmount>

      <FundItemVerticalLine />

      <FundItemYearToDateChanges isNotProfitable={ytd_return < 0}>
        {formatNumber({
          value: ytd_return,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          type: NUMBER_FORMATS.percent,
        })}
      </FundItemYearToDateChanges>

      <FundItemPeriod>1Y</FundItemPeriod>

      <EditFund
        id={id}
        allocation={allocation}
        max_allocation={max_allocation}
        name={name}
      />
    </FundItemBottomWrapper>
  );
}

FundItemBottom.propTypes = {
  fund: PropTypes.shape({
    symbol: PropTypes.string,
    percent: PropTypes.number,
  }).isRequired,
};
