import { updateBasePortfolio } from 'store/portfolio/actions';
import {
  selectActivePortfolioId,
  selectIsPortfolioLoading,
} from 'store/portfolio/selectors';
import { usePortfolioBackButtonHandler } from 'pages/Portfolio/components/PortfolioBackButton/hooks';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAppDispatch } from 'store/hooks/useAppDispatch';

export const useUpdateBasePortfolio = () => {
  const dispatch = useAppDispatch();
  const { handleGoBack } = usePortfolioBackButtonHandler();

  const activePortfolioId = useAppSelector(selectActivePortfolioId);
  const isPortfolioLoading = useAppSelector(selectIsPortfolioLoading);

  const handleChangeBasePortfolio = () => {
    dispatch(
      updateBasePortfolio({
        activePortfolioId,
        onSuccess: handleGoBack,
      }),
    );
  };

  return {
    handleChangeBasePortfolio,
    isPortfolioLoading,
  };
};
