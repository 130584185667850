import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

import {
  INVESTMENT_REGISTRATION,
  INVESTMENTS_REGISTRATION,
  OTHER_NOTIFICATION,
  OTHER_REGISTRATION,
  PERSONAL_REGISTRATION,
  PORTFOLIO_REGISTRATION,
} from '../../../store/dependentUser/types';

class ProgressBar extends Component {
  componentDidMount() {
    const progressBarQuery = document.querySelector('.progress-state');

    const progressFirstElement = document.querySelector(
      '.progress-state > div span',
    );

    const progressFirstElementLeft = progressFirstElement
      ? progressFirstElement.offsetLeft
      : 0;

    if (progressFirstElementLeft) {
      progressBarQuery.style.marginLeft = `-${progressFirstElementLeft}px`;
    }
  }

  render() {
    const { activeStep, stepName, t } = this.props;

    const checkActiveStep =
      activeStep === PORTFOLIO_REGISTRATION ||
      activeStep === INVESTMENT_REGISTRATION ||
      activeStep === OTHER_REGISTRATION
        ? 'already'
        : '';

    const checkOtherStep =
      activeStep === OTHER_NOTIFICATION ||
      activeStep === OTHER_REGISTRATION ||
      activeStep === PORTFOLIO_REGISTRATION
        ? 'already'
        : '';

    const checkOtherActiveStep =
      activeStep === OTHER_NOTIFICATION || activeStep === OTHER_REGISTRATION
        ? 'already'
        : '';

    const isActiveInvestmentStep =
      activeStep === INVESTMENTS_REGISTRATION ? 'already' : '';

    return (
      <div className="top-part">
        <div className="progress-state -id-progress">
          <div
            className={`${
              activeStep === PERSONAL_REGISTRATION
                ? `active ${stepName === 'info' ? '-in-progress' : ''}`
                : checkActiveStep
            }`}
          >
            <Trans t={t} i18nKey="registrationPage.progressBar.personal" />

            <div className="dot">
              <div className="dot-line -left" />

              <div className="dot-line -right" />
            </div>
          </div>

          <div
            className={`${
              activeStep === INVESTMENT_REGISTRATION
                ? `active ${
                    stepName === 'recurring' || stepName === 'portfolio'
                      ? '-in-progress'
                      : ''
                  }`
                : checkOtherStep
            }`}
          >
            {t('registrationPage.progressBar.investments')}

            <div className="dot">
              <div className="dot-line -left" />

              <div className="dot-line -right" />
            </div>
          </div>

          <div
            className={`${
              activeStep === PORTFOLIO_REGISTRATION
                ? `active ${
                    stepName === 'recurring' || stepName === 'select'
                      ? '-in-progress'
                      : ''
                  }`
                : checkOtherActiveStep
            }`}
          >
            {t('registrationPage.progressBar.portfolio')}

            <div className="dot">
              <div className="dot-line -left" />

              <div className="dot-line -right" />
            </div>
          </div>

          <div
            className={`${
              activeStep === OTHER_REGISTRATION
                ? 'active'
                : isActiveInvestmentStep
            }`}
          >
            {t('common.other')}

            <div className="dot">
              <div className="dot-line -left" />

              <div className="dot-line -right" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProgressBar);

ProgressBar.propTypes = {
  activeStep: PropTypes.string,
  stepName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

ProgressBar.defaultProps = {
  activeStep: '',
  stepName: '',
};
