import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { selectIsYodleeFastlinkEnabled } from 'store/firebaseConfig/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';

export const useConnectionStatusTitle = (isSpending?: boolean) => {
  const { t } = useTranslation();
  const isYodleeFastlinkEnabled = useAppSelector(selectIsYodleeFastlinkEnabled);

  const title = useMemo(() => {
    if (isYodleeFastlinkEnabled) {
      return t(
        `registrationPage.bankAccountConnectionStatus.title.${
          isSpending ? 'roundUps' : 'funding'
        }`,
      );
    }

    return t('registrationPage.bankAccountConnectionStatus.title.default');
  }, [isSpending, isYodleeFastlinkEnabled, t]);

  return title;
};
