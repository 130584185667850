import { BANK_PROVIDERS } from 'store/fundingAccount/consts';

interface ICheckIsEligibleForFastlink {
  isYodleeFastlinkEnabled: boolean;
  provider: string;
}

export const checkIsEligibleForFastlink = ({
  isYodleeFastlinkEnabled,
  provider,
}: ICheckIsEligibleForFastlink) =>
  (isYodleeFastlinkEnabled && provider !== BANK_PROVIDERS.yodlee) ||
  provider === BANK_PROVIDERS.yodleeFastlink;
