import { useTranslation } from 'react-i18next';

import { useKidsAccessRejectionReason } from './hooks';
import {
  Banner,
  CloseButton,
  Description,
  Logo,
  ModalWrapper,
  Title,
} from './styles';
import { KIDS_ACCESS_REJECTION_SOURCE } from './consts';

import { Button } from 'components/elements/Button';
import { IconButton } from 'components/elements/IconButton';

interface IKidsAccessRejectionModalProps {
  toggleKidsAccessRejectionModal: (isOpen: boolean) => void;
  isRejectionModalOpen: boolean;
  rejectionSource: string;
}

export default function KidsAccessRejectionModal({
  isRejectionModalOpen,
  toggleKidsAccessRejectionModal,
  rejectionSource,
}: IKidsAccessRejectionModalProps) {
  const { t } = useTranslation();

  const { getKidsAccessRejectionReason } =
    useKidsAccessRejectionReason(rejectionSource);

  return (
    <ModalWrapper
      isOpen={isRejectionModalOpen}
      onRequestClose={() => toggleKidsAccessRejectionModal(false)}
      shouldCloseOnOverlayClick
      isRewards={rejectionSource === KIDS_ACCESS_REJECTION_SOURCE.rewards}
    >
      <CloseButton>
        <IconButton
          icon="closeWhite"
          onClick={() => toggleKidsAccessRejectionModal(false)}
          size={19}
        />
      </CloseButton>

      <Logo />

      <Banner />

      <Title>{t('kidsAccessRejectionModal.title')}</Title>

      <Description>{getKidsAccessRejectionReason}</Description>

      <Button
        label={t('common.ok')}
        onClick={() => toggleKidsAccessRejectionModal(false)}
        variant="transparent"
      />
    </ModalWrapper>
  );
}
