import styled from '@emotion/styled';

import { Icon } from 'components/elements/Icon';
import { Button } from 'components/elements/Button';

export const RoundUpsIcon = styled(Icon)`
  margin-right: 13px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SeeMoreBanksButton = styled(Button)`
  margin-top: 25px;
  padding-left: 55px;
  padding-right: 64px;
  width: auto;
`;

export const AddAnotherCardButton = styled(Button)`
  width: auto;
`;

export const DataSharingIcon = styled(Icon)`
  margin-right: 13px;
`;

export const InfinitiumFormWrapper = styled.form`
  display: none;
`;
