import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import {
  PORTFOLIO_INFORMATION_MODAL_TIMEOUT,
  PORTFOLIO_PLANS,
  PORTFOLIO_SEARCH_PARAMS,
  SAFE_ALLOCATION_THRESHOLD,
} from './consts';
import getPortfolioColor from './utils/getPortfolioColor';
import { FUNDS_TAGS } from './subpages/PortfolioCustomization/consts';

import { useIsSuperRegistration } from 'store/user/hooks/useIsSuperRegistration';
import { PATHS } from 'constants/paths';
import { ALERT_TYPES } from 'constants/alerts';
import { selectIsUserAlertOpen } from 'store/user/selectors';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/comonConstants';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import {
  getProPortfolio,
  getProPortfolioColors,
  setActivePortfolioId,
  updateProPortfolio,
} from 'store/portfolio/actions';
import {
  getPortfolios,
  getSuperUserData,
  getUserData,
  savePortfolio as savePortfolioUser,
} from 'store/user/actions';
import {
  savePortfolio as savePortfolioKids,
  getDependencyUser,
} from 'store/dependentUser/actions';
import {
  selectSavedPortfolioId,
  selectActivePortfolioType,
  selectIsBasicPortfolioActive,
  selectProPortfolio,
  selectUserPortfolios,
  selectActivePortfolioId,
  selectBaseFundTag,
  selectIsProPortfolioLoading,
  selectPortfolioName,
  selectProPortfolioColors,
  selectPortfolioParams,
  selectPortfolioLocation,
} from 'store/portfolio/selectors';
import { selectChild } from 'store/dependentUser/selectors';
import { toggleInfoModal } from 'store/modals/actions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { DEFAULT_PORTFOLIO_PRO_NAME } from 'store/portfolio/consts';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

export const useProPortfolio = () => {
  const { basePortfolio, fundTags, isProPortfolioLoading, proPortfolio } =
    useSelector((state) => ({
      basePortfolio: state.portfolio?.proPortfolio?.structure?.portfolio || {},
      fundTags: state.portfolio?.proPortfolio?.structure?.funds_tags || [],
      isProPortfolioLoading: state.portfolio?.isProPortfolioLoading,
      proPortfolio: selectProPortfolio(state),
    }));

  return {
    basePortfolio,
    fundTags,
    isProPortfolioLoading,
    proPortfolio,
  };
};

export const usePortfolioColor = () => {
  const dispatch = useDispatch();

  const { savedPortfolioColor, isProPortfolioColorLoading, portfolioType } =
    useSelector((state) => ({
      savedPortfolioColor:
        state.portfolio?.proPortfolio?.structure?.color_set || {},
      isProPortfolioColorLoading: state.portfolio?.isProPortfolioColorLoading,
      portfolioType: selectActivePortfolioType(state),
    }));

  const { isProPortfolio, isRegistration } = useAppSelector(
    selectPortfolioLocation,
  );

  const { isParamsLoaded } = useAppSelector(selectPortfolioParams);

  const portfolioColor = getPortfolioColor({
    portfolioType,
    portfolioColor: savedPortfolioColor,
    isProPortfolio,
    isRegistration,
  });

  useEffect(() => {
    if (isParamsLoaded) {
      dispatch(getProPortfolioColors());
    }
  }, [dispatch, isParamsLoaded]);

  return {
    portfolioColor,
    isProPortfolioColorLoading,
    savedPortfolioColor,
  };
};

export const usePortfolioPlan = ({ isBasicPortfolioActive }) => {
  const [_, setSearchParams] = useSearchParams();

  const { childId, portfolioPlan, isParamsLoaded } = useAppSelector(
    selectPortfolioParams,
  );

  const { isPortfolioCustomization } = useAppSelector(selectPortfolioLocation);

  const isProPortfolioLoading = useSelector(selectIsProPortfolioLoading);

  useEffect(() => {
    if (
      isParamsLoaded &&
      !isProPortfolioLoading &&
      !portfolioPlan &&
      !isPortfolioCustomization
    ) {
      setSearchParams(
        {
          ...(childId && { childId }),
          [PORTFOLIO_SEARCH_PARAMS.portfolioPlan]: isBasicPortfolioActive
            ? PORTFOLIO_PLANS.basic
            : PORTFOLIO_PLANS.pro,
        },
        { replace: true },
      );
    }
  }, [
    isBasicPortfolioActive,
    portfolioPlan,
    setSearchParams,
    isPortfolioCustomization,
    isProPortfolioLoading,
    childId,
    isParamsLoaded,
  ]);

  return { portfolioPlan };
};

export const usePortfolio = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { childId, isSuper, isParamsLoaded } = useAppSelector(
    selectPortfolioParams,
  );

  const isRaizKids = !!childId;

  const { isProPortfolio, isRegistration } = useAppSelector(
    selectPortfolioLocation,
  );

  const {
    basePortfolio,
    userPortfolios,
    proPortfolio,
    activePortfolioId,
    isBasicPortfolioActive,
    savedPortfolioId,
    isProPortfolioLoading,
    proPortfolioColors,
  } = useAppSelector((state) => ({
    basePortfolio: selectBaseFundTag({ isRaizKids, isSuper })(state),
    userPortfolios: selectUserPortfolios(state),
    proPortfolio: selectProPortfolio(state),
    activePortfolioId: selectActivePortfolioId(state),
    isBasicPortfolioActive: selectIsBasicPortfolioActive({
      isRaizKids,
      isSuper,
    })(state),
    savedPortfolioId: selectSavedPortfolioId({
      isRaizKids,
      isSuper,
    })(state),
    isProPortfolioLoading: selectIsProPortfolioLoading(state),
    proPortfolioColors: selectProPortfolioColors(state),
  }));

  const [activePortfolioPlan, setActivePortfolioPlan] = useState(
    PORTFOLIO_PLANS.basic,
  );

  const isInvestmentsVisible = !useAppSelector(
    selectIsUserAlertOpen(ALERT_TYPES.registration.incompleteFundingAccount),
  );

  const isSuperRegistration = useIsSuperRegistration();

  const { portfolioPlan } = usePortfolioPlan({
    isBasicPortfolioActive,
  });

  const isBasicPortfolio = portfolioPlan === PORTFOLIO_PLANS.basic;

  useEffect(() => {
    if (
      (isParamsLoaded && isSuper && !savedPortfolioId) ||
      (!isSuper && isParamsLoaded)
    ) {
      dispatch(getPortfolios(isSuper)).then(() => {
        if (isRaizKids) {
          dispatch(getDependencyUser()).then(() => {
            dispatch(getProPortfolio());
          });
        } else if (isSuper) {
          dispatch(getSuperUserData()).then(() => {
            dispatch(getProPortfolio());
          });
        } else {
          dispatch(getProPortfolio());
        }
      });
    }
  }, [
    dispatch,
    childId,
    isRaizKids,
    isSuper,
    savedPortfolioId,
    isParamsLoaded,
  ]);

  useEffect(() => {
    if (isBasicPortfolioActive) {
      setActivePortfolioPlan(PORTFOLIO_PLANS.basic);
    } else {
      setActivePortfolioPlan(PORTFOLIO_PLANS.pro);
    }
  }, [setActivePortfolioPlan, isBasicPortfolioActive]);

  const updateActivePortfolio = () => {
    const savePortfolio = isRaizKids ? savePortfolioKids : savePortfolioUser;

    if (isBasicPortfolio) {
      dispatch(
        savePortfolio({
          portfolioId: activePortfolioId,
          childId,
          isRegistration,
          isInvestmentsVisible,
          isSuper,
        }),
      ).then(() => {
        if (isSuper) {
          dispatch(getSuperUserData());
        }
      });

      dispatch(setActivePortfolioId(activePortfolioId));
    } else if (isProPortfolio) {
      if (!proPortfolio?.id) {
        dispatch(
          updateProPortfolio({
            values: {
              name: DEFAULT_PORTFOLIO_PRO_NAME,
            },
            updatedPortfolioColor: proPortfolioColors[0],
          }),
        );
      } else {
        dispatch(
          savePortfolio({
            portfolioId: proPortfolio?.id,
            childId,
            isRegistration,
            isSuper,
            isInvestmentsVisible,
          }),
        ).then(() => {
          if (isSuper) {
            dispatch(getSuperUserData());
          }
        });
      }
    }

    dispatch(getUserData());
  };

  const openInformationModal = () => {
    if (isSuper && isBasicPortfolioActive) {
      setTimeout(() => {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              title: t('portfolio.informationModal.title'),
              description: t('portfolio.informationModal.description'),
              acceptButtonText: t('portfolio.informationModal.acceptButton'),
              isCloseButtonVisible: false,
              onAccept: () => {
                updateActivePortfolio();
              },
            },
          }),
        );
      }, PORTFOLIO_INFORMATION_MODAL_TIMEOUT);

      return;
    }

    updateActivePortfolio();
  };

  const openConfirmationModals = (currentPortfolioName) => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('portfolio.confirmationModal.title'),
          description: t('portfolio.confirmationModal.description', {
            currentPortfolioName: capitalizeFirstLetter(currentPortfolioName),
          }),
          rejectButtonText: t('common.cancel'),
          acceptButtonText: t('common.change'),
          isButtonHorizontal: true,
          onAccept: openInformationModal,
        },
      }),
    );
  };

  const handleOpenChangePortfolioModal = () => {
    if (isRegistration) {
      updateActivePortfolio();

      return;
    }

    if (isSuperRegistration) {
      openConfirmationModals().then(() => {
        navigate(PATHS.super.registration);
      });

      return;
    }

    if (isBasicPortfolio) {
      openConfirmationModals(
        userPortfolios?.find(({ id }) => id === activePortfolioId)?.name ||
          basePortfolio.name,
      );

      return;
    }

    if (isProPortfolio) {
      openConfirmationModals(PORTFOLIO_PLANS.pro);
    }
  };

  const isActivePortfolio = isBasicPortfolio
    ? activePortfolioId === savedPortfolioId
    : activePortfolioPlan === portfolioPlan;

  const isProPortfolioExist = !!proPortfolio?.id;

  return {
    isActivePortfolio,
    handleOpenChangePortfolioModal,
    updateActivePortfolio,
    isRaizKids,
    isProPortfolioExist,
    isProPortfolioLoading,
  };
};

export const useSafeAllocationWarningModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const showSafeAllocationWarning = useCallback(() => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          description: t('portfolio.safeAllocationWarningModal.description'),
        },
      }),
    );
  }, [dispatch, t]);

  return { showSafeAllocationWarning };
};

export const useCheckSafeAllocationThreshold = (initialAllocation = 0) => {
  const previousAllocationRef = useRef(initialAllocation);
  const { showSafeAllocationWarning } = useSafeAllocationWarningModal();
  const { fundTag } = useParams();
  const isDisabled = [FUNDS_TAGS.rpf, FUNDS_TAGS.rrpf].includes(fundTag);

  const checkSafeAllocationThreshold = useMemo(
    () =>
      debounce(async (nextAllocation) => {
        if (isDisabled) return;

        const previousAllocation = previousAllocationRef.current;

        if (
          nextAllocation > SAFE_ALLOCATION_THRESHOLD &&
          previousAllocation <= SAFE_ALLOCATION_THRESHOLD
        ) {
          showSafeAllocationWarning();
        }

        previousAllocationRef.current = nextAllocation;
      }, DEFAULT_DEBOUNCE_TIME),
    [isDisabled, showSafeAllocationWarning],
  );

  return { checkSafeAllocationThreshold };
};

// TODO: https://acornsau.atlassian.net/browse/RAIZ-6345
export const usePortfolioName = () => {
  const portfolioName = useAppSelector(selectPortfolioName);
  const child = useAppSelector(selectChild);

  const { isRegistration, isKids } = useAppSelector(selectPortfolioLocation);

  const isPortfolioNameFormattingNeeded =
    isRegistration &&
    isKids &&
    portfolioName &&
    !portfolioName?.includes(child?.name);

  const portfolioNameFormatted = isPortfolioNameFormattingNeeded
    ? `${portfolioName} - ${child?.name}`
    : portfolioName;

  return portfolioNameFormatted;
};
