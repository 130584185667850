import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';

import {
  ModalWrapper,
  CloseButtonWrapper,
  ModalTitle,
  ModalSubTitle,
  ModalDescription,
} from './styles';
import { VerificationForm } from './VerificationForm';
import { emptyOption } from './consts';
import { VerificationSkeleton } from './VerificationSkeleton/VerificationSkeleton';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { SelectField } from 'components/form/select';
import { IconButton } from 'components/elements/IconButton';
import { Icon } from 'components/elements/Icon';
import { useIdentityVerificationsSourcesQuery } from 'store/identityVerification/api';
import { ISource } from 'store/identityVerification/types';

interface IIdentityVerificationModal {
  isOpen: boolean;
  closeRequest: () => void;
  isUserTarget?: boolean;
}

export const IdentityVerificationModal = ({
  isOpen,
  closeRequest,
  isUserTarget,
}: IIdentityVerificationModal) => {
  const { t } = useTranslation();

  const { data: verificationSources = [], isFetching } =
    useIdentityVerificationsSourcesQuery(undefined, {
      skip: !isOpen,
    });

  const form = useForm({
    defaultValues: {
      document_type: [emptyOption],
    },
    reValidateMode: 'onChange',
  });

  const { watch, reset } = form;

  const documentType = watch('document_type');
  const selectedDocumentType = documentType[0].value;
  const isDocumentTypeSelected = selectedDocumentType !== '';

  const documentSources = verificationSources.map((source) => {
    const subSourceTitle = source?.sources?.[0]?.title;

    const title = source?.sources?.length === 1 ? subSourceTitle : source.title;

    return {
      value: source.method_id,
      label: title,
    };
  });

  const documentTypeOptions = [emptyOption, ...documentSources];

  const verificationSource = useMemo(() => {
    const selectedForm = verificationSources.find(
      (source: ISource) => source.method_id === selectedDocumentType,
    );

    return selectedForm as ISource;
  }, [verificationSources, selectedDocumentType]);

  const handleCloseModal = () => {
    closeRequest();

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.isAutoVerificationModalOpen,
      'true',
    );

    reset();
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick
    >
      <CloseButtonWrapper>
        <IconButton icon="close" onClick={handleCloseModal} size={18} />
      </CloseButtonWrapper>

      <ModalTitle>
        {t('settingsPage.identityVerificationModal.title')}
      </ModalTitle>

      <ModalSubTitle>
        <Icon size={24} icon="secured" />

        {t('common.space')}

        {t('settingsPage.identityVerificationModal.subtitle')}
      </ModalSubTitle>

      <ModalDescription>
        {t('settingsPage.identityVerificationModal.description')}
      </ModalDescription>

      <FormProvider {...form}>
        {isFetching ? (
          <VerificationSkeleton />
        ) : (
          <SelectField
            label={t(
              'settingsPage.identityVerificationModal.documentTypeLabel',
            )}
            options={documentTypeOptions}
            name="document_type"
          />
        )}
      </FormProvider>

      {isDocumentTypeSelected && (
        <VerificationForm
          data={verificationSource}
          isUserTarget={isUserTarget}
          resetMainForm={reset}
          closeRequest={closeRequest}
        />
      )}
    </ModalWrapper>
  );
};
