import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BANK_LINKING_STATUS } from '../consts';
import { IBankAccountConnectionStatusProps } from '../types';

import { selectConnectionStatusData } from 'store/fundingAccount/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';
import {
  setConnectionStatusData,
  setIsBankAccountLinkingStep,
} from 'store/fundingAccount/actions';

export const useConnectionFailedModal = ({
  onUnmount,
}: Pick<IBankAccountConnectionStatusProps, 'onUnmount'>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const connectionStatusData = useAppSelector(selectConnectionStatusData);

  const isFailed = connectionStatusData?.status === BANK_LINKING_STATUS.failed;

  useEffect(() => {
    if (isFailed) {
      const handleStopLinkingProcess = () => {
        dispatch(toggleInfoModal({ isInfoModalVisible: false }));

        dispatch(setIsBankAccountLinkingStep(false));

        dispatch(setConnectionStatusData({ status: '', message: '' }));

        onUnmount();
      };

      dispatch(
        toggleInfoModal({
          isInfoModalVisible: true,
          config: {
            title: t('common.oops'),
            description: connectionStatusData?.message,
            onAccept: handleStopLinkingProcess,
            onReject: handleStopLinkingProcess,
          },
        }),
      );
    }
  }, [connectionStatusData?.message, dispatch, onUnmount, isFailed, t]);
};
