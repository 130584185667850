// TODO: https://acornsau.atlassian.net/browse/RAIZ-6610
import Firebase from 'firebase/app';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import ReactPixel from 'react-facebook-pixel';
import dayjs from 'dayjs';
import { t } from 'i18next';

import {
  CLOSE_MODAL,
  RESET_PROPS,
  SHOW_LOADER,
  SHOW_VERIFICATION_MODAL,
  GET_SUPER_USER_DATA_SUCCESS,
  PORTFOLIO_SAVE_SUCCESS,
  REGISTRATION_PERSONAL_INFO,
  OPEN_LOGOUT_MODAL,
  CLOSE_LOGOUT_MODAL,
} from '../commonTypes';
import { showGlobalError } from '../errors/actions';
import { AU_APP, MY_APP, BLOG_API } from '../../constants/localeConfigs';
import { showFundingSourceError } from '../fundingAccount/actions';
import { checkIsSmsfUser, checkIsDependentUser } from '../../utils/user';
import { ISO_DATE_FORMAT } from '../../config/date';
import { BLOG_POSTS_COUNT } from '../../components/layout/Widgets/BlogWidget/consts';
import { SUITABILITIES_PICKER_ID } from '../../pages/Settings/consts';
import { API_TAGS } from '../consts';

import {
  GO_TO_LOGIN,
  LOGIN_ERROR,
  UNAUTHORIZED,
  LOGOUT_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  GET_USER_STORE,
  USER_UPDATE_ERROR,
  NEW_PASSWORD_ERROR,
  GO_TO_REGISTRATION,
  REGISTRATION_ERROR,
  VERIFICATION_DEVICE,
  SEND_INVESTS_SUCCESS,
  NEW_PASSWORD_SUCCESS,
  SHOW_ERROR_GET_TRANSACTIONS,
  CLOSE_ACCOUNT_SUCCESS,
  CLOSE_ACCOUNT_LOADING,
  VERIFY_QUESTION_ERROR,
  VERIFY_PASSWORD_ERROR,
  GET_USER_DATA_SUCCESS,
  GET_PORTFOLIOS_SUCCESS,
  REGISTRATION_STEP_AUTH,
  SHOW_ERROR_SEND_INVESTS,
  CHANGE_PASSWORD_SUCCESS,
  GET_TRANSACTIONS_SUCCESS,
  SAVE_SAVINGS_RATE_SUCCESS,
  SHOW_ERROR_CLOSE_QUESTIONS,
  GET_CLOSE_QUESTIONS_SUCCESS,
  GET_REWARDS_SUMMARY_SUCCESS,
  GET_USER_INVITATION_SUCCESS,
  GET_ACCOUNT_STRENGTH_SUCCESS,
  REGISTRATION_PERSONAL_ADDRESS,
  REGISTRATION_ACCOUNT_QUESTIONS,
  REGISTRATION_BANK_ACCOUNT_BANKS,
  GET_SUPER_USER_ACCOUNTS_SUCCESS,
  GET_ACCOUNT_SUMMARY_ROUND_SUCCESS,
  REGISTRATION_INVESTMENTS_INVESTMENT,
  REGISTRATION_ACCOUNT_AGEREEMENT_FORM,
  GO_TO_TERMS,
  ERROR_CREATE_USER_ON_REGISTRATION,
  CHANGE_ADDRESS_DATA,
  GET_REQUIRED_DOCUMENTS_SUCCESS,
  REGISTRATION_INVESTING_ERROR,
  LINK_FUNDING_NOW,
  REGISTRATION_PERSONAL_TAXRESIDENT,
  REGISTRATION_ACCOUNT_MYKAD,
  GO_TO_FUNDING,
  UPLOAD_SUCCESS,
  UPLOAD_FAILED,
  SET_PORTFOLIO_TYPE,
  PORTFOLIO_SAVE_ERROR,
  GET_COUNTRIES_SUCCESS,
  CREATE_IDENTIFIER_SUCCESS,
  GET_IDENTIFIERS_SUCCESS,
  DELETE_IDENTIFIER_SUCCESS,
  SHOW_ADD_TAX,
  GET_SIGNUP_PROGRESS_SUCCESS,
  GET_SECURITY_QUESTION_SUCCESS,
  GET_USER_DATA_ERROR,
  REGISTRATION_ACCOUNT_OTHER,
  GET_BLOG_POSTS_SUCCESS,
  HIDE_BLOG,
  WITHDRAW_ALL_MONEY,
  GET_PORTFOLIO_FUNDS,
  GET_CUSTOM_PORTFOLIO_SUCCESS,
  GET_NEW_PORTFOLIO_ID,
  GET_CUSTOM_PORTFOLIO_PREVIEW_SUCCESS,
  CUSTOM_PORTFOLIO_ERROR,
  REGISTRATION_SUPERFUND_INFO,
  GET_SUPER_FUND_DETAILS_SUCCESS,
  GET_SUPER_FUND_MEMBERS_SUCCESS,
  REGISTRATION_SUPERFUND_MEMBERS,
  REGISTRATION_CREATE_MEMBERS_SUCCESS,
  SET_SMSF_USER_TYPE_SUCCESS,
  SHOW_WITHDRAWAL,
  LINK_WITHDRAWAL_NOW,
  OPEN_PORTFOLIO,
  GET_FEATURES_SUCCESS,
  UPDATE_USER_DATA_SUCCESS,
  CLOSE_VERIFICATION_MODAL,
  START_BANK_VERIFICATION,
  RE_SEND_INVEST,
  GET_CPF_SUCCESS,
  APPLY_CPF_SUCCESS,
  SHOW_RDV_MODAL,
  RDV_ERROR,
  CLOSE_RDV_MODAL_ERROR,
  RDV_VERIFICATION_SUCCESS,
  SHOW_PURE_PROFILE_ERROR,
  GET_DEPENDENT_USER_ACCESS_SUCCESS,
  ERROR_CREATE_USER_ON_KID_REGISTRATION,
  REMITTANCES,
  FUNDING_ERROR,
  SET_CLOSE_ACCOUNT_STATUS,
  SET_ZAKAT_TYPES,
  GET_BLOG_POSTS_LOADING,
  GET_USER_DATA_LOADING,
  SET_SECURITY_QUESTIONS,
  GET_PORTFOLIO_CATEGORIES_SUCCESS,
  GET_PORTFOLIOS_LOADING,
  SET_IS_USER_LOADING,
  SET_CURRENT_REGISTRATION_STEP,
  SET_IS_SECURITY_QUESTIONS_LOADING,
} from './consts';
import mapBlogPostsResponse from './utils/mapBlogPostsResponse';
import { selectAcceptanceDocumentVersion } from './selectors';

import { api, getHeaders } from 'services/api';
import { api as RTK } from 'store/api';
import { MYS_MY_KAD_DATE_FORMAT } from 'pages/Registration/const';
import { displayErrorModal, toggleInfoModal } from 'store/modals/actions';
import { STATUS } from 'constants/api';
import { IS_DEPENDENT_USER_REGISTRATION } from 'constants/comonConstants';
import { rewardsApi } from 'store/rewards/api';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { selectIsYodleeFastlinkEnabled } from 'store/firebaseConfig/selectors';

export function loginSuccess(userData) {
  if (AU_APP) {
    Firebase.analytics().setUserId(userData.user_uuid);
  }

  Firebase.analytics().logEvent('Login', {
    eventKey: 'Login by email successful',
  });

  return { type: LOGIN_SUCCESS, userData };
}

export function logoutSuccess() {
  if (AU_APP) {
    Firebase.analytics().setUserId(null);
  }

  sessionStorage.clear();

  return { type: LOGOUT_SUCCESS };
}

export function loginError(message, isRegistration) {
  Firebase.analytics().logEvent('Login', {
    eventKey: 'Get User Failure',
    messages: message,
  });

  return { type: LOGIN_ERROR, isRegistration };
}

export function logoutError(message) {
  return { type: LOGOUT_ERROR, message };
}

export function unauthorized(message) {
  return { type: UNAUTHORIZED, message };
}

export function registrationError(message) {
  if (message !== '') {
    Firebase.analytics().logEvent('Login', {
      eventKey: 'Login Error',
      messages: message,
    });
  }

  return { type: REGISTRATION_ERROR, message };
}

export function registrationInvestingError(message) {
  return { type: REGISTRATION_INVESTING_ERROR, message };
}

export function errorCreateUserOnRegistration(errorMessage) {
  return { type: ERROR_CREATE_USER_ON_REGISTRATION, errorMessage };
}

export function errorCreateUserOnKidRegistration(errorMessage) {
  return { type: ERROR_CREATE_USER_ON_KID_REGISTRATION, errorMessage };
}

export function userUpdateError(message, user) {
  return { type: USER_UPDATE_ERROR, message, user };
}

export function verifyPasswordsError(message, changePassword) {
  return { type: VERIFY_PASSWORD_ERROR, message, changePassword };
}

export function verifyQuestionError(message, changePassword) {
  return { type: VERIFY_QUESTION_ERROR, message, changePassword };
}

export function getRewardsSummarySuccess(rewardsSummary) {
  return { type: GET_REWARDS_SUMMARY_SUCCESS, rewardsSummary };
}

export function getUserInvitationSuccess(userInvitation) {
  return { type: GET_USER_INVITATION_SUCCESS, userInvitation };
}

export function getStoreSuccess(userStore) {
  return { type: GET_USER_STORE, userStore };
}

export function uploadSuccess(isFunding = false) {
  return { type: UPLOAD_SUCCESS, isFunding };
}

export function uploadFailed(errorMessage) {
  return { type: UPLOAD_FAILED, errorMessage };
}

export function getSignupProgressSuccess(progress) {
  return { type: GET_SIGNUP_PROGRESS_SUCCESS, progress };
}

export function getPortfoliosSuccess(portfolios) {
  return { type: GET_PORTFOLIOS_SUCCESS, portfolios };
}

export function saveSavingsRateSuccess(response) {
  return { type: SAVE_SAVINGS_RATE_SUCCESS, response };
}

export function portfolioSaveError(errorMessage, isShowwithdrawAll = false) {
  return { type: PORTFOLIO_SAVE_ERROR, errorMessage, isShowwithdrawAll };
}

export function verificationDevice(verificationToken, maskedMobileNumber) {
  return { type: VERIFICATION_DEVICE, verificationToken, maskedMobileNumber };
}

export function getCloseQuestionsSuccess(questions) {
  return { type: GET_CLOSE_QUESTIONS_SUCCESS, questions };
}

export function showGetCloseQuestions(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_CLOSE_QUESTIONS, errorMessage, errorTitle };
}

export function savePortfolioSuccess({
  isRegistration,
  portfolioId,
  isInvestmentsVisible = true,
  isKids,
}) {
  return {
    type: PORTFOLIO_SAVE_SUCCESS,
    isRegistration,
    portfolioId,
    isInvestmentsVisible,
    isKids,
  };
}

export function getFeaturesSuccess(features, supportLinks) {
  return { type: GET_FEATURES_SUCCESS, features, supportLinks };
}

export function setPortfolioType(portfolioTypeFuture) {
  return { type: SET_PORTFOLIO_TYPE, portfolioTypeFuture };
}

export function goToRegistration() {
  return { type: GO_TO_REGISTRATION };
}

export function goToLogin() {
  return { type: GO_TO_LOGIN };
}

export function goToTerms() {
  return { type: GO_TO_TERMS };
}

export function goToFundingAccount() {
  return { type: GO_TO_FUNDING };
}

export function closeAccountSuccess(errorMessage) {
  return { type: CLOSE_ACCOUNT_SUCCESS, errorMessage };
}

export function closeAccountLoading() {
  return { type: CLOSE_ACCOUNT_LOADING };
}

export function withdrawAllMoney() {
  return { type: WITHDRAW_ALL_MONEY };
}

export function uploadDocumentSuccess() {
  return { type: UPLOAD_SUCCESS };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}

export function getRequiredDocumentsSuccess(requiredDocuments) {
  return { type: GET_REQUIRED_DOCUMENTS_SUCCESS, requiredDocuments };
}

export function linkFundingNow() {
  return { type: LINK_FUNDING_NOW };
}

export function linkWithdrawalNow() {
  return { type: LINK_WITHDRAWAL_NOW };
}

export function openPortfolio() {
  return { type: OPEN_PORTFOLIO };
}

export function resetProps() {
  return { type: RESET_PROPS };
}

export function getCountriesSuccess(countries) {
  return { type: GET_COUNTRIES_SUCCESS, countries };
}

export function createIdentifierSuccess() {
  return { type: CREATE_IDENTIFIER_SUCCESS };
}

export function getIdentifiersSuccess(identifiers) {
  return { type: GET_IDENTIFIERS_SUCCESS, identifiers };
}

export function deleteIdentifiersSuccess() {
  return { type: DELETE_IDENTIFIER_SUCCESS };
}

export function showAddTax(isShowListTax) {
  return { type: SHOW_ADD_TAX, isShowListTax };
}

export function getPortfolioFundsSuccess(portfolioFunds) {
  return { type: GET_PORTFOLIO_FUNDS, portfolioFunds };
}

export function setCloseAccountStatus(closeAccountRequesStatus) {
  return { type: SET_CLOSE_ACCOUNT_STATUS, closeAccountRequesStatus };
}

export function registrationStepAuthSuccess(userData, isKids) {
  Firebase.analytics().logEvent('Registration', { eventKey: 'Login Success' });

  return { type: REGISTRATION_STEP_AUTH, userData, isKids };
}

export function registrationStepOneSuccess(userData, step) {
  return { type: step, userData };
}

export function createSMSFDetailsSuccess() {
  return { type: REGISTRATION_SUPERFUND_INFO };
}

export function createSMSFMembersSuccess() {
  return { type: REGISTRATION_CREATE_MEMBERS_SUCCESS };
}

export function setSMSFUserTypeSuccess() {
  return { type: SET_SMSF_USER_TYPE_SUCCESS };
}

export function getSMSFDetailsSuccess(smsfDetails) {
  return { type: GET_SUPER_FUND_DETAILS_SUCCESS, smsfDetails };
}

export function getSMSFMembersSuccess(smsfMembers) {
  return { type: GET_SUPER_FUND_MEMBERS_SUCCESS, smsfMembers };
}

export function getUserDataSuccess(userData, isNeedCheckRedirect) {
  return { type: GET_USER_DATA_SUCCESS, userData, isNeedCheckRedirect };
}

export function getDependentUserAccessSuccess(dependentUserAccess) {
  return { type: GET_DEPENDENT_USER_ACCESS_SUCCESS, dependentUserAccess };
}

export function updateUserDataSuccess(userData) {
  return { type: UPDATE_USER_DATA_SUCCESS, userData };
}

export function getUserDataError(errorMessage) {
  return { type: GET_USER_DATA_ERROR, errorMessage };
}

export function getSuperUserDataSuccess(superUser) {
  return { type: GET_SUPER_USER_DATA_SUCCESS, superUser };
}

export function getSuperUserAccountsSuccess(userAccounts) {
  return { type: GET_SUPER_USER_ACCOUNTS_SUCCESS, userAccounts };
}

export function getAccountSummaryRoundSuccess(roundUpsSummary) {
  return { type: GET_ACCOUNT_SUMMARY_ROUND_SUCCESS, roundUpsSummary };
}

export function changePasswordSuccess() {
  return { type: CHANGE_PASSWORD_SUCCESS };
}

export function getAccountStrengthSuccess(accountStrength) {
  return { type: GET_ACCOUNT_STRENGTH_SUCCESS, accountStrength };
}

export function getTransactionsSuccess(transactions) {
  return { type: GET_TRANSACTIONS_SUCCESS, transactions };
}

export function sendInvestsSuccess() {
  return { type: SEND_INVESTS_SUCCESS };
}

export function showErrorSendInvests(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_SEND_INVESTS, errorMessage, errorTitle };
}

export function showErrorGetTransactions(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_GET_TRANSACTIONS, errorMessage, errorTitle };
}

export function newPasswordSuccess(message) {
  return { type: NEW_PASSWORD_SUCCESS, message };
}

export function newPasswordError(errorMessage, errorTitle = '') {
  return { type: NEW_PASSWORD_ERROR, errorMessage, errorTitle };
}

export function changeAddress(addressData = {}) {
  return { type: CHANGE_ADDRESS_DATA, addressData };
}

export function showLoader() {
  return { type: SHOW_LOADER };
}

export function getSecurityQuestionSuccess(questionId, questionText) {
  return { type: GET_SECURITY_QUESTION_SUCCESS, questionId, questionText };
}

export function getBlogPostsSuccess(posts) {
  return { type: GET_BLOG_POSTS_SUCCESS, posts };
}

export function hideBlog() {
  return { type: HIDE_BLOG };
}

export function openLogoutModal() {
  return { type: OPEN_LOGOUT_MODAL };
}

export function closeLogoutModal() {
  return { type: CLOSE_LOGOUT_MODAL };
}

export function getCustomPortfolioSuccess(customPortfolio) {
  return { type: GET_CUSTOM_PORTFOLIO_SUCCESS, customPortfolio };
}

export function getCustomPortfolioPreviewSuccess(customPortfolioPreview) {
  return { type: GET_CUSTOM_PORTFOLIO_PREVIEW_SUCCESS, customPortfolioPreview };
}

export function getCpfSuccess(cpfList) {
  return { type: GET_CPF_SUCCESS, cpfList };
}

export function applyCpfSuccess(depositId) {
  return { type: APPLY_CPF_SUCCESS, depositId };
}

export function getNewPortfolioId(customPortfolioId) {
  return { type: GET_NEW_PORTFOLIO_ID, customPortfolioId };
}

export function customPortfolioError(errorMessageCustom) {
  return { type: CUSTOM_PORTFOLIO_ERROR, errorMessageCustom };
}

export function showWithdrawal(isSpendingWithdrawal = true) {
  return { type: SHOW_WITHDRAWAL, isSpendingWithdrawal };
}

export const setCurrentRegistrationStep = (currentStep) => ({
  type: SET_CURRENT_REGISTRATION_STEP,
  currentStep,
});

export function showVerificationModal(
  title = '',
  description = '',
  isShowVerificationModal = true,
) {
  return {
    type: SHOW_VERIFICATION_MODAL,
    title,
    description,
    isShowVerificationModal,
  };
}

export function closeVerificationModal(isShowVerificationModal = false) {
  return { type: CLOSE_VERIFICATION_MODAL, isShowVerificationModal };
}

export function stopBankVerification(isStartBankVerification = false) {
  return { type: START_BANK_VERIFICATION, isStartBankVerification };
}

export function reSendInvest() {
  return { type: RE_SEND_INVEST };
}

export function showRDVModal(title, description) {
  return { type: SHOW_RDV_MODAL, title, description };
}

export function RdvError(errorMessage) {
  return { type: RDV_ERROR, errorMessage };
}

export function closeRDWModalError() {
  return { type: CLOSE_RDV_MODAL_ERROR };
}

export function rdvVerificationSuccess(message) {
  return { type: RDV_VERIFICATION_SUCCESS, message };
}

export function pureProfileError() {
  return { type: SHOW_PURE_PROFILE_ERROR };
}

export function setRemittances(value) {
  return { type: REMITTANCES, value };
}

export function showFundingError(value) {
  return { type: FUNDING_ERROR, value };
}

export function setZakatTypes(zakatTypes) {
  return { type: SET_ZAKAT_TYPES, zakatTypes };
}

export function getBlogPostsLoading() {
  return { type: GET_BLOG_POSTS_LOADING };
}

export function getUserDataLoading() {
  return { type: GET_USER_DATA_LOADING };
}

export function setSecurityQuestions(securityQuestions) {
  return { type: SET_SECURITY_QUESTIONS, securityQuestions };
}

export function getPortfolioCategoriesSuccess(portfolioCategories) {
  return { type: GET_PORTFOLIO_CATEGORIES_SUCCESS, portfolioCategories };
}

export function getPortfoliosLoading() {
  return { type: GET_PORTFOLIOS_LOADING };
}

export function setIsUserLoading(isLoading) {
  return { type: SET_IS_USER_LOADING, isLoading };
}

export function setIsSecurityQuestionsLoading(isLoading) {
  return { type: SET_IS_SECURITY_QUESTIONS_LOADING, isLoading };
}

export function createRequest(data, method, endpoint, platformPrefix = '') {
  const requestData = {
    method,
    mode: 'cors',
    headers: getHeaders(),
  };

  if (data) {
    requestData.body = data;
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}${platformPrefix}/${process.env.REACT_APP_API_VERSION}/${endpoint}`,
    requestData,
  );
}

export function createOutRequest(data, method, endpoint) {
  const requestData = {
    method,
    mode: 'cors',
    headers: getHeaders(),
  };

  if (data) {
    requestData.body = data;
  }

  return fetch(`${endpoint}`, requestData);
}

export function assignFastlinkFlowToUser() {
  const data = {
    XCCBSN: true,
  };

  return async (dispatch) => {
    try {
      await api.put('user/store', {
        data,
      });
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function registrationStepAuth(savedUserAuth) {
  if (AU_APP) {
    ReactPixel.trackCustom('Lead');
  }

  let data = {
    acceptance_document_version_uuids: null,
    user: {
      email: savedUserAuth.email,
      password: savedUserAuth.password,
      profile: {},
    },
  };

  if (MY_APP) {
    const currentLang = localStorage.getItem('lang');
    let local = 'en';

    if (currentLang === 'bahasa') {
      local = 'ms';
    }

    data.user.locale = local;
  }

  if (savedUserAuth.source_id) {
    data.source_id = savedUserAuth.source_id;
  }

  const inviteCode = sessionStorage.getItem(SESSION_STORAGE_KEYS.inviteCode);
  let inviteData = {};

  if (inviteCode) {
    inviteData = {
      source_id: 'Referrals',
      source_attributes: { trackerName: 'Referrals', trackerToken: inviteCode },
      invitation_code: inviteCode,
    };
  }

  data = {
    ...data,
    udid: window.crypto.randomUUID(),
    ...inviteData,
  };

  if (AU_APP) {
    const requiredDocuments = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.requiredDocuments),
    );

    let documentId = '';

    if (requiredDocuments && requiredDocuments.acceptance_documents) {
      requiredDocuments.acceptance_documents.map((document) => {
        if (document.document_type === 'customer_agreement') {
          documentId = document.acceptance_document_version_uuid;
        }

        return document;
      });

      if (!AU_APP) {
        data.acceptance_document_version_uuids = [documentId];
      } else {
        data.acceptance_document_version_uuids = {
          deposits: [documentId],
        };
      }
    }
  }

  data = JSON.stringify(data);

  if (AU_APP) {
    data = data.replace(
      'acceptance_document_version_uuids',
      'acceptance_document_version_uuids[]',
    );
  }

  return (dispatch, getState) =>
    createRequest(data, 'POST', 'user')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(errorCreateUserOnRegistration(response.error));
        } else {
          const state = getState();

          const isYodleeFastlinkEnabled = selectIsYodleeFastlinkEnabled(state);

          if (isYodleeFastlinkEnabled) {
            dispatch(assignFastlinkFlowToUser());
          }

          dispatch(registrationError(''));

          const registration = JSON.parse(data);

          delete registration.user.password;

          registration.token = response.token;

          sessionStorage.setItem(SESSION_STORAGE_KEYS.token, response.token);

          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.registration,
            JSON.stringify(registration),
          );

          dispatch(registrationStepAuthSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function newPin(formData) {
  const data = JSON.stringify(formData);

  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/users/pin.json`, {
      method: 'PUT',
      mode: 'cors',
      body: data,
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.errors) {
          dispatch(newPasswordError(response.errors));
        } else {
          dispatch(newPasswordSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getQuestions() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/suitability`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        const questions = response.map((question) => {
          question.answers.map((answer) => {
            const copyAnswer = answer;

            if (question.picker_id === 'employment') {
              if (/(full|part)[\s-]time/gi.test(answer.answer)) {
                copyAnswer.textInput = true;

                copyAnswer.textInputName = 'employer';

                copyAnswer.textInputPlaceholder = "Employer's name?";
              }

              if (answer.answer.indexOf('Pekerja') === 0) {
                copyAnswer.textInput = true;

                copyAnswer.textInputName = 'employer';

                copyAnswer.textInputPlaceholder = "Employer's name?";
              }
            }

            if (
              !MY_APP &&
              question.picker_id === SUITABILITIES_PICKER_ID.sourceOfIncome
            ) {
              const activeOptions = [
                'Employment',
                'Business',
                'Other',
                'Perkerjaan',
                'Perniagaan',
                'Lain-lain',
              ];

              if (
                activeOptions.some((option) => answer.answer.includes(option))
              ) {
                copyAnswer.textInput = true;

                copyAnswer.textInputName = 'source_of_income_industry';
              }
            }

            if (MY_APP) {
              if (
                question?.expand_on_answer_ids?.some(
                  (answerId) => answerId === answer?.answer_id,
                )
              ) {
                copyAnswer.textInput = true;

                copyAnswer.textInputName = 'source_of_income_industry';
              }
            }

            copyAnswer.question_id = question.question_id;

            return copyAnswer;
          });

          return question;
        });

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.registrationQuestions,
          JSON.stringify(questions),
        );

        dispatch(registrationError(''));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getScurityQuestion() {
  return async (dispatch) => {
    try {
      dispatch(setIsSecurityQuestionsLoading(true));

      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/security_questions`,
      );

      const selects = response?.map((question) => ({
        label: question.text,
        value: question.id,
      }));

      dispatch(setSecurityQuestions(selects));

      dispatch(registrationError(''));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getSMSFDetails() {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/smsf/v1/details`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(registrationError(response.error));
        } else {
          dispatch(getSMSFDetailsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getSMSFMembers() {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/smsf/v1/members`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch(getSMSFMembersSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function createSMSFMembers(formData) {
  const data = JSON.stringify(formData);

  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/smsf/v1/members`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(registrationError(response.error));
        } else {
          dispatch(createSMSFMembersSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function setSMSFUserType() {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/smsf/v1/account/mark`, {
      method: 'PATCH',
      mode: 'cors',
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch(setSMSFUserTypeSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function createSMSFDetails(formData) {
  const data = JSON.stringify({
    details: {
      ...formData,
      fund_establishment_date: dayjs(formData.fund_establishment_date).format(
        ISO_DATE_FORMAT,
      ),
    },
  });

  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/smsf/v1/details`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(registrationError(response.error));
        } else {
          dispatch(createSMSFDetailsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function registrationStepAccount(
  formData,
  _,
  formObj,
  isNeedCompleete = false,
) {
  let data = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.registration),
  );

  if (!data || data === null) {
    if (
      formObj &&
      formObj.user &&
      formObj.user.user &&
      formObj.user.user.email
    ) {
      data = { user: formObj.user.user };

      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.registration,
        JSON.stringify(data),
      );
    } else {
      data = {};
    }
  }

  const step = formObj.form;
  let investmentAmount = 0;

  switch (formObj.form) {
    case REGISTRATION_ACCOUNT_AGEREEMENT_FORM:
      return registrationStepAuth(formData);
    case REGISTRATION_ACCOUNT_OTHER:
      if (formData.inviteCode) {
        data.invitation_code = formData.inviteCode;
      }

      data.user.profile.suitability = formData.suitability;

      if (AU_APP || MY_APP) {
        data.user.profile.us_resident = true;
      }

      if (formData.employer) {
        data.user.profile.employer = formData.employer;
      } else if (MY_APP && formData.emptyEmployer) {
        data.user.profile.employer = '';
      }

      if (MY_APP) {
        data.user.profile.source_of_income_industry =
          formData.source_of_income_industry;
      }

      break;
    case REGISTRATION_ACCOUNT_QUESTIONS:
      return { type: step };
    case REGISTRATION_PERSONAL_INFO:
      data.user.profile.first_name = formData.first_name;

      if (!MY_APP) {
        data.user.profile.last_name = formData.last_name;

        data.user.profile.phone_number = formData.phone_number
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace('-', '');

        data.user.profile.dob = dayjs(formData.dob).format(ISO_DATE_FORMAT);

        Firebase.analytics().logEvent('Reg_NameDOB');
      } else {
        data.user.profile.phone_number = formData.phone_number;
      }

      if (formData.us_resident) {
        data.user.profile.us_resident = formData.us_resident;
      }

      if (
        formData.question1 &&
        formData.question1[0] &&
        formData.question1[0].value
      ) {
        data.user.profile.question1 = formData.question1[0].value;
      } else {
        data.user.profile.question1 = formObj.question1;
      }

      data.user.profile.answer1 = formData.answer1;

      if (MY_APP) {
        data.user.profile.my_kad = formData.my_kad;

        data.user.profile.gender = formData.gender;

        data.user.profile.dob = dayjs(
          formData.dob,
          MYS_MY_KAD_DATE_FORMAT,
        ).format(ISO_DATE_FORMAT);
      }

      if (formData.inviteCode) {
        data.invitation_code = formData.inviteCode;
      }

      break;
    case REGISTRATION_PERSONAL_TAXRESIDENT:
      if (formData.tin_resident) {
        data.user.profile.tin_country = formData.tin_country?.[0]?.name;

        if (formData?.no_tin_reason?.length) {
          data.user.profile.no_tin_reason = formData.no_tin_reason[0].label;
        } else {
          data.user.profile.ssn = formData.ssn;
        }
      } else {
        delete data.user.profile.tin_country;

        delete data.user.profile.no_tin_reason;

        delete data.user.profile.ssn;
      }

      data.user.pep = formData.pep;

      break;
    case REGISTRATION_PERSONAL_ADDRESS:
      Firebase.analytics().logEvent('Registration', {
        eventKey: 'Address|Complete',
      });

      Firebase.analytics().logEvent('Reg_Address2');

      if (AU_APP || MY_APP) {
        data.user.profile.us_citizen = !!formData?.us_citizen;

        if (formObj.addressMapBox) {
          data.user.profile.address2 = formObj.addressMapBox;
        } else {
          data.user.profile.address1 = formData.address1;

          if (AU_APP) {
            data.user.profile.street_number = formData.street_number;
          }

          data.user.profile.city = formData.city;

          data.user.profile.zip = formData.zip;

          if (formData.state !== '') {
            data.user.profile.state = formData.state[0].value || formData.state;
          } else {
            data.user.profile.state = formObj.stateAddress;
          }
        }
      }

      break;
    case REGISTRATION_INVESTMENTS_INVESTMENT:
      if (!isNeedCompleete) {
        if (formData && formData.investment) {
          investmentAmount = formData.investment.amount;
        }

        return (dispatch) =>
          createRequest(
            '',
            'POST',
            `investments?investment[type]=credit&investment[amount]=${investmentAmount}`,
          )
            .then((response) => response.json())
            .then((response) => {
              const onFinishRegistration = () => {
                dispatch(
                  registrationStepAccount(
                    {},
                    '',
                    { form: REGISTRATION_INVESTMENTS_INVESTMENT },
                    true,
                  ),
                );
              };

              if (response.error) {
                dispatch(registrationInvestingError(response.error));
              } else {
                Firebase.analytics().logEvent('CompleteRegistration');

                if (AU_APP) {
                  ReactPixel.trackCustom('Purchase');
                }

                dispatch(
                  toggleInfoModal({
                    isInfoModalVisible: true,
                    config: {
                      title: t('registrationPage.investmentSuccess.title'),
                      description: t(
                        'registrationPage.investmentSuccess.description',
                      ),
                      onAccept: onFinishRegistration,
                      onReject: onFinishRegistration,
                    },
                  }),
                );
              }
            })
            .catch((error) => dispatch(showGlobalError(error)));
      }

      break;
    case REGISTRATION_BANK_ACCOUNT_BANKS:
    case REGISTRATION_ACCOUNT_MYKAD:
    case REGISTRATION_SUPERFUND_INFO:
    case REGISTRATION_SUPERFUND_MEMBERS:
      break;
    default:
      return [];
  }

  if (isNeedCompleete) {
    if (data.user && data.user.profile) {
      data.user.profile.registration_complete = true;
    } else {
      data.user = {
        profile: {
          registration_complete: true,
        },
      };
    }

    Firebase.analytics().logEvent('Registration', { eventKey: 'Complete' });

    Firebase.analytics().logEvent('RegistrationUnlinked');

    if (AU_APP) {
      ReactPixel.trackCustom('CompleteRegistration');
    }
  }

  data = {
    user: {
      profile: data?.user?.profile || {},
    },
  };

  data = JSON.stringify(data);

  return (dispatch) =>
    createRequest(data, 'PUT', 'user')
      .then((response) => response.json())
      .then((response) => {
        if (response?.error) {
          dispatch(
            toggleInfoModal({
              isInfoModalVisible: true,
              config: {
                description: response.error,
              },
            }),
          );

          dispatch(registrationError(response.error));
        } else {
          dispatch(registrationError(''));

          sessionStorage.setItem(SESSION_STORAGE_KEYS.registration, data);

          dispatch(registrationStepOneSuccess(response, step));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getDependentAccess(res) {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/v1/dependent_user`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else if (res) {
          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.kidsAccess,
            JSON.stringify(response),
          );

          if (MY_APP) {
            dispatch(showWithdrawal());
          }

          dispatch(registrationStepAuthSuccess(response, true));

          dispatch(getDependentUserAccessSuccess(response));
        } else {
          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.kidsAccess,
            JSON.stringify(response),
          );

          dispatch(getDependentUserAccessSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function sendInvestemtns(amount) {
  return (dispatch) =>
    createRequest(
      '',
      'POST',
      `investments?investment[type]=credit&investment[amount]=${amount}`,
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(registrationInvestingError(response.error));
        } else {
          Firebase.analytics().logEvent('CompleteRegistration');

          if (AU_APP) {
            ReactPixel.trackCustom('Purchase');
          }

          dispatch(
            registrationStepAccount(
              {},
              '',
              { form: REGISTRATION_INVESTMENTS_INVESTMENT },
              true,
            ),
          );
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getUserData(isNeedCheckRedirect = true) {
  return async (dispatch) => {
    try {
      dispatch(getUserDataLoading());

      const response = await api.get('/user');

      if (response.error) {
        dispatch(getUserDataError(response.error));
      } else if (!response.user && !response.user.email) {
        dispatch(goToLogin());
      } else {
        let userSuitability = [];

        if (response.user.suitability) {
          userSuitability = response.user.suitability.map((suitability) => ({
            picker_id: suitability.picker_id,
            user_answer_id: suitability.answer_id,
          }));
        }

        const user = response;

        if (user.user) {
          user.user.profile = {};
        }

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.userSuitability,
          JSON.stringify(userSuitability),
        );

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.user,
          JSON.stringify(response),
        );

        if (checkIsSmsfUser(user)) {
          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.isSmsfUser,
            checkIsSmsfUser(user),
          );

          dispatch(getSMSFDetails());
        }

        if (checkIsDependentUser(user)) {
          dispatch(getDependentAccess());
        }

        dispatch(getUserDataSuccess(response, isNeedCheckRedirect));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(unauthorized(error?.response?.statusText));
      }

      dispatch(showGlobalError(error));
    }
  };
}

export function login(formData, isReload = false, isRegistration = false) {
  const formDataTrimmed = { ...formData, email: formData.email?.trim() };

  const data = isReload
    ? formDataTrimmed
    : {
        ...formDataTrimmed,
        udid: window.crypto.randomUUID(),
      };

  return async (dispatch) => {
    dispatch(setIsUserLoading(true));

    try {
      const response = await api.post('/sessions', data).catch((error) => {
        const errorData = error.response.data;

        if (errorData.verification_token) {
          // TODO: https://acornsau.atlassian.net/browse/RAIZ-6515
          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.reloginData,
            JSON.stringify(data),
          );

          dispatch(
            verificationDevice(
              errorData.verification_token,
              errorData?.masked_mobile_number,
            ),
          );
        } else {
          if (
            !isRegistration &&
            [STATUS.unauthorized, STATUS.forbidden, STATUS.badRequest].includes(
              error.response.status,
            )
          ) {
            dispatch(
              displayErrorModal({
                errorMessage: errorData.error,
              }),
            );
          }

          dispatch(loginError(errorData.error, isRegistration));
        }
      });

      sessionStorage.setItem(SESSION_STORAGE_KEYS.token, response.token);

      dispatch(loginSuccess(response));

      dispatch(getUserData());
    } catch (error) {
      dispatch(showGlobalError(error));
    } finally {
      dispatch(setIsUserLoading(false));
    }
  };
}

export function updateProfile(formData) {
  const data = formData;

  if (
    formData.state &&
    formData.state.length &&
    Array.isArray(formData.state)
  ) {
    data.state = formData.state[0].value;
  }

  const profile = JSON.stringify({
    user: {
      profile: {
        phone_number: data.phone_number,
        street_number: data.street_number,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        ...{ suitability: data.suitability },
        employer: data.employer,
        source_of_income_industry: data.source_of_income_industry,
        ...(AU_APP && { us_citizen: !!data?.us_citizen }),
        ...(data?.ssn !== '' && { ssn: data.ssn }),
        ...(data?.tfn_status === 'exempt' && { tfn_status: data.tfn_status }),
      },
    },
  });

  return (dispatch) =>
    createRequest(profile, 'PUT', 'user')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(userUpdateError(response.error, data));
        } else {
          dispatch(updateUserDataSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function verifyPasswords(formData, question1) {
  const data = JSON.stringify({
    old_password: formData.old_password,
    new_password: formData.new_password,
  });

  return (dispatch) =>
    createRequest(data, 'POST', 'user/verify_passwords')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(verifyPasswordsError(response.error, formData));
        } else {
          const dataForReset = JSON.stringify({
            old_password: formData.old_password,
            new_password: formData.new_password,
            security_answers: [
              {
                answer: formData.answer,
                question_id: question1,
              },
            ],
          });

          createRequest(dataForReset, 'PUT', 'user/reset_password')
            .then((responseReset) => responseReset.json())
            .then((responseReset) => {
              if (responseReset.error) {
                dispatch(verifyQuestionError(responseReset.error, formData));
              } else {
                dispatch(changePasswordSuccess());
              }
            })
            .catch((error) => dispatch(showGlobalError(error)));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getAccountStrength() {
  const apiVersion = process.env.REACT_APP_API_VERSION;

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${apiVersion}/user/account_strength`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getAccountStrengthSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getTransactions(params = { offset: 1, limit: 25 }) {
  return (dispatch, getState) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/transactions?` +
        `offset=${params.offset}&limit=${params.limit}` +
        `${params.status ? `&status=${params.status}` : ''}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        const {
          user: { transactions },
        } = getState();

        if (response.error) {
          dispatch(
            displayErrorModal({
              errorMessage: response.error,
            }),
          );
        } else {
          dispatch(
            getTransactionsSuccess(
              params.offset > 1
                ? {
                    ...response,
                    items: [...transactions.items, ...response.items],
                  }
                : response,
            ),
          );
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function investTransactions(data, onSuccess) {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/transactions`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: getHeaders(),
        body: JSON.stringify(data),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            displayErrorModal({
              errorMessage: response.error,
            }),
          );
        } else {
          dispatch(RTK.util.invalidateTags([API_TAGS.roundUpsSettings]));

          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function checkBankVerification() {
  return (dispatch) =>
    createRequest(null, 'GET', 'accounts/current')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showFundingSourceError(response.error));
        } else if (
          response.funding_source &&
          response.funding_source.external_verification_status &&
          response.funding_source.external_verification_status !== 'failed' &&
          response.funding_source.external_verification_status !== 'success'
        ) {
          setTimeout(() => {
            dispatch(checkBankVerification());
          }, 4000);
        } else if (
          response.funding_source &&
          response.funding_source.external_verification_status &&
          response.funding_source.external_verification_status === 'failed'
        ) {
          if (
            response.funding_source.alert &&
            response.funding_source.alert.type &&
            response.funding_source.alert.type ===
              'FUNDING_SOURCE_NOT_EXTERNALLY_VERIFIED'
          ) {
            dispatch(stopBankVerification());

            dispatch(
              showVerificationModal(
                response.funding_source.alert.title,
                response.funding_source.alert.description,
              ),
            );
          }
        } else if (
          response.funding_source &&
          response.funding_source.external_verification_status &&
          response.funding_source.external_verification_status === 'success'
        ) {
          dispatch(reSendInvest());
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function sendInvests(data, isKids = false) {
  let statusCode = null;

  const requestUrl = isKids
    ? `${process.env.REACT_APP_API_URL}/dependency_users/v1/investments`
    : `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/investments`;

  return (dispatch) =>
    fetch(requestUrl, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body: JSON.stringify(data),
    })
      .then((response) => {
        statusCode = response.status;

        return response.json();
      })
      .then((response) => {
        if (response.error) {
          dispatch(showErrorSendInvests(response.error));
        } else {
          dispatch(sendInvestsSuccess(response));
        }

        return statusCode;
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

// Referral actions

export function getRewardsSummary() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/rewards/summary`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getRewardsSummarySuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getUserInvitation() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/user/invitation`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getUserInvitationSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getStore() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/user/store`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.store,
          JSON.stringify(response),
        );

        dispatch(getStoreSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getPortfolios(isSuper = false) {
  const superPortfoliosUrl = isSuper
    ? `${process.env.REACT_APP_API_URL}/super_annuation/${process.env.REACT_APP_API_VERSION}`
    : '';

  return async (dispatch) => {
    try {
      dispatch(getPortfoliosLoading());

      const response = await api.get(`${superPortfoliosUrl}/portfolios`);

      if (response?.error) {
        dispatch(portfolioSaveError(response.error));
      }

      if (response?.message) {
        dispatch(portfolioSaveError(response.message));
      } else {
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.portfolios,
          JSON.stringify(response),
        );

        dispatch(getPortfoliosSuccess(response));
      }
    } catch (error) {
      dispatch(getUserDataError(error?.response?.data?.error));
    }
  };
}

export function saveSavingsRate(savingsRate) {
  const data = JSON.stringify({
    data: { webapp: { dashboard: { savingsRate } } },
  });

  return (dispatch) =>
    createRequest(data, 'PUT', 'user/store')
      .then((response) => response.json())
      .then((response) => {
        dispatch(saveSavingsRateSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function logout() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/sessions`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => {
        if (response.error) {
          dispatch(logoutError(response.error));
        } else {
          if (AU_APP) {
            sessionStorage.removeItem(
              SESSION_STORAGE_KEYS.sessionTripsResponse,
            );
          }

          dispatch(closeLogoutModal());

          dispatch(rewardsApi.util.resetApiState());

          dispatch(logoutSuccess());
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function savePortfolio({
  portfolioId,
  isSuper = false,
  isRegistration = false,
  isInvestmentsVisible = false,
}) {
  let params = 'user';

  if (isSuper && !isRegistration) {
    params = 'super_annuation/user';
  }

  if (isRegistration) {
    Firebase.analytics().logEvent('Registration', {
      eventKey: 'Portfolio Select|Complete',
    });
  } else {
    Firebase.analytics().logEvent('Portfolio', { eventKey: 'Changed' });
  }

  let data = JSON.stringify({ user: { allocation_profile_id: portfolioId } });

  if (isRegistration) {
    Firebase.analytics().logEvent('Registration', { eventKey: 'Complete' });

    Firebase.analytics().logEvent('RegistrationUnlinked');

    data = JSON.stringify({
      user: {
        allocation_profile_id: portfolioId,
        profile: { registration_complete: true },
      },
    });
  }

  return (dispatch, getState) =>
    createRequest(data, 'PUT', params)
      .then((response) => response.json())
      .then((response) => {
        if (
          response &&
          response.error &&
          response.error_code &&
          response.error_code === 4
        ) {
          dispatch(portfolioSaveError(response.error, true));
        } else if (response && response.error) {
          dispatch(
            toggleInfoModal({
              isInfoModalVisible: true,
              config: {
                description: response.error,
              },
            }),
          );
        } else {
          const { user } = getState();
          const isKids = checkIsDependentUser(user);

          dispatch(
            savePortfolioSuccess({
              isRegistration,
              portfolioId,
              isInvestmentsVisible,
              isKids,
            }),
          );
        }
      })
      .catch((error) => {
        if (error && error.toString() === 'TypeError: response is null') {
          dispatch(
            savePortfolioSuccess({
              isRegistration,
              portfolioId,
              isInvestmentsVisible,
            }),
          );
        } else {
          dispatch(showGlobalError(error));
        }
      });
}

export function getCloseQuestions() {
  return (dispatch) =>
    createRequest(null, 'GET', 'close/questions')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGetCloseQuestions(response.error));
        } else {
          dispatch(getCloseQuestionsSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getCloseAccountRequestStatus() {
  return async (dispatch) => {
    try {
      const response = await api
        .get('/close/requests/latest')
        .catch((error) => {
          const errorData = error.response.data;

          if (errorData) {
            dispatch(showGlobalError(errorData.error, false));
          }
        });

      if (response) {
        dispatch(setCloseAccountStatus(response));
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function closeAccount(data) {
  const requestData = {
    answer_keys: [data.primaryReason, data.secondaryReason],
    answer: data.answer,
    comments: data.comment,
  };

  return async (dispatch) => {
    dispatch(closeAccountLoading());

    try {
      await api.post('close/requests', requestData);

      await dispatch(getCloseAccountRequestStatus());

      await dispatch(getUserData());

      dispatch(closeAccountSuccess());
    } catch (error) {
      const responseData = error?.response?.data;
      const message = responseData?.message || responseData?.error;

      await dispatch(getCloseAccountRequestStatus());

      await dispatch(getUserData());

      dispatch(closeAccountSuccess(message));
    }
  };
}

export function getSuperUserData() {
  const params = 'super_annuation/user';

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/${params}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => {
        const statusCode = response.status;

        if (statusCode === 401) {
          dispatch(unauthorized(response.statusText));
        }

        return response.json();
      })
      .then((response) => {
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.superUser,
          JSON.stringify(response),
        );

        dispatch(getSuperUserDataSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getSuperUserAccounts() {
  const params = 'super_annuation/user/accounts';

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/${params}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => {
        const statusCode = response.status;

        if (statusCode === 401) {
          dispatch(unauthorized(response.statusText));
        }

        return response.json();
      })
      .then((response) => {
        dispatch(getSuperUserAccountsSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getAccountSummaryRound() {
  const params = 'account_summary/roundups';

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/${params}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getAccountSummaryRoundSuccess(response));
      })
      .catch((error) =>
        dispatch(
          displayErrorModal({
            errorMessage: error,
          }),
        ),
      );
}

export function getRequiredDocuments() {
  const params = 'acceptance_documents/required_acceptance_documents';

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/${params}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.requiredDocuments,
          JSON.stringify(response),
        );

        dispatch(getRequiredDocumentsSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function uploadDocument(document) {
  let statusCode = '';
  const formdata = new FormData();

  formdata.append('document_type', document.documentType);

  formdata.append(
    'file',
    document.documentObject,
    cyrillicToTranslit().transform(document.documentObject.name),
  );

  let url = 'user/upload';

  if (document.isFunding) {
    url = 'funding_sources/upload';
  }

  const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.token);

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/${url}`,
      {
        method: 'POST',
        body: formdata,
        headers: {
          Authorization: `token ${token}`,
        },
        redirect: 'follow',
      },
    )
      .then((response) => {
        statusCode = response.status;

        return response.json();
      })
      .then((response) => {
        if (response.error) {
          dispatch(uploadFailed(response.error));
        } else if (statusCode === 400 || statusCode === 500) {
          if (response && response.message) {
            dispatch(uploadFailed(response.message));
          } else {
            dispatch(uploadFailed(response));
          }
        } else {
          dispatch(uploadSuccess(document.isFunding));
        }

        return statusCode;
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getSignupProgress() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/` +
        'user/signup_progress',
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getSignupProgressSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getCountries() {
  return (dispatch) =>
    createRequest(null, 'GET', 'tax_identifiers/countries')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          const countries = Object.entries(response).map(([key, value]) => ({
            label: value,
            value: key,
          }));

          dispatch(getCountriesSuccess(countries));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function createIdentifier(data) {
  return (dispatch) =>
    createRequest(JSON.stringify(data), 'POST', 'tax_identifiers')
      .then((response) => response.json())
      .then(() => {
        dispatch(createIdentifierSuccess());
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getIdentifiers() {
  return (dispatch) =>
    createRequest(null, 'GET', 'tax_identifiers')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          dispatch(getIdentifiersSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function deleteIdentifier(id) {
  return (dispatch) =>
    createRequest(null, 'DELETE', `tax_identifiers/${id}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          dispatch(deleteIdentifiersSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getSecurityQuestion(token = '') {
  return (dispatch) =>
    createRequest(
      null,
      'GET',
      `user/security_question?reset_password_token=${token}`,
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          dispatch(
            getSecurityQuestionSuccess(
              response.security_question.id,
              response.security_question.text,
            ),
          );
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getBlogPosts() {
  return async (dispatch) => {
    try {
      dispatch(getBlogPostsLoading());

      fetch(`${BLOG_API}?per_page=${BLOG_POSTS_COUNT}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response || response.error) {
            dispatch(hideBlog());
          } else {
            dispatch(getBlogPostsSuccess(response));
          }
        });
    } catch (error) {
      dispatch(hideBlog());
    }
  };
}

export function getContentfulBlogPosts(contentfulBlogConfig) {
  const { apiURL, blogURL } = contentfulBlogConfig;

  return async (dispatch) => {
    try {
      dispatch(getBlogPostsLoading());

      fetch(apiURL, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response || response.error) {
            dispatch(hideBlog());
          } else {
            dispatch(
              getBlogPostsSuccess(
                mapBlogPostsResponse({
                  blogPosts: response,
                  blogURL,
                }),
              ),
            );
          }
        });
    } catch (error) {
      dispatch(hideBlog());
    }
  };
}

export function getCustomPortfolio() {
  return (dispatch) =>
    createRequest(null, 'GET', 'custom_portfolios/last')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          dispatch(getCustomPortfolioSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getCustomPortfolioById(id) {
  return (dispatch) =>
    createRequest(null, 'GET', `custom_portfolios/${id}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          dispatch(getCustomPortfolioSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getPortfolioFunds() {
  return (dispatch) =>
    createRequest(null, 'GET', 'custom_portfolios/funds')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          dispatch(getPortfolioFundsSuccess(response.funds));

          dispatch(getPortfolioCategoriesSuccess(response.portfolio));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function fundsPreview(formData, customPortfolio, isNew = false) {
  let customPortfolioNew = customPortfolio;
  const data = JSON.stringify(formData);

  return (dispatch) =>
    createRequest(data, 'POST', 'custom_portfolios/preview')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          if (!customPortfolioNew || !customPortfolioNew.portfolio) {
            customPortfolioNew = response;
          } else {
            customPortfolioNew.portfolio.risk = response.portfolio.risk;

            customPortfolioNew.portfolio.categories =
              response.portfolio.categories;
          }

          if (isNew) {
            dispatch(getCustomPortfolioSuccess(customPortfolioNew));
          } else {
            dispatch(getCustomPortfolioPreviewSuccess(customPortfolioNew));
          }
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function customPortfolioSave(formData) {
  const data = JSON.stringify(formData);

  return (dispatch) =>
    createRequest(data, 'POST', 'custom_portfolios')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(customPortfolioError(response.error));
        } else {
          dispatch(getCustomPortfolio());

          dispatch(getNewPortfolioId(response.portfolio_id));

          dispatch(getCustomPortfolioById(response.portfolio_id));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getCpf(type) {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/cpf/v1/funds/${type}`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          dispatch(getCpfSuccess(response.funds));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function applyCpf(id, amount) {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/cpf/v1/funds/${id}/deposit`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        amount,
      }),
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error, false));
        } else {
          dispatch(applyCpfSuccess(response.deposit_id));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function applyRedeem(id) {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/cpf/v1/funds/${id}/withdraw`, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error, false));
        } else {
          // dispatch(depositCPF(response.deposit_id));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function rdvVerification(formData) {
  const data = JSON.stringify(formData);

  return (dispatch) =>
    createRequest(data, 'POST', 'accounts/rdv')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(RdvError(response.error));
        } else if (response.message) {
          dispatch(rdvVerificationSuccess(response.message));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function updateSequrity(formData) {
  const user = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user));

  const registration = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.registration),
  );

  // break action if there is no needed data
  if (!user || !registration) {
    return () => null;
  }

  user.user.question1 = formData.question1;

  user.user.answer1 = formData.answer1;

  user.user.registration_complete = formData.registration_complete;

  user.uuid = registration.uuid;

  const reqData = JSON.stringify({
    user: {
      uuid: JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.registration),
      ).uuid,
      profile: {
        question1: formData.question1,
        answer1: formData.answer1,
        registration_complete: formData.registration_complete,
      },
    },
  });

  return (dispatch) =>
    createRequest(reqData, 'PUT', 'user')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(RdvError(response.error));
        } else if (response.message) {
          dispatch(rdvVerificationSuccess(response.message));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function pureProfileLogin() {
  return (dispatch) =>
    createRequest(null, 'get', 'user_offers/pure_profile_token')
      .then((response) => response.json())
      .then((response) => {
        if (response.ppToken && response.instanceDomain) {
          window.location.href = `https://${response.instanceDomain}?token=${response.ppToken}`;
        } else {
          dispatch(pureProfileError());
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function updateUserLocale(loc) {
  const data = JSON.stringify({ user: { locale: loc } });

  return (dispatch) =>
    createRequest(data, 'PUT', 'user')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getUserDataSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function registrationDependentUser(data) {
  const reqData = JSON.stringify({
    ...data,
    udid: window.crypto.randomUUID(),
  });

  return (dispatch) =>
    createRequest(reqData, 'POST', 'user/dependent_user_signup')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            displayErrorModal({
              errorMessage: response.error,
            }),
          );
        } else {
          sessionStorage.setItem(SESSION_STORAGE_KEYS.token, response.token);

          sessionStorage.setItem(IS_DEPENDENT_USER_REGISTRATION, true);

          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.registration,
            JSON.stringify({ uuid: reqData.uuid }),
          );

          dispatch(getDependentAccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function sendTradeStatement({ target = '', onSendStatementsAuSuccess }) {
  return (dispatch) =>
    createRequest(
      null,
      'POST',
      `user/send_trade_statement${target ? `?target=${target}` : ''}`,
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error, false));
        } else {
          onSendStatementsAuSuccess?.();
        }
      })
      .catch((error) => {
        dispatch(showGlobalError(error));
      });
}

export function getRemittances({
  offset = 1,
  areFailedTransactionsVisible,
  zakatSource,
}) {
  return async (dispatch, getState) => {
    try {
      const params = new URLSearchParams();

      if (offset > 1) {
        params.append('offset', offset);
      }

      if (!areFailedTransactionsVisible) {
        params.append('excluded_statuses[]', 'failed');
      }

      if (zakatSource) {
        params.append('zakat_source', zakatSource);
      }

      const response = await api
        .get(`/remittances?identifier=zakat&${params.toString()}`)
        .catch((error) => {
          const errorData = error.response.data;

          if (errorData) {
            dispatch(showGlobalError(errorData.error, false));
          }
        });

      if (response) {
        const {
          user: {
            remittances: { remittances },
          },
        } = getState();

        dispatch(
          setRemittances(
            offset > 1
              ? {
                  ...response,
                  remittances: [...remittances, ...response.remittances],
                }
              : response,
          ),
        );
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function postRemittances(formData, onSuccess) {
  const requestData = {
    amount: formData.amount,
    additional_data: {
      type: formData.type,
      year: `${formData.year}`,
      source: formData.zakatSource,
    },
  };

  return async (dispatch) => {
    try {
      const response = await api
        .post(
          `${process.env.REACT_APP_API_URL}/v2/remittances/zakat`,
          requestData,
        )
        .catch((error) => {
          const errorData = error.response.data;

          console.error(error);

          if (error.response.status === 402) {
            dispatch(
              showFundingError({
                isFundingErrorModalOpen: true,
                fundingErrorMessage: errorData.error,
              }),
            );
          } else if (errorData) {
            dispatch(showGlobalError(errorData.error, false));
          }
        });

      if (response) {
        onSuccess();
      }
    } catch (error) {
      console.error(error);

      dispatch(showGlobalError(error));
    }
  };
}

export function getZakatTypes({ zakatSource }) {
  return async (dispatch) => {
    try {
      const response = await api
        .get('/remittances/zakat_types', {
          baseURL: `${process.env.REACT_APP_API_URL}/v2`,
        })
        .catch((error) => {
          const errorData = error.response.data;

          if (errorData) {
            dispatch(showGlobalError(errorData.error, false));
          }
        });

      if (response) {
        dispatch(setZakatTypes(response?.zakat_types?.[zakatSource]));
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function saveUserData(data) {
  return async (dispatch) => {
    try {
      dispatch(getUserDataLoading());

      const response = await api.put('/user', {
        user: {
          profile: data,
        },
      });

      await dispatch(getUserDataSuccess(response));
    } catch (error) {
      dispatch(getUserDataError(error?.response?.data?.error));
    }
  };
}

export const setRegistrationCompleted = (registrationPayload) =>
  saveUserData({
    registration_complete: true,
    registrationPayload,
  });

export function setSuperAnnuationPDSAccepted() {
  return async (dispatch) => {
    try {
      dispatch(getUserDataLoading());

      const response = await api.put('/user', {
        user: {
          profile: {
            super_annuation_pds_sent_at: dayjs().format(),
          },
        },
      });

      await dispatch(getUserDataSuccess(response));
    } catch (error) {
      dispatch(getUserDataError(error?.response?.data?.error));
    }
  };
}

export function setUserPDSAccepted() {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const acceptanceDocumentVersionUuid =
        selectAcceptanceDocumentVersion(state);

      await api.post('/user/accept_document', {
        uuid: acceptanceDocumentVersionUuid,
      });
    } catch (error) {
      dispatch(getUserDataError(error?.response?.data?.error));
    }
  };
}
