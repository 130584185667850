const fontFamily = {
  textMedium: '"SF Text Medium", sans-serif',
  textRegular: '"SF Text Regular", sans-serif',
  regular: '"SF Display Regular", sans-serif',
  medium: '"SF Display Medium", sans-serif',
  semiBold: '"SF Display Semi Bold", sans-serif',
  bold: '"SF Display Black", sans-serif',
  black: '"SF Display Bold", sans-serif',
} as const;

export default fontFamily;
