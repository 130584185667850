import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import { StaticGoogleMap, Marker } from 'react-static-google-map';

import GiftCardModal from '../../../components/layout/Modals/GiftCardModal';
import { RAIZ_REWARD_PURE_PROFILE } from '../../../constants/comonConstants';

import RewardsErrorModal from './RewardsErrorModal';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';

function locationRender(location, key) {
  if (key === 0) {
    return <div key={location.id} />;
  }

  const url = `https://www.google.com/maps/dir/?api=1&destination=${location.latitude},${location.longitude}`;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" key={location.id}>
      <div className="locations__items">
        <div>{location.address}</div>
      </div>
    </a>
  );
}

export default function NewRewardInternalView({
  offer,
  isOnline,
  toggleLikeOffer,
  pureProfileLogin,
  isShowMoreLocation,
  showMoreLocation,
  isFeatured,
  isRecommended,
  isGiftCardModalVisible,
  giftCardModalToggle,
  user,
}) {
  let bagroundImg = '';
  let brandName = '';
  let logo = '';
  let title = '';
  let about = '';
  const { isAGiftCard, giftCardId } = offer;

  const { t } = useTranslation();

  if (offer.id || (!isOnline && offer.advertiserId)) {
    if (isOnline) {
      bagroundImg = offer.coverArt;

      brandName = offer.advertiser;

      ({ logo, title, about } = offer);
    } else {
      bagroundImg = offer.coverShotUrl;

      brandName = offer.advertiserName;

      logo = offer.logoUrl;

      title = offer.offerTitle;

      about = offer.advertiserAboutUs;
    }
  }

  let location = '';
  let urlDestination = '';

  if (
    offer &&
    offer.locations &&
    offer.locations[0] &&
    offer.locations[0].latitude
  ) {
    location = `${offer.locations[0].latitude},${offer.locations[0].longitude}`;

    urlDestination = `https://maps.google.com/?q=${offer.locations[0].address}&ll=${offer.locations[0].latitude},${offer.locations[0].longitude}`;
  }

  let isPureProfile = false;

  if (offer?.ctaDetails?.web?.includes(RAIZ_REWARD_PURE_PROFILE)) {
    isPureProfile = true;
  }

  return (
    <div className="rewards-internal-new">
      {!isShowMoreLocation && (
        <div>
          {offer.id || offer.offerId || (!isOnline && offer.advertiserId) ? (
            <div className="reward-internal__banner reward-internal__banner__new">
              <div>
                {isFeatured && (
                  <div className="grid__carousel-feature">
                    {t('newRewardsPage.newRewardInternal.featured')}
                  </div>
                )}

                {isRecommended && (
                  <div className="grid__carousel-feature grid__carousel-recommended">
                    {t('newRewardsPage.newRewardInternal.recommended')}
                  </div>
                )}

                <img
                  className="reward-internal__banner-background reward-internal__banner-background__new"
                  src={bagroundImg}
                  alt={brandName}
                  onError={(e) => {
                    e.target.style.display = 'none';

                    return true;
                  }}
                />

                <div
                  className="like"
                  onClick={() => {
                    toggleLikeOffer({
                      isFavourite: offer.isFavourite,
                      id: offer.id ? offer.id : offer.offerId,
                      isInstore: !isOnline,
                      isShowFavourite: true,
                      isInternal: true,
                    });
                  }}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <div
                    className={
                      offer.isFavourite ? 'like-active' : 'like-inactive'
                    }
                  />
                </div>
              </div>

              <div className="reward-internal__banner-icon reward-internal__banner-icon__new">
                <img
                  src={logo}
                  alt={brandName}
                  onError={(e) => {
                    e.target.style.display = 'none';

                    return true;
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="reward-internal__banner">
              <Skeleton width="150%" borderRadius="0" />
            </div>
          )}

          <div className="reward-internal__banner__title">
            <div className="brand">{brandName}</div>

            <div className="invest">{title}</div>

            <div>{offer.description}</div>
          </div>

          {location && (
            <div className="reward-internal__details">
              <div className="info-panel -wide">
                <div className="info-panel__block">
                  <Accordion allowZeroExpanded preExpanded={['location']}>
                    <AccordionItem key="location" uuid="location">
                      <AccordionItemHeading>
                        <AccordionItemButton className="my-finance__modal-category">
                          <div className="info-panel__block-label">
                            {t('newRewardsPage.newRewardInternal.location')}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <div className="info-panel__divider" />

                        <div className="info-panel__block-content">
                          <a
                            href={urlDestination}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <StaticGoogleMap
                              size="1280x150"
                              className="img-fluid"
                              scale="2"
                              apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                            >
                              <Marker
                                location={location}
                                color="0x0AAE77"
                                scale="2"
                              />
                            </StaticGoogleMap>
                          </a>

                          {offer.locations && offer.locations.length >= 2 && (
                            <div className="more-location">
                              <div className="more-location__address">
                                {offer.locations[0].address}
                              </div>

                              <div
                                className="more-location__btn"
                                onClick={showMoreLocation}
                                onKeyPress={() => {}}
                                role="button"
                                tabIndex="0"
                              >
                                {t('newRewardsPage.newRewardInternal.more')}
                              </div>
                            </div>
                          )}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          {offer.howItWorks && (
            <div className="reward-internal__details">
              <div className="info-panel -wide">
                <div className="info-panel__block">
                  <Accordion
                    allowZeroExpanded
                    preExpanded={location ? [] : ['howItWorks']}
                  >
                    <AccordionItem key="howItWorks" uuid="howItWorks">
                      <AccordionItemHeading>
                        <AccordionItemButton className="my-finance__modal-category">
                          <div className="info-panel__block-label">
                            {isAGiftCard
                              ? t('newRewardsPage.newRewardInternal.tips')
                              : t('newRewardsPage.newRewardInternal.how')}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <div className="info-panel__divider" />

                        <div className="info-panel__block-content">
                          {offer.howItWorks}
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          {about && (
            <div className="reward-internal__details">
              <div className="info-panel -wide">
                <div className="info-panel__block">
                  <Accordion allowZeroExpanded>
                    <AccordionItem key="aboutTheBrand">
                      <AccordionItemHeading>
                        <AccordionItemButton className="my-finance__modal-category">
                          <div className="info-panel__block-label">
                            {isAGiftCard
                              ? t('newRewardsPage.newRewardInternal.gift')
                              : t('newRewardsPage.newRewardInternal.brand')}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <div className="info-panel__divider" />

                        <div className="info-panel__block-content">{about}</div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          {(offer.termsAndConditions || offer.terms) && (
            <div className="reward-internal__details">
              <div className="info-panel -wide">
                <div className="info-panel__block">
                  <Accordion allowZeroExpanded>
                    <AccordionItem key="termsConditions">
                      <AccordionItemHeading>
                        <AccordionItemButton className="my-finance__modal-category">
                          <div className="info-panel__block-label">
                            {t('common.termsAndConditions')}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <div className="info-panel__divider" />

                        <div className="info-panel__block-content">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: offer.termsAndConditions || offer.terms,
                            }}
                          />
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          )}

          {offer.ctaDetails && offer.ctaDetails.web && (
            <div className="buttons-part">
              {!isPureProfile && (
                <a
                  href={offer.ctaDetails.web}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DeprecatedButton
                    title={offer.ctaDetails.title}
                    buttonClass="button button--submit"
                  />
                </a>
              )}

              {isPureProfile && (
                <DeprecatedButton
                  title={offer.ctaDetails.title}
                  buttonClass="button__basic-fill"
                  handleClick={pureProfileLogin}
                />
              )}
            </div>
          )}

          {isAGiftCard && (
            <div className="buttons-part">
              <DeprecatedButton
                title={t('newRewardsPage.newRewardInternal.buy')}
                buttonClass="button__basic-fill"
                handleClick={giftCardModalToggle}
              />
            </div>
          )}
        </div>
      )}

      {isShowMoreLocation &&
        offer.locations &&
        offer.locations.length !== 0 && (
          <div className="in-store rewards-search">
            <div className="profile-content settings__content-default-container">
              <div className="profile-content__header -with-navigation ">
                <div className="profile-content__title">
                  <div>{t('newRewardsPage.newRewardInternal.more')}</div>
                </div>
              </div>

              <div className="locations">
                {offer.locations.map((moreLocation, key) =>
                  locationRender(moreLocation, key),
                )}
              </div>
            </div>
          </div>
        )}

      {isGiftCardModalVisible && user?.uuid && (
        <GiftCardModal
          isGiftCardModalVisible={isGiftCardModalVisible}
          giftCardId={giftCardId}
          giftCardModalToggle={giftCardModalToggle}
          userUuid={user.uuid}
        />
      )}

      <RewardsErrorModal />
    </div>
  );
}

NewRewardInternalView.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    advertiserId: PropTypes.number,
    offerId: PropTypes.number,
    coverArt: PropTypes.string,
    coverShotUrl: PropTypes.string,
    description: PropTypes.string,
    advertiser: PropTypes.string,
    terms: PropTypes.string,
    howItWorks: PropTypes.string,
    offerTitle: PropTypes.string,
    advertiserAboutUs: PropTypes.string,
    isFavourite: PropTypes.bool,
    logoUrl: PropTypes.string,
    advertiserName: PropTypes.string,
    termsAndConditions: PropTypes.string,
    isAGiftCard: PropTypes.bool,
    giftCardId: PropTypes.string,
    ctaDetails: PropTypes.shape({
      android: PropTypes.string,
      web: PropTypes.string,
      title: PropTypes.string,
    }),
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        address: PropTypes.string,
      }),
    ),
  }).isRequired,
  isOnline: PropTypes.bool.isRequired,
  toggleLikeOffer: PropTypes.func.isRequired,
  pureProfileLogin: PropTypes.func.isRequired,
  isShowMoreLocation: PropTypes.bool.isRequired,
  showMoreLocation: PropTypes.func.isRequired,
  isFeatured: PropTypes.bool.isRequired,
  isRecommended: PropTypes.bool.isRequired,
  isGiftCardModalVisible: PropTypes.bool.isRequired,
  giftCardModalToggle: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};
