import { useTranslation } from 'react-i18next';

import {
  Card,
  Content,
  Header,
  WarningWrapper,
  WarningTitle,
  WarningDescription,
} from './styles';
import { VisaAccountItem } from './VisaAccountItem';

import { Icon } from 'components/elements/Icon';
import { IAccount } from 'store/spendingAccounts';

interface IVisaWithoutBankCardProps {
  visaWithoutBank: IAccount[];
}

export const VisaWithoutBankCard = ({
  visaWithoutBank,
}: IVisaWithoutBankCardProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Header>
        <Icon icon="warning" size={30} />

        <WarningWrapper>
          <WarningTitle>{t('settingsPage.visaWithoutBank.title')}</WarningTitle>

          <WarningDescription>
            {t('settingsPage.visaWithoutBank.description')}
          </WarningDescription>
        </WarningWrapper>
      </Header>

      <Content>
        {visaWithoutBank.map((account) => (
          <VisaAccountItem
            key={account.id}
            account={account}
            hasNoBank
            bankName="Visa"
          />
        ))}
      </Content>
    </Card>
  );
};
