import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  Title,
  Divider,
  Header,
  Row,
  Column,
  RecurringWidgetButton,
  BalanceText,
  Subtitle,
  ValueText,
  FlagIcon,
} from './styles';

import FundingModal from 'components/layout/Modals/FundingModal';
import KidsAccessRejectionModal from 'components/layout/Modals/KidsAccessRejectionModal/KidsAccessRejectionModal';
import { KIDS_ACCESS_REJECTION_SOURCE } from 'components/layout/Modals/KidsAccessRejectionModal/consts';
import { formatNumber } from 'utils/formatters/formatNumber';

interface IRecurringWidgetProps {
  currentBalance: number;
  targetAmount: number;
  openRecurringPage: () => void;
  toggleFundingModal: () => void;
  toggleKidsAccessRejectionModal: () => void;
  invAmount?: number;
  showFundingModal?: boolean;
  isRejectionModalOpen?: boolean;
  isDependentUser?: boolean;
}

export default function RecurringWidget({
  currentBalance,
  targetAmount,
  invAmount = 0,
  openRecurringPage,
  showFundingModal = false,
  toggleFundingModal,
  isRejectionModalOpen = false,
  toggleKidsAccessRejectionModal,
  isDependentUser = false,
}: IRecurringWidgetProps) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t('recurringInvestmentWidget.title')}</Title>

      <Divider />

      <Header>
        {t('recurringInvestmentWidget.balanceHeader')}

        <BalanceText>
          {formatNumber({
            value: currentBalance,
          })}
        </BalanceText>
      </Header>

      <Row>
        {!isDependentUser && (
          <Column>
            <Subtitle>
              <FlagIcon icon="goalFlag" size={25} />

              {t('recurringInvestmentWidget.goalAmount')}
            </Subtitle>

            <ValueText>
              {formatNumber({
                value: targetAmount,
              })}
            </ValueText>
          </Column>
        )}

        <Column>
          <Subtitle>
            {t('recurringInvestmentWidget.recurringInvAmount')}
          </Subtitle>

          <ValueText>
            {formatNumber({
              value: invAmount,
            })}
          </ValueText>
        </Column>
      </Row>

      <RecurringWidgetButton
        label={t('recurringInvestmentWidget.button')}
        onClick={() => openRecurringPage()}
      />

      {showFundingModal && (
        <FundingModal
          showFundingModal={showFundingModal}
          toggleFundingModal={toggleFundingModal}
        />
      )}

      {isRejectionModalOpen && (
        <KidsAccessRejectionModal
          isRejectionModalOpen={isRejectionModalOpen}
          toggleKidsAccessRejectionModal={toggleKidsAccessRejectionModal}
          rejectionSource={KIDS_ACCESS_REJECTION_SOURCE.recurring}
        />
      )}
    </Wrapper>
  );
}
