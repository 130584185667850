import { showGlobalError } from '../errors/actions';
import { toggleInfoModal } from '../modals/actions';
import { sortFundsTags } from '../../pages/Portfolio/subpages/PortfolioCustomization/components/PortfolioCustomizationContent/FundsList/Filters/utils/utils';

import {
  CUSTOM_PORTFOLIO_BASE_URL,
  FUNDS_TO_FETCH,
  DEFAULT_PORTFOLIO_PRO_NAME,
  PORTFOLIO_ACTIONS,
  BASE_PORTFOLIO_FULLY_ALLOCATED,
} from './consts';
import {
  selectUserFundsFlattened,
  selectIsBasicPortfolioActive,
  selectDefaultPortfolioId,
  selectProPortfolioId,
  selectProPortfolioColorId,
  selectSavedPortfolioName,
  selectBasePortfolioAllocation,
  selectSavedPortfolioId,
  selectUserPortfolios,
  selectProPortfolio,
  selectProPortfolioColors,
  selectPortfolioParams,
  selectIsParentProPortfolioPreview,
  selectPortfolioLocation,
} from './selectors';
import { handleAllocationExceededError } from './utils';
import createTagsParams from './utils/createTagsParams';
import {
  IColorSets,
  IFund,
  IFundDetails,
  IFundStatistics,
  IFundTagsFilter,
  IGetFunds,
  IPortfolioLocation,
  IPortfolioParams,
  IProPortfolio,
  IProPortfolioColors,
  TFundsToUpdate,
} from './types';
import { getSuperPortfolioHeaders } from './utils/getSuperPortfolioHeaders';

import { ALERT_TYPES } from 'constants/alerts';
import { selectDependentUserId } from 'store/dependentUser/selectors';
import { DEFAULT_FUNDS_TAGS_SORTING_ORDER } from 'pages/Portfolio/subpages/PortfolioCustomization/components/PortfolioCustomizationContent/FundsList/Sorting/consts';
import { api } from 'services/api';
import {
  getSuperUserData,
  getUserData,
  getUserDataSuccess,
  savePortfolio,
} from 'store/user/actions';
import { dependencyUserPortfolioSuccess } from 'store/dependentUser/actions';
import {
  selectIsUserAlertOpen,
  selectUserAllocationProfileId,
} from 'store/user/selectors';
import { checkIsSmsfUser } from 'utils/user';
import { GetState } from 'store/configureStore';
import { AppDispatch } from 'store/hooks/useAppDispatch';

export function getFundsTagsLoading() {
  return { type: PORTFOLIO_ACTIONS.GET_FUNDS_TAGS_LOADING };
}

export function getFundsTagsSuccess(fundsTags: IFundTagsFilter[]) {
  return { type: PORTFOLIO_ACTIONS.GET_FUNDS_TAGS_SUCCESS, fundsTags };
}

export function getFundsLoading() {
  return { type: PORTFOLIO_ACTIONS.GET_FUNDS_LOADING };
}

export function getFundsSuccess({ funds }: { funds: IFund[] }) {
  return { type: PORTFOLIO_ACTIONS.GET_FUNDS_SUCCESS, funds };
}

export function getProPortfolioLoading() {
  return { type: PORTFOLIO_ACTIONS.GET_PRO_PORTFOLIO_LOADING };
}

export function getProPortfolioSuccess(proPortfolio: IProPortfolio) {
  return { type: PORTFOLIO_ACTIONS.GET_PRO_PORTFOLIO_SUCCESS, proPortfolio };
}

export function getFundDetailsLoading() {
  return { type: PORTFOLIO_ACTIONS.GET_FUND_DETAILS_LOADING };
}

export function getFundStatisticsLoading() {
  return { type: PORTFOLIO_ACTIONS.GET_FUND_STATISTICS_LOADING };
}

export function getFundDetailsSuccess(fundDetails: IFundDetails) {
  return { type: PORTFOLIO_ACTIONS.GET_FUND_DETAILS_SUCCESS, fundDetails };
}

export function getFundStatisticsSuccess(fundStatistics: IFundStatistics) {
  return {
    type: PORTFOLIO_ACTIONS.GET_FUND_STATISTICS_SUCCESS,
    fundStatistics,
  };
}

export function getProPortfolioColorsSuccess(
  proPortfolioColors: IProPortfolioColors[],
) {
  return {
    type: PORTFOLIO_ACTIONS.GET_PRO_PORTFOLIO_COLORS_SUCCESS,
    proPortfolioColors,
  };
}

export function getResidentialPropertyFundsLoading() {
  return { type: PORTFOLIO_ACTIONS.GET_RESIDENTIAL_PROPERTY_FUNDS_LOADING };
}

export function getProPortfolioColorsLoading() {
  return { type: PORTFOLIO_ACTIONS.GET_PRO_PORTFOLIO_COLORS_LOADING };
}

export function getResidentialPropertyFundsSuccess(
  residentialPropertyFunds: IFund[],
) {
  return {
    type: PORTFOLIO_ACTIONS.GET_RESIDENTIAL_PROPERTY_FUNDS_SUCCESS,
    residentialPropertyFunds,
  };
}

export function setFilterModalVisible(isFilterModalVisible: boolean) {
  return {
    type: PORTFOLIO_ACTIONS.SET_FILTER_MODAL_VISIBLE,
    isFilterModalVisible,
  };
}

export function setFundsToUpdate(fundsToUpdate: TFundsToUpdate) {
  return {
    type: PORTFOLIO_ACTIONS.SET_FUNDS_TO_UPDATE,
    fundsToUpdate,
  };
}

export function setIsUpdateFundsLoading(isLoading: boolean) {
  return { type: PORTFOLIO_ACTIONS.SET_IS_UPDATE_FUNDS_LOADING, isLoading };
}

export function setFundsSorting(sorting: string) {
  return { type: PORTFOLIO_ACTIONS.FUNDS_SORTING, sorting };
}

export function setActivePortfolioId(activePortfolioId: string) {
  return { type: PORTFOLIO_ACTIONS.SET_ACTIVE_PORTFOLIO_ID, activePortfolioId };
}

function setIsPortfolioLoading(isPortfolioLoading: boolean) {
  return {
    type: PORTFOLIO_ACTIONS.SET_IS_PORTFOLIO_LOADING,
    isPortfolioLoading,
  };
}

export function updatePortfolioParams(portfolioParams: IPortfolioParams) {
  return {
    type: PORTFOLIO_ACTIONS.UPDATE_PORTFOLIO_PARAMS,
    portfolioParams,
  };
}

export function updatePortfolioLocation(portfolioLocation: IPortfolioLocation) {
  return {
    type: PORTFOLIO_ACTIONS.UPDATE_PORTFOLIO_LOCATION,
    portfolioLocation,
  };
}

export function setIsBasePortfolioFullyAllocated(
  isBasePortfolioFullyAllocated: boolean,
) {
  return {
    type: PORTFOLIO_ACTIONS.SET_IS_BASE_PORTFOLIO_FULLY_ALLOCATED,
    isBasePortfolioFullyAllocated,
  };
}

export function setIsParentProPortfolioPreview({
  isParentProPortfolioPreview,
}: {
  isParentProPortfolioPreview: boolean;
}) {
  return {
    type: PORTFOLIO_ACTIONS.SET_IS_PARENT_PRO_PORTFOLIO_PREVIEW,
    isParentProPortfolioPreview,
  };
}

export function getFundsTags() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();
      const { isSuper } = selectPortfolioParams(state);

      dispatch(getFundsTagsLoading());

      const headers = getSuperPortfolioHeaders({ isSuper });

      const response = await api.get<IFundTagsFilter[], IFundTagsFilter[]>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION_V2}/tags/funds`,
        {
          headers,
        },
      );

      const fundsTags = sortFundsTags({ fundsTags: response });

      dispatch(getFundsTagsSuccess(fundsTags));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getFunds({
  activeFundTag,
  portfolioId,
  sorting,
  searchValue = '',
  searchParams,
}: IGetFunds) {
  const tags = createTagsParams({ activeFundTag, searchParams });

  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();
      const { isSuper, childId } = selectPortfolioParams(state);

      const params = {
        portfolio_id: portfolioId,
        limit: FUNDS_TO_FETCH,
        sorting: sorting || DEFAULT_FUNDS_TAGS_SORTING_ORDER,
        tags,
        ...(childId && { dependent_user_id: childId }),
        ...(searchValue && { name: searchValue }),
      };

      const headers = getSuperPortfolioHeaders({ isSuper });

      dispatch(getFundsLoading());

      const { funds } = await api.get<{ funds: IFund[] }, { funds: IFund[] }>(
        `${CUSTOM_PORTFOLIO_BASE_URL}/funds_with_user_allocation`,
        {
          params,
          headers,
        },
      );

      dispatch(getFundsSuccess({ funds }));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function applyFundsSorting({
  activeFundTag: fundTag,
  sorting,
  searchValue = '',
  searchParams,
}: {
  activeFundTag: string;
  sorting: string;
  searchValue?: string;
  searchParams?: URLSearchParams;
}) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();
      const portfolioId = state.portfolio?.proPortfolio?.id;

      dispatch(setFundsSorting(sorting));

      if (portfolioId) {
        dispatch(
          getFunds({
            portfolioId,
            activeFundTag: fundTag,
            sorting,
            searchValue,
            searchParams,
          }),
        );
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getPortfolioById({ portfolioId }: { portfolioId: string }) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      dispatch(getProPortfolioLoading());

      const state = getState();
      const { childId, isSuper } = selectPortfolioParams(state);

      const headers = getSuperPortfolioHeaders({ isSuper });

      const response = await api.get<
        { portfolio: IProPortfolio; error: string },
        { portfolio: IProPortfolio; error: string }
      >(`${CUSTOM_PORTFOLIO_BASE_URL}/${portfolioId}`, {
        params: {
          ...(childId && { dependent_user_id: childId }),
        },
        headers,
      });

      if (response.error) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              description: response.error,
            },
          }),
        );
      } else {
        dispatch(getProPortfolioSuccess(response.portfolio));
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

// TODO: https://acornsau.atlassian.net/browse/RAIZ-6289
export function getProPortfolio(
  {
    isParentProPortfolioPreview,
  }: {
    isParentProPortfolioPreview?: boolean;
  } = { isParentProPortfolioPreview: false },
) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();
      const { childId, isSuper } = selectPortfolioParams(state);

      const params = {
        plus: true,
        ...(childId &&
          !isParentProPortfolioPreview && { dependent_user_id: childId }),
      };

      if (!isParentProPortfolioPreview) {
        await dispatch(
          setIsParentProPortfolioPreview({
            isParentProPortfolioPreview: false,
          }),
        );
      }

      const isRaizKids = !!childId && !isParentProPortfolioPreview;

      dispatch(getProPortfolioLoading());

      const isBasicPortfolioActive = selectIsBasicPortfolioActive({
        isRaizKids,
        isSuper,
      })(state);

      const savedPortfolioId = selectSavedPortfolioId({
        isRaizKids,
        isSuper,
      })(state);

      const isSmsfUser = checkIsSmsfUser(state.user);
      const isSmsfRegistration = isSmsfUser && !savedPortfolioId;
      const headers = getSuperPortfolioHeaders({ isSuper });

      if (isBasicPortfolioActive || isSmsfRegistration) {
        const response = await api.get<
          { portfolio: IProPortfolio },
          { portfolio: IProPortfolio }
        >(`${CUSTOM_PORTFOLIO_BASE_URL}/last`, {
          params,
          headers,
        });

        /* For new kids, we need to display the plus portfolio form the parent. To achieve this, we need to execute a custom_portfolio/last request without passing the child id.
        If the kid makes any changes to plus portfolio, we will have to load it using the custom_portfolio/last request with the child id provided.
        To correctly determine the moment when we need to first load the parent's portfolio, we send two requests: one custom_portfolio/last request with the child id,
        which will return an empty object, and when we receive such a result, we send another custom_portfolio/last request without the child id. */
        if (!response.portfolio && childId) {
          await dispatch(
            setIsParentProPortfolioPreview({
              isParentProPortfolioPreview: !response.portfolio,
            }),
          );

          await dispatch(
            getProPortfolio({ isParentProPortfolioPreview: true }),
          );
        } else {
          await dispatch(getProPortfolioSuccess(response.portfolio));
        }
      } else {
        await dispatch(
          getPortfolioById({
            portfolioId: savedPortfolioId,
          }),
        );
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getFundDetails({ fundId }: { fundId: string }) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      dispatch(getFundDetailsLoading());

      const state = getState();
      const { childId, isSuper } = selectPortfolioParams(state);

      const isParentProPortfolioPreview =
        selectIsParentProPortfolioPreview(state);

      const headers = getSuperPortfolioHeaders({ isSuper });

      const response = await api.get<IFundDetails, IFundDetails>(
        `${CUSTOM_PORTFOLIO_BASE_URL}/funds/${fundId}`,
        {
          params: {
            ...(childId &&
              !isParentProPortfolioPreview && { dependent_user_id: childId }),
          },
          headers,
        },
      );

      dispatch(getFundDetailsSuccess(response));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getFundStatistics({ id }: { id: string }) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      dispatch(getFundStatisticsLoading());

      const state = getState();
      const { isSuper } = selectPortfolioParams(state);

      const headers = getSuperPortfolioHeaders({ isSuper });

      const response = await api.get<
        { fundamentals: IFundStatistics },
        { fundamentals: IFundStatistics }
      >(`securities/${id}`, {
        headers,
      });

      dispatch(getFundStatisticsSuccess(response?.fundamentals));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getProPortfolioColors() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      dispatch(getProPortfolioColorsLoading());

      const state = getState();
      const { isSuper } = selectPortfolioParams(state);

      const headers = getSuperPortfolioHeaders({ isSuper });

      const response = await api.get<IColorSets, IColorSets>(
        `${CUSTOM_PORTFOLIO_BASE_URL}/color_sets`,
        {
          headers,
        },
      );

      dispatch(getProPortfolioColorsSuccess(response?.color_sets));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function replaceUserPortfolio({
  newPortfolioId,
}: {
  newPortfolioId: string;
}) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const dependentUserId = selectDependentUserId(state);

    const { isSuper, childId } = selectPortfolioParams(state);

    const data = childId
      ? {
          portfolio_id: newPortfolioId,
          dependent_user_id: dependentUserId,
        }
      : {
          user: {
            allocation_profile_id: newPortfolioId,
          },
        };

    const userUrl = isSuper ? '/super_annuation/user' : '/user';

    const url = childId
      ? `${process.env.REACT_APP_API_URL}/dependency_users/v1/portfolios`
      : userUrl;

    const headers = getSuperPortfolioHeaders({ isSuper });

    try {
      const response = await api.put<{ error: string }, { error: string }>(
        url,
        data,
        {
          headers,
        },
      );

      if (response.error) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              description: response.error,
            },
          }),
        );
      } else {
        await dispatch(
          getPortfolioById({
            portfolioId: newPortfolioId,
          }),
        );

        if (childId) {
          await dispatch(dependencyUserPortfolioSuccess(response));
        } else {
          await dispatch(getUserDataSuccess(response));
        }
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function updateProPortfolio({
  values,
  updatedPortfolioColor,
  basePortfolio,
}: {
  values: { name: string };
  updatedPortfolioColor: { id: string };
  basePortfolio?: { id: string; allocation: number };
}) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();

      const { childId, isSuper } = selectPortfolioParams(state);

      const { isRegistration } = selectPortfolioLocation(state);

      const isBasicPortfolioActive = selectIsBasicPortfolioActive({
        isRaizKids: !!childId,
        isSuper,
      })(state);

      const userAllocationProfileId =
        selectUserAllocationProfileId(isSuper)(state);

      const userPortfolios = selectUserPortfolios(state);
      const proPortfolio = selectProPortfolio(state);

      const isInvestmentsVisible = !selectIsUserAlertOpen(
        ALERT_TYPES.registration.incompleteFundingAccount,
      )(state);

      const data = {
        name: values.name,
        color_set_id: updatedPortfolioColor.id,
        portfolio: {
          id:
            basePortfolio?.id ||
            userAllocationProfileId ||
            userPortfolios[0].id,
          allocation: `${
            basePortfolio?.allocation ?? BASE_PORTFOLIO_FULLY_ALLOCATED
          }`,
        },
        funds:
          state.portfolio?.fundsToUpdate?.length &&
          state.portfolio?.fundsToUpdate?.length > 0
            ? state.portfolio?.fundsToUpdate
            : selectUserFundsFlattened(state),
        ...(childId && { dependent_user_id: childId }),
      };

      dispatch(getProPortfolioLoading());

      const headers = getSuperPortfolioHeaders({ isSuper });

      // TODO: implement PATCH method instead in the future
      const response = await api.post<
        { portfolio_id: string },
        { portfolio_id: string }
      >(CUSTOM_PORTFOLIO_BASE_URL, data, {
        headers,
      });

      if (!proPortfolio?.id && !childId) {
        await dispatch(
          savePortfolio({
            portfolioId: response?.portfolio_id,
            isRegistration,
            isInvestmentsVisible,
          }),
        );

        await dispatch(getProPortfolio());
      } else if (isBasicPortfolioActive && !childId) {
        await dispatch(getProPortfolio());
      } else {
        await dispatch(
          replaceUserPortfolio({
            newPortfolioId: response?.portfolio_id,
          }),
        );
      }

      if (isSuper) {
        await dispatch(getSuperUserData());
      }

      await dispatch(getUserData());
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getResidentialPropertyFunds({
  fundIdByTag,
}: {
  fundIdByTag: string;
}) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();
      const { isSuper } = selectPortfolioParams(state);
      const headers = getSuperPortfolioHeaders({ isSuper });

      dispatch(getResidentialPropertyFundsLoading());

      const response = await api.get<IFund[], IFund[]>(
        `funds/properties/${fundIdByTag}`,
        {
          headers,
        },
      );

      dispatch(getResidentialPropertyFundsSuccess(response));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function updateFunds({ portfolioName }: { portfolioName?: string }) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      dispatch(setIsUpdateFundsLoading(true));

      const state = getState();
      const { isSuper, childId } = selectPortfolioParams(state);
      const { proPortfolio, fundsToUpdate } = state.portfolio;
      const portfolioId = selectProPortfolioId(state);
      const defaultPortfolioId = selectDefaultPortfolioId(state);
      const headers = getSuperPortfolioHeaders({ isSuper });

      const isBasicPortfolioActive = selectIsBasicPortfolioActive({
        isRaizKids: !!childId,
        isSuper,
      })(state);

      const response = await api.post<
        { error: string; portfolio_id: string },
        { error: string; portfolio_id: string }
      >(
        `${CUSTOM_PORTFOLIO_BASE_URL}?plus=true`,
        {
          name: portfolioName || DEFAULT_PORTFOLIO_PRO_NAME,
          funds: fundsToUpdate,
          portfolio: {
            id: portfolioId || defaultPortfolioId,
          },
          color_set_id: proPortfolio?.structure?.color_set?.id,
          ...(childId && { dependent_user_id: childId }),
        },
        {
          headers,
        },
      );

      if (response.error) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              description: response.error,
            },
          }),
        );
      } else {
        dispatch(setFundsToUpdate([]));

        if (isBasicPortfolioActive) {
          await dispatch(getProPortfolio());
        } else {
          await dispatch(
            replaceUserPortfolio({
              newPortfolioId: response.portfolio_id,
            }),
          );
        }
      }
    } catch (error) {
      handleAllocationExceededError(dispatch, error);
    } finally {
      dispatch(setIsUpdateFundsLoading(false));
    }
  };
}

export function updateFundsPreview({
  fundId,
  updatedAllocationValue: allocation,
  onAllocationExceeded,
  max_allocation,
}: {
  fundId: string;
  updatedAllocationValue: number;
  onAllocationExceeded: (decreasedAllocationValue: number) => void;
  max_allocation: number;
}) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      dispatch(setIsUpdateFundsLoading(true));

      const state = getState();

      const portfolioId = selectProPortfolioId(state);
      const defaultPortfolioId = selectDefaultPortfolioId(state);
      const { isSuper, childId } = selectPortfolioParams(state);

      const fundsToUpdate = state.portfolio?.fundsToUpdate;

      const userFundsFlattened = fundsToUpdate?.length
        ? fundsToUpdate
        : selectUserFundsFlattened(state);

      const userFundsWithoutUpdatedOne =
        userFundsFlattened?.filter((fund) => fund.id !== fundId) || [];

      const updatedUserFunds = [
        ...userFundsWithoutUpdatedOne,
        { id: fundId, allocation },
      ];

      const nonEmptyUpdatedUserFunds = updatedUserFunds.filter(
        (fund) => fund.allocation > 0,
      );

      const userAllocationAfterUpdate = nonEmptyUpdatedUserFunds.reduce(
        (acc, curr) => acc + curr.allocation,
        0,
      );

      const headers = getSuperPortfolioHeaders({ isSuper });

      const isMaxAllocationForFundExceeded = allocation > max_allocation;

      if (isMaxAllocationForFundExceeded || userAllocationAfterUpdate > 100) {
        const decreasedAllocationValue =
          allocation -
          Math.max(
            userAllocationAfterUpdate - 100,
            allocation - max_allocation,
          );

        onAllocationExceeded(decreasedAllocationValue);

        dispatch(
          setFundsToUpdate([
            ...userFundsWithoutUpdatedOne,
            { id: fundId, decreasedAllocationValue },
          ]),
        );

        return;
      }

      const response = await api.post<
        {
          error: string;
          portfolio: {
            allocation: number;
          };
        },
        {
          error: string;
          portfolio: {
            allocation: number;
          };
        }
      >(
        'custom_portfolios/preview_plus',
        {
          funds: nonEmptyUpdatedUserFunds,
          portfolio_id: portfolioId || defaultPortfolioId,
          ...(childId && { dependent_user_id: childId }),
        },
        {
          headers,
        },
      );

      if (response.error) {
        dispatch(
          toggleInfoModal({
            isInfoModalVisible: true,
            config: {
              description: response.error,
            },
          }),
        );
      } else {
        dispatch(
          setIsBasePortfolioFullyAllocated(
            response?.portfolio?.allocation === BASE_PORTFOLIO_FULLY_ALLOCATED,
          ),
        );

        dispatch(setFundsToUpdate(nonEmptyUpdatedUserFunds));
      }
    } catch (error) {
      handleAllocationExceededError(dispatch, error);
    } finally {
      dispatch(setIsUpdateFundsLoading(false));
    }
  };
}

export function updateBasePortfolio({
  activePortfolioId,
  onSuccess,
}: {
  activePortfolioId: string;
  onSuccess?: () => void;
}) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      dispatch(setIsPortfolioLoading(true));

      const state = getState();
      const { isSuper, childId } = selectPortfolioParams(state);

      const isBasicPortfolioActive = selectIsBasicPortfolioActive({
        isRaizKids: !!childId,
        isSuper,
      })(state);

      const funds =
        state.portfolio?.fundsToUpdate?.length &&
        state.portfolio?.fundsToUpdate?.length > 0
          ? state.portfolio?.fundsToUpdate
          : selectUserFundsFlattened(state);

      const data = {
        name: selectSavedPortfolioName(state) || DEFAULT_PORTFOLIO_PRO_NAME,
        color_set_id:
          selectProPortfolioColorId(state) ||
          selectProPortfolioColors(state)[0].id,
        portfolio: {
          id: activePortfolioId,
          allocation: `${
            selectBasePortfolioAllocation(state) ||
            BASE_PORTFOLIO_FULLY_ALLOCATED
          }`,
        },
        funds,
        ...(childId && { dependent_user_id: childId }),
      };

      const headers = getSuperPortfolioHeaders({ isSuper });

      // TODO: implement PATCH method instead in the future
      const response = await api.post<
        { error: string; portfolio_id: string },
        { error: string; portfolio_id: string }
      >(CUSTOM_PORTFOLIO_BASE_URL, data, {
        headers,
      });

      if (isBasicPortfolioActive) {
        await dispatch(getProPortfolio());
      } else {
        await dispatch(
          replaceUserPortfolio({
            newPortfolioId: response.portfolio_id,
          }),
        );
      }

      await dispatch(getUserData());

      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch(showGlobalError(error));
    } finally {
      dispatch(setIsPortfolioLoading(false));
    }
  };
}
