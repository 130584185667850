import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IRoundUpWholeDollar } from './types';
import { SmartRoundUpsTitle } from './styles';

import RoundUpsExtendedHelpCard from 'pages/RoundUps/components/RoundUpsExtendedHelpCard';
import RoundUpsSlider from 'pages/RoundUps/components/RoundUpsSlider';
import { ROUND_UP_WHOLE_DOLLAR_ON } from 'constants/togglesFunctions';
import { IconButton } from 'components/elements/IconButton';

export default function RoundUpWholeDollar({
  defaultAmount,
  handleChangeDefaultAmount,
}: IRoundUpWholeDollar) {
  const { t } = useTranslation();
  const [isExtendedHelpCardOpen, setIsExtendedHelpCardOpen] = useState(false);

  if (!ROUND_UP_WHOLE_DOLLAR_ON) {
    return null;
  }

  return (
    <>
      <SmartRoundUpsTitle>
        {t('roundUps.modal.wholeDollar')}

        <IconButton
          icon="info"
          size={24}
          onClick={() => setIsExtendedHelpCardOpen(true)}
        />
      </SmartRoundUpsTitle>

      <RoundUpsSlider
        defaultAmount={defaultAmount}
        handleChangeDefaultAmount={handleChangeDefaultAmount}
      />

      <RoundUpsExtendedHelpCard
        isExtendedHelpCardOpen={isExtendedHelpCardOpen}
        setIsExtendedHelpCardOpen={setIsExtendedHelpCardOpen}
      />
    </>
  );
}
