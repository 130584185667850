import { IUpdateStoreData, IUpdateSuitabilityData } from './types';

import { api } from 'store/api';

export interface IPostRecoverPasswordData {
  email: string;
}

export type IPostRecoverPasswordResponse = {
  message: string;
};

export type IPostRecoverPasswordError = {
  data: { error?: string };
};

export type INewPasswordData = {
  user: {
    confirmation_password: string;
    password: string;
    reset_password_token: string | null;
  };
};

export type INewPasswordResponse = {
  message: string;
};

export interface IPostVerifyDeviceData {
  verificationToken: string;
  verificationCode: string;
}

export type IPostVerifyDeviceResponse = {
  message: string;
};

export const USER_BASE_URL = '/v1/user';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    // TODO: Need to Rename it to recoverPasswordMutation
    postRecoverPassword: build.mutation<
      IPostRecoverPasswordResponse,
      IPostRecoverPasswordData
    >({
      query: (data) => ({
        url: `${USER_BASE_URL}/recover_password`,
        method: 'POST',
        data,
      }),
    }),
    verifyDevice: build.mutation<
      IPostVerifyDeviceResponse,
      IPostVerifyDeviceData
    >({
      query: ({ verificationToken, verificationCode }) => ({
        url: `/${process.env.REACT_APP_API_VERSION_V2}/verifications/verify_device`,
        method: 'POST',
        data: {
          verification_token: verificationToken,
          code: verificationCode,
        },
      }),
    }),
    createNewPassword: build.mutation<INewPasswordResponse, INewPasswordData>({
      query: (data) => ({
        url: '/users/password.json',
        method: 'PUT',
        data,
      }),
    }),
    updateSuitability: build.mutation<void, IUpdateSuitabilityData>({
      query: (data) => ({
        url: USER_BASE_URL,
        method: 'PUT',
        data,
      }),
    }),
    updateStore: build.mutation<void, IUpdateStoreData>({
      query: (data) => ({
        url: `${USER_BASE_URL}/store`,
        method: 'PUT',
        data,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  usePostRecoverPasswordMutation,
  useVerifyDeviceMutation,
  useCreateNewPasswordMutation,
  useUpdateSuitabilityMutation,
  useUpdateStoreMutation,
} = userApi;

export const {
  endpoints: { postRecoverPassword, verifyDevice },
} = userApi;
