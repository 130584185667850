import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  REGISTRATION_ACCOUNT_PORTFOLIO,
  REGISTRATION_BANKS_FUNDING_ACCOUNT,
} from '../../../store/commonTypes';
import {
  REGISTRATION_BANKS_SPENDING_ACCOUNT,
  REGISTRATION_INVESTMENTS_INVESTMENT,
} from '../../../store/user/consts';
import BanksList from '../../Settings/components/Banks';
import BankSignInModal from '../../../components/layout/Modals/BankSignInModal';
import BanksSearch from '../../Settings/components/BanksSearch';
import BankSignInDifferentModal from '../../../components/layout/Modals/BankSignInDifferentModal';
import BankCardModal from '../../../components/layout/Modals/BankCardModal';
import WithdrawalSignInModal from '../../../components/layout/Modals/WithdrawalSignInModal';
import BankConnectModal from '../../../components/layout/Modals/BankConnectModal';
import BankLinkModal from '../../../components/layout/Modals/BankLinkModal';
import BankInformationModal from '../../../components/layout/Modals/BankInformationModal';

import BankAccountConnectionStatus from './BankAccountConnectionStatus/BankAccountConnectionStatus';

import { useLinkCard } from 'pages/Settings/components/hooks/useLinkCard';
import { InfinitiumForm } from 'pages/Settings/components/InfinitiumForm';
import { selectIsNewCardBindingFlowEnabled } from 'store/firebaseConfig/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { AU_APP, MY_APP } from 'constants/localeConfigs';
import AddCardModal from 'components/layout/Modals/AddCardModal';
import { FastlinkModal } from 'components/modals/Fastlink/FastlinkModal';
import { PROVIDER_TYPES } from 'store/yodlee/consts';

export default function Banks({
  errorMessage,
  goBack,
  banks,
  signInBank,
  openDifferentBank,
  isWithdrawal,
  isShowBankModal,
  isShowBankModalError,
  bank,
  credentials,
  handleCloseModal,
  handleCloseModalError,
  handleChangeBank,
  handleStopBankAccountLinking,
  isShowBankModalAlert,
  bankLoginProcessWait,
  errorTitle,
  bankInSubmit,
  isShowMoreBanks,
  moreBanks,
  skip,
  showLoader,
  isSpending,
  isShowDifferentBankModal,
  bankInDifferentSubmit,
  bankCurrentTarget,
  isFromInvestments,
  isShowBankCardModal,
  openBankCardModal,
  cardMonth,
  cardYear,
  cardCvv,
  changeDate,
  linkCard,
  toggleShowPassword,
  isShowPassword,
  handleChangeCardNumber,
  cardNumberError,
  paymentCard,
  isShowWithdrawalSignIn,
  fundingPaymentCards,
  showMainLoader,
  bankInSubmitWithdrawal,
  isShowConnectModal,
  monitoredInstitutions,
  handleSubAccount,
  isShowModalError,
  changeAutoRoundUps,
  roundUpsCheck,
  handleSubAccountFunding,
  clearSearch,
  searchStart,
  isShowLinkFunding,
  spendingPaymentCards,
  linkCardForFunding,
  selectLinkCard,
  isReconectForFunding,
  confirmationConnect,
  isShowConfirmationConnect,
  bankData,
  showBanks,
  isFromMyKad,
  isShowBankInformationModal,
  closeBankInfoModal,
  visaEnabled,
  isInstitutionsApiEnabled,
  isBankCardModalOpen,
  addCard,
  openAddCardModal,
  isAllVisaBanksDisplayed,
  displayAllVisaBanks,
  isBankAccountLinkingStep,
  connectionStatusBack,
  toggleFastlinkModal,
  isFastlinkModalOpen,
  showMoreBanks,
  isYodleeFastlinkEnabled,
  searchValue,
  changeSearchValue,
}) {
  const { t } = useTranslation();
  let back = '';

  if (isFromInvestments) {
    back = REGISTRATION_INVESTMENTS_INVESTMENT;
  } else if (isFromMyKad) {
    back = REGISTRATION_ACCOUNT_PORTFOLIO;
  } else if (isWithdrawal) {
    back = REGISTRATION_BANKS_FUNDING_ACCOUNT;
  } else {
    back = REGISTRATION_BANKS_SPENDING_ACCOUNT;
  }

  const isBanksListDisplayed =
    (!isShowMoreBanks && !isSpending) ||
    (banks.length !== 0 && moreBanks.length === 0 && !searchStart);

  const isNewCardBindingFlowEnabled = useAppSelector(
    selectIsNewCardBindingFlowEnabled,
  );

  const { linkBankCardFlowNew, isLinkCardLoading } = useLinkCard({
    isSpendingAccount: isSpending,
  });

  const linkBankCard = isNewCardBindingFlowEnabled
    ? linkBankCardFlowNew
    : openBankCardModal;

  const handleBankClick = MY_APP && !isWithdrawal ? linkBankCard : signInBank;

  return (
    <div className="form">
      {isNewCardBindingFlowEnabled && (
        <InfinitiumForm paymentCard={paymentCard} />
      )}

      {isBankAccountLinkingStep ? (
        <BankAccountConnectionStatus
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          onUnmount={handleCloseModal}
          connectionStatusBack={connectionStatusBack}
          isSpending={isSpending}
          isRegistration
        />
      ) : (
        <>
          <div className="content-fill">
            <h1>
              {!isSpending &&
                !isWithdrawal &&
                t('registrationPage.banks.title.funding')}

              {AU_APP &&
                isSpending &&
                !isWithdrawal &&
                t('registrationPage.banks.title.spending')}

              {isWithdrawal && t('registrationPage.banks.title.withdrawal')}
            </h1>

            {!isShowMoreBanks && (
              <div className="bank-text">
                {!isSpending &&
                  !isWithdrawal &&
                  t('registrationPage.banks.subtitle.funding')}

                {AU_APP &&
                  isSpending &&
                  t('registrationPage.banks.subtitle.spending')}

                {MY_APP &&
                  isWithdrawal &&
                  t('registrationPage.banks.subtitle.withdrawal')}
              </div>
            )}

            <div>
              {(isShowMoreBanks ||
                (!isYodleeFastlinkEnabled && isSpending && !MY_APP)) && (
                <BanksSearch
                  banks={moreBanks}
                  handleClick={signInBank}
                  isRegistration
                  isFunding
                  isSpending={isSpending}
                  clearSearch={clearSearch}
                  searchStart={searchStart}
                  searchValue={searchValue}
                  changeSearchValue={changeSearchValue}
                  showLoader={showLoader}
                />
              )}

              {isBanksListDisplayed && (
                <div className="heightLoader1">
                  <BanksList
                    handleClick={handleBankClick}
                    openDifferentBank={openDifferentBank}
                    isSpending={isSpending}
                    showMainLoader={showMainLoader || isLinkCardLoading}
                    isRegistration
                    isWithdrawal={isWithdrawal}
                    visaEnabled={visaEnabled}
                    isInstitutionsApiEnabled={isInstitutionsApiEnabled}
                    signInBank={signInBank}
                    addCard={addCard}
                    showAddCardModal={openAddCardModal}
                    isShowAllVisaBanks={isAllVisaBanksDisplayed}
                    showVisaAllBanks={displayAllVisaBanks}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="buttons-part">
            {!isShowMoreBanks && (
              <div>
                {(((!isSpending || isWithdrawal) &&
                  isWithdrawal &&
                  (Object.entries(fundingPaymentCards).length === 0 ||
                    !fundingPaymentCards.payment_cards ||
                    fundingPaymentCards.payment_cards.length === 0)) ||
                  isFromInvestments) && (
                  <div className="form-field">
                    <DeprecatedButton
                      title={t('common.back')}
                      buttonClass="button back small"
                      handleClick={() => goBack(back)}
                    />
                  </div>
                )}

                {!isFromMyKad && !showMainLoader && (
                  <div className="form-field">
                    <DeprecatedButton
                      title={t('common.skip')}
                      buttonClass="button back small"
                      handleClick={skip}
                    />
                  </div>
                )}

                {isYodleeFastlinkEnabled && !showMainLoader && (
                  <div className="form-field">
                    <DeprecatedButton
                      title={t('settingsPage.seeMoreBanksButton')}
                      buttonClass="more-banks-button -exclude"
                      handleClick={showMoreBanks}
                    />
                  </div>
                )}

                {isYodleeFastlinkEnabled && !isSpending && !MY_APP && (
                  <div className="form-field">
                    <DeprecatedButton
                      title={t('settingsPage.enterBankDetailsManuallyButton')}
                      buttonClass="more-banks-button enter-data-manually -exclude"
                      handleClick={openDifferentBank}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="hide">{errorMessage}</div>
          </div>
        </>
      )}

      {isShowBankModal && !isBankAccountLinkingStep && (
        <BankSignInModal
          isShowBankModal={isShowBankModal}
          isShowPassword={isShowPassword}
          toggleShowPassword={toggleShowPassword}
          handleCloseModal={handleCloseModal}
          bank={bank}
          showLoader={showLoader}
          handleSubmit={bankInSubmit}
          credentials={credentials}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          isShowBankModalAlert={isShowBankModalAlert}
          bankLoginProcessWait={bankLoginProcessWait}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowBankModalError={isShowBankModalError}
          changeAutoRoundUps={changeAutoRoundUps}
          roundUpsCheck={roundUpsCheck}
          isSpending={isSpending}
          isRegistration
        />
      )}

      {isShowConnectModal && (
        <BankConnectModal
          monitoredInstitutions={monitoredInstitutions}
          handleSubAccount={handleSubAccount}
          errorMessage={errorMessage}
          handleCloseModalError={handleCloseModalError}
          isShowModalError={isShowModalError}
          isSpending={isSpending}
          skip={skip}
          handleSubAccountFunding={handleSubAccountFunding}
          isReconectForFunding={isReconectForFunding}
          showLoader={showLoader}
          confirmationConnect={confirmationConnect}
          isShowConfirmationConnect={isShowConfirmationConnect}
          openDifferentBank={openDifferentBank}
          bankData={bankData}
          handleCloseModal={handleCloseModal}
        />
      )}

      {MY_APP &&
        isShowLinkFunding &&
        spendingPaymentCards &&
        spendingPaymentCards.payment_cards &&
        spendingPaymentCards.payment_cards.length !== 0 && (
          <BankLinkModal
            errorMessage={errorMessage}
            handleCloseModalError={handleCloseModalError}
            isShowModalError={isShowBankModalError}
            paymentCards={spendingPaymentCards}
            linkCardForFunding={linkCardForFunding}
            isRegistration
            selectLinkCard={selectLinkCard}
            skip={skip}
            showBanks={showBanks}
          />
        )}

      {isShowDifferentBankModal && (
        <BankSignInDifferentModal
          isShowBankModal={isShowDifferentBankModal}
          handleCloseModal={handleCloseModal}
          bank={bank}
          handleSubmit={bankInDifferentSubmit}
          credentials={credentials}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          isShowBankModalAlert={isShowBankModalAlert}
          bankLoginProcessWait={bankLoginProcessWait}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowBankModalError={isShowBankModalError}
          showLoader={showLoader}
        />
      )}

      {isShowBankCardModal && (
        <BankCardModal
          isShowBankModal={isShowBankCardModal}
          handleCloseModal={handleCloseModal}
          bank={bank}
          handleSubmit={linkCard}
          showLoader={showLoader}
          handleChangeBank={handleChangeBank}
          credentials={credentials}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          isShowBankModalAlert={isShowBankModalAlert}
          bankLoginProcessWait={bankLoginProcessWait}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowBankModalError={isShowBankModalError}
          bankCurrentTarget={bankCurrentTarget}
          cardMonth={cardMonth}
          cardYear={cardYear}
          cardCvv={cardCvv}
          changeDate={changeDate}
          toggleShowPassword={toggleShowPassword}
          isShowPassword={isShowPassword}
          handleChangeCardNumber={handleChangeCardNumber}
          cardNumberError={cardNumberError}
          paymentCard={paymentCard}
          isSpending={isSpending}
        />
      )}

      {(isShowWithdrawalSignIn || isShowBankModalError) && !isShowBankModal && (
        <WithdrawalSignInModal
          isShowBankModal={isShowWithdrawalSignIn}
          handleCloseModal={handleCloseModal}
          bank={bank}
          handleSubmit={bankInSubmitWithdrawal}
          showLoader={showLoader}
          handleChangeBank={handleChangeBank}
          credentials={credentials}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          isShowBankModalAlert={isShowBankModalAlert}
          bankLoginProcessWait={bankLoginProcessWait}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowBankModalError={isShowBankModalError}
        />
      )}

      {isShowBankInformationModal && (
        <BankInformationModal
          isShowBankInformationModal={isShowBankInformationModal}
          closeBankInfoModal={closeBankInfoModal}
        />
      )}

      {isBankCardModalOpen && (
        <AddCardModal
          isShowBankModal={isBankCardModalOpen}
          handleCloseModal={handleCloseModal}
          handleSubmit={addCard}
          showLoader={showLoader}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          handleChangeCardNumber={handleChangeCardNumber}
          cardNumberError={cardNumberError}
        />
      )}

      {isFastlinkModalOpen && (
        <FastlinkModal
          isFastlinkModalOpen={isFastlinkModalOpen}
          toggleFastlinkModal={toggleFastlinkModal}
          type={isSpending ? PROVIDER_TYPES.spending : PROVIDER_TYPES.funding}
        />
      )}
    </div>
  );
}

Banks.propTypes = {
  errorMessage: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  banks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  bank: PropTypes.shape({}).isRequired,
  handleChangeBank: PropTypes.func.isRequired,
  credentials: PropTypes.shape({}).isRequired,
  handleStopBankAccountLinking: PropTypes.func.isRequired,
  isShowBankModalAlert: PropTypes.bool.isRequired,
  bankLoginProcessWait: PropTypes.func.isRequired,
  errorTitle: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowBankModalError: PropTypes.bool.isRequired,
  signInBank: PropTypes.func.isRequired,
  openDifferentBank: PropTypes.func.isRequired,
  bankInSubmit: PropTypes.func.isRequired,
  isShowBankModal: PropTypes.bool,
  showLoader: PropTypes.bool,
  isShowMoreBanks: PropTypes.bool.isRequired,
  moreBanks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skip: PropTypes.func.isRequired,
  isSpending: PropTypes.bool.isRequired,
  isWithdrawal: PropTypes.bool,
  isShowDifferentBankModal: PropTypes.bool,
  bankInDifferentSubmit: PropTypes.func.isRequired,
  bankCurrentTarget: PropTypes.string.isRequired,
  isFromInvestments: PropTypes.bool.isRequired,
  openBankCardModal: PropTypes.func.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  cardMonth: PropTypes.string.isRequired,
  cardYear: PropTypes.string.isRequired,
  cardCvv: PropTypes.string.isRequired,
  changeDate: PropTypes.func.isRequired,
  linkCard: PropTypes.func.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  handleChangeCardNumber: PropTypes.func.isRequired,
  cardNumberError: PropTypes.string.isRequired,
  paymentCard: PropTypes.shape({}).isRequired,
  fundingPaymentCards: PropTypes.shape({
    payment_cards: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  spendingPaymentCards: PropTypes.shape({
    payment_cards: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isShowBankCardModal: PropTypes.bool.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  bankInSubmitWithdrawal: PropTypes.func.isRequired,
  isShowWithdrawalSignIn: PropTypes.bool.isRequired,
  monitoredInstitutions: PropTypes.arrayOf(PropTypes.shape({})),
  handleSubAccount: PropTypes.func.isRequired,
  isShowModalError: PropTypes.bool.isRequired,
  isShowConnectModal: PropTypes.bool,
  handleSubAccountFunding: PropTypes.func.isRequired,
  changeAutoRoundUps: PropTypes.func.isRequired,
  roundUpsCheck: PropTypes.bool.isRequired,
  clearSearch: PropTypes.func.isRequired,
  searchStart: PropTypes.bool.isRequired,
  isShowLinkFunding: PropTypes.bool.isRequired,
  linkCardForFunding: PropTypes.func.isRequired,
  selectLinkCard: PropTypes.func.isRequired,
  isReconectForFunding: PropTypes.bool.isRequired,
  confirmationConnect: PropTypes.func.isRequired,
  isShowConfirmationConnect: PropTypes.bool.isRequired,
  bankData: PropTypes.shape({}),
  showBanks: PropTypes.func.isRequired,
  isFromMyKad: PropTypes.bool,
  isShowBankInformationModal: PropTypes.bool.isRequired,
  closeBankInfoModal: PropTypes.func.isRequired,
  visaEnabled: PropTypes.bool,
  isInstitutionsApiEnabled: PropTypes.bool,
  isBankCardModalOpen: PropTypes.bool.isRequired,
  addCard: PropTypes.func.isRequired,
  openAddCardModal: PropTypes.func.isRequired,
  isAllVisaBanksDisplayed: PropTypes.bool.isRequired,
  displayAllVisaBanks: PropTypes.func.isRequired,
  isBankAccountLinkingStep: PropTypes.bool,
  connectionStatusBack: PropTypes.func.isRequired,
  toggleFastlinkModal: PropTypes.func.isRequired,
  isFastlinkModalOpen: PropTypes.bool.isRequired,
  showMoreBanks: PropTypes.func.isRequired,
  isYodleeFastlinkEnabled: PropTypes.bool,
  searchValue: PropTypes.string,
  changeSearchValue: PropTypes.func.isRequired,
};

Banks.defaultProps = {
  errorMessage: '',
  isShowBankModal: false,
  isWithdrawal: false,
  showLoader: false,
  isShowDifferentBankModal: false,
  monitoredInstitutions: [],
  isShowConnectModal: false,
  bankData: {},
  isFromMyKad: false,
  visaEnabled: false,
  isInstitutionsApiEnabled: false,
  isBankAccountLinkingStep: false,
  isYodleeFastlinkEnabled: false,
  searchValue: null,
};
