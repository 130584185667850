import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PortfolioWrapper,
  PortfolioTitle,
  PortfolioHelpCardButton,
} from './styles';
import PortfolioPlanSwitch from './components/PortfolioPlanSwitch';
import { ActivePortfolioPlan } from './components/ActivePortfolioPlan';
import PortfolioPlanFooter from './components/PortfolioPlanFooter';
import { usePortfolio, usePortfolioColor } from './hooks';
import KidPortfolioHeader from './components/KidPortfolioHeader/KidPortfolioHeader';
import { IS_PLUS_PORTFOLIO_HELP_CARD_OPEN } from './consts';
import { usePortfolioSearchParams } from './hooks/usePortfolioSearchParams';
import { usePortfolioLocation } from './hooks/usePortfolioLocation';

import { selectPortfolioLocation } from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useIsSuperRegistration } from 'store/user/hooks/useIsSuperRegistration';
import { SuperRegistrationBanner } from 'pages/Super/components/SuperRegistration/components/SuperRegistrationBanner';
import { IconButton } from 'components/elements/IconButton';
import HelpCardsModalAU from 'components/layout/Modals/HelpCardsModalAU';
import { PORTFOLIO } from 'constants/comonConstants';

interface IPortfolioProps {
  handleGoBack?: () => void;
}

export default function Portfolio({ handleGoBack }: IPortfolioProps) {
  const { t } = useTranslation();

  usePortfolioSearchParams();

  usePortfolioLocation();

  const {
    isActivePortfolio,
    handleOpenChangePortfolioModal,
    isRaizKids,
    isProPortfolioExist,
    isProPortfolioLoading,
  } = usePortfolio();

  const { portfolioColor } = usePortfolioColor();

  const { isRegistration, isProPortfolio } = useAppSelector(
    selectPortfolioLocation,
  );

  const isSuperRegistration = useIsSuperRegistration();

  const [isHelpCardOpen, setIsHelpCardOpen] = useState(false);

  const isHelpCardOpenedAutomatically =
    isProPortfolio &&
    !isProPortfolioLoading &&
    !isProPortfolioExist &&
    !isHelpCardOpen &&
    !localStorage.getItem(IS_PLUS_PORTFOLIO_HELP_CARD_OPEN);

  if (isHelpCardOpenedAutomatically) {
    setIsHelpCardOpen(isHelpCardOpenedAutomatically);

    localStorage.setItem(IS_PLUS_PORTFOLIO_HELP_CARD_OPEN, 'true');
  }

  return (
    <>
      {isRaizKids && <KidPortfolioHeader />}

      {isSuperRegistration && <SuperRegistrationBanner />}

      <PortfolioWrapper portfolioColor={portfolioColor}>
        <PortfolioTitle isRegistration={isRegistration && !isProPortfolio}>
          {t('portfolio.title')}
        </PortfolioTitle>

        <PortfolioPlanSwitch />

        {!isRegistration && (
          <PortfolioHelpCardButton>
            <IconButton
              icon="helpWhite"
              onClick={() => setIsHelpCardOpen(true)}
              size={28}
            />
          </PortfolioHelpCardButton>
        )}

        <ActivePortfolioPlan isRaizKids={isRaizKids} />
      </PortfolioWrapper>

      <PortfolioPlanFooter
        isActivePortfolio={isActivePortfolio}
        handleOpenChangePortfolioModal={handleOpenChangePortfolioModal}
        handleGoBack={handleGoBack}
      />

      {!isRegistration && (
        <HelpCardsModalAU
          isOpenHelpCards={isHelpCardOpen}
          currentPage={PORTFOLIO}
          toggleHelpCard={setIsHelpCardOpen}
          isProPortfolio={isProPortfolio}
        />
      )}
    </>
  );
}
