import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';
import '../../../styles/layout/help-cards.scss';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import Modal from 'react-modal';
import { Trans, withTranslation } from 'react-i18next';

import * as spendingAnalyticsActions from '../../../store/spendingAnalytics/actions';
import * as fundingAccountActions from '../../../store/fundingAccount/actions';
import {
  HOMEPAGE,
  NO_LINKED,
  NO_LINKED_WARN,
  PORTFOLIO,
  RECURRINGINVESTMENT,
  REWARDS,
  TRANSACTION_CATEGORISATION,
  WHERE_YOU_SPEND,
  HOMEPAGE_MONEY_THOR,
  CHARGEBACK_FUNDING_ACCOUNT_RELINK,
  DEPOSITS_SUSPENSION_REMOVAL,
  GENERIC,
  OFFER_RECOMMENDATION,
  FREE_CASH,
  CATEGORY_EXPENSES,
  LIKE,
  DISLIKE,
  CUSTOM,
  IMPRESSION,
  FEEDBACK,
  FUTURE,
  BLOG,
  WA_NUMBER,
  KIDS,
  ROUND_UP_HELP_CARD,
  REGISTRATION,
  AUS,
  MYS,
  NEW_KIDS,
  HOME_ACTIVE_TAB,
  HOME_HISTORY_TAB,
  FUNDING_ACCOUNT,
  ROUND_UP_ACCOUNT,
} from '../../../constants/comonConstants';
import TARGET_TYPES from '../../../constants/helpCardsTarget';
import {
  ACCOUNT_ACTIVITY,
  DEPOSIT_SUSPENSION,
  EXPLORE_ETFS,
  HOW_SEE_REWARDS,
  HOW_WORKS,
  FUTURE_INFO,
  MY_FINANCE,
  POTENTIAL_INVESTMENT_PROJECTION,
  RAIZ_REWARDS,
  SCHEDULING,
  NO_CARD_LINKED,
  NO_CARD_LINKED_WARN,
  SETUP_FUNDING_ACCOUNT,
  SPENDING_CATEGORIES,
  WHERE_YOU_SPEND_CARD,
  TRANSFERRING_MONEY,
  TRANSFERRING_MONEY_INVEST,
  USE_LINK_CARD,
  WELCOME_RAIZ_REWARDS,
  ROUND_UP_INFO,
  REG_INFO,
  USE_LINK_CARD_REWARDS,
  WELCOME_RAIZ_REWARDS_NEW,
  HOW_SEE_REWARDS_NEW,
  NO_CARD_LINKED_NEW,
  KIDS_INFO,
  WELCOM_KIDS_NEW,
  RAIZ_KIDS_ACCESS,
  RAIZ_KIDS_INVEST,
  RAIZ_KIDS_ADULT,
  BANK_FUNDING,
  BANK_LEVEL_SECURITY,
  BANK_ROUND_UP,
} from '../../../constants/helpCards';
import { AU_APP, COUNTRY, MY_APP } from '../../../constants/localeConfigs';
import MoneyThorView from '../Widgets/MoneyThor/MoneyThorView';
import withRouter from '../../../routes/withRouter';

import { getPortfolioPlusHelpCards } from './components/getPortfolioPlusHelpCards';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { Button } from 'components/elements/Button';

const changeState = (path) => {
  window.location.href = path;
};

// TODO: https://acornsau.atlassian.net/browse/RAIZ-5061
class HelpCardsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: null,
      content: [],
      showLoader: false,
    };

    this.toggleRating = this.toggleRating.bind(this);
  }

  componentDidMount() {
    const { isProPortfolio } = this.props;

    this.formattedTips({ isProPortfolio });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { isProPortfolio } = this.props;

    if (newProps.isProPortfolio !== isProPortfolio) {
      this.formattedTips({ isProPortfolio: newProps.isProPortfolio });
    }
  }

  componentDidUpdate(prevProps) {
    const { rewardCard, isProPortfolio } = this.props;

    if (prevProps.rewardCard !== rewardCard) {
      this.formattedTips({ isProPortfolio });
    }
  }

  static getUrlParam(name) {
    const regex = `[?&]${name}=([^&#]*)`;
    const results = new RegExp(regex).exec(window.location.href);

    if (results == null) {
      return null;
    }

    return decodeURI(results[1]) || 0;
  }

  formattedTips({ isProPortfolio = false }) {
    const {
      actionsSpendingAnalytics,
      history,
      currentPage,
      toggleHelpCard,
      tips,
      isNewRewards,
    } = this.props;

    const additionalHelpCards = [];

    if (
      tips.length &&
      (currentPage === HOMEPAGE || currentPage === HOMEPAGE_MONEY_THOR)
    ) {
      tips.map((tip) => {
        switch (tip.data.tip_type) {
          case GENERIC: {
            const currentTarget = TARGET_TYPES.find(
              (type) => type.id === tip.data.target,
            );

            const currentCard = {
              ...tip,
              id: `${GENERIC}_${tip.tip_key}`,
              showLikes: true,
            };

            if (tip.data.button_text || tip.data.target) {
              currentCard.clickEvent = () => {
                actionsSpendingAnalytics
                  .trackEvents({
                    action: CUSTOM,
                    tipKey: tip.tip_key,
                    value: { data: 'shop_now' },
                  })
                  .then(() => {
                    if (
                      tip.data.target === OFFER_RECOMMENDATION &&
                      currentTarget
                    ) {
                      history.push(
                        `${currentTarget.target}/${tip.data.campaign_id}`,
                      );
                    } else if (currentTarget && currentTarget.target) {
                      window.location.href = currentTarget.target;
                    } else if (tip.data.target === BLOG) {
                      window.open(
                        `https://raizinvest.com.au/blog${
                          tip.data.blog_post_id
                            ? `/${tip.data.blog_post_id}`
                            : ''
                        }`,
                        '_blank',
                      );
                    } else if (
                      tip.data.target === 'link_external' ||
                      tip.data.target === 'deep_link'
                    ) {
                      // TODO: https://acornsau.atlassian.net/browse/RAIZ-5268
                      if (
                        tip.data.link_external.includes('raiz://offer_details')
                      ) {
                        history.push(
                          `rewards/online/${tip.data.link_external
                            .split('/')
                            .pop()}`,
                        );
                      } else {
                        window.open(tip.data.link_external, '_blank');
                      }
                    } else {
                      toggleHelpCard(false);
                    }
                  });
              };
            }

            currentCard.greenBtn = {
              isBtn: true,
              message: tip.button_text,
            };

            additionalHelpCards.push(currentCard);

            break;
          }

          case CATEGORY_EXPENSES:
            additionalHelpCards.push({
              ...tip,
              id: `${CATEGORY_EXPENSES}_${tip.tip_key}`,
              showLikes: true,
              greenBtn: {
                isBtn: true,
                message: tip.button_text,
              },
              clickEvent: () => {
                actionsSpendingAnalytics
                  .trackEvents({
                    action: CUSTOM,
                    tipKey: tip.tip_key,
                    value: { data: 'showme' },
                  })
                  .then(() => {
                    history.push(`/where-spend?category=${tip.data.category}`);
                  });
              },
            });

            break;
          case OFFER_RECOMMENDATION:
            additionalHelpCards.push({
              ...tip,
              id: `${OFFER_RECOMMENDATION}_${tip.tip_key}`,
              showLikes: true,
              greenBtn: {
                isBtn: true,
                message: tip.button_text,
              },
              clickEvent: () => {
                actionsSpendingAnalytics
                  .trackEvents({
                    action: CUSTOM,
                    tipKey: tip.tip_key,
                    value: { data: 'shop_now' },
                  })
                  .then(() => {
                    history.push(
                      `/rewards/${isNewRewards ? 'online/' : ''}${
                        tip.data.campaign_id
                      }`,
                    );
                  });
              },
            });

            break;
          case FREE_CASH:
            additionalHelpCards.push({
              ...tip,
              id: `${FREE_CASH}_${tip.tip_key}`,
              showLikes: true,
              greenBtn: {
                isBtn: true,
                message: tip.button_text,
              },
              clickEvent: () => {
                actionsSpendingAnalytics
                  .trackEvents({
                    action: CUSTOM,
                    tipKey: tip.tip_key,
                    value: { data: 'showme' },
                  })
                  .then(() => {
                    history.push('/potential-free-cash');
                  });
              },
            });

            break;
          default:
            break;
        }

        return additionalHelpCards;
      });
    }

    this.renderHelpContent({
      currentPage,
      additionalHelpCards,
      isProPortfolio,
    });
  }

  toggleRating(rating, tipKey, index, isWidget) {
    const { actionsSpendingAnalytics, toggleHelpCard, isProPortfolio } =
      this.props;

    const { content, showLoader } = this.state;

    if (!showLoader) {
      this.setState({ rating, showLoader: true });

      actionsSpendingAnalytics
        .trackEvents({
          action: FEEDBACK,
          tipKey,
          value: { reaction: rating },
        })
        .then(() => {
          if (isWidget) {
            actionsSpendingAnalytics.getTipsByPattern().then(() => {
              this.formattedTips({ isProPortfolio });

              this.setState({ showLoader: false });
            });
          } else {
            const currentContent = content;

            currentContent.splice(index, 1);

            this.setState({ content: currentContent });

            toggleHelpCard(false);

            toggleHelpCard(true);

            this.setState({ showLoader: false });
          }
        });
    }
  }

  trackImpression(content) {
    const { actionsSpendingAnalytics } = this.props;

    if (!content.impression && content.tip_key) {
      actionsSpendingAnalytics.trackEvents({
        action: IMPRESSION,
        tipKey: content.tip_key,
      });
    }
  }

  renderHelpCard(content, index, toggleHelpCard) {
    const { history, isProPortfolio, t } = this.props;

    const samePageLink =
      (history.location.pathname === '/where-spend' &&
        content.id === 'whereYouSpend') ||
      (history.location.pathname === '/transaction-categorisation' &&
        content.id === 'spendingCategories') ||
      history.location.pathname === content?.greenBtn?.link;

    const prortfolioButtonTitle =
      isProPortfolio && content?.greenBtn?.title
        ? t(`${content.greenBtn.title}`)
        : null;

    const buttonTitle =
      !isProPortfolio && content?.greenBtn?.title
        ? t(`helpCardsModal.button.${content.greenBtn.title}`)
        : content?.greenBtn?.message;

    return (
      <div
        className={`help-cards__modal-item ${content.additionalClass}`}
        key={`${content.id}_${index}`}
      >
        <DeprecatedButton
          buttonClass="button__close help-cards__modal-close"
          handleClick={() => {
            toggleHelpCard(false);
          }}
        />

        {content.data && (content.data.title || content.title) && (
          <div className="help-cards__modal-title">
            {content.title || content.data.title}
          </div>
        )}

        {content.data && content.data.image_url && (
          <img
            src={content.data.image_url}
            alt={content.data.title}
            height={20}
          />
        )}

        {content.className && !content.externalLink && content.pageColor && (
          <div
            className={`help-cards__modal-img ${content.className}`}
            style={{
              background: `linear-gradient(116.57deg, ${content.pageColor[0]} 0%, ${content.pageColor[1]} 100%)`,
            }}
          >
            {content.name && (
              <div className="help-cards__modal-title">{content.name}</div>
            )}
          </div>
        )}

        {content.className &&
          !content.externalLink &&
          !content.pageColor &&
          content.customHeader && <div>{content.customHeader}</div>}

        {content.className &&
          !content.externalLink &&
          !content.pageColor &&
          !content.customHeader && (
            <div className={`help-cards__modal-img ${content.className}`} />
          )}

        {content.className && content.externalLink && (
          <a
            href={content.greenBtn.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.customHeader ? (
              <div>{content.customHeader}</div>
            ) : (
              <div className={`help-cards__modal-img ${content.className}`} />
            )}
          </a>
        )}

        <section className="help-cards__modal-section">
          <section className="help-cards__modal-info">
            {content.info && !isProPortfolio && (
              <Trans t={t} i18nKey={`helpCardsModal.info.${content.info}`} />
            )}

            {isProPortfolio && content?.info && t(content.info)}

            {content.message && (
              <span dangerouslySetInnerHTML={{ __html: content.message }} />
            )}

            {content.description && content.description}
          </section>

          <div>{content.reaction}</div>

          {content.showLikes && (
            <section>
              <i
                className={`help-cards__modal-rating -like ${
                  content.reaction === LIKE ? '-active' : ''
                }`}
                onClick={() => this.toggleRating(LIKE, content.tip_key, index)}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              />

              <i
                className={`help-cards__modal-rating -dislike ${
                  content.reaction === DISLIKE ? '-active' : ''
                }`}
                onClick={() =>
                  this.toggleRating(DISLIKE, content.tip_key, index)
                }
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
              />
            </section>
          )}

          {content.showCareBtns && (
            <section className="help-cards__modal-care">
              <a
                href={`https://api.whatsapp.com/send?phone=${WA_NUMBER}`}
                target="_blank"
                rel="noopener noreferrer"
                className="help-cards__modal-care-icon -wa"
              >
                wa
              </a>

              <a
                href="https://line.me/R/ti/p/@286dpzwy"
                target="_blank"
                rel="noopener noreferrer"
                className="help-cards__modal-care-icon -line"
              >
                ln
              </a>

              <a
                href="https://chat.sociomile.com/livechat/5f30e8e8801ddf4c360cb270"
                target="_blank"
                rel="noopener noreferrer"
                className="help-cards__modal-care-icon -chat"
              >
                sm
              </a>
            </section>
          )}

          {content.greenBtn &&
            !content.greenBtn.isBtn &&
            !content.externalLink &&
            !content.linkWithAction &&
            !content.greenBtn.isCloseButton &&
            content.id !== 'howSeeRewards' &&
            history.location.pathname !== '/recurring-investment' &&
            !samePageLink && (
              <Link
                to={content.greenBtn.link}
                className="button__basic-fill -green -link"
              >
                {content.greenBtn.title &&
                  t(`helpCardsModal.button.${content.greenBtn.title}`)}

                {content.greenBtn.message && content.greenBtn.message}
              </Link>
            )}

          {content.greenBtn &&
            !content.greenBtn.isBtn &&
            !content.externalLink &&
            !content.linkWithAction &&
            ((history.location.pathname === '/rewards' &&
              content.id === 'howSeeRewards') ||
              history.location.pathname === '/recurring-investment' ||
              samePageLink) && (
              <Link
                onClick={() => toggleHelpCard(false)}
                to={content.greenBtn.link}
                className="button__basic-fill -green -link"
              >
                {content.greenBtn.title &&
                  t(`helpCardsModal.button.${content.greenBtn.title}`)}

                {content.greenBtn.message && content.greenBtn.message}
              </Link>
            )}

          {content.greenBtn &&
            !content.greenBtn.isBtn &&
            !content.externalLink &&
            content.linkWithAction && (
              <Link
                onClick={() => changeState(HOME_HISTORY_TAB)}
                to={content.greenBtn.link}
                className="button__basic-fill -green -link"
              >
                {content.greenBtn.title &&
                  t(`helpCardsModal.button.${content.greenBtn.title}`)}

                {content.greenBtn.message && content.greenBtn.message}
              </Link>
            )}

          {content.greenBtn &&
            !content.greenBtn.isBtn &&
            content.externalLink && (
              <a
                href={content.greenBtn.link}
                target="_blank"
                rel="noopener noreferrer"
                className="button__basic-fill -green -link"
              >
                {content.greenBtn.title &&
                  t(`helpCardsModal.button.${content.greenBtn.title}`)}

                {content.greenBtn.message && content.greenBtn.message}
              </a>
            )}

          {content.greenBtn && content.greenBtn.isBtn && (
            <Button
              label={prortfolioButtonTitle || buttonTitle}
              onClick={() => {
                content.clickEvent();
              }}
            />
          )}

          {content.greenBtn && content.greenBtn.isCloseButton && (
            <Button
              title={prortfolioButtonTitle || buttonTitle}
              buttonClass="button__basic-fill -green"
              handleClick={() => {
                toggleHelpCard(false);
              }}
            />
          )}

          {content.transparentBtn && (
            <DeprecatedButton
              title={t(`helpCardsModal.button.${content.transparentBtn.title}`)}
              buttonClass="button__basic-wtbackground"
              handleClick={() => false}
            />
          )}
        </section>
      </div>
    );
  }

  renderHelpContent({ currentPage, additionalHelpCards, isProPortfolio }) {
    const {
      user,
      toggleHelpCard,
      actionsFundingAccount,
      toggleSuspensionSuccess,
      rewardCard,
      rewardsEnable,
      isHaveCard,
      isNewRewards,
    } = this.props;

    const bannerTimeFromURL = HelpCardsModal.getUrlParam(HOME_ACTIVE_TAB);
    let currentHelpContent = [];

    const defaultPortfolios = [];
    const showCardInfoFromAPI = true;
    const portfolios = sessionStorage.getItem(SESSION_STORAGE_KEYS.portfolios);

    if (showCardInfoFromAPI && portfolios && JSON.parse(portfolios).length) {
      JSON.parse(portfolios).map((portfolio) => {
        defaultPortfolios.push({
          id: portfolio.name,
          imgUrl: false,
          className: '-custom-card',
          info: false,
          description: portfolio.description,
          name: portfolio.name,
          title: false,
          externalLink: false,
          pageColor: portfolio.page_color,
        });

        return portfolio;
      });
    }

    const portfolioPlusHelpCards = getPortfolioPlusHelpCards();

    switch (currentPage) {
      case HOMEPAGE_MONEY_THOR:
        currentHelpContent = additionalHelpCards;

        break;

      case HOMEPAGE: {
        const homeHelpCards = {
          [AUS]: isNewRewards
            ? [
                HOW_WORKS,
                MY_FINANCE,
                WELCOME_RAIZ_REWARDS_NEW,
                TRANSFERRING_MONEY,
                ACCOUNT_ACTIVITY,
              ]
            : [
                HOW_WORKS,
                MY_FINANCE,
                RAIZ_REWARDS,
                TRANSFERRING_MONEY,
                ACCOUNT_ACTIVITY,
              ],
          [MYS]: rewardsEnable
            ? [
                HOW_WORKS,
                MY_FINANCE,
                RAIZ_REWARDS,
                TRANSFERRING_MONEY,
                ACCOUNT_ACTIVITY,
              ]
            : [HOW_WORKS, MY_FINANCE, TRANSFERRING_MONEY, ACCOUNT_ACTIVITY],
        };

        const homepageArray = homeHelpCards[COUNTRY] || homeHelpCards[AUS];

        if ((MY_APP || AU_APP) && bannerTimeFromURL === FUTURE) {
          homepageArray.unshift(FUTURE_INFO);
        }

        if (user && user.alerts) {
          if (
            user.alerts.find(
              (alertMsg) => alertMsg.type === CHARGEBACK_FUNDING_ACCOUNT_RELINK,
            )
          ) {
            homepageArray.unshift(SETUP_FUNDING_ACCOUNT);
          }

          if (
            user.alerts.find(
              (alertMsg) => alertMsg.type === DEPOSITS_SUSPENSION_REMOVAL,
            )
          ) {
            const card = DEPOSIT_SUSPENSION;

            card.clickEvent = () => {
              actionsFundingAccount.removeSuspension().then(() => {
                toggleHelpCard(false);

                toggleSuspensionSuccess(true);
              });
            };

            homepageArray.unshift(card);
          }
        }

        currentHelpContent = homepageArray;

        break;
      }

      case RECURRINGINVESTMENT:
        currentHelpContent = [TRANSFERRING_MONEY_INVEST, SCHEDULING];

        break;
      case TRANSACTION_CATEGORISATION:
      case WHERE_YOU_SPEND:
        currentHelpContent = [WHERE_YOU_SPEND_CARD, SPENDING_CATEGORIES];

        break;

      case REWARDS: {
        let rewardsArray = [WELCOME_RAIZ_REWARDS];

        if (isNewRewards) {
          rewardsArray = [WELCOME_RAIZ_REWARDS_NEW];
        }

        if (!MY_APP && rewardCard === NO_LINKED) {
          rewardsArray.push(NO_CARD_LINKED);
        } else if (!MY_APP && rewardCard === NO_LINKED_WARN) {
          rewardsArray.push(NO_CARD_LINKED_WARN);
        } else if (AU_APP) {
          if (isHaveCard) {
            if (isNewRewards) {
              rewardsArray.push(USE_LINK_CARD_REWARDS);
            }
          } else if (isNewRewards) {
            rewardsArray.push(NO_CARD_LINKED_NEW);
          } else {
            rewardsArray.push(NO_CARD_LINKED);
          }
        } else if (!MY_APP) {
          rewardsArray.push(USE_LINK_CARD);
        }

        if (isNewRewards) {
          rewardsArray.push(HOW_SEE_REWARDS_NEW);
        } else {
          rewardsArray.push(HOW_SEE_REWARDS);
        }

        currentHelpContent = rewardsArray;

        break;
      }

      case PORTFOLIO: {
        const portfolioCards = {
          [AUS]: isProPortfolio
            ? portfolioPlusHelpCards
            : [EXPLORE_ETFS, POTENTIAL_INVESTMENT_PROJECTION],
          [MYS]: [],
        };

        currentHelpContent = portfolioCards[COUNTRY] || portfolioCards[AUS];

        break;
      }

      case ROUND_UP_HELP_CARD:
        currentHelpContent = [ROUND_UP_INFO];

        break;
      case KIDS:
        currentHelpContent = [KIDS_INFO];

        break;

      case NEW_KIDS: {
        const kidsCards = [
          WELCOM_KIDS_NEW,
          RAIZ_KIDS_ACCESS,
          RAIZ_KIDS_INVEST,
          RAIZ_KIDS_ADULT,
        ];

        currentHelpContent = kidsCards;

        break;
      }

      case REGISTRATION:
        currentHelpContent = [REG_INFO];

        break;
      case FUNDING_ACCOUNT:
        currentHelpContent = [BANK_FUNDING, BANK_LEVEL_SECURITY];

        break;
      case ROUND_UP_ACCOUNT:
        currentHelpContent = [BANK_ROUND_UP, BANK_LEVEL_SECURITY];

        break;
      default:
        currentHelpContent = MY_APP
          ? [HOW_WORKS, MY_FINANCE, TRANSFERRING_MONEY, ACCOUNT_ACTIVITY]
          : [
              HOW_WORKS,
              MY_FINANCE,
              RAIZ_REWARDS,
              TRANSFERRING_MONEY,
              ACCOUNT_ACTIVITY,
            ];
    }

    this.setState({ content: currentHelpContent, rating: null });
  }

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
      },
    };

    const { isOpenHelpCards, toggleHelpCard, isWidget } = this.props;
    const { content, rating, showLoader } = this.state;

    if (isWidget && content && content[0]) {
      if (!content[0].impression) {
        this.trackImpression(content[0]);
      }

      return (
        <MoneyThorView
          showLoader={showLoader}
          rating={rating}
          content={content[0]}
          count={content.length}
          toggleRating={this.toggleRating}
          toggleHelpCard={toggleHelpCard}
        />
      );
    }

    return (
      <Modal
        isOpen={isOpenHelpCards}
        onRequestClose={() => toggleHelpCard(false)}
        contentLabel="help-card-modal"
        shouldCloseOnOverlayClick
        className="help-cards__modal"
      >
        <Carousel
          infinite={false}
          responsive={responsive}
          ssr
          swipeable
          removeArrowOnDeviceType={['tablet', 'mobile']}
          slidesToSlide={1}
          showDots={content.length !== 1}
          afterChange={(previousSlide, { currentSlide }) => {
            this.trackImpression(content[currentSlide]);
          }}
        >
          {content.map((currentContent, index) =>
            this.renderHelpCard(currentContent, index, toggleHelpCard),
          )}
        </Carousel>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  type: state.user.type,
});

const mapDispatchToProps = (dispatch) => ({
  actionsSpendingAnalytics: bindActionCreators(
    spendingAnalyticsActions,
    dispatch,
  ),
  actionsFundingAccount: bindActionCreators(fundingAccountActions, dispatch),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(HelpCardsModal)),
);

HelpCardsModal.defaultProps = {
  isWidget: false,
  rewardCard: '',
  user: {},
  tips: [],
  actionsFundingAccount: {},
  actionsSpendingAnalytics: {},
  toggleSuspensionSuccess: () => {},
  rewardsEnable: false,
  isHaveCard: false,
  isNewRewards: false,
  toggleHelpCard: () => {},
  isProPortfolio: false,
};

HelpCardsModal.propTypes = {
  t: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  isWidget: PropTypes.bool,
  toggleHelpCard: PropTypes.func,
  toggleSuspensionSuccess: PropTypes.func,
  rewardCard: PropTypes.string,
  user: PropTypes.shape({
    alerts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  tips: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  isOpenHelpCards: PropTypes.bool.isRequired,
  actionsSpendingAnalytics: PropTypes.shape({
    trackEvents: PropTypes.func,
    getTipsByPattern: PropTypes.func,
  }),
  actionsFundingAccount: PropTypes.shape({
    removeSuspension: PropTypes.func,
  }),
  rewardsEnable: PropTypes.bool,
  isHaveCard: PropTypes.bool,
  isNewRewards: PropTypes.bool,
  isProPortfolio: PropTypes.bool,
};
