import { AUS, MYS } from './comonConstants';

export const COUNTRY = process.env.REACT_APP_CODE_BASE_VERSION;

const buildVerison = {
  [AUS]: '2.73.0',
  [MYS]: '1.58.0',
};

export const BUILD_VERSION = buildVerison[COUNTRY] || '0.0.1';
