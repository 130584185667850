export const REWARDS_ADDITIONAL_PAGES = [
  '/rewards/search',
  '/rewards/category/*',
  '/rewards/online/*',
];

export const DEFAULT_LOCATION = {
  address: '205 Pitt St, Sydney NSW 2000',
  longitude: 151.2059639,
  latitude: -33.8691991,
};

export const GOOGLE_MAPS_CONFIG = {
  urlDestination: 'https://www.google.com/maps/dir/?api=1&origin=',
  zoom: 14,
};

export const FAVOURITE_CATEGORY_ID = 999;

export const DEFAULT_FAVOURITE_CATEGORY = {
  name: 'Favourites',
  id: 'favourite',
  image: '',
};

export const REWARDS_IN_STORE_SETTINGS = {
  latitude: DEFAULT_LOCATION.latitude,
  longitude: DEFAULT_LOCATION.longitude,
  distance: '10',
};

export const REWARDS_HISTORY_STATUSES = {
  invested: 'invested',
  pending: 'pending',
  reversed: 'reversed',
};
