import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import { PastBannerContent } from './PastBannerContent';
import { BannerContentRow, BannerContentWrapper } from './styles';
import { BannerContentItem } from './BannerContentItem';
import { PastBannerKidsContent } from './PastBannerKidsContent';

import { MY_APP } from 'constants/togglesFunctions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUserAccountSummary } from 'store/history/selectors';
import { PATHS } from 'constants/paths';

function HomePastBanner() {
  const { t } = useTranslation();

  const isKids = !!useMatch(PATHS.kids.history);

  const {
    reinvested_dividends,
    invested_by_you,
    total_referrals_rewarded,
    gain_loss,
    total_found_money_rewarded,
    withdrawals,
  } = useAppSelector(selectUserAccountSummary({ isKids }));

  if (MY_APP) {
    return <PastBannerContent />;
  }

  if (isKids) {
    return <PastBannerKidsContent />;
  }

  if (MY_APP) {
    return <PastBannerContent />;
  }

  return (
    <BannerContentWrapper>
      <BannerContentRow>
        <BannerContentItem
          label={t('homePage.homePastBanner.investedByYou')}
          value={invested_by_you?.value}
        />

        <BannerContentItem
          label={t('homePage.homePastBanner.reinvestedDividends')}
          value={reinvested_dividends?.value}
        />

        <BannerContentItem
          label={t('homePage.homePastBanner.referrals')}
          value={total_referrals_rewarded?.value}
        />
      </BannerContentRow>

      <BannerContentRow>
        <BannerContentItem
          label={t('homePage.homePastBanner.marketReturns')}
          value={gain_loss?.value}
        />

        <BannerContentItem
          label={t('homePage.homePastBanner.raizRewards')}
          value={total_found_money_rewarded?.value}
        />

        <BannerContentItem
          label={t('homePage.homePastBanner.withdrawn')}
          value={withdrawals?.value}
        />
      </BannerContentRow>
    </BannerContentWrapper>
  );
}

export default HomePastBanner;
