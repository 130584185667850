import styled from '@emotion/styled';

import portfolioWelcome from 'assets/images/help-cards/portfolio-welcome.svg';
import constructingYourPortfolio from 'assets/images/help-cards/constructing-your-portfolio.svg';
import customizeYourPortfolio from 'assets/images/help-cards/customize-your-portfolio.svg';
import makeChangesAnytime from 'assets/images/help-cards/make-changes-anytime.svg';
import headsUp from 'assets/images/help-cards/heads-up.svg';
import { Button } from 'components/elements/IconButton/styles';

const INVITE_ICON_BUTTON_SIZE = 87;

export const CardWrapper = styled.div`
  height: 200px;
`;
export const CardTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes['2lg']};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-top: 20px;
`;

export const WelcomeCardWrapper = styled(CardWrapper)`
  background: url(${`${portfolioWelcome}`}) no-repeat center 102px,
    ${({ theme }) => theme.gradients.goldenRuby};
`;

export const WelcomeCardTitle = styled(CardTitle)`
  padding-top: 44px;
`;

export const ConstructingTitle = styled(CardTitle)`
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;

export const CustomizeYourPortfolioCardWrapper = styled(CardWrapper)`
  background: url(${`${customizeYourPortfolio}`}) no-repeat center 70px,
    ${({ theme }) => theme.gradients.azureNightfall};
`;

export const ConstructingYourPortfolioCardWrapper = styled(CardWrapper)`
  background: url(${`${constructingYourPortfolio}`}) no-repeat center 70px,
    ${({ theme }) => theme.gradients.magentaMaroon};
`;

export const MakeChangesAnytimeCardWrapper = styled(CardWrapper)`
  background: url(${`${makeChangesAnytime}`}) no-repeat center 96px,
    ${({ theme }) => theme.gradients.verdantOasis};
`;

export const HeadsUpCardWrapper = styled(CardWrapper)`
  background: url(${`${headsUp}`}) no-repeat center 70px,
    ${({ theme }) => theme.gradients.crimsonMidnight};
`;

export const InviteIconButton = styled(Button)`
  box-shadow: ${({ theme }) => theme.shadows.medium};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 100%;
  height: ${INVITE_ICON_BUTTON_SIZE}px;
  width: ${INVITE_ICON_BUTTON_SIZE}px;
  padding-top: 10px;
`;
