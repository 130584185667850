import Firebase from 'firebase/app';

import {
  SHOW_MFA_FIELD,
  CLOSE_CONNECT_SCREEN,
  SHOW_CONNECT_SCREEN,
  GET_WITHDRAWAL_ACCOUNT_SUCCESS,
  GET_FUNDING_PAYMENT_CARD_SUCCESS,
  SHOW_ERROR_POPUP_PAYMENT_CARD,
  SHOW_WAIT_POPUP_BANK_LOGIN,
  RESPONSE_FROM_BANK_SUCCESS,
  GET_FUNDING_ACCOUNT_SUCCESS,
  SHOW_ERROR_POPUP_BANK_LOGIN,
  BACK_FROM_MORE_BANKS,
  WAITING_IN_PROCESS,
  GET_BANKS_SUCCESS,
  GET_MORE_BANKS,
  CLOSE_MODAL,
  SKIP_BANK,
  RESET_PROPS,
} from '../commonTypes';
import { showGlobalError } from '../errors/actions';
import { MY_APP, AU_APP } from '../../constants/localeConfigs';

import {
  CONNECT_ACCOUNT,
  SKIP_BANK_LOGIN,
  SHOW_DIFFERENT_BANK,
  STOP_BANK_LOGIN_REQUEST,
  GET_INSTITUTIONS_SUCCESS,
  ADD_PAYMENT_CARD_SUCCESS,
  GET_SPENDING_PAYMENT_CARD_SUCCESS,
  SHOW_ERROR_SWITHC_PAYMENT_CARD,
  SWITHC_CARD_OTHER_ACCOUNT_SUCCESS,
  SHOW_ERROR_UNLINK_CARD,
  START_CONNECT_TO_BANK,
  WATING_BANKS,
  CLOSE_BANK_CONNECT_MODAL,
  REMOVE_SUSPENSION_SUCCESS,
  SHOW_WITHDRAWAL_ERROR,
  FUNDING_SOURCE_ERROR,
  RESET_MFA_FIELD,
  SHOW_FUNDING_SECURITY_MODAL,
  GET_VISA_PAYMENT_CARD_SUCCESS,
  DELETE_VISA_CARD_SUCCESS,
  ADD_VISA_CARD_SUCCESS,
  GET_BANK_INSTITUTIONS_SUCCESS,
  REFRESH_ROUNDUP_SUCCESS,
  REFRESH_ROUNDUP,
  SET_IS_CONNECTION_STEP,
  SET_CONNECTION_STATUS_DATA,
  DEFAULT_SPENDING_SKIP_DELAY,
  SET_BANK_ACCOUNT_DATA,
  SET_CURRENT_BANK,
  SET_FASTLINK_FLOW,
  SET_IS_SHOW_CONNECT_MODAL,
  SET_IS_SHOW_CONNECT_STEP,
  SET_IS_LINK_CARD_LOADING,
} from './consts';
import getBankListRequestParams from './utils/getBankListRequestParams';
import { setLoadingStartTime, getLoadingDuration } from './utils/utils';

import createRequest from 'services/api/createRequest';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { api } from 'services/api';
import { selectIsYodleeFastlinkEnabled } from 'store/firebaseConfig/selectors';
import { API } from 'services/api/consts';

export function getFundingAccountSuccess(
  fundingAccount,
  isRegistration = false,
  isFromInvestments = false,
  isForLinkFunding,
) {
  return {
    type: GET_FUNDING_ACCOUNT_SUCCESS,
    fundingAccount,
    isRegistration,
    isFromInvestments,
    isForLinkFunding,
  };
}

export function getWithdrawalAccountSuccess(
  fundingAccount,
  isRegistration = false,
  isFromInvestments = false,
  isFromMyKad = false,
) {
  return {
    type: GET_WITHDRAWAL_ACCOUNT_SUCCESS,
    fundingAccount,
    isRegistration,
    isFromInvestments,
    isFromMyKad,
  };
}

export function getBanksSuccess(banks, monitoredInstitutions = {}) {
  return { type: GET_BANKS_SUCCESS, banks, monitoredInstitutions };
}

export function getMoreBanksSuccess(banks, addListener, isRegistration) {
  return {
    type: GET_MORE_BANKS,
    banks,
    addListener,
    isRegistration,
  };
}

export function switchСardOtherAccountSuccess(isFromLinkFunding) {
  return { type: SWITHC_CARD_OTHER_ACCOUNT_SUCCESS, isFromLinkFunding };
}

export function removeSuspensionSuccess() {
  return { type: REMOVE_SUSPENSION_SUCCESS };
}

export function getInstitutionsSuccess(
  monitoredInstitutions,
  isReconectForFunding = false,
) {
  return {
    type: GET_INSTITUTIONS_SUCCESS,
    monitoredInstitutions,
    isReconectForFunding,
  };
}

export function showWaitPopUpBankLogin(isShowBankModalAlert) {
  return { type: SHOW_WAIT_POPUP_BANK_LOGIN, isShowBankModalAlert };
}

export function waitingInProcess() {
  setLoadingStartTime();

  return { type: WAITING_IN_PROCESS };
}

export function showErrorPopUpBankLogin({
  errorMessage,
  errorTitle = '',
  isRegistration = false,
}) {
  if (isRegistration) {
    Firebase.analytics().logEvent('Registration', {
      eventKey: 'Funding Account Link Error',
    });
  } else {
    Firebase.analytics().logEvent('LinkedFundingSourceError');

    Firebase.analytics().logEvent('User', {
      eventKey: 'Funding Account Link Error',
    });
  }

  return { type: SHOW_ERROR_POPUP_BANK_LOGIN, errorMessage, errorTitle };
}

export function showErrorAddPaymentCard(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_POPUP_PAYMENT_CARD, errorMessage, errorTitle };
}

export function showErrorSwitchPaymentCard(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_SWITHC_PAYMENT_CARD, errorMessage, errorTitle };
}

export function showErrorUnlinkCard(errorMessage, errorTitle = '') {
  return { type: SHOW_ERROR_UNLINK_CARD, errorMessage, errorTitle };
}

export function startConnectToBank() {
  return { type: START_CONNECT_TO_BANK };
}

export function skipBankLogin() {
  return { type: SKIP_BANK_LOGIN };
}

export function resetProps() {
  sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLoginRequest, 0);

  return { type: RESET_PROPS };
}

export function showDifferetnBank() {
  return { type: SHOW_DIFFERENT_BANK };
}

export function closeModal() {
  return { type: CLOSE_MODAL };
}

export function skipBank(
  userData,
  isSpending,
  isFromInvestments = false,
  isWithdrawal = false,
  isKidsRegistrationHome = false,
  isKidsRegistrationPortfolio = false,
) {
  return {
    type: SKIP_BANK,
    userData,
    isSpending,
    isFromInvestments,
    isWithdrawal,
    isKidsRegistrationHome,
    isKidsRegistrationPortfolio,
  };
}

export function backFromMoreBanks(isSpending) {
  return { type: BACK_FROM_MORE_BANKS, isSpending };
}

export function addPaymentCardSuccess(paymentCard) {
  return { type: ADD_PAYMENT_CARD_SUCCESS, paymentCard };
}

export function getSpendingPaymentCardSuccess(
  spendingPaymentCards,
  isForBankLinkFunding,
) {
  return {
    type: GET_SPENDING_PAYMENT_CARD_SUCCESS,
    spendingPaymentCards,
    isForBankLinkFunding,
  };
}

export function getFundingPaymentCardSuccess(fundingPaymentCards) {
  return { type: GET_FUNDING_PAYMENT_CARD_SUCCESS, fundingPaymentCards };
}

export function waitingBanks() {
  return { type: WATING_BANKS };
}

export function closeBankConnectModal() {
  return { type: CLOSE_BANK_CONNECT_MODAL };
}

export function showWithdrawalError(errorMessage) {
  return { type: SHOW_WITHDRAWAL_ERROR, errorMessage };
}

export function showFundingSourceError(errorMessage, fundingStatusCode = 200) {
  return { type: FUNDING_SOURCE_ERROR, errorMessage, fundingStatusCode };
}

export function showFundingSecurityModal(messages) {
  return { type: SHOW_FUNDING_SECURITY_MODAL, messages };
}

export function showMfaField(otpData) {
  return { type: SHOW_MFA_FIELD, otpData };
}

export function resetMfaField() {
  return { type: RESET_MFA_FIELD };
}

export function getVisaPaymentCardSuccess(visaPaymentCards) {
  return { type: GET_VISA_PAYMENT_CARD_SUCCESS, visaPaymentCards };
}

export function deleteVisaCardSuccess() {
  return { type: DELETE_VISA_CARD_SUCCESS };
}

export function addVisaCardSuccess(isKidWithPortfolioAccess) {
  return { type: ADD_VISA_CARD_SUCCESS, isKidWithPortfolioAccess };
}

export function initiateRefreshRoundUpSuccess(
  refreshSessionId,
  roundUpRefreshHint,
) {
  return { type: REFRESH_ROUNDUP, refreshSessionId, roundUpRefreshHint };
}

export const setIsBankAccountLinkingStep = (isBankAccountLinkingStep) => ({
  type: SET_IS_CONNECTION_STEP,
  isBankAccountLinkingStep,
});

export const setConnectionStatusData = (connectionStatusData) => ({
  type: SET_CONNECTION_STATUS_DATA,
  connectionStatusData,
});

export const setBankAccountData = (bankAccountData) => ({
  type: SET_BANK_ACCOUNT_DATA,
  bankAccountData,
});

export const setCurrentBank = (currentBank) => ({
  type: SET_CURRENT_BANK,
  currentBank,
});

export const setFastlinkFlow = (fastlinkFlow) => ({
  type: SET_FASTLINK_FLOW,
  fastlinkFlow,
});

export function responseFromBankSuccess(
  monitoredInstitution,
  isRegistration = false,
  isSpending = false,
  isFromInvestments = false,
  isKidsRegistrationPortfolio = false,
  isKidsRegistrationHome = false,
) {
  sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLoginRequest, 1);

  let newMonitoredInstitution = {};

  if (monitoredInstitution && monitoredInstitution.monitored_institution) {
    newMonitoredInstitution = [monitoredInstitution.monitored_institution];
  } else {
    newMonitoredInstitution = monitoredInstitution;
  }

  if (isRegistration) {
    Firebase.analytics().logEvent('Registration', {
      eventKey: 'Funding Account|Complete',
    });

    Firebase.analytics().logEvent('Registration', {
      eventKey: 'Funding Account Linked(LinkedFundingSource)',
    });
  } else if (!isSpending) {
    Firebase.analytics().logEvent('LinkedFundingSource');

    Firebase.analytics().logEvent('User', {
      eventKey: 'Funding Account Linked',
    });
  }

  const isReconectForFunding = false;

  if (
    isRegistration &&
    !isKidsRegistrationPortfolio &&
    !isKidsRegistrationHome
  ) {
    return {
      type: SHOW_CONNECT_SCREEN,
      newMonitoredInstitution,
      isRegistration,
      isSpending,
      isFromInvestments,
      isReconectForFunding,
    };
  }

  return {
    type: RESPONSE_FROM_BANK_SUCCESS,
    newMonitoredInstitution,
    isRegistration,
    isSpending,
    isFromInvestments,
    isKidsRegistrationPortfolio,
    isKidsRegistrationHome,
  };
}

export function stopLoginRequest() {
  return { type: STOP_BANK_LOGIN_REQUEST };
}

export function closeConnectScreen() {
  return { type: CLOSE_CONNECT_SCREEN };
}

export function refreshRoundUpSuccess() {
  return { type: REFRESH_ROUNDUP_SUCCESS };
}

export function setIsLinkCardLoading(isLinkCardLoading) {
  return {
    type: SET_IS_LINK_CARD_LOADING,
    isLinkCardLoading,
  };
}

export function showConnectScreen(
  newMonitoredInstitution,
  isRegistration,
  isSpending,
  isFromInvestments,
  isReconectForFunding,
) {
  return {
    type: SHOW_CONNECT_SCREEN,
    newMonitoredInstitution,
    isRegistration,
    isSpending,
    isFromInvestments,
    isReconectForFunding,
  };
}

export function getBankInstitutionsSuccess(banksInstitutions) {
  return { type: GET_BANK_INSTITUTIONS_SUCCESS, banksInstitutions };
}

// TODO: https://acornsau.atlassian.net/browse/RAIZ-6448
export function setIsShowConnectModal(data) {
  return { type: SET_IS_SHOW_CONNECT_MODAL, data };
}

export function setIsShowConnectStep(isShowConnect) {
  return { type: SET_IS_SHOW_CONNECT_STEP, isShowConnect };
}

export function getFundingAccount(
  isRegistration = false,
  isFromInvestments = false,
  isWithdrawal = false,
  isFromMyKad = false,
  isForLinkFunding = false,
) {
  return (dispatch) =>
    createRequest(null, 'GET', 'accounts')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage: response.error,
            }),
          );
        } else if (!response.error && isWithdrawal && response.funding) {
          dispatch(
            getWithdrawalAccountSuccess(
              response,
              isRegistration,
              isFromInvestments,
              isFromMyKad,
            ),
          );
        } else if (response.funding) {
          dispatch(
            getFundingAccountSuccess(
              response,
              isRegistration,
              isFromInvestments,
              isForLinkFunding,
            ),
          );
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function checkIfHaveSubAccounts(
  responseInstitutions,
  isSpending = false,
) {
  let monitoredInstitutions = {};

  if (responseInstitutions.monitored_institutions.length !== 0) {
    monitoredInstitutions = responseInstitutions.monitored_institutions.map(
      (institution) => {
        if (isSpending) {
          return institution;
        }

        if (institution.subaccounts.length !== 0) {
          return institution;
        }

        return {};
      },
    );
  } else if (responseInstitutions.monitored_institutions.length === 0) {
    return [];
  }

  return monitoredInstitutions.filter(
    (value) => Object.keys(value).length !== 0,
  );
}

export function getBanksList({
  isMoreBanks = false,
  name = '',
  isOffset = false,
  limit = 100,
  isSpending = false,
  isRegistration = false,
  isWithdrawal = true,
} = {}) {
  return async (dispatch, getState) => {
    try {
      const bankListEndpoint = MY_APP
        ? 'banks/'
        : 'institutions/shared_accessible';

      const state = getState();

      const isYodleeFastlinkEnabled = selectIsYodleeFastlinkEnabled(state);

      const params = getBankListRequestParams({
        isMoreBanks,
        name,
        isOffset,
        limit,
        isSpending,
        isWithdrawal,
        isYodleeFastlinkEnabled,
        isRegistration,
      });

      const apiVersion = AU_APP ? API.v2 : process.env.REACT_APP_API_VERSION;

      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/${apiVersion}/${bankListEndpoint}?${params}`,
      );

      if (response.error) {
        dispatch(
          showErrorPopUpBankLogin({
            errorMessage: response.error,
          }),
        );
      } else if (isMoreBanks) {
        const responseBanks = response;

        let bankSearchInstitutions = responseBanks.institutions;

        if (isOffset) {
          const bankSearchInstitutionsStore = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.bankSearchInstitutions),
          );

          if (bankSearchInstitutionsStore) {
            bankSearchInstitutions = [
              ...bankSearchInstitutionsStore,
              ...responseBanks.institutions,
            ];
          }
        }

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.bankSearchInstitutions,
          JSON.stringify(bankSearchInstitutions),
        );

        if (!name) {
          dispatch(
            getMoreBanksSuccess(bankSearchInstitutions, true, isRegistration),
          );
        } else {
          dispatch(
            getMoreBanksSuccess(bankSearchInstitutions, false, isRegistration),
          );
        }
      } else {
        const responseBanks = response;

        dispatch(getBanksSuccess(responseBanks, []));
      }
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function getBankInstitutions() {
  return (dispatch) =>
    createRequest(null, 'GET', 'institutions/list')
      .then((response) => response.json())
      .then((responseInstitutions) => {
        if (responseInstitutions.error) {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage: responseInstitutions.error,
            }),
          );
        } else {
          dispatch(getBankInstitutionsSuccess(responseInstitutions));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getBanks({
  isSpending = false,
  isShowBank = true,
  isReconectForFunding = false,
  isWithdrawal = false,
} = {}) {
  return (dispatch) =>
    createRequest(null, 'GET', 'institutions')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage: response.error,
            }),
          );
        } else {
          const monitoredInstitutions = checkIfHaveSubAccounts(
            response,
            isSpending,
          );

          if (monitoredInstitutions && monitoredInstitutions.length !== 0) {
            dispatch(
              getInstitutionsSuccess(
                monitoredInstitutions,
                isReconectForFunding,
              ),
            );
          } else if (isShowBank) {
            dispatch(
              getBanksList({
                isSpending,
                isWithdrawal,
              }),
            );
          } else {
            dispatch(getInstitutionsSuccess([]));
          }
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function connectAccount() {
  return { type: CONNECT_ACCOUNT };
}

export function loginInBank({
  id,
  dispatch,
  isSpending = false,
  isRegistration,
  isFromInvestments = false,
  isKidsRegistrationPortfolio = false,
  isKidsRegistrationHome = false,
  requestTimeout = DEFAULT_SPENDING_SKIP_DELAY,
}) {
  const bankLoginCount = parseInt(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.bankLoginCount),
  );

  const stopBankLogin = parseInt(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.stopBankLogin),
  );

  const stopBankLoginRequest = parseInt(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.stopBankLoginRequest),
  );

  const loadingDuration = getLoadingDuration();

  if (stopBankLoginRequest) {
    const intervalId = window.setInterval(() => {}, 50);

    for (let i = 1; i < intervalId; i += 1) {
      window.clearInterval(i);
    }

    return;
  }

  if (stopBankLogin) {
    dispatch(skipBankLogin());

    clearInterval(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.loginInBankIntervalId),
    );

    return;
  }

  let source = '?funding_source=1';

  if (isSpending) {
    source = '';
  }

  createRequest(null, 'GET', `institutions/${id}${source}`)
    .then((responseSignIn) => responseSignIn.json())
    .then((responseSignIn) => {
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.bankLoginCount,
        bankLoginCount + 1,
      );

      if (responseSignIn.monitored_institution.link_state) {
        dispatch(
          setConnectionStatusData(
            responseSignIn.monitored_institution.link_state,
          ),
        );
      }

      if (responseSignIn.error) {
        dispatch(
          showErrorPopUpBankLogin({
            errorMessage: responseSignIn.error,
            isRegistration,
          }),
        );
      } else if (
        responseSignIn.monitored_institution.error_info.error_code &&
        responseSignIn.monitored_institution.error_info.error_code !==
          'VERIFY_SPENDING_ACCOUNT' &&
        responseSignIn.monitored_institution.error_info.error_code !==
          'ROUNDUPSNOTFETCHED'
      ) {
        if (responseSignIn.monitored_institution.linked) {
          dispatch(
            responseFromBankSuccess(
              responseSignIn,
              isRegistration,
              isSpending,
              isFromInvestments,
              isKidsRegistrationPortfolio,
              isKidsRegistrationHome,
            ),
          );
        } else {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage:
                responseSignIn.monitored_institution.error_info.resolve,
              errorTitle:
                responseSignIn.monitored_institution.error_info.error_message,
              isRegistration,
            }),
          );
        }
      } else if (
        responseSignIn.monitored_institution.alert &&
        responseSignIn.monitored_institution.alert.type &&
        responseSignIn.monitored_institution.alert.type !==
          'VERIFY_SPENDING_ACCOUNT' &&
        responseSignIn.monitored_institution.alert.type !== 'ROUNDUPSNOTFETCHED'
      ) {
        if (responseSignIn.monitored_institution.linked) {
          dispatch(
            responseFromBankSuccess(
              responseSignIn,
              isRegistration,
              isSpending,
              isFromInvestments,
              isKidsRegistrationPortfolio,
              isKidsRegistrationHome,
            ),
          );
        } else {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage:
                responseSignIn.monitored_institution.alert.description,
              errorTitle: responseSignIn.monitored_institution.alert.title,
              isRegistration,
            }),
          );
        }
      } else if (
        !responseSignIn.monitored_institution.alert &&
        !responseSignIn.monitored_institution.error_info
      ) {
        if (responseSignIn.monitored_institution.linked) {
          dispatch(
            responseFromBankSuccess(
              responseSignIn,
              isRegistration,
              isSpending,
              isFromInvestments,
              isKidsRegistrationPortfolio,
              isKidsRegistrationHome,
            ),
          );
        }
      } else if (responseSignIn.monitored_institution.linked) {
        dispatch(
          responseFromBankSuccess(
            responseSignIn,
            isRegistration,
            isSpending,
            isFromInvestments,
            isKidsRegistrationPortfolio,
            isKidsRegistrationHome,
          ),
        );
      } else if (loadingDuration >= requestTimeout) {
        dispatch(showWaitPopUpBankLogin(true));
      }
    })
    .catch((error) => dispatch(showGlobalError(error)));
}

export function bankSignIn(bankData) {
  const {
    formData,
    bank,
    isSpending,
    isRegistration,
    isFromInvestments,
    autoRoundUps,
    isMfa,
    isKidsRegistrationPortfolio,
    isKidsRegistrationHome,
    isReConnectAccount,
  } = bankData;

  let credentials = {};

  if (isSpending) {
    if (isMfa) {
      credentials = {
        credentials: formData,
      };
    } else {
      credentials = {
        credentials: formData,
        funding_source: false,
        automatic_roundup: autoRoundUps,
      };
    }
  } else {
    credentials = {
      credentials: formData,
      funding_source: true,
      automatic_roundup: true,
    };
  }

  sessionStorage.setItem(SESSION_STORAGE_KEYS.bankLoginCount, 1);

  sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLogin, 0);

  sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLoginRequest, 0);

  const { id, institution_id } = bank;
  const institutionId = isReConnectAccount ? id : institution_id;
  let data = {};

  data = credentials;

  let url = `institutions/${institutionId}`;

  if (isMfa) {
    url = `institutions/otp/${institutionId}`;
  }

  const requestMethod = isReConnectAccount ? 'PUT' : 'POST';

  return (dispatch, getState) => {
    const spendingSkipDelay =
      getState()?.user?.features?.spendingSkipDelay || 0;

    return createRequest(JSON.stringify(data), requestMethod, url)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage: response.error,
            }),
          );
        } else {
          if (AU_APP) {
            dispatch(setBankAccountData(response));

            dispatch(setIsBankAccountLinkingStep(true));
          }

          const transactionId = isReConnectAccount
            ? response.monitored_institution.id
            : response.id;

          setLoadingStartTime();

          const loginInBankData = {
            id: transactionId,
            dispatch,
            isSpending,
            isRegistration,
            isFromInvestments,
            isKidsRegistrationPortfolio,
            isKidsRegistrationHome,
            requestTimeout: spendingSkipDelay,
          };

          if (!AU_APP && transactionId) {
            const loginInBankIntervalId = setInterval(
              () => loginInBank(loginInBankData),
              3000,
            );

            sessionStorage.setItem(
              SESSION_STORAGE_KEYS.loginInBankIntervalId,
              loginInBankIntervalId,
            );
          }

          if (isMfa) {
            const { instruction_text } = response;

            if (instruction_text) {
              dispatch(
                showErrorPopUpBankLogin({
                  errorMessage: instruction_text,
                }),
              );
            }

            dispatch(showMfaField(response));
          } else {
            dispatch(startConnectToBank());
          }
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function bankSignDifferentIn(
  formData,
  isRegistration = false,
  isFromInvestments = false,
  isWithdrawal = false,
  isFromMyKad = false,
) {
  let data = {};

  if (isWithdrawal) {
    data = {
      account: {
        account_number: formData.account_number,
        bank_id: formData.bank_id,
      },
    };
  } else {
    data = {
      account: {
        funds_type: 'ACH',
        type: 'funding',
        account_number: formData.accountNumber,
        routing_number: formData.bsb,
      },
    };
  }

  return (dispatch) =>
    createRequest(JSON.stringify(data), 'POST', 'accounts')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage: response.error,
              isRegistration,
            }),
          );
        } else {
          if (isRegistration && !isWithdrawal) {
            Firebase.analytics().logEvent('Registration', {
              eventKey: 'Funding Account|Complete',
            });

            Firebase.analytics().logEvent('Registration', {
              eventKey: 'Funding Account Linked(LinkedFundingSource)',
            });
          } else if (isWithdrawal) {
            Firebase.analytics().logEvent('LinkedWithdrawalAccount');
          } else {
            Firebase.analytics().logEvent('LinkedFundingSource');

            Firebase.analytics().logEvent('User', {
              eventKey: 'Funding Account Linked',
            });
          }

          dispatch(
            getFundingAccount(
              isRegistration,
              isFromInvestments,
              isWithdrawal,
              isFromMyKad,
            ),
          );
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function addAccountForInvestments(
  subAccountid,
  institutionId,
  bankId,
  isRegistration = false,
) {
  const data = {
    account: {
      monitored_institution_id: institutionId,
      funds_type: 'ACH',
      type: 'funding',
      account_number: subAccountid,
      routing_number: bankId,
    },
  };

  return (dispatch) =>
    createRequest(JSON.stringify(data), 'POST', 'accounts')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage: response.error,
            }),
          );
        } else {
          dispatch(getFundingAccount(isRegistration));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function subAccountLinkUnlink(accountId, monitoredId, linked) {
  let linkParameter = 'unlink_accounts ';

  if (!linked) {
    linkParameter = 'link_accounts';
  }

  const data = {
    id: monitoredId,
    account_ids: [accountId],
  };

  return (dispatch) =>
    createRequest(
      JSON.stringify(data),
      'PUT',
      `institutions/${monitoredId}/${linkParameter}`,
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage: response.error,
            }),
          );
        } else {
          dispatch(getBanks({ isSpending: true }));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function deleteAccount(accountId) {
  return (dispatch) =>
    createRequest(null, 'DELETE', `institutions/${accountId}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            showErrorPopUpBankLogin({
              errorMessage: response.error,
            }),
          );
        } else {
          dispatch(getBanks({ isSpending: true }));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function cardBind(cardId, redirectData) {
  const data = {
    card_id: cardId,
    failed_url: redirectData.failed_url,
    success_url: redirectData.success_url,
  };

  return (dispatch) =>
    createRequest(JSON.stringify(data), 'POST', 'infinitium/card_bind')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorAddPaymentCard(response.error));
        } else {
          dispatch(addPaymentCardSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function addPaymentCard(data, redirectData) {
  return (dispatch) => {
    dispatch(setIsLinkCardLoading(true));

    return createRequest(JSON.stringify(data), 'POST', 'payment_cards')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorAddPaymentCard(response.error));

          setIsLinkCardLoading(false);
        } else if (response.payment_card.id) {
          dispatch(addPaymentCardSuccess(response));

          dispatch(cardBind(response.payment_card.id, redirectData));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function getPaymentCard(type = 'funding', isForBankLinkFunding = false) {
  return (dispatch) =>
    createRequest('', 'GET', `payment_cards?type=${type}&status[]=connected`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorAddPaymentCard(response.error));
        } else if (type === 'spending') {
          dispatch(
            getSpendingPaymentCardSuccess(response, isForBankLinkFunding),
          );
        } else {
          dispatch(getFundingPaymentCardSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function switchСardOtherAccount(
  type = 'funding',
  cardId,
  isFromLinkFunding = false,
) {
  const data = {
    spending: type !== 'funding',
    funding: type !== 'spending',
    card_id: cardId,
  };

  return (dispatch) =>
    createRequest(JSON.stringify(data), 'PATCH', 'payment_cards')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorSwitchPaymentCard(response.error));
        } else {
          dispatch(switchСardOtherAccountSuccess(isFromLinkFunding));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function unlinkSpendingCard(
  cardId,
  spending = false,
  funding = false,
  isFromLinkFunding = false,
) {
  const data = {
    spending,
    funding,
    card_id: cardId,
  };

  return (dispatch) =>
    createRequest(JSON.stringify(data), 'PATCH', 'payment_cards')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorUnlinkCard(response.error));
        } else {
          dispatch(switchСardOtherAccountSuccess(isFromLinkFunding));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function removeSuspension() {
  return (dispatch) =>
    createRequest(
      JSON.stringify({}),
      'PUT',
      'funding_sources/remove_deposits_suspension',
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showGlobalError(response.error));
        } else {
          dispatch(removeSuspensionSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function checkChangeFunding(isNewFundingChangeable = false) {
  let url = 'accounts/changeable';

  if (AU_APP && isNewFundingChangeable) {
    url = 'funding_sources/changeable';
  } else if (MY_APP) {
    url = 'payment_cards/funding_card_changeable';
  }

  let fundingStatusCode = 200;

  return (dispatch) =>
    createRequest('', 'GET', url)
      .then((response) => {
        fundingStatusCode = response.status;

        return response.json();
      })
      .then((response) => {
        if (response.error) {
          dispatch(showFundingSourceError(response.error, fundingStatusCode));
        } else if (MY_APP) {
          dispatch(getBanksList({ isWithdrawal: false }));
        } else {
          dispatch(getBanks());
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function requestChange() {
  let fundingStatusCode = 200;

  return (dispatch) =>
    createRequest('', 'POST', 'funding_sources/request_change')
      .then((response) => {
        fundingStatusCode = response.status;

        return response.json();
      })
      .then((response) => {
        if (response.error) {
          dispatch(showFundingSourceError(response.error, fundingStatusCode));
        } else if (fundingStatusCode === 200 || fundingStatusCode === 201) {
          dispatch(showFundingSecurityModal(response.message));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function addVisaCard({ data, isKidWithPortfolioAccess }) {
  return (dispatch) =>
    createRequest(JSON.stringify(data), 'POST', 'cards')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorAddPaymentCard(response.error));
        } else {
          dispatch(addVisaCardSuccess(isKidWithPortfolioAccess));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getVisaPaymentCard() {
  return (dispatch) =>
    createRequest(null, 'GET', 'cards')
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showErrorAddPaymentCard(response.error));
        } else {
          dispatch(getVisaPaymentCardSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function deleteVisaCard(cardId) {
  return (dispatch) =>
    createRequest(null, 'DELETE', `cards/${cardId}`)
      .then((response) => response.json())
      .then(() => {
        dispatch(deleteVisaCardSuccess());
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function initiateRefreshRoundUp(accountId = null) {
  return (dispatch) =>
    createRequest(null, 'POST', `institutions/${accountId}/initiate_refresh`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showFundingSourceError(response.error));
        } else {
          dispatch(
            initiateRefreshRoundUpSuccess(response.sessionId, response.hint),
          );
        }

        return response;
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function refreshRoundUp(data, accountId = null) {
  return (dispatch) =>
    createRequest(
      JSON.stringify(data),
      'POST',
      `institutions/${accountId}/refresh`,
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(showFundingSourceError(response.error));
        } else {
          dispatch(refreshRoundUpSuccess());
        }

        return response;
      })
      .catch((error) => dispatch(showGlobalError(error)));
}
