import styled from '@emotion/styled';

import { iconset, IIconSet } from './iconset';

interface IIconProps {
  size?: number;
  icon: IIconSet;
}

const DEFAULT_ICONS_SIZE = 32;

export const Icon = styled.div<IIconProps>`
  background: url(${({ icon }) => `${iconset[icon]}`}) no-repeat center center;
  background-size: contain;
  display: inline-block;
  height: ${({ size = DEFAULT_ICONS_SIZE }) => size}px;
  width: ${({ size = DEFAULT_ICONS_SIZE }) => size}px;
`;
