import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import { BannerContentRow, BannerContentWrapper } from './styles';
import { BannerContentItem } from './BannerContentItem';

import { PATHS } from 'constants/paths';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectUserAccountSummary } from 'store/history/selectors';

export const PastBannerKidsContent = () => {
  const { t } = useTranslation();
  const isKids = !!useMatch(PATHS.kids.history);

  const {
    invested_by_you,
    reinvested_dividends,
    total_referrals_rewarded,
    gain_loss,
    total_found_money_rewarded,
    withdrawals,
  } = useAppSelector(selectUserAccountSummary({ isKids }));

  return (
    <BannerContentWrapper>
      <BannerContentRow>
        <BannerContentItem
          label={t('raizKidsEditHistoryPage.cards.amountInvestedLabel')}
          value={invested_by_you?.value}
        />

        <BannerContentItem
          label={t('raizKidsEditHistoryPage.cards.reinvestedDividendsLabel')}
          value={reinvested_dividends?.value}
        />

        <BannerContentItem
          label={t('raizKidsEditHistoryPage.cards.referralsLabel')}
          value={total_referrals_rewarded?.value}
        />
      </BannerContentRow>

      <BannerContentRow>
        <BannerContentItem
          label={t('raizKidsEditHistoryPage.cards.marketReturnsLabel')}
          value={gain_loss?.value}
        />

        <BannerContentItem
          label={t('raizKidsEditHistoryPage.cards.rewardsLabel')}
          value={total_found_money_rewarded?.value}
        />

        <BannerContentItem
          label={t('raizKidsEditHistoryPage.cards.withdrawnLabel')}
          value={withdrawals?.value}
        />
      </BannerContentRow>
    </BannerContentWrapper>
  );
};
