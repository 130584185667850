// TODO: https://acornsau.atlassian.net/browse/RAIZ-6859
import { RESET_PROPS } from '../commonTypes';
import initialTranslations from '../../translates';

import {
  GET_CATEGORIES_SUCCESS,
  GET_BRAND_SINGLE_SUCCESS,
  GET_BRANDS_SLIDER_SUCCESS,
  LOADING,
  GET_MONITORED_ACCOUNTS_SUCCESS,
  JOIN_OFFSETTERS_SUCCESS,
  GET_CASHBACK_SUCCESS,
  GET_ONLINE_OFFERS_SUCCESS,
  GET_FEATURED_OFFERS_SUCCESS,
  GET_ONLINE_OFFERS_BY_ID_SUCCESS,
  GET_INSTORE_OFFERS_SUCCESS,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_FAVOURITES_SUCCESS,
  GET_MAP_MARKERS_SUCCESS,
  SET_LOCATION_SUCCESS,
  GET_CARDS_SUCCESS,
  GET_LINK_CARD_URL_SUCCESS,
  GET_OFFSETTERS_SUCCESS,
  GET_REWARDS_TOTALS_SUCCESS,
  GET_REWARDS_HISTORY_SUCCESS,
  GET_INSURANCE_CERTIFICATES_SUCCESS,
  GET_REWARDS_OFFERS_SUCCESS,
  SET_IS_LOADING,
  UPDATE_LOCATION,
  SET_REWARD_ERROR_MESSAGE,
} from './types';

const initialState = {
  ...initialTranslations,
  brand: {
    id: '',
    background_image_url: '',
    campaign_owner_name: '',
    campaign_owner_image_url: '',
    rewards: [],
  },
  type: '',
};

export default function rewards(state = initialState, action) {
  switch (action.type) {
    case GET_REWARDS_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        offers: action.offers,
      };
    case GET_MONITORED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        monitoredAccounts: action.monitoredAccounts,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_BRANDS_SLIDER_SUCCESS:
      return {
        ...state,
        type: action.type,
        sliders: action.sliders,
      };
    case JOIN_OFFSETTERS_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesType: action.type,
        categories: action.categories,
      };
    case GET_BRAND_SINGLE_SUCCESS:
      return {
        ...state,
        brand: action.brand,
      };
    case GET_CASHBACK_SUCCESS:
      return {
        ...state,
        cashback: action.cashback,
        showMainLoader: false,
      };
    case GET_ONLINE_OFFERS_SUCCESS:
      return {
        ...state,
        onlineOffers: action.onlineOffers,
        addListener: true,
        showMainLoader: false,
      };
    case GET_FEATURED_OFFERS_SUCCESS:
      return {
        ...state,
        featuredOffers: action.featuredOffers,
      };
    case GET_ONLINE_OFFERS_BY_ID_SUCCESS:
      return {
        ...state,
        offer: action.offer,
      };
    case RESET_PROPS:
      return {
        ...state,
        offer: {},
        isShowFavourite: false,
      };
    case GET_INSTORE_OFFERS_SUCCESS:
      return {
        ...state,
        instoreOffers: action.instoreOffers,
        addListener: true,
        showMainLoader: false,
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        showMainLoader: false,
      };
    case GET_FAVOURITES_SUCCESS:
      return {
        ...state,
        favourites: action.favourites,
        isShowFavourite: true,
        showMainLoader: false,
      };
    case GET_MAP_MARKERS_SUCCESS:
      return {
        ...state,
        markers: action.markers,
      };
    case SET_LOCATION_SUCCESS:
      return {
        ...state,
        address: action.address,
        latitude: action.latitude,
        longitude: action.longitude,
      };
    case GET_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.cards,
      };
    case GET_LINK_CARD_URL_SUCCESS:
      return {
        ...state,
        linkCardUrl: action.linkCardUrl,
      };
    case GET_OFFSETTERS_SUCCESS:
      return {
        ...state,
        offsetters: action.offsetters,
      };
    case GET_REWARDS_TOTALS_SUCCESS:
      return {
        ...state,
        rewardsTotals: action.rewardsTotals,
      };
    case GET_REWARDS_HISTORY_SUCCESS:
      return {
        ...state,
        rewardsHistory: action.rewardsHistory,
      };
    case GET_INSURANCE_CERTIFICATES_SUCCESS:
      return {
        ...state,
        insuranceCertificates: action.insuranceCertificates,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude,
        currentMarker: action.currentMarker,
      };
    case SET_REWARD_ERROR_MESSAGE:
      return {
        ...state,
        rewardErrorMessage: action.rewardErrorMessage,
      };
    default:
      return state;
  }
}

export function sortFavoritesOffers(favourites) {
  const favouritesSort = { instore: [], online: [] };

  if (
    favourites &&
    favourites.favourites &&
    favourites.favourites.length !== 0
  ) {
    favourites.favourites.map((offer) => {
      if (offer.isOnline) {
        favouritesSort.online.push(offer);
      } else {
        favouritesSort.instore.push(offer);
      }

      return true;
    });
  }

  return favouritesSort;
}
