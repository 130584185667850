import { INFO_MODAL } from './consts';
import { IDisplayErrorModal, IToggleInfoModalAction } from './types';

export function toggleInfoModal({
  isInfoModalVisible,
  config,
}: IToggleInfoModalAction) {
  return {
    type: INFO_MODAL,
    isInfoModalVisible,
    config,
  };
}

export const displayErrorModal = ({ errorMessage }: IDisplayErrorModal) =>
  toggleInfoModal({
    isInfoModalVisible: true,
    config: {
      title: errorMessage,
      size: 350,
    },
  });
