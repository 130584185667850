/* eslint-disable no-console */
/* TODO: Need to upgdrade to react-router-dom v6 and use
 `element` prop as described in https://reactrouter.com/docs/en/v6/api#router
 task: https://acornsau.atlassian.net/browse/RAIZ-4269 */

/* eslint-disable react/no-unstable-nested-components */

import 'firebase/remote-config';
import 'firebase/analytics';
import { Component } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import Alerts from './components/layout/Alerts/Alerts';
import { CLOSE, SUPER_REGISTRATION_PAGE } from './constants/comonConstants';
import { AU_APP } from './constants/localeConfigs';
import { KIDS_ON, STATEMENTS_ON, SUPER_ON } from './constants/togglesFunctions';
import { Layout } from './routes/Layout';
import RaizKidsRegistration from './pages/RaizKidsRegistration/RaizKidsRegistration';
import ChangePortfolio from './pages/ChangePortfolio/ChangePortfolio';
import Crs from './pages/Crs/Crs';
import HistoryDetails from './pages/HistoryDetails/HistoryDetails';
import Home from './pages/Home/Home';
import Invest from './pages/Invest/Invest';
import MyFinance from './pages/MyFinance/MyFinance';
import NewRewards from './pages/NewRewards/NewRewards';
import NotFound from './pages/NotFound/NotFound';
import OffsettersDetails from './pages/OffsettersDetails/OffsettersDetails';
import PerfomanceDetails from './pages/PerfomanceDetails/PerfomanceDetails';
import PotentialFreeCash from './pages/PotentialFreeCash/PotentialFreeCash';
import RaizKids from './pages/RaizKids/RaizKids';
import RaizKidsEdit from './pages/RaizKidsEdit/RaizKidsEdit';
import RaizKidsEditHistory from './pages/RaizKidsEditHistory/RaizKidsEditHistory';
import { RecoverPassword } from './pages/RecoverPassword/RecoverPassword';
import RecurringInvestment from './pages/RecurringInvestment/RecurringInvestment';
import Registration from './pages/Registration/Registration';
import ResetPin from './pages/ResetPin/ResetPin';
import Rewards from './pages/Rewards/Rewards';
import RoundUps from './pages/RoundUps/RoundUps';
import Settings from './pages/Settings/Settings';
import Statements from './pages/Statements/Statements';
import SuperForms from './pages/Super/components/SuperForms/SuperForms';
import { SuperAccountInfo } from './pages/Super/components/SuperAccountInfo/SuperAccountInfo';
import SuperInformation from './pages/Super/components/SuperInformation/SuperInformation';
import SuperRegistration from './pages/Super/components/SuperRegistration/SuperRegistration';
import SuperRewardContribution from './pages/Super/components/SuperRewardContribution/SuperRewardContribution';
import SuperRollovers from './pages/Super/components/SuperRollovers/SuperRollovers';
import Super from './pages/Super/Super';
import TransactionCategorisation from './pages/TransactionCategorisation/TransactionCategorisation';
import WhereSpend from './pages/WhereSpend/WhereSpend';
import { TempRouteWrapper } from './routes/TempRouteWrapper';
import './styles/App.scss';
import { checkIsSmsfUser, checkIsDependentUser } from './utils/user';
import Portfolio from './pages/Portfolio/Portfolio';
import PortfolioCustomization from './pages/Portfolio/subpages/PortfolioCustomization/PortfolioCustomization';
import PortfolioCustomizationContent from './pages/Portfolio/subpages/PortfolioCustomization/components/PortfolioCustomizationContent/PortfolioCustomizationContent';
import { AccountMigrationPassword } from './pages/AccountMigrationPassword/AccountMigrationPassword';
import AccountMigration from './pages/AccountMigration/AccountMigration';
import AccountMigrationAddress from './pages/AccountMigration/subpages/AccountMigrationAddress/AccountMigrationAddress';
import AccountMigrationDetails from './pages/AccountMigration/subpages/AccountMigrationDetails/AccountMigrationDetails';
import AccountMigrationTerms from './pages/AccountMigration/subpages/AccountMigrationTerms/AccountMigrationTerms';
import AccountMigrationPDS from './pages/AccountMigration/subpages/AccountMigrationPDS/AccountMigrationPDS';
import AccountMigrationInsurance from './pages/AccountMigration/subpages/AccountMigrationInsurance/AccountMigrationInsurance';
import GlobalInfoModal from './components/modals/InfoModal/GlobalInfoModal';
import * as actionsFirebase from './store/firebaseConfig/actions';
import * as actionsUser from './store/user/actions';

import FundDetails from 'pages/Portfolio/subpages/PortfolioCustomization/components/PortfolioCustomizationContent/FundDetails/FundDetails';
import { Referrals } from 'pages/Referrals';
import { PATHS } from 'constants/paths';
import { RootState } from 'store/configureStore';
import {
  selectIsNewRewardsEnabled,
  selectIsSuperPlusPortfolioEnabled,
} from 'store/firebaseConfig/selectors';
import NewPassword from 'pages/NewPassword/NewPassword';
import Login from 'pages/Login/Loadable';
import AccountStrength from 'pages/AccountStrength/Loadable';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import Dividends from 'pages/Dividends/Loadable';
import RewardDetails from 'pages/RewardDetails/Loadable';
import ZakatPaymentHistory from 'pages/RewardDetails/subpages/ZakatPaymentHistory/Loadable';
import RecurringZakatPayment from 'pages/RewardDetails/subpages/RecurringZakatPayment/Loadable';
import { SuperInsurance } from 'pages/Super/components/SuperInsurance/SuperInsurance';

type IAppProps = {
  firebaseActions: {
    getFirebaseConfig: () => void;
  };
  userActions: {
    getStore: () => void;
  };
  isNewRewards: boolean;
  isSuperPlusPortfolioEnabled: boolean;
};

class App extends Component<IAppProps> {
  componentDidMount() {
    const { firebaseActions, userActions } = this.props;

    userActions.getStore();

    firebaseActions.getFirebaseConfig();
  }

  render() {
    const { isNewRewards, isSuperPlusPortfolioEnabled } = this.props;

    const user = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.user) ?? '{}',
    );

    const isKidsEnabled = !checkIsDependentUser(user) && !checkIsSmsfUser(user);

    const router = createBrowserRouter(
      createRoutesFromElements(
        <Route>
          <Route
            path={PATHS.migration.account.index}
            element={<AccountMigration />}
          >
            <Route
              path={PATHS.migration.account.pds}
              element={<AccountMigrationPDS />}
            />

            <Route
              path={PATHS.migration.account.superPds}
              element={<AccountMigrationPDS />}
            />

            <Route
              path={PATHS.migration.account.details}
              element={<AccountMigrationDetails />}
            />

            <Route
              path={PATHS.migration.account.address}
              element={<AccountMigrationAddress />}
            />

            <Route
              path={PATHS.migration.account.terms}
              element={<AccountMigrationTerms />}
            />

            <Route
              path={PATHS.migration.account.insurance}
              element={<AccountMigrationInsurance />}
            />
          </Route>

          <Route
            path={PATHS.migration.password.index}
            element={<AccountMigrationPassword />}
          />

          <Route
            element={
              <TempRouteWrapper>
                <Layout />
              </TempRouteWrapper>
            }
          >
            <Route
              path="/login"
              element={
                <TempRouteWrapper>
                  <Login />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/auth/login"
              element={
                <TempRouteWrapper>
                  <Login />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/recover-password"
              element={
                <TempRouteWrapper>
                  <RecoverPassword />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/users/password/edit"
              element={
                <TempRouteWrapper>
                  <NewPassword />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/users/pin/edit"
              element={
                <TempRouteWrapper>
                  <ResetPin />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/invite/:inviteCode"
              element={
                <TempRouteWrapper>
                  <Registration />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/registration"
              element={
                <TempRouteWrapper>
                  <Registration />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/kids-signup/"
              element={
                <TempRouteWrapper>
                  <Registration />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/kids-signup/:stepType"
              element={
                <TempRouteWrapper>
                  <Registration />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/registration/:stepType/:stepName"
              element={
                <TempRouteWrapper>
                  <Registration />
                </TempRouteWrapper>
              }
            >
              <Route
                path={PATHS.registration.customization.tags}
                element={<PortfolioCustomizationContent />}
              />

              <Route
                path={PATHS.registration.customization.details}
                element={<FundDetails />}
              />
            </Route>

            <Route
              path="/raiz-registration/:stepType/:stepName"
              element={
                <TempRouteWrapper>
                  <RaizKidsRegistration />
                </TempRouteWrapper>
              }
            >
              <Route
                path={PATHS.kids.registration.customization.tags}
                element={<PortfolioCustomizationContent />}
              />

              <Route
                path={PATHS.kids.registration.customization.details}
                element={<FundDetails />}
              />
            </Route>

            <Route
              path="/"
              element={
                <TempRouteWrapper>
                  <Home isNewRewards={isNewRewards} />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/account-strength"
              element={
                <TempRouteWrapper>
                  <AccountStrength />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/alerts"
              element={
                <TempRouteWrapper>
                  <Alerts />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/invest"
              element={
                <TempRouteWrapper>
                  <Invest />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/round-ups"
              element={
                <TempRouteWrapper>
                  <RoundUps />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/recurring-investment"
              element={
                <TempRouteWrapper>
                  <RecurringInvestment />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/history-details"
              element={
                <TempRouteWrapper>
                  <HistoryDetails />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/perfomance-details"
              element={
                <TempRouteWrapper>
                  <PerfomanceDetails />
                </TempRouteWrapper>
              }
            />

            {/* To be removed after ProPortfolio implementation */}
            <Route
              path="/change-portfolio"
              element={
                <TempRouteWrapper>
                  <ChangePortfolio />
                </TempRouteWrapper>
              }
            />

            <Route
              path={PATHS.settings.profile}
              element={
                <TempRouteWrapper>
                  <Settings />
                </TempRouteWrapper>
              }
            />

            <Route
              path={PATHS.settings.updateProfile}
              element={
                <TempRouteWrapper>
                  <Settings />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/settings/:step"
              element={
                <TempRouteWrapper isNewRewards={isNewRewards}>
                  <Settings />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/settings"
              element={
                <TempRouteWrapper>
                  <Settings />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/dashboard/settings/profile/close"
              element={
                <TempRouteWrapper match={{ params: { step: 'close' } }}>
                  <Settings supportStep={CLOSE} />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/settings/support/close"
              element={
                <TempRouteWrapper match={{ params: { step: 'close' } }}>
                  <Settings supportStep={CLOSE} />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/dashboard/settings/profile"
              element={
                <TempRouteWrapper>
                  <Settings />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/dashboard/settings/profile/close"
              element={
                <TempRouteWrapper>
                  <Settings supportStep={CLOSE} />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/dashboard/account/crs"
              element={
                <TempRouteWrapper>
                  <Crs />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/crs"
              element={
                <TempRouteWrapper>
                  <Crs />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/dashboard/documents/statements"
              element={
                <TempRouteWrapper>
                  <Statements />
                </TempRouteWrapper>
              }
            />

            {STATEMENTS_ON && (
              <Route
                path="/statements"
                element={
                  <TempRouteWrapper>
                    <Statements />
                  </TempRouteWrapper>
                }
              />
            )}

            <Route
              path="/my-finance"
              element={
                <TempRouteWrapper>
                  <MyFinance />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/where-spend"
              element={
                <TempRouteWrapper>
                  <WhereSpend />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/potential-free-cash"
              element={
                <TempRouteWrapper>
                  <PotentialFreeCash />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/transaction-categorisation"
              element={
                <TempRouteWrapper>
                  <TransactionCategorisation />
                </TempRouteWrapper>
              }
            />

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path="/super"
                  element={
                    <TempRouteWrapper>
                      <Super />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path="/super/perfomance"
                  element={
                    <TempRouteWrapper>
                      <PerfomanceDetails isSuper />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path="/super/history"
                  element={
                    <TempRouteWrapper>
                      <HistoryDetails />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path={PATHS.super.portfolio}
                  element={
                    <TempRouteWrapper>
                      {isSuperPlusPortfolioEnabled ? (
                        <Portfolio />
                      ) : (
                        <ChangePortfolio isSuper />
                      )}
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path={PATHS.super.recurringContribution}
                  element={
                    <TempRouteWrapper>
                      <RecurringInvestment isSuper />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path={PATHS.super.info}
                  element={
                    <TempRouteWrapper>
                      <SuperAccountInfo />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path="/super/information"
                  element={
                    <TempRouteWrapper>
                      <SuperInformation />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path="/super/forms"
                  element={
                    <TempRouteWrapper>
                      <SuperForms />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path={PATHS.super.rewardContribution}
                  element={
                    <TempRouteWrapper>
                      <SuperRewardContribution />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path={SUPER_REGISTRATION_PAGE}
                  element={
                    <TempRouteWrapper>
                      <SuperRegistration />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path={PATHS.super.rollovers}
                  element={
                    <TempRouteWrapper>
                      <SuperRollovers />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path={PATHS.super.statements}
                  element={
                    <TempRouteWrapper>
                      <Statements />
                    </TempRouteWrapper>
                  }
                />
              )}

            {SUPER_ON &&
              !sessionStorage.getItem(SESSION_STORAGE_KEYS.isSmsfUser) &&
              !checkIsDependentUser(user) && (
                <Route
                  path={PATHS.super.insurance}
                  element={<SuperInsurance />}
                />
              )}

            <Route
              path="/rewards"
              element={
                <TempRouteWrapper>
                  {AU_APP && isNewRewards ? <NewRewards /> : <Rewards />}
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/insurances"
              element={
                <TempRouteWrapper>
                  <Rewards />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/in-store"
              element={
                <TempRouteWrapper>
                  <NewRewards isInStoreRewards />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/category/:catId"
              element={
                <TempRouteWrapper>
                  <NewRewards isSearchRewards />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/search"
              element={
                <TempRouteWrapper>
                  <NewRewards isSearchRewards />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/history"
              element={
                <TempRouteWrapper>
                  {AU_APP ? <NewRewards isHistory /> : <Rewards />}
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/cards"
              element={
                <TempRouteWrapper>
                  <NewRewards isCards />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/:id"
              element={
                <TempRouteWrapper>
                  <RewardDetails />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/online/recommended/:id"
              element={
                <TempRouteWrapper>
                  <NewRewards isRecommended />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/online/featured/:id"
              element={
                <TempRouteWrapper>
                  <NewRewards isFeatured />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/online/:id"
              element={
                <TempRouteWrapper>
                  <NewRewards />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/rewards/in-store/:id"
              element={
                <TempRouteWrapper>
                  <NewRewards isOnline={false} />
                </TempRouteWrapper>
              }
            />

            <Route
              path="/offsetters"
              element={
                <TempRouteWrapper>
                  <OffsettersDetails />
                </TempRouteWrapper>
              }
            />

            {isKidsEnabled && KIDS_ON && (
              <Route
                path="/raiz-kids"
                element={
                  <TempRouteWrapper>
                    <RaizKids />
                  </TempRouteWrapper>
                }
              />
            )}

            {isKidsEnabled && (
              <Route
                path={PATHS.kids.edit}
                element={
                  <TempRouteWrapper>
                    <RaizKidsEdit />
                  </TempRouteWrapper>
                }
              />
            )}

            {isKidsEnabled && (
              <Route
                path="/raiz-kids/history/:childId"
                element={
                  <TempRouteWrapper>
                    <RaizKidsEditHistory />
                  </TempRouteWrapper>
                }
              />
            )}

            {isKidsEnabled && (
              <Route path={PATHS.portfolio.index} element={<Portfolio />} />
            )}

            {isKidsEnabled && (
              <Route
                path="/raiz-kids/change-portfolio/:childId"
                element={
                  <TempRouteWrapper>
                    <ChangePortfolio isEditKids />
                  </TempRouteWrapper>
                }
              />
            )}

            {isKidsEnabled && (
              <Route
                path="/raiz-kids/perfomance/:childId"
                element={
                  <TempRouteWrapper>
                    <PerfomanceDetails isEditKids />
                  </TempRouteWrapper>
                }
              />
            )}

            {isKidsEnabled && (
              <Route
                path="/raiz-kids/statements/:childId"
                element={
                  <TempRouteWrapper>
                    <Statements />
                  </TempRouteWrapper>
                }
              />
            )}

            <Route
              path={PATHS.portfolio.customization.index}
              element={<PortfolioCustomization />}
            >
              <Route
                path={PATHS.portfolio.customization.tags}
                element={<PortfolioCustomizationContent />}
              />

              <Route
                path={PATHS.portfolio.customization.details}
                element={<FundDetails />}
              />
            </Route>

            <Route path={PATHS.portfolio.index} element={<Portfolio />} />

            <Route path={PATHS.referrals.index} element={<Referrals />} />

            <Route
              path={PATHS.rewards.recurringZakatPayment}
              element={<RecurringZakatPayment />}
            />

            <Route path={PATHS.dividends.index} element={<Dividends />} />

            <Route
              path={PATHS.rewards.zakatPaymentHistory}
              element={<ZakatPaymentHistory />}
            />

            <Route
              path="*"
              element={
                <TempRouteWrapper>
                  <NotFound />
                </TempRouteWrapper>
              }
            />
          </Route>
        </Route>,
      ),
    );

    return (
      <>
        <RouterProvider router={router} />

        <GlobalInfoModal />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  firebaseActions: bindActionCreators(actionsFirebase, dispatch),
  userActions: bindActionCreators(actionsUser, dispatch),
});

const mapStateToProps = (state: RootState) => ({
  isNewRewards: selectIsNewRewardsEnabled(state),
  isSuperPlusPortfolioEnabled: selectIsSuperPlusPortfolioEnabled(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
