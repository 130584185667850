import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AU_APP } from '../../../constants/localeConfigs';

export default function RewardsMenuLinks({ checkToggle, isRewardsActivePath }) {
  const { t } = useTranslation();

  const { cards } = useSelector((state) => ({
    cards: state.rewards.cards,
  }));

  return (
    <div>
      <NavLink
        to="/rewards"
        className={`page-menu__item ${
          !isRewardsActivePath('/rewards') ? 'inactive' : ''
        }`}
        onClick={checkToggle}
      >
        <i className="page-menu__item-icon -rewards-sub" />

        <span>{t('rewardsPage.menu.raizRewards')}</span>
      </NavLink>

      <NavLink
        to="/rewards/history"
        className="page-menu__item"
        onClick={checkToggle}
      >
        <i className="page-menu__item-icon -rewards-history" />

        <span>{t('rewardsPage.menu.rewardsHistoryMenu')}</span>
      </NavLink>

      {AU_APP && (
        <div>
          <NavLink
            to="/rewards/cards"
            className="page-menu__item"
            onClick={checkToggle}
          >
            <i className="page-menu__item-icon -rewards-cards" />

            {t('rewardsPage.menu.linkedCards')}

            <div className="page-menu__item-cards-count">
              {t('rewardsPage.menu.cards', {
                linkedCards: cards?.cards?.length || 0,
              })}
            </div>
          </NavLink>

          <NavLink
            to="/rewards/in-store"
            className="page-menu__item"
            onClick={checkToggle}
          >
            <i className="page-menu__item-icon -rewards-instore" />

            {t('rewardsPage.menu.inStoreRewards')}
          </NavLink>
        </div>
      )}
    </div>
  );
}

RewardsMenuLinks.propTypes = {
  checkToggle: PropTypes.func.isRequired,
  isRewardsActivePath: PropTypes.func.isRequired,
};
