import { API_TAGS } from '../../consts';

import transformZakatTypesResponse from './utils/transformZakatTypesResponse';
import {
  IGetRewardsResponse,
  IGetRewardsParams,
  IRecurringRemittanceSettingsResponse,
  IZakatTypeTransformed,
  IRecurringRemittanceSettingsData,
  IZakatTypeRequest,
} from './types';

import { api } from 'store/api';

export const rewardsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRewards: build.query<IGetRewardsResponse, IGetRewardsParams>({
      query: ({ fulfilled = true }) => ({
        url: '/mobile/v1/rewards',
        method: 'GET',
        params: {
          fulfilled,
        },
        invalidatesTags: (
          _result: unknown,
          _error: unknown,
          params: IGetRewardsParams,
        ) => [{ type: 'rewards', fulfilled: params.fulfilled }],
      }),
    }),
    recurringRemittanceSettings: build.query<
      IRecurringRemittanceSettingsResponse,
      void
    >({
      query: () => ({
        url: '/v1/recurring_remittance_settings',
      }),
      providesTags: [API_TAGS.recurringRemittanceSettings],
    }),
    remittancesZakatTypes: build.query<
      IZakatTypeTransformed[],
      IZakatTypeRequest | void
    >({
      query: () => ({
        url: '/v1/remittances/zakat_types',
      }),
      transformResponse: transformZakatTypesResponse,
    }),
    updateRecurringRemittanceSettings: build.mutation<
      IZakatTypeTransformed[],
      IRecurringRemittanceSettingsData
    >({
      query: (data) => ({
        url: '/v1/recurring_remittance_settings',
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [API_TAGS.recurringRemittanceSettings],
    }),
    deleteRecurringRemittanceSettings: build.mutation<
      IZakatTypeTransformed[],
      void
    >({
      query: () => ({
        url: '/v1/recurring_remittance_settings',
        method: 'DELETE',
      }),
      invalidatesTags: [API_TAGS.recurringRemittanceSettings],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetRewardsQuery,
  useRecurringRemittanceSettingsQuery,
  useRemittancesZakatTypesQuery,
  useUpdateRecurringRemittanceSettingsMutation,
  useDeleteRecurringRemittanceSettingsMutation,
} = rewardsApi;

export const {
  endpoints: {
    getRewards,
    recurringRemittanceSettings,
    remittancesZakatTypes,
    updateRecurringRemittanceSettings,
  },
  util,
} = rewardsApi;

export const { resetApiState } = util;

export const { initiate, useQueryState } = getRewards;
