import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IN_STORE_PAGE } from '../../../constants/comonConstants';
import { FAVOURITE_CATEGORY_ID } from '../consts';

import OfferDistance from './OfferDistance';

export default function InstoreOfferItem({
  offer,
  toggleLikeOffer,
  categoryId,
}) {
  const {
    locations,
    offerId,
    coverShotUrl,
    logoUrl,
    advertiserName,
    offerTitle,
    isFavourite,
  } = offer;

  const isFavouriteCategory = categoryId === FAVOURITE_CATEGORY_ID;
  const isFavouriteOffer = isFavourite || isFavouriteCategory;

  return (
    <div className="rewards-block">
      <Link to={`${IN_STORE_PAGE}/${offerId}`}>
        <div className="grid__container-item">
          <div className="grid__container-image">
            <div
              className="grid__container-logo"
              style={{
                background: `url(${coverShotUrl}) no-repeat`,
                backgroundSize: 'cover',
              }}
            />
          </div>

          <div className="grid__container-bottom-part">
            <div className="brand-icon-placeholder" />

            <div
              className="brand-icon"
              style={{
                background: `url(${logoUrl}) no-repeat`,
                backgroundSize: 'cover',
              }}
            />

            <div className="brand-title-block">
              <div>{advertiserName}</div>

              <div>{offerTitle}</div>
            </div>

            <div className="distance">
              <div className="marker-icon">
                <div>
                  <OfferDistance locations={locations} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div
        className={isFavouriteOffer ? 'like-instore' : 'unlike-instore'}
        onClick={() => {
          toggleLikeOffer({
            isFavourite: isFavouriteOffer,
            id: offerId,
            isInstore: true,
            isShowFavourite: isFavouriteCategory,
          });
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
      />
    </div>
  );
}

InstoreOfferItem.propTypes = {
  offer: PropTypes.shape({
    offerId: PropTypes.string,
    offerTitle: PropTypes.string,
    logoUrl: PropTypes.string,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        distance: PropTypes.number,
      }),
    ),
    coverShotUrl: PropTypes.string,
    advertiserName: PropTypes.string,
    isFavourite: PropTypes.bool,
  }).isRequired,
  toggleLikeOffer: PropTypes.func.isRequired,
  categoryId: PropTypes.number.isRequired,
};
