import DotsLoader from '../DotsLoader/DotsLoader';
import { IIconSet } from '../Icon';

import { DEFAULT_ICON_POSITION, DEFAULT_ICON_SIZE } from './consts';
import { TButtonVariant, StyledButton, StyledIcon, StyledLink } from './styles';

interface IIcon {
  name: IIconSet;
  size?: number;
  position?: 'left' | 'right';
}

interface IButtonProps {
  label: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: (...args: never[]) => void;
  icon?: IIcon;
  type?: 'button' | 'submit';
  variant?: TButtonVariant;
  href?: string;
  className?: string;
  customStyles?: string;
}

export const Button = ({
  label,
  isLoading,
  isDisabled,
  onClick,
  icon,
  type = 'button',
  variant = 'primary',
  href,
  className,
  customStyles,
}: IButtonProps) => {
  const buttonContent = !icon ? (
    label
  ) : (
    <>
      <StyledIcon icon={icon.name} size={icon.size || DEFAULT_ICON_SIZE} />

      {label}
    </>
  );

  if (href?.length) {
    return (
      <StyledLink to={href} className={className} $variant={variant}>
        {buttonContent}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      disabled={isDisabled}
      onClick={onClick}
      type={type}
      variant={variant}
      iconPosition={icon?.position || DEFAULT_ICON_POSITION}
      className={className}
      customStyles={customStyles}
    >
      {isLoading ? <DotsLoader /> : buttonContent}
    </StyledButton>
  );
};
