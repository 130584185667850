export const PATHS = {
  login: {
    index: '/login',
  },
  recoverPassword: {
    index: '/recover-password',
  },
  portfolio: {
    index: '/portfolio',

    customization: {
      index: '/portfolio/customization',
      tags: '/portfolio/customization/:fundTag',
      details: '/portfolio/customization/:fundTag/:id',
    },
  },
  oldPortfolio: {
    index: '/change-portfolio',
    super: '/super/portfolio',
  },
  referrals: {
    index: '/referrals',
  },
  registration: {
    index: '/registration',
    portfolio: {
      index: '/registration/account/portfolio',
    },
    account: {
      other: '/registration/account/other',
    },
    personal: {
      taxResidents: '/registration/personal/tax-resident',
      accountQuestions: '/registration/personal/account-questions',
      address: '/registration/personal/address',
    },
    customization: {
      index: '/registration/:stepType/:stepName/customization',
      tags: '/registration/:stepType/:stepName/customization/:fundTag',
      details: '/registration/:stepType/:stepName/customization/:fundTag/:id',
    },
    spendingAccount: {
      index: '/registration/banks/spendingAccount',
      status: {
        success: '/registration/banks/spendingAccount-success',
        failure: '/registration/banks/spendingAccount-failed',
      },
    },
    fundingAccount: {
      index: '/registration/banks/fundingAccount',
      status: {
        success: '/registration/banks/fundingAccount-success',
        failure: '/registration/banks/fundingAccount-failed',
      },
    },
    investment: {
      index: '/registration/investments/investment',
    },
  },
  // TODO: https://acornsau.atlassian.net/browse/RAIZ-5605
  kids: {
    index: '/raiz-kids',
    customization: {
      index: '/raiz-registration/portfolio/select/customization',
    },
    registration: {
      index: '/raiz-registration/portfolio/select',
      investment: {
        recurring: '/raiz-registration/investment/recurring',
      },
      customization: {
        index: '/raiz-registration/:stepType/:stepName/customization',
        tags: '/raiz-registration/:stepType/:stepName/customization/:fundTag',
        details:
          '/raiz-registration/:stepType/:stepName/customization/:fundTag/:id',
      },
    },
    edit: '/raiz-kids/edit/:childStep/:childId/:viewMode?',
    history: '/raiz-kids/history/:childId',
  },
  migration: {
    password: {
      index: '/migration/password',
    },
    account: {
      index: '/migration/account',
      pds: '/migration/account/pds',
      superPds: '/migration/account/super-pds',
      details: '/migration/account/details',
      address: '/migration/account/address',
      terms: '/migration/account/terms',
      insurance: '/migration/account/insurance',
    },
  },
  super: {
    index: '/super',
    registration: '/super/registration',
    perfomance: '/super/perfomance',
    info: '/super/info',
    rollovers: '/super/rollovers',
    portfolio: '/super/portfolio',
    rewardContribution: '/super/reward-contribution',
    recurringContribution: '/super/recurring-contribution',
    statements: '/super/statements',
    insurance: '/super/insurance',
  },
  rewards: {
    index: '/rewards',
    recurringZakatPayment: '/rewards/recurring-zakat-payment/:viewMode?',
    zakatPaymentHistory: '/rewards/:id/zakat-payment-history',
  },
  settings: {
    index: '/settings',
    profile: '/settings/profile/:activeTab?',
    updateProfile: '/settings/updateProfile',
    fundingAccount: {
      index: '/settings/funding-account',
      status: {
        success: '/settings/funding-account-success',
        failure: '/settings/funding-account-failed',
      },
    },
    spendingAccount: {
      index: '/settings/spending-account',
      status: {
        success: '/settings/spending-account-success',
        failure: '/settings/spending-account-failed',
      },
    },
  },
  alerts: {
    index: '/alerts',
  },
  perfomanceDetails: {
    index: '/perfomance-details',
  },
  root: '/',
  dividends: {
    index: '/dividends',
    super: '/dividends?isSuper=true',
  },
  potentialFreeCash: {
    index: '/potential-free-cash',
  },
  whereSpend: {
    index: '/where-spend',
  },
  invest: {
    index: '/invest',
  },
} as const;

type AllPaths<T> = T extends object ? T[keyof T] | AllPaths<T[keyof T]> : T;

export type IPath = AllPaths<typeof PATHS>;
