import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grid from '../../components/elements/Grid/Grid';
import '../../styles/pages/rewards.scss';
import { REWARDS } from '../../constants/comonConstants';
import HelpCardsModalAU from '../../components/layout/Modals/HelpCardsModalAU';
import Input from '../../components/elements/Input/Input';

import BrandsCarousel from './components/BrandsCarousel';
import { FAVOURITE_CATEGORY_ID } from './consts';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { formatNumber } from 'utils/formatters/formatNumber';

export default function RewardsNewView({
  isNeedSearch,
  categoryTitle,
  filterByCategory,
  activeCategoryId,
  searchHandler,
  saveInvested,
  isOpenHelpCards,
  toggleHelpCard,
  type,
  categoriesType,
  showMainLoader,
  clearSearch,
  searchValue,
  cashback,
  onlineOffers,
  featuredOffers,
  categories,
  filterCategory,
  isSearchTypingEnd,
  toggleLikeOffer,
  accountSummary,
  categoryName,
  categoryId,
  favourites,
  isHaveCard,
}) {
  const { t } = useTranslation();
  const isFavouriteCategory = categoryId === FAVOURITE_CATEGORY_ID;

  const rewards = isFavouriteCategory
    ? favourites?.online
    : onlineOffers?.records;

  const isNotFoundVisible = !showMainLoader && rewards?.length === 0;

  return (
    <div className="rewards new-rewards">
      <div className="rewards__banner">
        <DeprecatedButton
          buttonClass="rewards__banner-info my-finance__banner-info"
          handleClick={() => toggleHelpCard(true)}
        />

        <div className="rewards__banner-title">{t('newRewardsPage.title')}</div>

        <div>
          <div className="invest-info">
            <div className="rewards__banner-label">
              {t('newRewardsPage.invested')}
            </div>

            <div className="rewards__banner-value">
              {formatNumber({
                value: accountSummary?.total_found_money_rewarded?.value
                  ? accountSummary.total_found_money_rewarded.value
                  : 0,
              })}
            </div>
          </div>

          <div className="invest-info">
            <div className="rewards__banner-label">
              {t('common.status.pending')}
            </div>

            <div className="rewards__banner-value">
              {formatNumber({
                value: cashback.pending,
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="new-rewards__carusel">
        <div className="rewards__banner-subtitle">
          {t('newRewardsPage.featuredOffers')}
        </div>

        {featuredOffers && (
          <div className="slider-block">
            <BrandsCarousel
              features={featuredOffers.trendingOffers}
              saveInvested={saveInvested}
              type={type}
              isNewRewards
            />
          </div>
        )}

        {featuredOffers && (
          <div className="recommended">
            <div className="rewards__banner-subtitle">
              {t('newRewardsPage.recommendedForYou')}
            </div>

            <BrandsCarousel
              features={featuredOffers.recommendedOffers}
              saveInvested={saveInvested}
              type={type}
              isRecommended
              isNewRewards
            />
          </div>
        )}
      </div>

      <div className="offers__panel">
        <div className="offers__panel-search">
          <div className="grid__title">
            <div className="search-content">
              <Input
                onChange={(e) => {
                  searchHandler(e, true);
                }}
                onClick={(e) => {
                  searchHandler(e, true);
                }}
                autoFocus={window.innerWidth > 768}
                defaultValue={searchValue}
                type="text"
                placeholder={t('newRewardsPage.searchByName')}
                classList="grid__input"
              />
            </div>
          </div>
        </div>

        <div className="offers__panel-grid">
          {rewards && categories?.categories && (
            <Grid
              items={rewards}
              categories={categories.categories}
              isNeedSearch={isNeedSearch}
              saveInvested={saveInvested}
              categoryTitle={categoryTitle}
              searchHandler={searchHandler}
              showMainLoader={showMainLoader}
              categoriesType={categoriesType}
              filterByCategory={filterByCategory}
              activeCategoryId={activeCategoryId}
              clearSearch={clearSearch}
              searchValue={searchValue}
              isNewRewards
              filterCategory={filterCategory}
              isSearchTypingEnd={isSearchTypingEnd}
              toggleLikeOffer={toggleLikeOffer}
              categoryName={categoryName}
              categoryId={categoryId}
            />
          )}

          {isNotFoundVisible && (
            <div className="not-found-content">
              <div className="icon-not-found" />

              <div className="not-found">
                {t('newRewardsPage.notFound', { name: searchValue })}
              </div>
            </div>
          )}

          {!showMainLoader && (
            <HelpCardsModalAU
              currentPage={REWARDS}
              toggleHelpCard={toggleHelpCard}
              isOpenHelpCards={isOpenHelpCards}
              isHaveCard={isHaveCard}
              isNewRewards
            />
          )}
        </div>
      </div>
    </div>
  );
}

RewardsNewView.defaultProps = {
  showMainLoader: false,
  type: '',
  categoriesType: '',
  searchValue: '',
};

RewardsNewView.propTypes = {
  isNeedSearch: PropTypes.bool.isRequired,
  showMainLoader: PropTypes.bool,
  categoryTitle: PropTypes.shape({}).isRequired,
  categories: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  filterByCategory: PropTypes.func.isRequired,
  activeCategoryId: PropTypes.number.isRequired,
  searchHandler: PropTypes.func.isRequired,
  saveInvested: PropTypes.func.isRequired,
  toggleHelpCard: PropTypes.func.isRequired,
  isOpenHelpCards: PropTypes.bool.isRequired,
  type: PropTypes.string,
  categoriesType: PropTypes.string,
  clearSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  cashback: PropTypes.shape({
    pending: PropTypes.number,
  }).isRequired,
  onlineOffers: PropTypes.shape({
    records: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  featuredOffers: PropTypes.shape({
    trendingOffers: PropTypes.arrayOf(PropTypes.shape({})),
    recommendedOffers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  filterCategory: PropTypes.func.isRequired,
  isSearchTypingEnd: PropTypes.bool.isRequired,
  toggleLikeOffer: PropTypes.func.isRequired,
  accountSummary: PropTypes.shape({
    total_found_money_rewarded: PropTypes.shape({
      value: PropTypes.number,
    }),
  }).isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryId: PropTypes.number.isRequired,
  favourites: PropTypes.shape({
    online: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isHaveCard: PropTypes.bool.isRequired,
};
