import React from 'react';
import { generatePath, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import {
  FUNDS_TAGS,
  PORTFOLIO_CUSTOMIZATION_REGISTRATION_PARAMS,
} from '../../../consts';

import {
  FundItemCategory,
  FundItemHeaderWrapper,
  FundItemImage,
  FundItemTitle,
} from './styles';

import { PATHS } from 'constants/paths';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectPortfolioLocation,
  selectPortfolioParams,
} from 'store/portfolio/selectors';

export default function FundItemHeader({ imageUrl, name, id, category }) {
  const navigate = useNavigate();
  const { fundTag } = useParams();
  const { search } = useAppSelector(selectPortfolioParams);

  const { isRegistration, isKids } = useAppSelector(selectPortfolioLocation);

  const customizationDetailsPath = PATHS.portfolio.customization.details;

  const registrationUrl = isKids
    ? PATHS.kids.registration.customization.details
    : PATHS.registration.customization.details;

  const path = generatePath(
    isRegistration ? registrationUrl : customizationDetailsPath,
    {
      fundTag,
      id,
      ...(isKids
        ? PORTFOLIO_CUSTOMIZATION_REGISTRATION_PARAMS.kids
        : PORTFOLIO_CUSTOMIZATION_REGISTRATION_PARAMS.regularUser),
    },
  );

  const openDetailsPage = () => {
    navigate({
      pathname: path,
      search,
    });
  };

  return (
    <FundItemHeaderWrapper onClick={openDetailsPage}>
      <FundItemImage background={imageUrl} />

      <FundItemTitle>
        {name}

        {fundTag === FUNDS_TAGS.stock && (
          <FundItemCategory>{category}</FundItemCategory>
        )}
      </FundItemTitle>
    </FundItemHeaderWrapper>
  );
}

FundItemHeader.defaultProps = {
  imageUrl: '',
};

FundItemHeader.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};
