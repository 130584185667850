import { useTranslation } from 'react-i18next';

import { CustomizeYourPortfolioCardWrapper, CardTitle } from './styles';

export default function CustomizeYourPortfolioCard() {
  const { t } = useTranslation();

  return (
    <CustomizeYourPortfolioCardWrapper>
      <CardTitle>
        {t('portfolio.helpCards.customizeYourPortfolioCard.title')}
      </CardTitle>
    </CustomizeYourPortfolioCardWrapper>
  );
}
