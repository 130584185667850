import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  NavLink,
  createSearchParams,
  generatePath,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import KidsAccess from '../RaizKidsRegistration/components/KidsAccess';
import KidsNotification from '../RaizKidsRegistration/components/KidsNotification';
import KidsInfo from '../RaizKidsRegistration/components/KidsInfo';
import KidsInitialInvestment from '../RaizKidsRegistration/components/KidsInitialInvestment';
import TransferIn from '../RaizKidsRegistration/components/TransferIn';
import { MY_APP } from '../../constants/localeConfigs';

import RecurringInvestment from './components/RecurringInvestment/RecurringInvestment';
import InvestmentLimitForm from './components/InvestmentLimitForm/InvestmentLimitForm';
import InvestingAccess from './components/InvestingAccess/InvestingAccess';
import {
  CHILD_ID_SEARCH_PARAM,
  KIDS_EDIT_STEPS,
  REQUESTED_DOCUMENT,
} from './consts';
import RaizKidsEditStatementsLink from './components/RaizKidsEditStatementsLink';
import { BirthCertificateAlert } from './components/BirthCertificateAlert';
import { checkIsAdult } from './utils';

import {
  KIDS_CLOSE_ACCOUNT,
  KIDS_CONVERT_ACCOUNT,
  KIDS_INVEST_NOW,
  KIDS_LINK_SENT,
  KIDS_WITHDRAW,
  KIDS_STATUS,
} from 'constants/kidsConstants';
import Loader from 'components/elements/Loader/Loader';
import RelSentModal from 'components/layout/Modals/RelSentModal';
import ConvertToFullModal from 'components/layout/Modals/ConvertToFullModal/ConvertToFullModal';
import CloseAccountKidsModal from 'components/layout/Modals/CloseAccountKidsModal';
import WithdrawModal from 'components/layout/Modals/WithdrawModal/WithdrawModal';
import RecurringModal from 'components/layout/Modals/RecurringModal';
import ErrorModal from 'components/layout/Modals/ErrorModal';
import SuccessModal from 'components/layout/Modals/SuccessModal';
import ReactivateAccountModal from 'components/layout/Modals/ReactivateAccountModal';
import { usePortfolioPath } from 'routes/hooks';
import { PORTFOLIO_SEARCH_PARAMS } from 'pages/Portfolio/consts';
import { formatNumber } from 'utils/formatters/formatNumber';
import { PATHS } from 'constants/paths';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { selectIsKidsDividendsEnabled } from 'store/firebaseConfig/selectors';

const checkActive = (match, location) => {
  if (!location) return false;

  const { pathname } = location;

  return pathname.indexOf('/raiz-kids/edit/access') !== -1;
};

export default function RaizKidsEditView({
  messages,
  childStep,
  kid,
  showLoader,
  showSubSettings,
  toggleMenu,
  isShowSentLinkModal,
  toggleSentlink,
  isShowConvertToFull,
  toggleConvertToFull,
  isOpenWithdrawModal,
  toggleWithdrawModal,
  dob,
  allowType,
  receiverIcons,
  renderIcons,
  convertToFull,
  notifications,
  handleSubmitNotifications,
  manage,
  handleChangeAccess,
  additionalStep,
  changeAccessType,
  handleSubmitAccess,
  handleSubmitInvestmentAccess,
  changeOneTimeInvestmentValue,
  oneTimeInvestmentValue,
  isShowInvestNow,
  toggleInvestNow,
  handleSubmitInvestNow,
  isShowErrorModal,
  toggleErrorModal,
  errorMessage,
  handleChangeDate,
  dobError,
  handleSubmitProfile,
  isShowCloseAccount,
  toggleCloseAccount,
  closeAccount,
  alert,
  newKidName,
  user,
  handleSubmitTransfer,
  errorTransferMessage,
  showCongratsModal,
  toggleCongratsModal,
  activateAccount,
  handleSaveNotificationsChange,
  isProPortfolioEnabled,
  history,
}) {
  const { t } = useTranslation();
  let editStep = '';
  let editTitle = '';
  const [isOpenReactivateModal, setIsOpenReactivateModal] = useState(false);
  const isConverted = kid.status === KIDS_STATUS.converted;
  const isDividendsEnabled = useAppSelector(selectIsKidsDividendsEnabled);

  switch (childStep) {
    case KIDS_EDIT_STEPS.settings:
    case KIDS_EDIT_STEPS.profile:
      editTitle = t('raizKidsEditPage.profile');

      editStep =
        kid && kid.name ? (
          <KidsInfo
            isEditChild
            kid={kid}
            allowType={allowType}
            dobError={dobError}
            changeAccessType={() => {}}
            dob={dob}
            handleChangeDate={handleChangeDate}
            showLoader={showLoader}
            handleSubmit={handleSubmitProfile}
            goBack={() => {}}
            renderIcons={renderIcons}
            receiverIcons={receiverIcons}
            newKidName={newKidName}
          />
        ) : (
          <div />
        );

      break;
    case KIDS_EDIT_STEPS.recurring:
      editStep = <RecurringInvestment />;

      break;

    case KIDS_EDIT_STEPS.invest:
      editTitle = t('raizKidsEditPage.recurringInvestment');

      editStep = (
        <KidsInitialInvestment
          isOpenInvestment
          child={kid}
          isEditChild
          oneTimeInvestmentValue={oneTimeInvestmentValue}
          allowType=""
          changeAccessInvestment={() => {}}
          unlimAmount=""
          goBack={() => {}}
          changeUnlimitedAmount={() => {}}
          showLoader={showLoader}
          changeOneTimeInvestmentValue={changeOneTimeInvestmentValue}
          handleSubmit={() => {}}
        />
      );

      break;
    case KIDS_EDIT_STEPS.access:
      editTitle = t('raizKidsEditPage.access.title');

      editStep = (
        <KidsAccess
          isEditChild
          child={kid}
          manage={manage}
          handleChange={handleChangeAccess}
          showLoader={showLoader}
          handleSubmit={handleSubmitAccess}
          goBack={() => {}}
          alert={alert}
        />
      );

      break;
    case KIDS_EDIT_STEPS.allowedAccess:
      editTitle = t('raizKidsEditPage.access.title');

      editStep = (
        <InvestmentLimitForm
          handleSubmit={handleSubmitInvestmentAccess}
          child={kid}
          showLoader={showLoader}
          allowType={allowType}
          changeAccessType={changeAccessType}
        />
      );

      break;
    case KIDS_EDIT_STEPS.investingAccess:
      editTitle = t('raizKidsEditPage.access.investment.title');

      editStep = <InvestingAccess />;

      break;
    case KIDS_EDIT_STEPS.notification:
      editTitle = t('raizKidsEditPage.notification.title');

      editStep = (
        <KidsNotification
          isEditChild
          notifications={notifications}
          handleChange={handleSaveNotificationsChange}
          showLoader={showLoader}
          handleSubmit={handleSubmitNotifications}
          goBack={() => {}}
        />
      );

      break;
    case KIDS_EDIT_STEPS['transfer-out']:
    case KIDS_EDIT_STEPS['transfer-in']:
      editTitle = t(
        `raizKidsEditPage.transfer.${
          childStep === 'transfer-in' ? 'in' : 'out'
        }.title`,
      );

      editStep = (
        <TransferIn
          isEditChild
          additionalClassName="-transfer-container"
          childStep={childStep}
          showLoader={showLoader}
          handleSubmit={handleSubmitTransfer}
          goBack={() => {}}
          child={kid}
          user={user}
          errorMessage={errorTransferMessage}
        />
      );

      break;
    default:
      break;
  }

  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const age = calculateAge(new Date(kid.date_of_birth));

  const isPendingConverting = kid?.status === KIDS_STATUS.pending_converting;

  const isAdult = checkIsAdult(age);

  const isConvertable = isAdult && (!MY_APP || !isPendingConverting);

  const portfolioPath = usePortfolioPath();

  const generatedPortfolioPath = isProPortfolioEnabled
    ? generatePath(portfolioPath)
    : `/raiz-kids${portfolioPath}/${kid.id}`;

  const isBirthCertificateAlertVisible =
    kid?.requested_documents?.includes?.(REQUESTED_DOCUMENT.birthCertificate) &&
    isPendingConverting;

  editStep = (
    <div
      className={`settings__content ${
        isBirthCertificateAlertVisible ? '-with-alert' : ''
      }`}
    >
      <div className="settings__content-right raiz-kids__edit">
        {isBirthCertificateAlertVisible && <BirthCertificateAlert />}

        <div className="settings__content-right-flex">
          <div className="raiz-kids__edit-menu">
            {showLoader ? (
              <div className="profile-content settings__content-default-container raiz-kids__menu-header">
                <Loader additionalClass=" -absolute -center" />
              </div>
            ) : (
              <div className="profile-content settings__content-default-container raiz-kids__menu-header">
                <img
                  src={kid.icon_url}
                  alt=""
                  width="52"
                  className="raiz-kids__card-img"
                />

                <div className="raiz-kids__menu-name">
                  {t('raizKidsEditPage.age', { count: age, name: kid.name })}
                </div>

                <div className="raiz-kids__menu-value">
                  {formatNumber({
                    value: kid.current_balance.amount,
                  })}
                </div>
              </div>
            )}

            <div
              className={`profile-content settings__content-default-container
              raiz-kids__menu ${showLoader && '-disable'}`}
            >
              {kid.closed ? (
                <>
                  <NavLink
                    to={`/raiz-kids/perfomance/${kid.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'page-menu__item raiz-kids__menu-item -active'
                        : 'page-menu__item raiz-kids__menu-item'
                    }
                    onClick={() => toggleMenu(false)}
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -perfomance" />

                      {t('raizKidsEditPage.viewPerformance')}
                    </div>
                  </NavLink>

                  <NavLink
                    to={`/raiz-kids/history/${kid.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'page-menu__item raiz-kids__menu-item -active'
                        : 'page-menu__item raiz-kids__menu-item'
                    }
                    onClick={() => toggleMenu(false)}
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -history" />

                      {t('raizKidsEditPage.viewHistory')}
                    </div>
                  </NavLink>

                  <RaizKidsEditStatementsLink
                    toggleMenu={toggleMenu}
                    kidId={kid.id}
                  />

                  {!isConverted && (
                    <div
                      className="page-menu__item raiz-kids__menu-item -reactivate"
                      role="button"
                      onKeyPress={() => {}}
                      tabIndex="0"
                      onClick={() => setIsOpenReactivateModal(true)}
                    >
                      <div className="-centered">
                        <i className="page-menu__item-icon -reactivate" />

                        {t('raizKidsEditPage.reActivateAccountModal.title')}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div
                    role="button"
                    onKeyPress={() => {}}
                    tabIndex="0"
                    className="page-menu__item raiz-kids__menu-item"
                    onClick={() => toggleMenu(true, KIDS_INVEST_NOW)}
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -invest-now" />

                      {t('raizKidsEditPage.investNow')}
                    </div>
                  </div>

                  <NavLink
                    to={`/raiz-kids/edit/transfer-in/${kid.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'page-menu__item raiz-kids__menu-item -active'
                        : 'page-menu__item raiz-kids__menu-item'
                    }
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -transfer-in" />

                      {t('raizKidsEditPage.transfer.in.title')}
                    </div>
                  </NavLink>

                  <NavLink
                    to={`/raiz-kids/edit/transfer-out/${kid.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'page-menu__item raiz-kids__menu-item -active'
                        : 'page-menu__item raiz-kids__menu-item'
                    }
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -transfer-out" />

                      {t('raizKidsEditPage.transfer.out.title')}
                    </div>
                  </NavLink>

                  <NavLink
                    to={`/raiz-kids/perfomance/${kid.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'page-menu__item raiz-kids__menu-item -active'
                        : 'page-menu__item raiz-kids__menu-item'
                    }
                    onClick={() => toggleMenu(false)}
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -perfomance" />

                      {t('raizKidsEditPage.viewPerformance')}
                    </div>
                  </NavLink>

                  <NavLink
                    to={`/raiz-kids/history/${kid.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'page-menu__item raiz-kids__menu-item -active'
                        : 'page-menu__item raiz-kids__menu-item'
                    }
                    onClick={() => toggleMenu(false)}
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -history" />

                      {t('raizKidsEditPage.viewHistory')}
                    </div>
                  </NavLink>

                  <RaizKidsEditStatementsLink
                    toggleMenu={toggleMenu}
                    kidId={kid.id}
                  />

                  <NavLink
                    to={`/raiz-kids/edit/recurring/${kid.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? 'page-menu__item raiz-kids__menu-item -active'
                        : 'page-menu__item raiz-kids__menu-item'
                    }
                    onClick={() => toggleMenu(false)}
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -recurring-green" />

                      {t('raizKidsEditPage.recurringInvestment')}
                    </div>
                  </NavLink>

                  <NavLink
                    to={{
                      pathname: generatedPortfolioPath,
                      search: createSearchParams({
                        [PORTFOLIO_SEARCH_PARAMS.childId]: kid.id,
                      }).toString(),
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? 'page-menu__item raiz-kids__menu-item -active'
                        : 'page-menu__item raiz-kids__menu-item'
                    }
                    onClick={() => toggleMenu(false)}
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -portfolio-kids" />

                      {t('raizKidsEditPage.portfolio')}
                    </div>

                    <div className="-name">{kid.portfolio?.name}</div>
                  </NavLink>

                  {isDividendsEnabled && (
                    <NavLink
                      to={{
                        pathname: PATHS.dividends.index,
                        search: createSearchParams({
                          [CHILD_ID_SEARCH_PARAM]: kid.id,
                        }).toString(),
                      }}
                      className={({ isActive }) =>
                        isActive
                          ? 'page-menu__item raiz-kids__menu-item -active'
                          : 'page-menu__item raiz-kids__menu-item'
                      }
                      onClick={() => toggleMenu(false)}
                    >
                      <div className="-centered">
                        <i className="page-menu__item-icon -dividends-kids" />

                        {t('raizKidsEditPage.dividends')}
                      </div>
                    </NavLink>
                  )}

                  <NavLink
                    to={`/raiz-kids/edit/profile/${kid.id}`}
                    className={({ isActive }) =>
                      isActive
                        ? `page-menu__item raiz-kids__menu-item ${
                            showSubSettings && '-active-arrow'
                          } -active -transparent`
                        : `page-menu__item raiz-kids__menu-item ${
                            showSubSettings && '-active-arrow'
                          }`
                    }
                    onClick={() => toggleMenu(true)}
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -settings" />

                      {t('raizKidsEditPage.settings')}
                    </div>
                  </NavLink>

                  <div
                    className={`raiz-kids__menu-sublist ${
                      showSubSettings && '-show'
                    }`}
                  >
                    <NavLink
                      to={`/raiz-kids/edit/profile/${kid.id}`}
                      className={({ isActive }) =>
                        isActive
                          ? 'page-menu__item raiz-kids__menu-item -active'
                          : 'page-menu__item raiz-kids__menu-item'
                      }
                    >
                      <div className="-centered">
                        <i className="page-menu__item-icon -profile-kids" />

                        {t('raizKidsEditPage.profile')}
                      </div>
                    </NavLink>

                    <NavLink
                      to={`/raiz-kids/edit/access/${kid.id}`}
                      isActive={checkActive}
                      className={({ isActive }) =>
                        isActive
                          ? 'page-menu__item raiz-kids__menu-item -active'
                          : 'page-menu__item raiz-kids__menu-item'
                      }
                    >
                      <div className="-centered">
                        <i className="page-menu__item-icon -access" />

                        {t('raizKidsEditPage.access.title')}
                      </div>
                    </NavLink>

                    <NavLink
                      to={`/raiz-kids/edit/notification/${kid.id}`}
                      className={({ isActive }) =>
                        isActive
                          ? 'page-menu__item raiz-kids__menu-item -active'
                          : 'page-menu__item raiz-kids__menu-item'
                      }
                    >
                      <div className="-centered">
                        <i className="page-menu__item-icon -notifications" />

                        {t('raizKidsEditPage.notification.title')}
                      </div>
                    </NavLink>
                  </div>

                  <div
                    role="button"
                    onKeyPress={() => {}}
                    tabIndex="0"
                    className="page-menu__item raiz-kids__menu-item"
                    onClick={() => toggleMenu(true, KIDS_WITHDRAW)}
                  >
                    <div className="-centered">
                      <i className="page-menu__item-icon -withdraw" />

                      {t('common.withdraw')}
                    </div>
                  </div>

                  <div
                    role="button"
                    onKeyPress={() => {}}
                    tabIndex="0"
                    aria-disabled={!isConvertable}
                    className={`page-menu__item raiz-kids__menu-item ${
                      isConvertable ? '' : '-disabled'
                    }`}
                    onClick={() =>
                      toggleMenu(true, KIDS_CONVERT_ACCOUNT, isConvertable)
                    }
                  >
                    <div
                      className={`-centered ${
                        isConvertable ? '' : '-disabled'
                      }`}
                    >
                      <i
                        className={`page-menu__item-icon ${
                          isPendingConverting
                            ? '-pending-converting'
                            : '-convert'
                        }`}
                      />

                      {t('raizKidsEditPage.convertToFullModal.title')}
                    </div>

                    {age < 18 && (
                      <span className="-sub-text">
                        {t('raizKidsEditPage.atYears')}
                      </span>
                    )}
                  </div>

                  {kid.email !== null &&
                    !kid.signup_complete &&
                    kid.account_access && (
                      <div
                        role="button"
                        onKeyPress={() => {}}
                        tabIndex="0"
                        className="page-menu__item raiz-kids__menu-item"
                        onClick={() => toggleMenu(true, KIDS_LINK_SENT)}
                      >
                        <div className="-centered">
                          <i className="page-menu__item-icon -invitation" />

                          {t('raizKidsEditPage.sendInvitationLink.title')}
                        </div>
                      </div>
                    )}

                  <div
                    className={`page-menu__item raiz-kids__menu-item ${
                      isPendingConverting ? '-disabled' : ''
                    }`}
                    role="button"
                    onKeyPress={() => {}}
                    tabIndex="0"
                    onClick={() => toggleMenu(true, KIDS_CLOSE_ACCOUNT)}
                  >
                    <div
                      className={`-centered ${
                        isPendingConverting ? '-disabled' : ''
                      }`}
                    >
                      <i className="page-menu__item-icon -close" />

                      {t('raizKidsEditPage.closeAccount')}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {childStep === KIDS_EDIT_STEPS.recurring ? (
            editStep
          ) : (
            <div className="profile-content settings__content-default-container raiz-kids__edit-content">
              <div className="raiz-kids__edit-title">
                {additionalStep && (
                  <Link
                    className="raiz-kids__edit-back"
                    to={`/raiz-kids/edit/access/${kid.id}`}
                  />
                )}

                {editTitle}
              </div>

              {editStep}
            </div>
          )}
        </div>
      </div>

      {isShowSentLinkModal && (
        <RelSentModal
          childEmail={kid.email}
          toggleSentlinkModal={toggleSentlink}
        />
      )}

      {isShowConvertToFull && (
        <ConvertToFullModal
          child={kid}
          showLoader={showLoader}
          convertToFull={convertToFull}
          toggleConvertToFull={toggleConvertToFull}
        />
      )}

      {isShowCloseAccount && (
        <CloseAccountKidsModal
          child={kid}
          showLoader={showLoader}
          closeAccount={closeAccount}
          toggleCloseAccount={toggleCloseAccount}
        />
      )}

      {isOpenWithdrawModal && (
        <WithdrawModal
          isKidsEdit
          childId={kid.id}
          isOpenWithdrawModal={isOpenWithdrawModal}
          toggleWithdrawModal={toggleWithdrawModal}
          availableBalance={kid.current_balance || 0}
          history={history}
        />
      )}

      {isShowInvestNow && (
        <KidsInitialInvestment
          isOpenInvestment
          child={kid}
          isEditChild
          oneTimeInvestmentValue={oneTimeInvestmentValue}
          allowType=""
          changeAccessInvestment={() => {}}
          unlimAmount=""
          goBack={toggleInvestNow}
          changeUnlimitedAmount={() => {}}
          showLoader={showLoader}
          changeOneTimeInvestmentValue={changeOneTimeInvestmentValue}
          handleSubmit={() => handleSubmitInvestNow()}
        />
      )}

      {isShowErrorModal && (
        <ErrorModal
          messages={messages}
          errorMessage={errorMessage}
          isOpenErrorModal={isShowErrorModal}
          toggleErrorModal={toggleErrorModal}
        />
      )}

      {showCongratsModal && (
        <SuccessModal
          messages={messages}
          modalMessage={t(
            'raizKidsPage.kidsInitialInvestmentModal.investmentSuccess.description',
          )}
          modalTitle={t(
            'raizKidsPage.kidsInitialInvestmentModal.investmentSuccess.title',
          )}
          isOpenModal={showCongratsModal}
          toggleModal={toggleCongratsModal}
        />
      )}

      {alert.isOpen && (
        <RecurringModal
          isOpen={alert.isOpen}
          modalTitle={alert.title}
          modalContent={alert.text}
          handleClick={alert.handleClick}
          modalFooterClassName="-single-btn"
        />
      )}

      {isOpenReactivateModal && (
        <ReactivateAccountModal
          kid={kid}
          showLoader={showLoader}
          activateAccount={activateAccount}
          toggleModal={setIsOpenReactivateModal}
        />
      )}
    </div>
  );

  return editStep;
}

RaizKidsEditView.defaultProps = {};

RaizKidsEditView.propTypes = {
  messages: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};
