import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { TAdditionalSuitabilityFields, TSuitabilityNameKey } from '../types';

import { INVITE_CODE_FIELD_NAME } from './const';

import { SUITABILITIES_PICKER_ID } from 'pages/Settings/consts';
import { checkIsIndustryFieldVisible } from 'pages/Settings/utils';
import { SUITABILITY_NAMES } from 'constants/suitabilityConstants';

interface IUseFinancialFormSchema {
  additionalSuitabilityFields: TAdditionalSuitabilityFields;
  financialValues: Record<string, { value: string }[]>;
}

export const useFinancialFormSchema = ({
  additionalSuitabilityFields,
  financialValues,
}: IUseFinancialFormSchema) => {
  const { t } = useTranslation();

  const filteredSuitabilityFields = Object.entries(
    additionalSuitabilityFields,
  ).filter(([fieldKey]) =>
    Object.keys(SUITABILITY_NAMES).includes(fieldKey),
  ) as [TSuitabilityNameKey, boolean][];

  const financialAdditionalFieldsShape = filteredSuitabilityFields.reduce(
    (fields, [name, value]) => {
      if (!value || name === SUITABILITIES_PICKER_ID.employment) {
        return fields;
      }

      if (name === SUITABILITIES_PICKER_ID.employment) {
        return {
          ...fields,
          [SUITABILITY_NAMES?.employment]: yup.string(),
        };
      }

      return {
        ...fields,
        [SUITABILITY_NAMES?.[name]]: yup
          .string()
          .required(t('fields.validation.required')),
      };
    },

    {},
  );

  const financialFormShape = Object.entries(financialValues)?.reduce(
    (fields, [name, value]) => {
      const isCurrentPickerIndustry = name === SUITABILITIES_PICKER_ID.industry;

      if (isCurrentPickerIndustry) {
        const sourceOfIncomeValue =
          financialValues?.[SUITABILITIES_PICKER_ID.sourceOfIncome]?.[0]?.value;

        const isIndustryFieldVisible = checkIsIndustryFieldVisible({
          value: sourceOfIncomeValue,
        });

        if (!isIndustryFieldVisible) {
          return fields;
        }
      }

      if (name !== INVITE_CODE_FIELD_NAME && !value) {
        return {
          ...fields,
          [name]: yup.string().required(t('fields.validation.required')),
        };
      }

      return fields;
    },

    {},
  );

  return yup.object({
    ...financialFormShape,
    ...financialAdditionalFieldsShape,
  });
};
