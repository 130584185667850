import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  AllocationInputWrapper,
  ChangeEtfWrapper as EditFundWrapper,
  DecreaseButton,
  IncreaseButton,
  AllocationInput,
} from '../styles';

import {
  ALLOCATION_FIELD_REGEX,
  ASSET_PERCENTAGE_CHANGE_VALUE,
  MAX_FUND_ALLOCATION,
  MIN_FUND_ALLOCATION,
} from './consts';
import { useUpdateFundAllocation } from './hooks';

import { useCheckSafeAllocationThreshold } from 'pages/Portfolio/hooks';
import DotsLoader from 'components/elements/DotsLoader/DotsLoader';
import { DEFAULT_DEBOUNCE_TIME } from 'constants/comonConstants';

export default function EditFund({ id, allocation, max_allocation, name }) {
  const {
    isFundsLoading,
    isCurrentFundLoading,
    updateFundAllocation,
    currentAllocation,
    setCurrentAllocation,
    isAllocationExhausted,
  } = useUpdateFundAllocation({ id, allocation, max_allocation, name });

  const [allocationInputValue, setAllocationInputValue] = useState(
    `${currentAllocation}`,
  );

  const { checkSafeAllocationThreshold } =
    useCheckSafeAllocationThreshold(allocation);

  const isDisabled = isCurrentFundLoading || isFundsLoading;

  const isMinusButtonDisabled =
    isDisabled || currentAllocation <= MIN_FUND_ALLOCATION;

  const isPlusButtonDisabled =
    isDisabled ||
    isAllocationExhausted ||
    currentAllocation >= MAX_FUND_ALLOCATION ||
    currentAllocation >= max_allocation;

  // Info: we need empty deps array here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAllocationUpdate = useCallback(
    debounce((updatedAllocationValue) => {
      updateFundAllocation({
        updatedAllocationValue,
      });

      setAllocationInputValue(`${updatedAllocationValue}`);
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const prevAllocationRef = useRef(currentAllocation);

  useEffect(() => {
    if (currentAllocation !== prevAllocationRef.current) {
      debounceAllocationUpdate(currentAllocation);

      prevAllocationRef.current = currentAllocation;
    }
  }, [currentAllocation, debounceAllocationUpdate, allocation]);

  useEffect(() => {
    const newAllocation = Number(allocationInputValue);

    checkSafeAllocationThreshold(newAllocation);

    setCurrentAllocation(newAllocation);
  }, [
    allocationInputValue,
    checkSafeAllocationThreshold,
    setCurrentAllocation,
  ]);

  const handleDecreaseButtonClick = () => {
    const decreasedAllocationValue =
      currentAllocation - ASSET_PERCENTAGE_CHANGE_VALUE;

    checkSafeAllocationThreshold(decreasedAllocationValue);

    setAllocationInputValue(decreasedAllocationValue);
  };

  const handleIncreaseButtonClick = () => {
    const increasedAllocationValue =
      currentAllocation + ASSET_PERCENTAGE_CHANGE_VALUE;

    checkSafeAllocationThreshold(increasedAllocationValue);

    setAllocationInputValue(increasedAllocationValue);
  };

  const handleAllocationInputChange = (e) => {
    const newAllocationString = e.target?.value;

    if (!ALLOCATION_FIELD_REGEX.test(newAllocationString)) {
      return;
    }

    if (
      Number(newAllocationString) < MIN_FUND_ALLOCATION ||
      Number(newAllocationString) > MAX_FUND_ALLOCATION
    ) {
      return;
    }

    setAllocationInputValue(newAllocationString);
  };

  return (
    <EditFundWrapper>
      {/* TODO: className until global styles are removed upon registration https://acornsau.atlassian.net/browse/RAIZ-5548 */}
      <DecreaseButton
        className="-exclude"
        onClick={handleDecreaseButtonClick}
        disabled={isMinusButtonDisabled}
      />

      {isCurrentFundLoading ? (
        <DotsLoader size={10} />
      ) : (
        <AllocationInputWrapper>
          <AllocationInput
            type="text"
            value={allocationInputValue}
            disabled={isDisabled}
            onChange={handleAllocationInputChange}
          />
          %
        </AllocationInputWrapper>
      )}

      {/* TODO: className until global styles are removed upon registration https://acornsau.atlassian.net/browse/RAIZ-5548 */}
      <IncreaseButton
        className="-exclude"
        onClick={handleIncreaseButtonClick}
        disabled={isPlusButtonDisabled}
      />
    </EditFundWrapper>
  );
}

EditFund.defaultProps = {
  allocation: 0,
  max_allocation: MAX_FUND_ALLOCATION,
};

EditFund.propTypes = {
  allocation: PropTypes.number,
  id: PropTypes.string.isRequired,
  max_allocation: PropTypes.number,
  name: PropTypes.string.isRequired,
};
