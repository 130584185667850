import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Account } from './Account/Account';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { YODLEE_FASTLINK_STATUSES } from 'pages/Settings/consts';
import { ImageWithDefaultIcon } from 'components/elements/ImageWithDefaultIcon';
import { selectIsYodleeFastlinkEnabled } from 'store/firebaseConfig/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { BANK_PROVIDERS } from 'store/fundingAccount/consts';

function SubFundingAccount({
  account,
  handleSubAccount,
  isSpending,
  deleteAccount,
  fixAccount,
  isShowConnect,
  showConfirmModal,
  setCurrentBank,
}) {
  const { t } = useTranslation();
  const [isFixButtonVisible, setIsFixButtonVisible] = useState(false);

  const isYodleeFastlinkEnabled = useAppSelector(selectIsYodleeFastlinkEnabled);

  const isFastlinkFixButtonVisible = !!(
    account?.additional_status?.updateEligibility &&
    account?.additional_status?.updateEligibility !==
      YODLEE_FASTLINK_STATUSES.disallowUpdate &&
    account?.additional_status?.additionalStatus !==
      YODLEE_FASTLINK_STATUSES.consentRevoked
  );

  useEffect(() => {
    const errorCode = account?.error_info?.error_code;

    const isFixableErrorCode = [
      'STATUS_LOGIN_FAILED',
      'ERRLINKING_SPENDING_ACCOUNT',
      'CHALLENGE_RESPONSE_REQUIRE',
      'ACCOUNT_LOCKED',
    ].includes(errorCode);

    if (account?.provider === BANK_PROVIDERS.yodleeFastlink) {
      setIsFixButtonVisible(isFastlinkFixButtonVisible);
    } else if (account?.error_info && isFixableErrorCode) {
      setIsFixButtonVisible(true);
    }
  }, [
    account?.error_info,
    isYodleeFastlinkEnabled,
    isFastlinkFixButtonVisible,
    account?.provider,
  ]);

  const isSubAccountsDisplayed =
    account.provider === BANK_PROVIDERS.yodleeFastlink
      ? account?.additional_status?.additionalStatus !==
        YODLEE_FASTLINK_STATUSES.consentRevoked
      : true;

  return (
    <div className="info-panel -company-name -with-padding">
      <div className="info-panel__title">
        <div className="flex-title">
          <div className="flex-title">
            <ImageWithDefaultIcon
              src={account.icon}
              withMargin
              className="grid__container-row-icon"
            />

            {account.name}
          </div>

          {isSpending && !isShowConnect && (
            <DeprecatedButton
              title={t('common.unlink')}
              buttonClass="button__basic-wtbackground settings__spending-account-unlink"
              handleClick={(e) => {
                setCurrentBank(account.name);

                deleteAccount(e, account.id);
              }}
            />
          )}
        </div>
      </div>

      {account?.error_info?.resolve && isSpending && !isShowConnect && (
        <div>
          <div className="info-panel__divider" />

          <div className="info-panel__item settings__spending-account-warning">
            {account.error_info.resolve}

            {isFixButtonVisible && (
              <DeprecatedButton
                title={t('settingsPage.fix')}
                buttonClass="button__basic-warning"
                handleClick={(e) => fixAccount(e, account)}
              />
            )}
          </div>
        </div>
      )}

      {account.subaccounts?.length !== 0 && isSubAccountsDisplayed && (
        <>
          <div className="info-panel__divider" />

          {account.subaccounts.map((subaccount) => (
            <Account
              key={subaccount.account_id}
              subaccount={subaccount}
              handleSubAccount={handleSubAccount}
              monitoredId={account.id}
              isSpending={isSpending}
              showConfirmModal={showConfirmModal}
            />
          ))}
        </>
      )}
    </div>
  );
}

SubFundingAccount.defaultProps = {
  account: {},
  handleSubAccount: () => {},
  isSpending: false,
  showConfirmModal: () => {},
  deleteAccount: () => {},
  fixAccount: () => {},
  isShowConnect: false,
  setCurrentBank: () => {},
};

SubFundingAccount.propTypes = {
  account: PropTypes.shape({
    additional_status: PropTypes.shape({
      additionalStatus: PropTypes.string,
      updateEligibility: PropTypes.string,
    }),
    error_info: PropTypes.shape({
      error_code: PropTypes.string,
      resolve: PropTypes.string,
    }),
    icon: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    subaccounts: PropTypes.arrayOf(
      PropTypes.shape({
        map: PropTypes.func,
        length: PropTypes.number,
      }),
    ),
    provider: PropTypes.string,
  }),
  handleSubAccount: PropTypes.func,
  isSpending: PropTypes.bool,
  showConfirmModal: PropTypes.func,
  deleteAccount: PropTypes.func,
  fixAccount: PropTypes.func,
  isShowConnect: PropTypes.bool,
  setCurrentBank: PropTypes.func,
};

export default SubFundingAccount;
