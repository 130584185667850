import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';

import * as fundingAccountAction from '../../../store/fundingAccount/actions';
import * as actionsUser from '../../../store/user/actions';
import { REQUIRED } from '../../../constants/errors';
import { MY_APP } from '../../../constants/localeConfigs';
import { HOME } from '../../../store/user/consts';

import FundingAccountView from './FundingAccountView';

import { FUNDING_PAGE } from 'store/fundingAccount/consts';
import {
  selectIsNewFundingChangeable,
  selectIsYodleeFastlinkEnabled,
} from 'store/firebaseConfig/selectors';
import { selectIsBankAccountLinkingStep } from 'store/fundingAccount/selectors';
import { FASTLINK_FLOWS } from 'components/modals/Fastlink/consts';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { checkIsEligibleForFastlink } from 'utils/checkIsEligibleForFastlink';

class FundingAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowModalError: false,
      isShowBankModal: false,
      isShowBankModalError: false,
      showMainLoader: true,
      bank: {},
      bankCurrentTarget: 'LOGIN',
      cardMonth: '',
      cardYear: '',
      cardCvv: '',
      isShowPassword: true,
      cardNumber: '',
      cardNumberError: '',
      isChangeBankCard: false,
      activeTarget: null,
      isShowConfirmationModal: false,
      accountName: '',
      connectCredentials: {},
      connectLoader: false,
      isShowLinkFunding: false,
      isCardSelect: false,
      linkCardId: '',
      isShowBankInformationModal: false,
      isFastlinkModalOpen: false,
      searchValue: null,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleStopBankAccountLinking =
      this.handleStopBankAccountLinking.bind(this);

    this.bankLoginProcessWait = this.bankLoginProcessWait.bind(this);

    this.handleCloseModalError = this.handleCloseModalError.bind(this);

    this.accountChange = this.accountChange.bind(this);

    this.openDifferentBank = this.openDifferentBank.bind(this);

    this.closeModal = this.closeModal.bind(this);

    this.changeDate = this.changeDate.bind(this);

    this.toggleShowPassword = this.toggleShowPassword.bind(this);

    this.handleChangeCardNumber = this.handleChangeCardNumber.bind(this);

    this.linkCard = this.linkCard.bind(this);

    this.accountChangeBankCard = this.accountChangeBankCard.bind(this);

    this.switchСardSpendingToFunding =
      this.switchСardSpendingToFunding.bind(this);

    this.showMoreBanks = this.showMoreBanks.bind(this);

    this.searchBanks = this.searchBanks.bind(this);

    this.showConfirmModal = this.showConfirmModal.bind(this);

    this.closeBankInfoModal = this.closeBankInfoModal.bind(this);

    this.requestChange = this.requestChange.bind(this);

    this.goHome = this.goHome.bind(this);

    this.toggleFastlinkModal = this.toggleFastlinkModal.bind(this);

    this.trackScrolling = this.trackScrolling.bind(this);

    this.changeSearchValue = this.changeSearchValue.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;
    const { isFundingErrorVisible } = this.props;

    if (!isFundingErrorVisible) {
      if (MY_APP) {
        actions.getPaymentCard().then(() => {
          actions.getPaymentCard('spending').then(() => {
            this.setState({ showMainLoader: false });
          });
        });
      } else {
        actions.getFundingAccount().then(() => {
          const { accountData } = this.props;

          if (accountData && accountData.funding) {
            this.setState({ showMainLoader: false });
          } else {
            const { isNewFundingChangeable } = this.props;

            actions.checkChangeFunding(isNewFundingChangeable).then(() => {
              actions.getBanks().then(() => {
                setTimeout(
                  () => this.setState({ showMainLoader: false }),
                  1000,
                );
              });
            });
          }
        });
      }
    } else {
      this.setState({ showMainLoader: false });
    }

    window.scrollTo(0, 0);
  }

  // TODO: Check new "UNSAFE_componentWillReceiveProps" => "componentDidUpdate + getDerivedStateFromProps"
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const newData = {};
  //   if (nextProps.isPaymentFailed !== prevState.isPaymentFailed) {
  //     newData.isPaymentFailed = nextProps.isPaymentFailed;
  //   }
  //   if (nextProps.isPaymentSuccess !== prevState.isPaymentSuccess) {
  //     newData.isPaymentSuccess = nextProps.isPaymentSuccess;
  //   }
  //   if (nextProps.switchCardSuccess !== prevState.switchCardSuccess) {
  //     newData.switchCardSuccess = nextProps.switchCardSuccess;
  //   }
  //   if (nextProps.isFromLinkFundingProps !== prevState.isFromLinkFundingProps) {
  //     newData.isFromLinkFundingProps = nextProps.isFromLinkFundingProps;
  //   }
  //   if (nextProps.skipBankLoginProps !== prevState.skipBankLoginProps) {
  //     newData.skipBankLoginProps = nextProps.skipBankLoginProps;
  //   }
  //   if (nextProps.paymentCard !== prevState.paymentCard) {
  //     newData.paymentCard = nextProps.paymentCard;
  //   }
  //   if (nextProps.isShowCreditCardError !== prevState.isShowCreditCardError) {
  //     newData.isShowCreditCardError = nextProps.isShowCreditCardError;
  //   }
  //   if (nextProps.errorMessageProps !== prevState.errorMessageProps) {
  //     newData.errorMessageProps = nextProps.errorMessageProps;
  //   }
  //   if (nextProps.isShowWithdrawalSignIn !== prevState.isShowWithdrawalSignIn) {
  //     newData.isShowWithdrawalSignIn = nextProps.isShowWithdrawalSignIn;
  //   }
  //   if (nextProps.currentStep !== prevState.currentStep) {
  //     newData.currentStep = nextProps.currentStep;
  //   }
  //   return newData;
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   const {
  //     isPaymentFailed, isPaymentSuccess, isShowWithdrawalSignIn, currentStep, switchCardSuccess,
  //     errorMessageProps, isShowCreditCardError, paymentCard, skipBankLoginProps, isFromLinkFundingProps,
  //   } = this.state;
  //   const {
  //     actions, history, isFromLinkFunding, isShowDifferentBankModal
  //   } = this.props;
  //   if (isPaymentFailed && prevState.isPaymentFailed !== isPaymentFailed) {
  //     this.setState({ isPaymentFailed: true });
  //   }
  //
  //   if (isPaymentSuccess && prevState.isPaymentSuccess !== isPaymentSuccess) {
  //     this.setState({ isPaymentSuccess: true });
  //   }
  //
  //   const { skipBankLogin, errorMessage } = this.props;
  //   if (switchCardSuccess && switchCardSuccess !== prevState.switchCardSuccess) {
  //     this.setState({ isChangeBankCard: false });
  //     if (MY_APP && isFromLinkFundingProps && prevState.isFromLinkFundingProps !== isFromLinkFundingProps
  //       && isFromLinkFundingProps !== isFromLinkFunding) {
  //       actions.getPaymentCard().then(() => {
  //         this.setState({ isShowLinkFunding: false, showMainLoader: false });
  //         history.push(FUNDING_PAGE);
  //       });
  //     }
  //   }
  //
  //   if (skipBankLoginProps && prevState.skipBankLoginProps !== skipBankLoginProps
  //     && skipBankLoginProps !== skipBankLogin) {
  //     this.setState({
  //       isShowBankModal: false
  //     });
  //   }
  //   if (paymentCard && prevState.paymentCard !== paymentCard && paymentCard.request_url) {
  //     setTimeout(() => {
  //       document.infinitium.submit();
  //     }, 2000);
  //   }
  //   const stopBankLoginRequest = parseInt(sessionStorage.getItem('stopBankLoginRequest'));
  //   if (isShowCreditCardError && prevState.isShowCreditCardError !== isShowCreditCardError) {
  //     this.setState({
  //       isShowBankModalError: true
  //     });
  //   }
  //   if (errorMessageProps && (prevState.errorMessageProps !== errorMessageProps
  //     && errorMessageProps !== errorMessage && !stopBankLoginRequest)) {
  //     this.setState({
  //       isShowBankModalError: true
  //     });
  //     sessionStorage.setItem('stopBankLoginRequest', 1);
  //   }
  //   if (isShowWithdrawalSignIn && (prevState.isShowWithdrawalSignIn !== isShowWithdrawalSignIn)
  //     && currentStep === FUNDING_PAGE) {
  //     this.setState({
  //       isShowWithdrawalSignIn
  //     });
  //   }
  //   if (errorMessageProps && (prevState.errorMessageProps !== errorMessageProps
  //     && errorMessageProps !== errorMessage && isShowDifferentBankModal)) {
  //     this.setState({
  //       isShowBankModalError: true
  //     });
  //   }
  //   if (errorMessageProps && (prevState.errorMessageProps !== errorMessageProps
  //     && errorMessageProps !== errorMessage)) {
  //
  //
  //     this.setState({
  //       isShowModalError: true
  //     });
  //   }
  // }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { isShowWithdrawalSignIn } = this.state;

    const { actions, history, isFromLinkFunding } = this.props;
    const { isShowDifferentBankModal } = this.props;

    const { skipBankLogin, errorMessage } = this.props;

    if (newProps.switchCardSuccess) {
      this.setState({ isChangeBankCard: false });

      if (
        MY_APP &&
        newProps.isFromLinkFunding &&
        newProps.isFromLinkFunding !== isFromLinkFunding
      ) {
        actions.getPaymentCard().then(() => {
          this.setState({ isShowLinkFunding: false, showMainLoader: false });

          history.push(FUNDING_PAGE);
        });
      }
    }

    if (newProps.skipBankLogin && newProps.skipBankLogin !== skipBankLogin) {
      this.setState({
        isShowBankModal: false,
      });
    }

    if (newProps.paymentCard && newProps.paymentCard.request_url) {
      setTimeout(() => {
        document.infinitium.submit();
      }, 2000);
    }

    const stopBankLoginRequest = parseInt(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.stopBankLoginRequest),
    );

    if (newProps.isShowCreditCardError) {
      this.setState({
        isShowBankModalError: true,
      });
    }

    if (
      newProps.errorMessage &&
      !newProps.isFundingErrorVisible &&
      newProps.errorMessage !== errorMessage &&
      !stopBankLoginRequest
    ) {
      this.setState({
        isShowBankModalError: true,
      });

      sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLoginRequest, 1);
    }

    if (
      isShowWithdrawalSignIn &&
      newProps.isShowWithdrawalSignIn !== isShowWithdrawalSignIn &&
      (newProps.currentStep === FUNDING_PAGE ||
        (newProps.history &&
          newProps.history.location &&
          newProps.history.location.pathname &&
          newProps.history.location.pathname === FUNDING_PAGE))
    ) {
      this.setState({
        isShowWithdrawalSignIn: newProps.isShowWithdrawalSignIn,
      });
    }

    if (
      newProps.errorMessage &&
      newProps.errorMessage !== errorMessage &&
      isShowDifferentBankModal
    ) {
      this.setState({
        isShowBankModalError: true,
      });
    }

    if (newProps.errorMessage && newProps.errorMessage !== errorMessage) {
      this.setState({
        isShowModalError: true,
      });
    }

    if (newProps.addListener) {
      document.addEventListener('scroll', this.trackScrolling);
    }
  }

  componentDidUpdate(_, prevState) {
    const { searchValue } = this.state;

    const { actions } = this.props;

    if (
      typeof searchValue === 'string' &&
      prevState.searchValue !== searchValue
    ) {
      actions.waitingInProcess();

      this.searchBanks();
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    document.removeEventListener('scroll', this.trackScrolling);

    actions.resetProps();
  }

  handleCloseModal() {
    this.setState({
      isShowBankModal: false,
      isShowWithdrawalSignIn: false,
      isShowModalError: false,
      isShowBankCardModal: false,
      isShowConfirmationModal: false,
    });
  }

  handleCloseModalError(_, isLinkCard = false) {
    const { actions, errorMessage } = this.props;

    actions.stopLoginRequest();

    if (errorMessage !== '') {
      this.setState({ isShowBankModalError: false });
    } else {
      this.setState({ isShowBankModalError: false, isShowLinkFunding: false });
    }

    if (isLinkCard) {
      actions.getBanks();
    }
  }

  handleStopBankAccountLinking() {
    const { actions } = this.props;

    actions.showWaitPopUpBankLogin(false);

    sessionStorage.setItem(SESSION_STORAGE_KEYS.stopBankLogin, 1);
  }

  selectLinkCard = (e, cardId) => {
    if (
      e &&
      e.target &&
      e.target.parentElement &&
      e.target.parentElement.classList
    ) {
      if (e.target.parentElement.classList.contains('checkbox__check')) {
        e.target.parentElement.className = 'checkbox__plus';

        this.setState({
          isCardSelect: false,
          linkCardId: '',
        });
      } else {
        this.setState({
          isCardSelect: true,
          linkCardId: cardId,
        });

        e.target.parentElement.className = 'checkbox__plus checkbox__check';
      }
    }
  };

  showBanks = () => {
    const { actions, isNewFundingChangeable } = this.props;

    actions.checkChangeFunding(isNewFundingChangeable);
  };

  showBanksFromSubAccount = () => {
    const { actions } = this.props;

    actions.getBanksList();
  };

  trackScrolling = () => {
    const wrappedElement = document.getElementById('bankSearch');
    const { actions, addListener } = this.props;

    if (addListener && this.isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this.trackScrolling);

      actions.getBanksList({
        isMoreBanks: true,
        isOffset: true,
      });
    }
  };

  signInBank = (bankId) => {
    const { state, isYodleeFastlinkEnabled, actions } = this.props;

    state.fundingAccount.credentials.LOGIN = '';

    state.fundingAccount.credentials.PASSWORD = '';

    let bank = [];

    if (
      state.fundingAccount.moreBanks &&
      state.fundingAccount.moreBanks.length !== 0
    ) {
      bank = state.fundingAccount.moreBanks.filter((banks) =>
        banks.institution_id.includes(bankId),
      );
    } else {
      bank = state.fundingAccount.banks.institutions.filter((banks) =>
        banks.institution_id.includes(bankId),
      );
    }

    if (bank) {
      this.setState({
        bank: bank[0],
      });
    }

    const isEligibleForFastlink = checkIsEligibleForFastlink({
      provider: bank[0].provider,
      isYodleeFastlinkEnabled,
    });

    if (isEligibleForFastlink) {
      actions.setCurrentBank(bank[0]);

      actions.setFastlinkFlow(FASTLINK_FLOWS.add);

      this.setState({
        isFastlinkModalOpen: true,
      });

      return;
    }

    this.setState({
      isShowBankModal: true,
    });
  };

  linkCard = (e, values, form, hasValidationErrors) => {
    const { cardNumber, bank } = this.state;
    const { actions, userActions, t } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        if (cardNumber === '') {
          this.setState({
            cardNumberError: REQUIRED,
          });
        }

        if (cardNumber.replace(/\s/g, '').length !== 16) {
          this.setState({
            cardNumberError: t(
              'settingsPage.registrationBankAccount.wrongCardNumber',
            ),
          });
        } else {
          let expirationYear = 0;
          let expirationMonth = 0;

          if (
            values.cardYear &&
            values.cardYear[0] &&
            values.cardMonth &&
            values.cardMonth[0]
          ) {
            expirationYear = values.cardYear[0].value;

            expirationMonth = values.cardMonth[0].value;
          }

          const data = {
            number: cardNumber.replace(/\s/g, ''),
            cvv: values.cvvCode,
            expiration_year: expirationYear,
            expiration_month: expirationMonth,
            spending: false,
            funding: true,
            bank_id: bank.id,
            holder_name: values.holderName,
          };

          const redirectData = {
            failed_url: `${window.location.href}-failed`,
            success_url: `${window.location.href}-success`,
          };

          userActions.showLoader();

          actions.addPaymentCard(data, redirectData);
        }
      }
    }
  };

  handleChangeCardNumber = (e) => {
    this.setState({ cardNumber: e.target.value, cardNumberError: '' });
  };

  openBankCardModal = (bankId) => {
    const { state } = this.props;

    const bank = state.fundingAccount.banks.banks.filter(
      (banks) => banks.id === bankId,
    );

    if (bank) {
      this.setState({
        bank: bank[0],
      });
    }

    this.setState({
      isShowBankCardModal: true,
    });
  };

  handleChangeBank = (e) => {
    const { state } = this.props;

    this.setState({ bankCurrentTarget: e.target.name });

    if (state.form.BANK_SIGN_IN && state.form.BANK_SIGN_IN.initial) {
      state.form.BANK_SIGN_IN.initial[e.target.name] = e.target.value;
    }

    if (
      state.form.BANK_SIGN_IN_DIFFERENT &&
      state.form.BANK_SIGN_IN_DIFFERENT.initial
    ) {
      state.form.BANK_SIGN_IN_DIFFERENT.initial[e.target.name] = e.target.value;
    }
  };

  bankInSubmit = (e, values, form, hasValidationErrors) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        const { actions, userActions } = this.props;
        const { bank } = this.state;

        userActions.showLoader();

        const bankData = {
          formData: values,
          bank,
          isSpending: false,
          isRegistration: false,
          isFromInvestments: false,
          roundUpsCheck: false,
          isMfa: false,
          isKidsRegistrationPortfolio: false,
          isKidsRegistrationHome: false,
          isReConnectAccount: false,
        };

        actions.bankSignIn(bankData);
      }
    }
  };

  bankInDifferentSubmit = (e, values, form, hasValidationErrors) => {
    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        const { actions, userActions } = this.props;

        userActions.showLoader();

        actions.bankSignDifferentIn(values);
      }
    }
  };

  handleSubAccount = (credentials) => {
    const { actions } = this.props;

    this.setState({ connectLoader: true });

    actions
      .addAccountForInvestments(
        credentials.accountId,
        credentials.monitoredId,
        credentials.bankId,
      )
      .then(() => {
        this.setState({ isShowConfirmationModal: false, connectLoader: false });

        actions.setIsShowConnectStep(false);

        actions.setBankAccountData(null);
      });
  };

  connectAccount = () => {
    const { actions } = this.props;

    actions.getBanks();

    actions.connectAccount();
  };

  bankInSubmitWithdrawal = (e, values, form, hasValidationErrors) => {
    const { bank } = this.state;
    const { actions, userActions } = this.props;

    if (e.persist) {
      e.persist();

      e.preventDefault();

      form.submit();

      if (!hasValidationErrors) {
        const data = {
          account_number: values.accountNumber,
          bank_id: bank.id,
        };

        userActions.showLoader();

        actions.bankSignDifferentIn(data, false, false, true).then(() => {
          this.setState({
            showMainLoader: false,
          });
        });
      }
    }
  };

  searchBanks = () => {
    const { actions } = this.props;
    const { searchValue } = this.state;

    if (searchValue) {
      actions.getBanksList({
        isMoreBanks: true,
        name: searchValue,
      });
    } else {
      actions.getBanksList({ isMoreBanks: true });
    }
  };

  linkCardForFunding = () => {
    const { actions, t } = this.props;
    const { isCardSelect, linkCardId } = this.state;

    if (isCardSelect && linkCardId !== '') {
      actions.unlinkSpendingCard(linkCardId, true, true, true).then(() => {
        this.setState({
          isCardSelect: false,
          linkCardId: '',
          isShowLinkFunding: false,
        });
      });
    } else {
      actions.showErrorUnlinkCard(
        t('settingsPage.registrationBankAccount.selectConnectedCardMessage'),
      );
    }
  };

  isBottom(el) {
    if (el) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    return false;
  }

  showConfirmModal(connectCredentials) {
    this.setState({
      isShowConfirmationModal: true,
      accountName: connectCredentials.accountNickname,
      connectCredentials,
    });
  }

  bankLoginProcessWait() {
    const { actions } = this.props;

    actions.waitingInProcess();
  }

  accountChange() {
    const { actions, isNewFundingChangeable } = this.props;

    actions.checkChangeFunding(isNewFundingChangeable);
  }

  accountChangeBankCard() {
    this.setState({
      isChangeBankCard: true,
    });
  }

  switchСardSpendingToFunding(e, cardId) {
    const { actions } = this.props;

    if (e.target.parentElement.classList.contains('checkbox__check')) {
      return;
    }

    this.setState({
      activeTarget: e.target,
    });

    actions.switchСardOtherAccount('funding', cardId).then(() => {
      const { activeTarget } = this.state;
      const { isShowCreditCardError } = this.props;

      if (!isShowCreditCardError) {
        activeTarget.parentElement.classList.toggle('checkbox__check');
      }
    });
  }

  openDifferentBank() {
    const { actions } = this.props;

    this.setState({
      bank: {},
    });

    actions.showDifferetnBank();
  }

  closeModal() {
    const { actions } = this.props;

    actions.closeModal();
  }

  changeDate(value, type) {
    if (type === 'month') {
      this.setState({
        cardMonth: value,
      });
    } else {
      this.setState({
        cardYear: value,
      });
    }
  }

  toggleShowPassword() {
    const { isShowPassword } = this.state;

    this.setState({ isShowPassword: !isShowPassword });
  }

  showMoreBanks() {
    const { actions } = this.props;

    actions.getBanksList({ isMoreBanks: true });
  }

  closeBankInfoModal() {
    this.setState({ isShowBankInformationModal: false });
  }

  requestChange() {
    const { actions } = this.props;

    actions.requestChange();
  }

  goHome() {
    const { history, actions } = this.props;

    actions.closeModal();

    history.push(HOME);
  }

  toggleFastlinkModal() {
    const { isFastlinkModalOpen } = this.state;
    const { actions } = this.props;

    actions.setFastlinkFlow(FASTLINK_FLOWS.add);

    this.setState({
      isFastlinkModalOpen: !isFastlinkModalOpen,
    });
  }

  changeSearchValue(searchValue) {
    this.setState({
      searchValue,
    });
  }

  render() {
    const {
      isShowBankModal,
      isShowBankModalError,
      bank,
      isShowModalError,
      showMainLoader,
      bankCurrentTarget,
      isShowBankCardModal,
      cardMonth,
      cardYear,
      cardCvv,
      isShowPassword,
      cardNumberError,
      isChangeBankCard,
      isShowWithdrawalSignIn,
      isShowConfirmationModal,
      accountName,
      connectCredentials,
      connectLoader,
      isShowLinkFunding,
      isShowBankInformationModal,
      isFastlinkModalOpen,
      searchValue,
    } = this.state;

    const {
      state,
      isShowBankModalAlert,
      errorMessage,
      errorTitle,
      accountData,
      monitoredInstitutions,
      isShowConnect,
      isShowBanks,
      isShowDifferentBankModal,
      showLoaderFunding,
      paymentCard,
      fundingPaymentCards,
      spendingPaymentCards,
      moreBanks,
      isShowMoreBanks,
      isFundingErrorVisible,
      fundingStatusCode,
      isShowFundingSecurityModal,
      messageFunding,
      user,
      isBankAccountLinkingStep,
      isYodleeFastlinkEnabled,
    } = this.props;

    return (
      <FundingAccountView
        showMainLoader={showMainLoader}
        user={user}
        showLoader={showLoaderFunding}
        showLoaderFunding={showLoaderFunding}
        accountData={accountData}
        showBanks={this.showBanks}
        isShowBanks={isShowBanks}
        signInBank={this.signInBank}
        isShowBankModal={isShowBankModal}
        handleCloseModal={this.handleCloseModal}
        bank={bank}
        bankInSubmit={this.bankInSubmit}
        handleChangeBank={this.handleChangeBank}
        credentials={state.fundingAccount.credentials}
        handleStopBankAccountLinking={this.handleStopBankAccountLinking}
        bankLoginProcessWait={this.bankLoginProcessWait}
        isShowBankModalAlert={isShowBankModalAlert}
        errorMessage={errorMessage}
        errorTitle={errorTitle}
        handleCloseModalError={this.handleCloseModalError}
        isShowBankModalError={isShowBankModalError}
        monitoredInstitutions={monitoredInstitutions}
        handleSubAccount={this.handleSubAccount}
        isShowModalError={isShowModalError}
        accountChange={this.accountChange}
        showBanksFromSubAccount={this.showBanksFromSubAccount}
        isShowConnect={isShowConnect}
        connectAccount={this.connectAccount}
        isShowDifferentBankModal={isShowDifferentBankModal}
        bankInDifferentSubmit={this.bankInDifferentSubmit}
        openDifferentBank={this.openDifferentBank}
        closeModal={this.closeModal}
        bankCurrentTarget={bankCurrentTarget}
        isShowBankCardModal={isShowBankCardModal}
        openBankCardModal={this.openBankCardModal}
        cardMonth={cardMonth}
        cardYear={cardYear}
        cardCvv={cardCvv}
        changeDate={this.changeDate}
        linkCard={this.linkCard}
        toggleShowPassword={this.toggleShowPassword}
        isShowPassword={isShowPassword}
        handleChangeCardNumber={this.handleChangeCardNumber}
        cardNumberError={cardNumberError}
        paymentCard={paymentCard}
        fundingPaymentCards={fundingPaymentCards}
        isChangeBankCard={isChangeBankCard}
        accountChangeBankCard={this.accountChangeBankCard}
        spendingPaymentCards={spendingPaymentCards}
        switchСardSpendingToFunding={this.switchСardSpendingToFunding}
        showMoreBanks={this.showMoreBanks}
        moreBanks={moreBanks}
        isShowMoreBanks={isShowMoreBanks}
        isShowWithdrawalSignIn={isShowWithdrawalSignIn}
        bankInSubmitWithdrawal={this.bankInSubmitWithdrawal}
        isShowConfirmationModal={isShowConfirmationModal}
        showConfirmModal={this.showConfirmModal}
        accountName={accountName}
        connectCredentials={connectCredentials}
        connectLoader={connectLoader}
        isShowLinkFunding={isShowLinkFunding}
        linkCardForFunding={this.linkCardForFunding}
        selectLinkCard={this.selectLinkCard}
        isFundingErrorVisible={isFundingErrorVisible}
        isShowBankInformationModal={isShowBankInformationModal}
        closeBankInfoModal={this.closeBankInfoModal}
        fundingStatusCode={fundingStatusCode}
        requestChange={this.requestChange}
        isShowFundingSecurityModal={isShowFundingSecurityModal}
        goHome={this.goHome}
        messageFunding={messageFunding}
        isBankAccountLinkingStep={isBankAccountLinkingStep}
        toggleFastlinkModal={this.toggleFastlinkModal}
        isFastlinkModalOpen={isFastlinkModalOpen}
        isYodleeFastlinkEnabled={isYodleeFastlinkEnabled}
        changeSearchValue={this.changeSearchValue}
        searchValue={searchValue}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  accountData: state.fundingAccount.accountData,
  errorMessage: state.fundingAccount.errorMessage,
  errorTitle: state.fundingAccount.errorTitle,
  credentials: state.fundingAccount.credentials,
  isShowBankModalAlert: state.fundingAccount.isShowBankModalAlert,
  skipBankLogin: state.fundingAccount.skipBankLogin,
  monitoredInstitutions: state.fundingAccount.monitoredInstitutions,
  isShowConnect: state.fundingAccount.isShowConnect,
  isShowBanks: state.fundingAccount.isShowBanks,
  isShowDifferentBankModal: state.fundingAccount.isShowDifferentBankModal,
  showLoaderFunding: state.user.showLoaderFunding,
  user: state.user.user,
  isShowCreditCardError: state.fundingAccount.isShowCreditCardError,
  paymentCard: state.fundingAccount.paymentCard,
  fundingPaymentCards: state.fundingAccount.fundingPaymentCards,
  spendingPaymentCards: state.fundingAccount.spendingPaymentCards,
  switchCardSuccess: state.fundingAccount.switchCardSuccess,
  isShowMoreBanks: state.fundingAccount.isShowMoreBanks,
  moreBanks: state.fundingAccount.moreBanks,
  isShowWithdrawalSignIn: state.fundingAccount.isShowWithdrawalSignIn,
  currentStep: state.fundingAccount.currentStep,
  isFromLinkFunding: state.fundingAccount.isFromLinkFunding,
  isFundingErrorVisible: state.fundingAccount.isFundingErrorVisible,
  fundingStatusCode: state.fundingAccount.fundingStatusCode,
  isShowFundingSecurityModal: state.fundingAccount.isShowFundingSecurityModal,
  messageFunding: state.fundingAccount.messageFunding,
  isNewFundingChangeable: selectIsNewFundingChangeable(state),
  isBankAccountLinkingStep: selectIsBankAccountLinkingStep(state),
  isYodleeFastlinkEnabled: selectIsYodleeFastlinkEnabled(state),
  addListener: state.fundingAccount.addListener,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(fundingAccountAction, dispatch),
  userActions: bindActionCreators(actionsUser, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FundingAccount));

FundingAccount.defaultProps = {
  user: {},
  accountData: {},
  state: {},
  isShowBankModalAlert: false,
  skipBankLogin: false,
  errorMessage: '',
  errorTitle: '',
  monitoredInstitutions: [],
  isShowConnect: false,
  isShowBanks: false,
  isShowDifferentBankModal: false,
  showLoaderFunding: false,
  isShowCreditCardError: false,
  paymentCard: {},
  fundingPaymentCards: {},
  spendingPaymentCards: {},
  isShowMoreBanks: false,
  moreBanks: [{}],
  isFromLinkFunding: false,
  isFundingErrorVisible: false,
  fundingStatusCode: 200,
  isShowFundingSecurityModal: false,
  messageFunding: '',
  isNewFundingChangeable: false,
  isYodleeFastlinkEnabled: false,
  addListener: false,
};

FundingAccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  actions: PropTypes.shape({
    resetProps: PropTypes.func,
    addPaymentCard: PropTypes.func,
    getPaymentCard: PropTypes.func,
    getFundingAccount: PropTypes.func,
    checkChangeFunding: PropTypes.func,
    getBanks: PropTypes.func,
    bankSignIn: PropTypes.func,
    bankSignDifferentIn: PropTypes.func,
    addAccountForInvestments: PropTypes.func,
    connectAccount: PropTypes.func,
    getMoreBanksSuccess: PropTypes.func,
    getBanksList: PropTypes.func,
    unlinkSpendingCard: PropTypes.func,
    showErrorUnlinkCard: PropTypes.func,
    stopLoginRequest: PropTypes.func,
    showWaitPopUpBankLogin: PropTypes.func,
    waitingInProcess: PropTypes.func,
    switchСardOtherAccount: PropTypes.func,
    closeModal: PropTypes.func,
    showDifferetnBank: PropTypes.func,
    requestChange: PropTypes.func,
    setCurrentBank: PropTypes.func,
    setFastlinkFlow: PropTypes.func,
    setBankAccountData: PropTypes.func,
    setIsShowConnectStep: PropTypes.func,
  }).isRequired,
  accountData: PropTypes.shape({
    funding: PropTypes.shape({}),
  }),
  user: PropTypes.shape({}),
  state: PropTypes.shape({
    fundingAccount: PropTypes.shape({
      credentials: PropTypes.shape({
        LOGIN: PropTypes.string,
        PASSWORD: PropTypes.string,
      }),
      banks: PropTypes.shape({
        institutions: PropTypes.arrayOf(PropTypes.shape({})),
        banks: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      moreBanks: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    form: PropTypes.shape({
      BANK_SIGN_IN_DIFFERENT: PropTypes.string,
      BANK_SIGN_IN: PropTypes.string,
    }),
  }),
  isShowBankModalAlert: PropTypes.bool,
  skipBankLogin: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorTitle: PropTypes.string,
  monitoredInstitutions: PropTypes.arrayOf(
    PropTypes.shape({
      additional_status: PropTypes.shape({
        additionalStatus: PropTypes.string,
        updateEligibility: PropTypes.string,
      }),
    }),
  ),
  isShowConnect: PropTypes.bool,
  isShowBanks: PropTypes.bool,
  isShowDifferentBankModal: PropTypes.bool,
  showLoaderFunding: PropTypes.bool,
  userActions: PropTypes.shape({
    showLoader: PropTypes.func,
  }).isRequired,
  isShowCreditCardError: PropTypes.bool,
  paymentCard: PropTypes.shape({}),
  fundingPaymentCards: PropTypes.shape({
    payment_cards: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  spendingPaymentCards: PropTypes.shape({}),
  isShowMoreBanks: PropTypes.bool,
  moreBanks: PropTypes.arrayOf(PropTypes.shape({})),
  isFromLinkFunding: PropTypes.bool,
  isFundingErrorVisible: PropTypes.bool,
  fundingStatusCode: PropTypes.number,
  isShowFundingSecurityModal: PropTypes.bool,
  messageFunding: PropTypes.string,
  isNewFundingChangeable: PropTypes.bool,
  isBankAccountLinkingStep: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isYodleeFastlinkEnabled: PropTypes.bool,
  addListener: PropTypes.bool,
};
