import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import CardRemoveConfirmModal from '../../../components/layout/Modals/CardRemoveConfirmModal';
import Time from '../../../components/elements/Time/Time';

function renderCard(card, removeCard, t) {
  return (
    <div className="card-block" key={card.id}>
      <div className={card.issuer !== 'visa' ? 'card' : 'card orange'}>
        <div className="card__header">
          {card.isExpired && (
            <div>
              <div
                className="card__header-warning"
                data-for="main"
                data-tip=""
                data-iscapture="true"
              />

              <ReactTooltip
                id="main"
                place="top"
                type="error"
                effect="solid"
                className="card__header-warning-tooltip"
              >
                <div>
                  <div className="info-warning" />

                  <div className="info-text">
                    {t('newRewardsPage.cards.expired')}
                  </div>
                </div>
              </ReactTooltip>
            </div>
          )}

          <div
            className="card__header-remove"
            onClick={() => removeCard(card.id)}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          />
        </div>

        <div className="card__number">
          {card.cardMasked.replaceAll('-', ' ')}
        </div>

        <div className="card__expirate">
          <Time format="MM/YY">{card.expiry}</Time>
        </div>

        <div className="card__holder">
          <div>{card.provider}</div>

          {card.issuer === 'visa' && <div className="card__visa" />}

          {card.issuer !== 'visa' && <div className="card__mastercard" />}
        </div>
      </div>
    </div>
  );
}

export default function CardsView({
  cards,
  linkCardUrl,
  removeCard,
  isShowConfirmationModal,
  closeConfirmationModal,
}) {
  const { t } = useTranslation();

  return (
    <div className="profile-content settings__content-default-container linked-cards">
      <div className="profile-content__header -with-navigation ">
        <div className="profile-content__title">
          {t('newRewardsPage.cards.title')}
        </div>
      </div>

      <div className="profile-content__container">
        <div className="linked-cards-info">
          {t('newRewardsPage.cards.description')}
        </div>

        <div className="card-body">
          {cards &&
            cards.cards &&
            cards.cards.length !== 0 &&
            cards.cards.map((card) => renderCard(card, removeCard, t))}

          <div className="empty">
            <a href={linkCardUrl} target="_blank" rel="noopener noreferrer">
              <div>{t('newRewardsPage.cards.link')}</div>
            </a>
          </div>
        </div>
      </div>

      <CardRemoveConfirmModal
        isShowConfirmationModal={isShowConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        removeCard={removeCard}
      />
    </div>
  );
}

CardsView.propTypes = {
  cards: PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  linkCardUrl: PropTypes.string.isRequired,
  removeCard: PropTypes.func.isRequired,
  isShowConfirmationModal: PropTypes.bool.isRequired,
  closeConfirmationModal: PropTypes.func.isRequired,
};
