import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { useNavigate, createSearchParams } from 'react-router-dom';

import { usePortfolioCustomizationRoute } from '../../hooks';
import { PORTFOLIO_PLANS, PORTFOLIO_SEARCH_PARAMS } from '../../../../consts';

import {
  selectFundsToUpdate,
  selectIsBasePortfolioFullyAllocated,
  selectPortfolioLocation,
  selectPortfolioParams,
} from 'store/portfolio/selectors';
import { PATHS } from 'constants/paths';
import { updateFunds } from 'store/portfolio/actions';
import { usePortfolioName } from 'pages/Portfolio/hooks';
import { useAppSelector } from 'store/hooks/useAppSelector';

export const useUpdateFunds = () => {
  const {
    isBitcoinFundPage,
    isResidentialPropertyFundsDetailsPage,
    isResidentialPropertyFundsListPage,
  } = usePortfolioCustomizationRoute();

  const dispatch = useDispatch();

  const { isRegistration, isKids } = useAppSelector(selectPortfolioLocation);

  const { childId, isSuper } = useAppSelector(selectPortfolioParams);
  const portfolioName = usePortfolioName();

  const { isUpdateFundsLoading, fundsToUpdate } = useSelector((state) => ({
    isUpdateFundsLoading: state.portfolio.isUpdateFundsLoading,
    fundsToUpdate: selectFundsToUpdate(state),
  }));

  const isBasePortfolioFullyAllocated = useAppSelector(
    selectIsBasePortfolioFullyAllocated,
  );

  const navigate = useNavigate();

  // TODO: add debounce logic https://acornsau.atlassian.net/browse/RAIZ-5335
  const handleUpdateFunds = () => {
    dispatch(updateFunds({ portfolioName })).then(() => {
      const registrationPath = isKids
        ? PATHS.kids.registration.index
        : PATHS.registration.portfolio.index;

      const portfolioUrl = PATHS.portfolio.index;

      if (
        isBitcoinFundPage ||
        isResidentialPropertyFundsDetailsPage ||
        isResidentialPropertyFundsListPage
      ) {
        navigate({
          pathname: generatePath(
            isRegistration ? registrationPath : portfolioUrl,
          ),
          search: createSearchParams({
            ...(childId && { childId }),
            ...(isSuper && { isSuper }),
            [PORTFOLIO_SEARCH_PARAMS.portfolioPlan]: PORTFOLIO_PLANS.pro,
          }).toString(),
        });
      }
    });
  };

  const isUpdateButtonDisabled =
    isUpdateFundsLoading ||
    (fundsToUpdate?.length === 0 && !isBasePortfolioFullyAllocated);

  return { isUpdateFundsLoading, handleUpdateFunds, isUpdateButtonDisabled };
};
