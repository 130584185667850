import { useState } from 'react';

import { Icon } from '../Icon';

import { DefaultIconWrapper } from './styles';

interface ImageWithDefaultIconProps {
  src: string;
  alt?: string;
  isDefaultImage?: boolean;
  className?: string;
  defaultIconSize?: number;
  withMargin?: boolean;
}

export const ImageWithDefaultIcon = ({
  src,
  alt,
  isDefaultImage,
  className = '',
  defaultIconSize = 35,
  withMargin,
}: ImageWithDefaultIconProps) => {
  const [isImageUrlBroken, setIsImageUrlBroken] = useState(false);

  if (isImageUrlBroken || isDefaultImage) {
    return (
      <DefaultIconWrapper withMargin={withMargin}>
        <Icon icon="defaultBank" size={defaultIconSize} />
      </DefaultIconWrapper>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      onError={() => {
        setIsImageUrlBroken(true);
      }}
    />
  );
};
