import React from 'react';

import { HeaderImage, HeaderWrapper } from './styles';
import { useFundDetails } from './hooks';
import FundDetailsSkeleton from './Skeleton/FundDetailsSkeleton';
import HeaderContent from './HeaderContent';

export default function FundDetailsHeader() {
  const { isLoading, fundDetails } = useFundDetails();
  const { image_url } = fundDetails;

  if (isLoading) {
    return <FundDetailsSkeleton />;
  }

  return (
    <HeaderWrapper>
      <HeaderImage background={image_url} />

      <HeaderContent />
    </HeaderWrapper>
  );
}
