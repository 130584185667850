import React from 'react';

import {
  BannerContentItemWrapper,
  BannerContentLabel,
  BannerContentValue,
  IconCustomButton,
} from './styles';

import { IconButton } from 'components/elements/IconButton';
import { formatNumber } from 'utils/formatters/formatNumber';

interface BannerContentItemProps {
  label: string;
  value: number;
  isHelpIcon?: boolean;
  openHelpDetailsModal?: () => void;
}

export const BannerContentItem = ({
  label,
  value,
  isHelpIcon,
  openHelpDetailsModal,
}: BannerContentItemProps) => (
  <BannerContentItemWrapper>
    <BannerContentLabel>
      {label}

      {isHelpIcon && openHelpDetailsModal && (
        <IconButton
          icon="infoWhite"
          size={18}
          CustomButton={IconCustomButton}
          onClick={openHelpDetailsModal}
        />
      )}
    </BannerContentLabel>

    <BannerContentValue>
      {formatNumber({
        value: value || 0,
        maximumFractionDigits: 2,
      })}
    </BannerContentValue>
  </BannerContentItemWrapper>
);
