import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import {
  ALL_FUND_TAGS_FILTER,
  DEFAULT_BASIC_PORTFOLIO,
  DEFAULT_PORTFOLIO,
  FUND_TAGS_KEY,
} from './consts';
import {
  IFundTagFunds,
  IFundTagsFilter,
  IRootPortfolioState,
  ISelectActiveFundTags,
  ISelectFiltersCategoriesProps,
} from './types';

import { IRootUserState } from 'store/user/types';
import { selectDependencyUserPortfolioId } from 'store/dependentUser/selectors';
import { selectUserAllocationProfileId } from 'store/user/selectors';

export interface IUserTypeProps {
  isRaizKids?: boolean;
  isSuper?: boolean;
}

export const selectUserFunds = (state: IRootPortfolioState) =>
  state.portfolio?.funds?.filter((fund) => fund.allocation) || [];

export const selectFundIdByTag =
  ({ fundTag }: { fundTag?: string }) =>
  (state: IRootPortfolioState) =>
    state.portfolio?.funds?.find(
      (fund) => fundTag && fund.tags.includes(fundTag),
    )?.id;

export const selectPortfolioFundsTags = (state: IRootPortfolioState) =>
  state.portfolio?.fundsTags;

export const selectFiltersCategories = ({
  state,
  fundTag,
  isPromoted,
}: ISelectFiltersCategoriesProps) => {
  const filterCategories = state.portfolio?.fundsTags;

  return Object.values(filterCategories)?.reduce(
    (filters: IFundTagsFilter[], filter) => {
      const isFilterAvailable =
        filter?.available_for === fundTag ||
        filter?.available_for === ALL_FUND_TAGS_FILTER;

      if (filter?.key === FUND_TAGS_KEY || !isFilterAvailable) {
        return filters;
      }

      const categoryFilters = filter?.tags.filter(
        (fund) => (isPromoted && fund.promoted) || !isPromoted,
      );

      if (!categoryFilters?.length) {
        return filters;
      }

      return [...filters, { ...filter, tags: categoryFilters }];
    },
    [],
  );
};

export const selectUserFundsFlattened = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolio?.structure?.funds_tags?.reduce(
    (funds: IFundTagFunds, tag) => [...funds, ...tag.funds],
    [],
  );

export const selectUserPortfolios = (state: IRootUserState) =>
  state.user?.portfolios || [];

export const selectBaseUserPortfolios = (state: IRootUserState) =>
  state.user?.portfolios?.filter((portfolio) => portfolio?.basic_for_custom) ||
  [];

export const selectSavedPortfolioId = ({
  isRaizKids,
  isSuper,
}: IUserTypeProps) =>
  createSelector(
    [selectDependencyUserPortfolioId, selectUserAllocationProfileId(isSuper)],
    (dependencyUserPortfolioId, userAllocationProfileId: string) =>
      isRaizKids ? dependencyUserPortfolioId : userAllocationProfileId,
  );

export const selectIsBasicPortfolioActive = ({
  isRaizKids,
  isSuper,
}: IUserTypeProps) =>
  createSelector(
    [selectUserPortfolios, selectSavedPortfolioId({ isRaizKids, isSuper })],
    (userPortfolios, savedPortfolioId) => {
      if (isSuper && !savedPortfolioId) {
        return true;
      }

      return userPortfolios?.some(
        (portfolio) => portfolio.id === savedPortfolioId,
      );
    },
  );

export const selectDefaultPortfolioId = (state: IRootUserState) =>
  state.user?.portfolios?.find(
    (portfolio) => portfolio.name_key === DEFAULT_PORTFOLIO,
  )?.id;

export const selectProPortfolioId = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolio?.structure?.portfolio?.id;

export const selectBasePortfolio = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolio?.structure?.portfolio;

export const selectBaseFundTag = ({ isRaizKids, isSuper }: IUserTypeProps) =>
  createSelector(
    [
      selectBasePortfolio,
      selectUserPortfolios,
      selectSavedPortfolioId({ isRaizKids, isSuper }),
    ],
    (basePortfolio, portfolios, savedPortfolioId) => {
      if (basePortfolio) return basePortfolio;

      const userAssignedPortfolio = portfolios?.find(
        (portfolio) => portfolio.id === savedPortfolioId,
      );

      if (userAssignedPortfolio) {
        return {
          id: userAssignedPortfolio?.id,
          name: userAssignedPortfolio?.name,
          allocation: DEFAULT_BASIC_PORTFOLIO.allocation,
          funds: userAssignedPortfolio?.etfs?.map(
            ({ id, etf_name, amount }) => ({
              id,
              name: etf_name,
              allocation: amount,
            }),
          ),
        };
      }

      return {
        name: DEFAULT_BASIC_PORTFOLIO.name,
        allocation: DEFAULT_BASIC_PORTFOLIO.allocation,
      };
    },
  );

export const selectSavedPortfolioName = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolio?.structure?.portfolio
    ? state.portfolio?.proPortfolio?.name
    : '';

export const selectPortfolioName = createSelector(
  [selectBasePortfolio, selectSavedPortfolioName],
  (basePortfolio, portfolioName) => (basePortfolio ? portfolioName : ''),
);

export const selectFundSorting = (state: IRootPortfolioState) =>
  state.portfolio?.sorting;

export const selectFundStatistics = (state: IRootPortfolioState) =>
  state.portfolio.fundStatistics;

export const selectActivePortfolioId = (state: IRootPortfolioState) =>
  state.portfolio?.activePortfolioId;

export const selectActivePortfolioType = createSelector(
  [selectUserPortfolios, selectActivePortfolioId],
  (userPortfolios, activePortfolioId) =>
    userPortfolios.find((portfolio) => portfolio?.id === activePortfolioId)
      ?.name_key || userPortfolios[0]?.name_key,
);

export const selectProPortfolio = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolio;

export const selectIsProPortfolioCreated = (state: IRootPortfolioState) =>
  Boolean(state.portfolio?.proPortfolio?.structure?.portfolio?.id);

export const selectFundsToUpdate = (state: IRootPortfolioState) =>
  state.portfolio?.fundsToUpdate || [];

export const selectBasePortfolioAllocation = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolio?.structure?.portfolio?.allocation || 0;

export const selectAvailableAllocation = createSelector(
  [
    selectFundsToUpdate,
    selectBasePortfolioAllocation,
    selectIsProPortfolioCreated,
  ],
  (fundsToUpdate, basePortfolioAllocation, isProPortfolioCreated) => {
    if (fundsToUpdate.length === 0) {
      return !isProPortfolioCreated ? 100 : basePortfolioAllocation;
    }

    const allocationAfterChanges = fundsToUpdate.reduce(
      (sum, { allocation }) => sum + allocation,
      0,
    );

    return 100 - allocationAfterChanges;
  },
);

export const selectIsAllocationExhausted = createSelector(
  selectAvailableAllocation,
  (availableAllocation) => availableAllocation <= 0,
);

export const selectIsProPortfolioLoading = (state: IRootPortfolioState) =>
  state.portfolio?.isProPortfolioLoading;

export const selectProPortfolioColorId = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolio?.structure?.color_set?.id;

export const selectIsBasePortfolioActive = ({
  isRaizKids,
  isSuper,
}: IUserTypeProps) =>
  createSelector(
    [selectBaseFundTag({ isRaizKids, isSuper }), selectActivePortfolioId],
    (basePortfolio, activePortfolioId) =>
      activePortfolioId === basePortfolio?.id,
  );

export const selectIsPortfolioLoading = (state: IRootPortfolioState) =>
  state.portfolio?.isPortfolioLoading;

export const selectProPortfolioFundsTags = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolio?.structure?.funds_tags || [];

export const selectFundsTags = createSelector(
  [selectPortfolioFundsTags, selectProPortfolioFundsTags],
  (fundsTags, targetFundTags) => {
    const sourceFundTags =
      fundsTags?.filter((fund) => fund.key === FUND_TAGS_KEY)?.[0]?.tags || [];

    return sourceFundTags?.map((fundTag) => {
      const comparedFundTag = targetFundTags?.filter(
        (fund) => fund.key === fundTag.key,
      )[0];

      return { ...comparedFundTag, ...fundTag };
    });
  },
);

export const selectIsFundsTagsLoading = (state: IRootPortfolioState) =>
  !!state.portfolio?.isFundsTagsLoading;

export const selectPortfolioId = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolio?.id;

export const selectPortfolioFunds = (state: IRootPortfolioState) =>
  state.portfolio?.funds || [];

export const selectIsFundsLoading = (state: IRootPortfolioState) =>
  state.portfolio?.isFundsLoading;

export const selectFundDetails = (state: IRootPortfolioState) =>
  state.portfolio?.fundDetails || {};

export const selectIsFundDetailsLoading = (state: IRootPortfolioState) =>
  state.portfolio?.isFundDetailsLoading;

export const selectActiveFundTags = ({ isRaizKids }: ISelectActiveFundTags) =>
  createSelector(
    [selectBaseFundTag({ isRaizKids }), selectFundsTags],
    (baseFundTag, fundsTags) => {
      const activeFundTags = [baseFundTag, ...fundsTags];

      return activeFundTags?.map((fund) => ({
        ...fund,
        funds: orderBy(fund?.funds, (item) => item.name.toLowerCase()),
      }));
    },
  );

export const selectIsBasePortfolioFullyAllocated = (
  state: IRootPortfolioState,
) => state.portfolio?.isBasePortfolioFullyAllocated;

export const selectProPortfolioColors = (state: IRootPortfolioState) =>
  state.portfolio?.proPortfolioColors || [];

export const selectIsParentProPortfolioPreview = (state: IRootPortfolioState) =>
  state.portfolio?.isParentProPortfolioPreview;

export const selectPortfolioParams = (state: IRootPortfolioState) =>
  state.portfolio?.portfolioParams || {};

export const selectPortfolioLocation = (state: IRootPortfolioState) =>
  state.portfolio?.portfolioLocation || {};
