import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/rewards.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { DEFAULT_FAVOURITE_CATEGORY } from '../consts';
import calculateCardsNumber from '../utils/calculateCardsNumber';

import CategoryCardsSkeleton from './CategoryCardsSkeleton';
import CategoryCard from './CategoryCard';

export default function CategoriesView({
  categories,
  filterCategory,
  withCarusel,
  isSearchFromMainPage,
  categoryId,
}) {
  const cardsNumber = calculateCardsNumber();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: cardsNumber,
    },
    tablet: {
      breakpoint: { max: 1200, min: 768 },
      items: cardsNumber,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: cardsNumber,
    },
  };

  return (
    <div
      className={
        categories?.length > 0
          ? 'rewards__filter'
          : 'rewards__filter rewards__filter-fake'
      }
    >
      {withCarusel &&
        (categories?.length > 0 ? (
          <Carousel
            responsive={responsive}
            ssr
            infinite={false}
            containerClass="rewards__carousel"
            slidesToSlide={cardsNumber}
            arrows
            centerMode
          >
            <CategoryCard
              category={DEFAULT_FAVOURITE_CATEGORY}
              filterCategory={filterCategory}
              isSearchFromMainPage={isSearchFromMainPage}
              categoryId={categoryId}
            />

            {categories?.map((category) => (
              <CategoryCard
                key={`category${category.id}`}
                category={category}
                filterCategory={filterCategory}
                isSearchFromMainPage={isSearchFromMainPage}
                categoryId={categoryId}
              />
            ))}
          </Carousel>
        ) : (
          <CategoryCardsSkeleton />
        ))}

      {!withCarusel && (
        <div>
          <CategoryCard
            category={DEFAULT_FAVOURITE_CATEGORY}
            filterCategory={filterCategory}
            isSearchFromMainPage={isSearchFromMainPage}
          />

          {categories?.map((category) => (
            <CategoryCard
              key={`category${category.id}`}
              category={category}
              filterCategory={filterCategory}
            />
          ))}
        </div>
      )}
    </div>
  );
}

CategoriesView.defaultProps = {
  withCarusel: true,
  isSearchFromMainPage: false,
  categoryId: null,
};

CategoriesView.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterCategory: PropTypes.func.isRequired,
  withCarusel: PropTypes.bool,
  isSearchFromMainPage: PropTypes.bool,
  categoryId: PropTypes.number,
};
