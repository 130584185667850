import {
  IDeleteInstitutionsData,
  IDeleteInstitutionsResponse,
  IUpdateInstitutionsUnlinkAccountsData,
  IUpdateInstitutionsUnlinkAccountsResponse,
} from './types';

import { API_TAGS } from 'store/consts';
import { api } from 'store/api';

export const institutionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateInstitutionsUnlinkAccounts: build.mutation<
      IUpdateInstitutionsUnlinkAccountsResponse,
      IUpdateInstitutionsUnlinkAccountsData
    >({
      query: ({ institutionId, accountIds }) => ({
        url: `/v1/institutions/${institutionId}/unlink_accounts`,
        method: 'PUT',
        data: {
          id: institutionId,
          account_ids: accountIds,
        },
      }),
      invalidatesTags: [API_TAGS.spendingAccounts],
    }),
    deleteInstitutions: build.mutation<
      IDeleteInstitutionsResponse,
      IDeleteInstitutionsData
    >({
      query: ({ institutionId }) => ({
        url: `/v1/institutions/${institutionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [API_TAGS.spendingAccounts],
    }),
  }),
  overrideExisting: true,
});

export const {
  useUpdateInstitutionsUnlinkAccountsMutation,
  useDeleteInstitutionsMutation,
} = institutionsApi;

export const {
  endpoints: { updateInstitutionsUnlinkAccounts, deleteInstitutions },
} = institutionsApi;
