import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  PortfolioThemeEditButtons,
  PortfolioThemeEditCancelButton,
  PortfolioThemeEditCloseButton,
  PortfolioThemeEditSubmitButton,
  PortfolioThemeEditTitle,
  TextField,
} from './styles';
import EditPortfolioThemeColor from './EditPortfolioThemeColor';

import { usePortfolioName } from 'pages/Portfolio/hooks';
import { IconButton } from 'components/elements/IconButton';

function EditPortfolioThemeForm({
  values,
  updatePortfolioTheme,
  handleClose,
  setIsThemeEditModalOpen,
  updatedPortfolioColor,
  setUpdatedPortfolioColor,
}) {
  const { t } = useTranslation();
  const portfolioName = usePortfolioName();

  return (
    <form
      className="form"
      onSubmit={(e) =>
        updatePortfolioTheme({
          e,
          values,
          updatedPortfolioColor,
        })
      }
    >
      <PortfolioThemeEditCloseButton>
        <IconButton
          icon="closeWhite"
          onClick={() => setIsThemeEditModalOpen(false)}
          size={19}
        />
      </PortfolioThemeEditCloseButton>

      <PortfolioThemeEditTitle>
        {t('portfolio.editThemeModal.title')}
      </PortfolioThemeEditTitle>

      <TextField
        name="name"
        maxLength="20"
        label={t('fields.name.label')}
        defaultValue={portfolioName}
      />

      <EditPortfolioThemeColor
        updatedPortfolioColor={updatedPortfolioColor}
        setUpdatedPortfolioColor={setUpdatedPortfolioColor}
      />

      <PortfolioThemeEditButtons>
        <PortfolioThemeEditSubmitButton
          type="submit"
          label={t('portfolio.editThemeModal.submitButton')}
        />

        <PortfolioThemeEditCancelButton
          type="button"
          label={t('common.cancel')}
          onClick={handleClose}
        />
      </PortfolioThemeEditButtons>
    </form>
  );
}

EditPortfolioThemeForm.defaultProps = {
  values: {},
  errors: {},
};

EditPortfolioThemeForm.propTypes = {
  updatePortfolioTheme: PropTypes.func.isRequired,
  setIsThemeEditModalOpen: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
  errors: PropTypes.shape({
    types: PropTypes.bool,
  }),
  handleClose: PropTypes.func.isRequired,
  updatedPortfolioColor: PropTypes.shape({}).isRequired,
  setUpdatedPortfolioColor: PropTypes.func.isRequired,
};

export default EditPortfolioThemeForm;
