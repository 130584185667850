import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';

import {
  BannerContentRow,
  BannerContentWrapper,
  helpModalStyle,
} from './styles';
import { CashRewardsContent } from './CashRewardsContent';
import { InvestedByYouContent } from './InvestedByYouContent';
import { BannerContentItem } from './BannerContentItem';
import { WithdrawnContent } from './WithdrawnContent';

import { PATHS } from 'constants/paths';
import { toggleInfoModal } from 'store/modals/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectCashRewards,
  selectUserAccountSummary,
} from 'store/history/selectors';

export const PastBannerContent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isKids = !!useMatch(PATHS.kids.history);

  const { deposits, reinvested_dividends, withdrawals } = useAppSelector(
    selectUserAccountSummary({ isKids }),
  );

  const cashRewards = useAppSelector(selectCashRewards({ isKids }));

  const openInvestedByYouModal = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('homePage.homePastBanner.investedByYou'),
          description: <InvestedByYouContent isKids={isKids} />,
          customStyles: helpModalStyle.styles,
        },
      }),
    );
  };

  const openCashRewardsModal = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('homePage.homePastBanner.cashRewards'),
          description: <CashRewardsContent isKids={isKids} />,
          customStyles: helpModalStyle.styles,
        },
      }),
    );
  };

  const openWithdrawnModal = () => {
    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t('homePage.homePastBanner.withdrawn'),
          description: <WithdrawnContent isKids={isKids} />,
          customStyles: helpModalStyle.styles,
        },
      }),
    );
  };

  return (
    <BannerContentWrapper>
      <BannerContentRow>
        <BannerContentItem
          label={t('homePage.homePastBanner.investedByYou')}
          value={deposits?.value}
          isHelpIcon
          openHelpDetailsModal={openInvestedByYouModal}
        />

        <BannerContentItem
          label={t('homePage.homePastBanner.withdrawn')}
          value={withdrawals?.value}
          isHelpIcon
          openHelpDetailsModal={openWithdrawnModal}
        />
      </BannerContentRow>

      <BannerContentRow>
        <BannerContentItem
          label={t('homePage.homePastBanner.dividend')}
          value={reinvested_dividends?.value}
        />

        <BannerContentItem
          label={t('homePage.homePastBanner.cashRewards')}
          value={cashRewards}
          isHelpIcon
          openHelpDetailsModal={openCashRewardsModal}
        />
      </BannerContentRow>
    </BannerContentWrapper>
  );
};
