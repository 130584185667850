import { DEFAULT_INFO_MODAL_SIZE, INFO_MODAL } from './consts';
import { IModalsReducer } from './types';

const initialState = {
  modals: {
    isInfoModalVisible: false,
    config: {
      title: '',
      description: '',
      acceptButtonText: '',
      rejectButtonText: '',
      onAccept: () => {},
      onReject: () => {},
      size: DEFAULT_INFO_MODAL_SIZE,
    },
  },
};

const modals: IModalsReducer = (
  state = initialState, // eslint-disable-line
  action,
) => {
  switch (action.type) {
    case INFO_MODAL:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default modals;
