// TODO: https://acornsau.atlassian.net/browse/RAIZ-6859
import { getHeaders } from '../../services/api/headers';
import { showGlobalError } from '../errors/actions';
import { RESET_PROPS } from '../commonTypes';
import { createOutRequest, createRequest } from '../user/actions';
import { REWARDS_ACTIVE_CATEGORY_ID } from '../../pages/Rewards/consts';
import { REWARDS_IN_STORE_SETTINGS } from '../../pages/NewRewards/consts';

import {
  GET_CATEGORIES_SUCCESS,
  GET_BRAND_SINGLE_SUCCESS,
  GET_BRANDS_SLIDER_SUCCESS,
  GET_MONITORED_ACCOUNTS_SUCCESS,
  JOIN_OFFSETTERS_SUCCESS,
  GET_CASHBACK_SUCCESS,
  GET_ONLINE_OFFERS_SUCCESS,
  GET_FEATURED_OFFERS_SUCCESS,
  GET_ONLINE_OFFERS_BY_ID_SUCCESS,
  GET_INSTORE_OFFERS_SUCCESS,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_FAVOURITES_SUCCESS,
  GET_MAP_MARKERS_SUCCESS,
  SET_LOCATION_SUCCESS,
  GET_CARDS_SUCCESS,
  GET_LINK_CARD_URL_SUCCESS,
  GET_OFFSETTERS_SUCCESS,
  GET_REWARDS_TOTALS_SUCCESS,
  GET_REWARDS_HISTORY_SUCCESS,
  GET_INSURANCE_CERTIFICATES_SUCCESS,
  GET_REWARDS_OFFERS_SUCCESS,
  SET_IS_LOADING,
  UPDATE_LOCATION,
  SET_REWARD_ERROR_MESSAGE,
} from './types';

import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';

export function getRewardsOffersSuccess(offers) {
  return { type: GET_REWARDS_OFFERS_SUCCESS, offers };
}

export function getBrandsSliderSuccess(sliders) {
  return { type: GET_BRANDS_SLIDER_SUCCESS, sliders };
}

export function getCategoriesSuccess(categories) {
  return { type: GET_CATEGORIES_SUCCESS, categories };
}

export function getBrandSingleSuccess(brand) {
  return { type: GET_BRAND_SINGLE_SUCCESS, brand };
}

export function joinOffsettersSuccess() {
  return { type: JOIN_OFFSETTERS_SUCCESS };
}

export function getMonitoredAccountsSuccess(monitoredAccounts) {
  return { type: GET_MONITORED_ACCOUNTS_SUCCESS, monitoredAccounts };
}

export function getCashbackSuccess(cashback) {
  return { type: GET_CASHBACK_SUCCESS, cashback };
}

export function getOnlineOffersSuccess(onlineOffers) {
  return { type: GET_ONLINE_OFFERS_SUCCESS, onlineOffers };
}

export function getFeaturedOffersSuccess(featuredOffers) {
  return { type: GET_FEATURED_OFFERS_SUCCESS, featuredOffers };
}

export function getOnlineOffersByIdSuccess(offer) {
  return { type: GET_ONLINE_OFFERS_BY_ID_SUCCESS, offer };
}

export function resetProps() {
  return { type: RESET_PROPS };
}

export function getInstoreOffersSuccess(instoreOffers) {
  return { type: GET_INSTORE_OFFERS_SUCCESS, instoreOffers };
}

export function getAllCategoriesSuccess(categories) {
  return { type: GET_ALL_CATEGORIES_SUCCESS, categories };
}

export function getfavouritesSuccess(favourites) {
  return { type: GET_FAVOURITES_SUCCESS, favourites };
}

export function getMapMarkersSuccess(markers) {
  return { type: GET_MAP_MARKERS_SUCCESS, markers };
}

export function setLocationSuccess(address, latitude, longitude) {
  return {
    type: SET_LOCATION_SUCCESS,
    address,
    latitude,
    longitude,
  };
}

export function updateLocation(newLocation, currentMarker) {
  const { latitude, longitude } = newLocation;

  return {
    type: UPDATE_LOCATION,
    latitude,
    longitude,
    currentMarker,
  };
}

export function getCardsSuccess(cards) {
  return { type: GET_CARDS_SUCCESS, cards };
}

export function getLinkCardUrlSuccess(linkCardUrl) {
  return { type: GET_LINK_CARD_URL_SUCCESS, linkCardUrl };
}

export function getOffsettersSuccess(offsetters) {
  return { type: GET_OFFSETTERS_SUCCESS, offsetters };
}

export function getRewardsTotalsSuccess(rewardsTotals) {
  return { type: GET_REWARDS_TOTALS_SUCCESS, rewardsTotals };
}

export function getRewardsHistorySuccess(rewardsHistory) {
  return { type: GET_REWARDS_HISTORY_SUCCESS, rewardsHistory };
}

export function getInsuranceCertificatesSuccess(insuranceCertificates) {
  return { type: GET_INSURANCE_CERTIFICATES_SUCCESS, insuranceCertificates };
}

export function setIsLoading(isLoading) {
  return { type: SET_IS_LOADING, isLoading };
}

export function setRewardErrorMessage(rewardErrorMessage) {
  return { type: SET_REWARD_ERROR_MESSAGE, rewardErrorMessage };
}

export function getRewardsOffers(search) {
  let keyword = '';

  if (search) {
    keyword = `&keyword=${search}`;
  }

  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}
/mobile/${process.env.REACT_APP_API_VERSION}/offers/all/filter?featured=0${keyword}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getRewardsOffersSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getBrandsForSlider() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}
/mobile/${process.env.REACT_APP_API_VERSION}/offers/all/filter?featured=1`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getBrandsSliderSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getCategories(textAll) {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}
/mobile/${process.env.REACT_APP_API_VERSION}/offers/categories/all`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        response.unshift({
          id: REWARDS_ACTIVE_CATEGORY_ID,
          name: textAll,
          order: 0,
        });

        dispatch(getCategoriesSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getRewardDetails(id) {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}
/mobile/${process.env.REACT_APP_API_VERSION}/offers/${id}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getBrandSingleSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getMonitoredAccounts() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}
/mobile/${process.env.REACT_APP_API_VERSION}/monitored_accounts`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getMonitoredAccountsSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getPaymentAccounts() {
  return (dispatch) =>
    fetch(`${process.env.REACT_APP_API_URL}/v1/payment_cards`, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch(getMonitoredAccountsSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function joinOffsetters() {
  return (dispatch) =>
    fetch(
      `${process.env.REACT_APP_API_URL}
/mobile/${process.env.REACT_APP_API_VERSION}/sales/offsetters`,
      {
        method: 'GET',
        mode: 'cors',
        headers: getHeaders(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(joinOffsettersSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getCashback() {
  return (dispatch) =>
    createRequest('', 'GET', 'user_offers/cashback_history')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getCashbackSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getOnlineOffers({
  page = 1,
  categoryId = null,
  query = '',
  isRefresh = false,
}) {
  let categoryFilter = '';
  let queryFilter = '';

  if (categoryId) {
    categoryFilter = `&categoryId=${categoryId}`;
  }

  if (query) {
    queryFilter = `&query=${query}`;
  }

  return (dispatch) =>
    createRequest(
      '',
      'GET',
      `user_offers/OnlineOffers/?pageNumber=${page}${categoryFilter}${queryFilter}`,
    )
      .then((response) => response.json())
      .then((response) => {
        const onlineRewards = response;

        if (page !== 1) {
          const onlineRewardsStore = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.rewardsOnlineItem),
          );

          if (onlineRewardsStore && !isRefresh) {
            onlineRewards.records = [
              ...onlineRewardsStore.records,
              ...onlineRewards.records,
            ];
          }
        }

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.rewardsOnlineItem,
          JSON.stringify(onlineRewards),
        );

        dispatch(getOnlineOffersSuccess(onlineRewards));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getFeaturedOffers() {
  return (dispatch) =>
    createRequest('', 'GET', 'user_offers/FeaturedOffers/online')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getFeaturedOffersSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getOnlineOffersById(id) {
  return (dispatch) =>
    createRequest('', 'GET', `user_offers/OnlineOffers/${id}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          dispatch(setRewardErrorMessage(response.error));
        } else {
          dispatch(getOnlineOffersByIdSuccess(response));
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getInstoreOffers({
  page = 1,
  categoryId = null,
  query = '',
  isRefresh = false,
  latitude = REWARDS_IN_STORE_SETTINGS.latitude,
  longitude = REWARDS_IN_STORE_SETTINGS.longitude,
  distance = REWARDS_IN_STORE_SETTINGS.distance,
}) {
  let categoryFilter = '';
  let queryFilter = '';

  if (categoryId) {
    categoryFilter = `&categoryId=${categoryId}`;
  }

  if (query) {
    queryFilter = `&query=${query}`;
  }

  const endpoint = `user_offers/InstoreOffers/?latitude=${latitude}&longitude=${longitude}${queryFilter}${categoryFilter}&distance=${distance}&pageNumber=${page}`;

  return (dispatch) =>
    createRequest('', 'GET', endpoint)
      .then((response) => response.json())
      .then((response) => {
        const instoreRewards = response;

        if (page !== 1) {
          const onlineRewardsStore = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.rewardsInstoreItem),
          );

          if (onlineRewardsStore && !isRefresh) {
            instoreRewards.records = [
              ...onlineRewardsStore.records,
              ...instoreRewards.records,
            ];
          }
        }

        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.rewardsInstoreItem,
          JSON.stringify(instoreRewards),
        );

        dispatch(getInstoreOffersSuccess(instoreRewards));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getInstoreOffersById(id) {
  return (dispatch) =>
    createRequest('', 'GET', `user_offers/InstoreOffers/${id}`)
      .then((response) => response.json())
      .then((response) => {
        dispatch(getOnlineOffersByIdSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getAllCategories() {
  return (dispatch) =>
    createRequest('', 'GET', 'user_offers/Offers/GetAllCategories')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getAllCategoriesSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function changeFavourite(
  id,
  isInstore,
  isFavourite,
  isInternal = false,
  offer = {},
  isShowFavourite = false,
) {
  return (dispatch) =>
    createRequest('', 'POST', `user_offers/favourite/toggle/${id}`)
      .then((response) => response.json())
      .then(() => {
        if (isShowFavourite) {
          const rewardsFavouritesItem = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.rewardsFavouritesItem),
          );

          if (
            rewardsFavouritesItem.favourites &&
            rewardsFavouritesItem.favourites.length !== 0
          ) {
            const recordId = rewardsFavouritesItem.favourites.findIndex(
              (record) => record.offerId === id,
            );

            if (rewardsFavouritesItem.favourites[recordId]) {
              rewardsFavouritesItem.favourites.splice(recordId, 1);

              sessionStorage.setItem(
                SESSION_STORAGE_KEYS.rewardsFavouritesItem,
                JSON.stringify(rewardsFavouritesItem),
              );

              dispatch(getfavouritesSuccess(rewardsFavouritesItem));
            }
          }
        } else if (isInternal) {
          if (isInstore) {
            dispatch(getOnlineOffersByIdSuccess(offer));
          } else {
            dispatch(getOnlineOffersByIdSuccess(offer));
          }
        } else if (isInstore) {
          const instoreRewardsStore = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.rewardsInstoreItem),
          );

          if (
            instoreRewardsStore.records &&
            instoreRewardsStore.records.length !== 0
          ) {
            const recordId = instoreRewardsStore.records.findIndex(
              (record) => record.offerId === id,
            );

            if (instoreRewardsStore.records[recordId]) {
              instoreRewardsStore.records[recordId].isFavourite = !isFavourite;

              sessionStorage.setItem(
                SESSION_STORAGE_KEYS.rewardsInstoreItem,
                JSON.stringify(instoreRewardsStore),
              );

              dispatch(getInstoreOffersSuccess(instoreRewardsStore));
            }
          }
        } else if (!isInternal) {
          const onlineRewardsStore = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.rewardsOnlineItem),
          );

          if (
            onlineRewardsStore.records &&
            onlineRewardsStore.records.length !== 0
          ) {
            const recordId = onlineRewardsStore.records.findIndex(
              (record) => record.id === id,
            );

            if (onlineRewardsStore.records[recordId]) {
              onlineRewardsStore.records[recordId].isFavourite = !isFavourite;

              sessionStorage.setItem(
                SESSION_STORAGE_KEYS.rewardsOnlineItem,
                JSON.stringify(onlineRewardsStore),
              );

              dispatch(getOnlineOffersSuccess(onlineRewardsStore));
            }
          }
        }
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getfavourites() {
  return (dispatch) =>
    createRequest('', 'GET', 'user_offers/favourite/')
      .then((response) => response.json())
      .then((response) => {
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.rewardsFavouritesItem,
          JSON.stringify(response),
        );

        dispatch(getfavouritesSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getMapMarkers(
  latitude = '-33.869199',
  longitude = '151.205963',
  distance = '50',
) {
  const endpoint = `user_offers/Map/?latitude=${latitude}&longitude=${longitude}&distance=${distance}`;

  return (dispatch) =>
    createRequest('', 'GET', endpoint)
      .then((response) => response.json())
      .then((response) => {
        dispatch(getMapMarkersSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getCards() {
  return (dispatch) =>
    createRequest('', 'GET', 'user_offers/DebitCards')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getCardsSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getLinkCardUrl() {
  return (dispatch) =>
    createRequest('', 'GET', 'user_offers/cardlinkservice_url')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getLinkCardUrlSuccess(response.url));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function setDefaultCard(id, isDefault) {
  const data = JSON.stringify({
    additional_params: {
      isDefault,
    },
  });

  return (dispatch) =>
    createRequest(data, 'PUT', `user_offers/DebitCards/${id}`)
      .then((response) => response.json())
      .then(() => {
        dispatch(getCards());
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function removeCard(id) {
  return (dispatch) =>
    createRequest('', 'DELETE', `user_offers/DebitCards/Remove?cardid=${id}`)
      .then((response) => {
        dispatch(getCards());

        return response.json();
      })
      .then(() => {
        dispatch(getCards());
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getOffsetters() {
  return (dispatch) =>
    createRequest('', 'GET', 'user_offers/offsetters')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getOffsettersSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getRewardsTotal() {
  return (dispatch) =>
    createRequest('', 'GET', 'rewards/totals')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getRewardsTotalsSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getRewardsHistory() {
  return (dispatch) =>
    createOutRequest(
      '',
      'GET',
      `${process.env.REACT_APP_API_URL}/mobile/v1/rewards`,
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch(getRewardsHistorySuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getInsuranceCertificates() {
  return (dispatch) =>
    createRequest('', 'GET', 'rewards/insurance_certificates')
      .then((response) => response.json())
      .then((response) => {
        dispatch(getInsuranceCertificatesSuccess(response));
      })
      .catch((error) => dispatch(showGlobalError(error)));
}

export function getGiftCardToken() {
  return (dispatch) =>
    createRequest(null, 'GET', 'user_offers/gift_token')
      .then((response) => response.json())
      .then((response) => {
        const { token } = response;

        return token;
      })
      .catch((error) => dispatch(showGlobalError(error)));
}
