import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';
import Skeleton from 'react-skeleton-loader';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { GET_BRANDS_SLIDER_SUCCESS } from '../../../store/rewards/types';

class BrandsCarousel extends React.Component {
  feturedSliderRender(fetured, saveInvested, isRecommended, t) {
    return (
      <Link
        to={`/rewards/online/${isRecommended ? 'recommended/' : 'featured/'}${
          fetured.id
        }`}
        key={fetured.id}
      >
        <div className="grid__carousel-item">
          <img src={fetured.coverArt} alt="" className="-background" />

          {isRecommended && (
            <div className="grid__carousel-feature grid__carousel-recommended">
              {t('newRewardsPage.brandCarousel.recommended')}
            </div>
          )}

          {!isRecommended && (
            <div className="grid__carousel-feature">
              {t('newRewardsPage.brandCarousel.featured')}
            </div>
          )}

          <div className="grid__carousel-info">
            <div className="grid__carousel-info-container">
              <div className="grid__carousel-info-icon">
                <img className="-logo" alt={fetured.title} src={fetured.logo} />
              </div>

              <div className="grid__carousel-info-content">
                <div className="grid__carousel-info-name">
                  {`  ${fetured.advertiser}`}
                </div>

                <div className="grid__carousel-info-action">
                  {`  ${fetured.title}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  brandsSliderRenderFake(e, index) {
    return (
      <div className="grid__carousel-fake" key={`${index * 2}`}>
        <Skeleton width="90%" height="148px" color="rgba(255,255,255,0.8)" />
      </div>
    );
  }

  render() {
    const { saveInvested, type, isRecommended, features, isNewRewards, t } =
      this.props;

    let slidesToSlide = 3;

    if (window.innerWidth <= 1200 && window.innerWidth > 768) {
      slidesToSlide = 2;
    } else if (window.innerWidth <= 768) {
      slidesToSlide = 1;
    }

    if (isNewRewards) {
      slidesToSlide = 5;

      if (window.innerWidth <= 2060 && window.innerWidth > 1700) {
        slidesToSlide = 5;
      } else if (window.innerWidth <= 1700 && window.innerWidth > 1580) {
        slidesToSlide = 4;
      } else if (window.innerWidth <= 1580 && window.innerWidth > 1300) {
        slidesToSlide = 3;
      } else if (window.innerWidth <= 1300 && window.innerWidth > 740) {
        slidesToSlide = 2;
      } else if (window.innerWidth <= 740) {
        slidesToSlide = 1;
      }
    }

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: slidesToSlide,
      },
      tablet: {
        breakpoint: { max: 1200, min: 768 },
        items: slidesToSlide,
      },
      mobile: {
        breakpoint: { max: 768, min: 0 },
        items: slidesToSlide,
      },
    };

    return (
      <div>
        {(features && features.length !== 0) ||
        type === GET_BRANDS_SLIDER_SUCCESS ? (
          <Carousel
            responsive={responsive}
            ssr
            containerClass="grid__carousel"
            slidesToSlide={slidesToSlide}
          >
            {features?.length !== 0 &&
              features.map((fetured) =>
                this.feturedSliderRender(
                  fetured,
                  saveInvested,
                  isRecommended,
                  t,
                ),
              )}
          </Carousel>
        ) : (
          [...Array(3)].map((e, index) => this.brandsSliderRenderFake(e, index))
        )}
      </div>
    );
  }
}

BrandsCarousel.defaultProps = {
  isRecommended: false,
  features: [],
  isNewRewards: false,
};

BrandsCarousel.propTypes = {
  type: PropTypes.string.isRequired,
  saveInvested: PropTypes.func.isRequired,
  isRecommended: PropTypes.bool,
  features: PropTypes.arrayOf(PropTypes.shape({})),
  isNewRewards: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(BrandsCarousel);
