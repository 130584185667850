import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AgreeButton,
  ChangeButton,
  CloseButtonWrapper,
  Descriptions,
  ModalChart,
  Content,
  Title,
  Wrapper,
  NameWrapper,
} from './styles';
import { getRecommendedPortfolioData } from './utils/getRecommendedPortfolioData';
import {
  RECOMMENDED_PORTFOLIO_CHART_OPTIONS,
  RECOMMENDED_PORTFOLIO_CHART_SIZE,
} from './consts';

import { IconButton } from 'components/elements/IconButton';
import { selectUser } from 'store/user/selectors';
import { selectUserPortfolios } from 'store/portfolio/selectors';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { getPortfolios } from 'store/user/actions';
import { useProfilePage } from 'pages/Settings/components/Forms/hooks/useProfilePage';
import { AU_APP } from 'constants/localeConfigs';

interface IRecommendedPortfolioModalProps {
  savePortfolio: () => void;
  handleOpenRecommendedPortfolioModal: (value: boolean) => void;
}

export const RecommendedPortfolioModal = ({
  savePortfolio,
  handleOpenRecommendedPortfolioModal,
}: IRecommendedPortfolioModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const portfolios = useAppSelector(selectUserPortfolios);
  const { recommended_allocation_profile_id } = useAppSelector(selectUser);
  const { isUpdateProfilePage, isProfilePage } = useProfilePage();

  const isTextForProfile = isUpdateProfilePage || isProfilePage;

  useEffect(() => {
    dispatch(getPortfolios());
  }, [dispatch]);

  const handleCloseModal = () => {
    handleOpenRecommendedPortfolioModal(false);
  };

  const isRecommendedPortfolioModalNotVisible =
    AU_APP || portfolios?.length === 0 || !recommended_allocation_profile_id;

  if (isRecommendedPortfolioModalNotVisible) {
    return null;
  }

  const { series, name } = getRecommendedPortfolioData({
    id: recommended_allocation_profile_id,
    portfolios,
  });

  return (
    <Wrapper isOpen>
      <Content>
        <CloseButtonWrapper>
          <IconButton icon="closeWhite" onClick={handleCloseModal} size={19} />
        </CloseButtonWrapper>

        <Title>{t('changePortfolioPage.recommendedPortfolio')}</Title>

        <ModalChart
          options={RECOMMENDED_PORTFOLIO_CHART_OPTIONS}
          series={series}
          type="pie"
          width={`${RECOMMENDED_PORTFOLIO_CHART_SIZE}px`}
        />

        <NameWrapper>{name}</NameWrapper>

        <Descriptions>
          {t('changePortfolioPage.thisPortfolioConstructed')}
        </Descriptions>

        <AgreeButton
          label={t('changePortfolioPage.iAgree')}
          onClick={savePortfolio}
          variant="initial"
        />

        {!isTextForProfile && (
          <Descriptions>{t('changePortfolioPage.notRecommended')}</Descriptions>
        )}

        <ChangeButton
          label={
            isTextForProfile
              ? t('changePortfolioPage.doNotChangeMyPortfolioButton')
              : t('changePortfolioPage.changeMyPortfolio')
          }
          onClick={handleCloseModal}
          variant="transparent"
        />
      </Content>
    </Wrapper>
  );
};
