export const API_TAGS = {
  investmentGoal: 'investmentGoal',
  spendingAccounts: 'spendingAccounts',
  banks: 'banks',
  recurringRemittanceSettings: 'recurringRemittanceSettings',
  roundUpsSettings: 'roundUpsSettings',
  suitability: 'suitability',
  superUser: 'superUser',
  superUserAccounts: 'superUserAccounts',
  verificationStatus: 'verificationStatus',
} as const;
