import WelcomeCard from './WelcomeCard';
import CustomizeYourPortfolioCard from './CustomizeYourPortfolioCard';
import ConstructingYourPortfolioCard from './ConstructingYourPortfolioCard';
import MakeChangesAnytimeCard from './MakeChangesAnytimeCard';
import HeadsUpCard from './HeadsUpCard';

export const getPortfolioPlusHelpCards = () => {
  const handleNextButton = () => {
    const nextButton = document.getElementsByClassName(
      'react-multiple-carousel__arrow--right',
    )[0] as HTMLButtonElement;

    if (nextButton) {
      nextButton.click();
    }
  };

  const handleCloseButton = () => {
    const closeButton = document.getElementsByClassName(
      'help-cards__modal-close',
    )[0] as HTMLButtonElement;

    if (closeButton) {
      closeButton.click();
    }
  };

  const className = '-how-works';
  const nextButtonTitle = 'common.next';
  const closeButtonTitle = 'common.close';

  return [
    {
      id: 'welcomeToPlus',
      className,
      info: 'portfolio.helpCards.welcomeCard.description',
      clickEvent: handleNextButton,
      greenBtn: {
        isBtn: true,
        title: nextButtonTitle,
      },
      customHeader: <WelcomeCard />,
    },
    {
      id: 'customizeYourPortfolio',
      className,
      info: 'portfolio.helpCards.customizeYourPortfolioCard.description',
      clickEvent: handleNextButton,
      greenBtn: {
        isBtn: true,
        title: nextButtonTitle,
      },
      customHeader: <CustomizeYourPortfolioCard />,
    },
    {
      id: 'constructingYourPortfolio',
      className,
      info: 'portfolio.helpCards.constructingYourPortfolioCard.description',
      clickEvent: handleNextButton,
      greenBtn: {
        isBtn: true,
        title: nextButtonTitle,
      },
      customHeader: <ConstructingYourPortfolioCard />,
    },
    {
      id: 'makeChangesAnytime',
      className,
      info: 'portfolio.helpCards.makeChangesAnytimeCard.description',
      clickEvent: handleNextButton,
      greenBtn: {
        isBtn: true,
        title: nextButtonTitle,
      },
      customHeader: <MakeChangesAnytimeCard />,
    },
    {
      id: 'headsUp',
      className,
      info: 'portfolio.helpCards.headsUpCard.description',
      clickEvent: handleCloseButton,
      greenBtn: {
        isBtn: true,
        title: closeButtonTitle,
      },
      customHeader: <HeadsUpCard />,
    },
  ];
};
