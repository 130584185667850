/* eslint-disable react/jsx-newline */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BankSignInModal from '../../../components/layout/Modals/BankSignInModal';
import BankCardModal from '../../../components/layout/Modals/BankCardModal';
import BankSignInDifferentModal from '../../../components/layout/Modals/BankSignInDifferentModal';
import Loader from '../../../components/elements/Loader/Loader';
import { MY_APP } from '../../../constants/localeConfigs';
import WithdrawalSignInModal from '../../../components/layout/Modals/WithdrawalSignInModal';
import BankLinkModal from '../../../components/layout/Modals/BankLinkModal';
import FundingConnectConfirmModal from '../../../components/layout/Modals/FundingConnectConfirmModal';
import FundingErrorModal from '../../../components/layout/Modals/FundingErrorModal';
import BankInformationModal from '../../../components/layout/Modals/BankInformationModal';
import BankErrorModal from '../../../components/layout/Modals/BankErrorModal';

import SubAccounts from './SubAccounts/SubAccounts';
import BanksSearch from './BanksSearch';
import Banks from './Banks';
import { useSuccessOrFailureModal } from './hooks/useSuccessOrFailureModal';
import { SUCCESS_OR_FAILURE_TRANSACTION_PATHS } from './hooks/const';
import {
  AddAnotherCardButton,
  ButtonWrapper,
  SeeMoreBanksButton,
} from './styles';
import { IdentityVerificationModal } from './IdentityVerificationModal';
import { FundingAccountChangeStatus } from './FundingAccountChangeStatus/FundingAccountChangeStatus';
import { useLinkCard } from './hooks/useLinkCard';
import { InfinitiumForm } from './InfinitiumForm';

import {
  selectIsIdentityVerificationCOFA,
  selectIsNewCardBindingFlowEnabled,
  selectIsNewFundingChangeable,
} from 'store/firebaseConfig/selectors';
import { IconButton } from 'components/elements/IconButton/IconButton';
import { PATHS } from 'constants/paths';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { checkChangeFunding } from 'store/fundingAccount/actions';
import BankAccountConnectionStatus from 'pages/Registration/components/BankAccountConnectionStatus/BankAccountConnectionStatus';
import { FastlinkModal } from 'components/modals/Fastlink/FastlinkModal';
import { PROVIDER_TYPES } from 'store/yodlee/consts';
import HelpCardsModalAU from 'components/layout/Modals/HelpCardsModalAU';
import { FUNDING_ACCOUNT } from 'constants/comonConstants';

const useConditionalRenderByCountry = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isNewFundingChangeable = useAppSelector(selectIsNewFundingChangeable);

  const [isChangeFundingLoading, setIsChangeFundingLoading] = useState(false);

  const isIdentityVerificationCOFA = useAppSelector(
    selectIsIdentityVerificationCOFA,
  );

  const handleAccountChangeClick = () => {
    setIsChangeFundingLoading(true);

    dispatch(checkChangeFunding(isNewFundingChangeable)).then(() => {
      setIsChangeFundingLoading(false);
    });
  };

  const rederSpendingCards = (
    spendingPaymentCard,
    switchСardSpendingToFunding,
  ) => {
    const checked = '';

    return (
      <div key={spendingPaymentCard.id} className="info-panel__item">
        <div className="name">
          {t('settingsPage.fundingAccountPage.cardStars')}
          {spendingPaymentCard.last_4}{' '}
        </div>

        <div className={`checkbox__plus ${checked}`}>
          +
          <input
            type="checkbox"
            onChange={(e) =>
              switchСardSpendingToFunding(e, spendingPaymentCard.id)
            }
          />
        </div>
      </div>
    );
  };

  const malaysiaAccount = (
    fundingPaymentCards,
    accountChange,
    isChangeBankCard,
    spendingPaymentCards,
    switchСardSpendingToFunding,
    showBanks,
  ) => {
    const { bank } = fundingPaymentCards.payment_cards[0];

    return (
      <div>
        <div
          key="info-panel"
          className="info-panel -company-name -with-padding"
        >
          <div className="info-panel__title">
            <img alt={bank.bank_name} src={bank.icon_url} width="38" />

            {`${bank.bank_name}`}

            {!isChangeBankCard && (
              <DeprecatedButton
                title={t('settingsPage.fundingAccountPage.accountChangeButton')}
                buttonClass="button__basic-wtbackground settings__spending-account-unlink"
                handleClick={accountChange}
              />
            )}
          </div>

          <div className="info-panel__divider" />

          {!isChangeBankCard && (
            <div className="info-panel__item">
              <div className="name">
                {t('settingsPage.fundingAccountPage.cardStars')}
                {fundingPaymentCards.payment_cards[0].last_4}{' '}
              </div>

              <div className="checkbox__plus checkbox__check">
                +
                <input disabled type="checkbox" />
              </div>
            </div>
          )}

          {isChangeBankCard &&
            spendingPaymentCards.payment_cards &&
            spendingPaymentCards.payment_cards.length !== 0 &&
            spendingPaymentCards.payment_cards.map((spendingPaymentCard) =>
              rederSpendingCards(
                spendingPaymentCard,
                switchСardSpendingToFunding,
              ),
            )}
        </div>

        {isChangeBankCard && (
          <div className="info-panel -company-name -with-padding add-card-block">
            <div className="info-panel__item">
              <div className="name">
                <AddAnotherCardButton
                  label={t('settingsPage.fundingAccountPage.addCardButton')}
                  onClick={showBanks}
                  variant="text"
                  icon={{
                    name: 'addGreen',
                    size: 35,
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {!isChangeBankCard && (
          <div className="profile-content__comment">
            <div
              className={`profile-content__comment-title ${
                MY_APP ? '-centered' : ''
              }`}
            >
              {t('settingsPage.fundingAccountPage.notACreditCardMessage')}
            </div>

            {!MY_APP && (
              <div className="profile-content__comment-info">
                {t('settingsPage.fundingAccountPage.changingSourceDelay')}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const australiaAccount = (accountData, accountChange) => (
    <div>
      <div key="info-panel" className="info-panel -company-name -with-padding">
        <div className="info-panel__title">
          {accountData.funding.icon && (
            <img alt="" src={accountData.funding.icon} width="35" />
          )}

          {!accountData.funding.icon && <div className="bank-icon" />}

          {`${accountData.funding.name ? accountData.funding.name : ''} (${
            accountData.funding.last_4
          })`}

          {isChangeFundingLoading ? (
            <Loader />
          ) : (
            <DeprecatedButton
              title={t('settingsPage.fundingAccountPage.accountChangeButton')}
              buttonClass="button__basic-wtbackground settings__spending-account-unlink 111"
              handleClick={
                isIdentityVerificationCOFA
                  ? handleAccountChangeClick
                  : accountChange
              }
            />
          )}
        </div>

        <div className="info-panel__divider" />

        <div className="info-panel__item">
          <div className="name">
            {t('settingsPage.fundingAccountPage.verified')}
          </div>

          <div className="checkbox__plus checkbox__check">
            +
            <input disabled type="checkbox" />
          </div>
        </div>
      </div>

      <FundingAccountChangeStatus />

      <div className="profile-content__comment">
        <div className="profile-content__comment-title">
          {t('settingsPage.fundingAccountPage.notACreditCardMessage')}
        </div>

        <div className="profile-content__comment-info">
          {t('settingsPage.fundingAccountPage.changingSourceDelay')}
        </div>
      </div>
    </div>
  );

  return { australiaAccount, malaysiaAccount };
};

export default function FundingAccountView({
  accountData,
  showBanks,
  isShowBanks,
  signInBank,
  isShowBankModal,
  handleCloseModal,
  bank,
  bankInSubmit,
  handleChangeBank,
  credentials,
  handleStopBankAccountLinking,
  isShowBankModalAlert,
  bankLoginProcessWait,
  errorMessage,
  errorTitle,
  handleCloseModalError,
  isShowBankModalError,
  monitoredInstitutions,
  handleSubAccount,
  isShowModalError,
  accountChange,
  showBanksFromSubAccount,
  isShowConnect,
  connectAccount,
  isShowDifferentBankModal,
  bankInDifferentSubmit,
  openDifferentBank,
  closeModal,
  showMainLoader,
  showLoader,
  bankCurrentTarget,
  isShowBankCardModal,
  openBankCardModal,
  cardMonth,
  cardYear,
  cardCvv,
  changeDate,
  linkCard,
  toggleShowPassword,
  isShowPassword,
  handleChangeCardNumber,
  cardNumberError,
  paymentCard,
  fundingPaymentCards,
  isChangeBankCard,
  spendingPaymentCards,
  switchСardSpendingToFunding,
  showMoreBanks,
  moreBanks,
  isShowMoreBanks,
  isShowWithdrawalSignIn,
  bankInSubmitWithdrawal,
  isShowConfirmationModal,
  showConfirmModal,
  accountName,
  connectCredentials,
  connectLoader,
  isShowLinkFunding,
  linkCardForFunding,
  selectLinkCard,
  isFundingErrorVisible,
  isShowBankInformationModal,
  closeBankInfoModal,
  fundingStatusCode,
  isShowFundingSecurityModal,
  goHome,
  user,
  requestChange,
  isBankAccountLinkingStep,
  toggleFastlinkModal,
  isFastlinkModalOpen,
  isYodleeFastlinkEnabled,
  searchValue,
  changeSearchValue,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isNewCardBindingFlowEnabled = useAppSelector(
    selectIsNewCardBindingFlowEnabled,
  );

  const [
    isAdditionalVerificationModalOpen,
    setIsAdditionalVerificationModalOpen,
  ] = useState(false);

  const handleExplanationModalAccept = () => {
    setIsAdditionalVerificationModalOpen(true);
  };

  const handleRequestChangeClick = () => {
    closeModal();

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          description: t('settingsPage.explanationModal.description'),
          onAccept: handleExplanationModalAccept,
        },
      }),
    );
  };

  const { australiaAccount, malaysiaAccount } = useConditionalRenderByCountry();

  useSuccessOrFailureModal({
    successUrl: PATHS.settings.fundingAccount.status.success,
    failureUrl: PATHS.settings.fundingAccount.status.failure,
    translationsPath: SUCCESS_OR_FAILURE_TRANSACTION_PATHS.settingsPage,
    navigatePath: PATHS.settings.fundingAccount.index,
  });

  const [isHelpCardModalOpen, setIsHelpCardModalOpen] = useState(false);

  const { linkBankCardFlowNew, isLinkCardLoading } = useLinkCard({
    isSpendingAccount: false,
  });

  const linkBankCard = isNewCardBindingFlowEnabled
    ? linkBankCardFlowNew
    : openBankCardModal;

  const handleBankClick = MY_APP ? linkBankCard : signInBank;

  const subTitle = useMemo(() => {
    if (isYodleeFastlinkEnabled && isShowBanks)
      return t('registrationPage.banks.subtitle.funding');

    if (monitoredInstitutions.length !== 0)
      return `${t('settingsPage.accountFundsInvestments')} ${t(
        'settingsPage.fundingAccountPage.selectAccountFund',
      )}`;

    if (accountData.funding) return t('settingsPage.accountFundsInvestments');

    if (
      !accountData.funding &&
      monitoredInstitutions.length === 0 &&
      !isShowBanks
    )
      return t('settingsPage.fundingAccountPage.addFundingMessage');

    if (isShowBanks) return t('settingsPage.fundingAccount.chooseBankFunding');

    return null;
  }, [
    accountData.funding,
    isShowBanks,
    isYodleeFastlinkEnabled,
    monitoredInstitutions.length,
    t,
  ]);

  const isIdentityVerificationCOFA = useAppSelector(
    selectIsIdentityVerificationCOFA,
  );

  return (
    <div className="settings__funding-account">
      {isNewCardBindingFlowEnabled && (
        <InfinitiumForm paymentCard={paymentCard} />
      )}
      {isBankAccountLinkingStep ? (
        <BankAccountConnectionStatus
          onUnmount={handleCloseModal}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
        />
      ) : (
        <>
          {!isShowMoreBanks && (
            <div className="profile-content settings__content-default-container">
              <div className="profile-content__header -with-help-card">
                <div className="profile-content__title">
                  {isShowBanks
                    ? t('settingsPage.linkFundingAccount')
                    : t('settingsPage.fundingAccount.title')}
                </div>

                <IconButton
                  icon="questionGreen"
                  size={28}
                  onClick={() => setIsHelpCardModalOpen(true)}
                />
              </div>

              <div className="profile-content__container">
                <div className="profile-content__container-item settings__funding-account-info">
                  <div>{subTitle}</div>
                </div>
              </div>
            </div>
          )}

          {((accountData.funding && monitoredInstitutions.length === 0) ||
            (fundingPaymentCards &&
              fundingPaymentCards.payment_cards &&
              fundingPaymentCards.payment_cards.length !== 0)) &&
            !isShowBanks &&
            !showMainLoader &&
            !isShowMoreBanks &&
            (MY_APP
              ? malaysiaAccount(
                  fundingPaymentCards,
                  accountChange,
                  isChangeBankCard,
                  spendingPaymentCards,
                  switchСardSpendingToFunding,
                  showBanks,
                )
              : australiaAccount(accountData, accountChange))}

          {!accountData.funding &&
            !isShowMoreBanks &&
            monitoredInstitutions.length === 0 &&
            (!MY_APP ||
              (MY_APP &&
                (!fundingPaymentCards ||
                  (fundingPaymentCards.payment_cards &&
                    fundingPaymentCards.payment_cards.length === 0)))) &&
            !isShowBanks &&
            !showMainLoader && (
              <div className="button-part">
                <div className="empty-content">
                  <div className="title">
                    <i className="bank__modal-secured-icon" />

                    {t('settingsPage.secured')}
                  </div>

                  <div>
                    {t('settingsPage.fundingAccountPage.addFundingMessage')}
                  </div>
                </div>

                <ButtonWrapper>
                  <SeeMoreBanksButton
                    label={t(
                      'settingsPage.fundingAccountPage.addFundingButton',
                    )}
                    onClick={showBanks}
                  />
                </ButtonWrapper>
              </div>
            )}

          {isShowMoreBanks && !showMainLoader && (
            <BanksSearch
              banks={moreBanks}
              handleClick={signInBank}
              isFunding
              searchValue={searchValue}
              changeSearchValue={changeSearchValue}
            />
          )}

          {isShowBanks && !showMainLoader && (
            <div>
              <Banks
                handleClick={handleBankClick}
                openDifferentBank={openDifferentBank}
                showMoreBanks={showMoreBanks}
                showMainLoader={isLinkCardLoading}
              />
            </div>
          )}

          {monitoredInstitutions.length !== 0 &&
            !isShowBanks &&
            !showMainLoader && (
              <div>
                <SubAccounts
                  user={user}
                  monitoredInstitutions={monitoredInstitutions}
                  handleSubAccount={handleSubAccount}
                  errorMessage={errorMessage}
                  handleCloseModalError={handleCloseModalError}
                  isShowModalError={isShowModalError}
                  showBanksFromSubAccount={showBanksFromSubAccount}
                  isShowConnect={isShowConnect}
                  connectAccount={connectAccount}
                  showConfirmModal={showConfirmModal}
                  isFundingErrorVisible={isFundingErrorVisible}
                />
              </div>
            )}
        </>
      )}

      {(isShowWithdrawalSignIn || isShowBankModalError) && (
        <WithdrawalSignInModal
          isShowBankModal={isShowWithdrawalSignIn}
          handleCloseModal={handleCloseModal}
          bank={bank}
          handleSubmit={bankInSubmitWithdrawal}
          showLoader={showLoader}
          handleChangeBank={handleChangeBank}
          credentials={credentials}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          isShowBankModalAlert={isShowBankModalAlert}
          bankLoginProcessWait={bankLoginProcessWait}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowBankModalError={isShowBankModalError}
        />
      )}

      {isShowConfirmationModal && (
        <FundingConnectConfirmModal
          connectCredentials={connectCredentials}
          accountName={accountName}
          isShowConfirmationModal={isShowConfirmationModal}
          connectLoader={connectLoader}
          handleCloseModal={handleCloseModal}
          handleSubAccount={handleSubAccount}
        />
      )}

      {(isShowBankModal || isShowBankModalError) &&
        !isBankAccountLinkingStep && (
          <BankSignInModal
            toggleShowPassword={toggleShowPassword}
            isShowPassword={isShowPassword}
            isShowBankModal={isShowBankModal}
            handleCloseModal={handleCloseModal}
            bank={bank}
            handleSubmit={bankInSubmit}
            showLoader={showLoader}
            credentials={credentials}
            handleStopBankAccountLinking={handleStopBankAccountLinking}
            isShowBankModalAlert={isShowBankModalAlert}
            bankLoginProcessWait={bankLoginProcessWait}
            errorMessage={errorMessage}
            errorTitle={errorTitle}
            handleCloseModalError={handleCloseModalError}
            isShowBankModalError={isShowBankModalError}
          />
        )}

      {MY_APP &&
        isShowLinkFunding &&
        spendingPaymentCards &&
        spendingPaymentCards.payment_cards &&
        spendingPaymentCards.payment_cards.length !== 0 && (
          <BankLinkModal
            errorMessage={errorMessage}
            handleCloseModalError={handleCloseModalError}
            isShowModalError={isShowModalError}
            paymentCards={spendingPaymentCards}
            linkCardForFunding={linkCardForFunding}
            selectLinkCard={selectLinkCard}
            showBanks={showBanks}
          />
        )}

      {isShowBankCardModal && (
        <BankCardModal
          isShowBankModal={isShowBankCardModal}
          handleCloseModal={handleCloseModal}
          bank={bank}
          handleSubmit={linkCard}
          showLoader={showLoader}
          handleChangeBank={handleChangeBank}
          credentials={credentials}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          isShowBankModalAlert={isShowBankModalAlert}
          bankLoginProcessWait={bankLoginProcessWait}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowBankModalError={isShowBankModalError}
          bankCurrentTarget={bankCurrentTarget}
          cardMonth={cardMonth}
          cardYear={cardYear}
          cardCvv={cardCvv}
          changeDate={changeDate}
          toggleShowPassword={toggleShowPassword}
          isShowPassword={isShowPassword}
          handleChangeCardNumber={handleChangeCardNumber}
          cardNumberError={cardNumberError}
          paymentCard={paymentCard}
        />
      )}

      {isShowDifferentBankModal && (
        <BankSignInDifferentModal
          isShowBankModal={isShowDifferentBankModal}
          handleCloseModal={closeModal}
          bank={bank}
          handleSubmit={bankInDifferentSubmit}
          credentials={credentials}
          handleStopBankAccountLinking={handleStopBankAccountLinking}
          isShowBankModalAlert={isShowBankModalAlert}
          bankLoginProcessWait={bankLoginProcessWait}
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          handleCloseModalError={handleCloseModalError}
          isShowBankModalError={isShowBankModalError}
          showLoader={showLoader}
        />
      )}
      {isFundingErrorVisible && (
        <FundingErrorModal
          errorMessage={errorMessage}
          isFundingErrorVisible={isFundingErrorVisible}
          fundingStatusCode={fundingStatusCode}
          closeModal={closeModal}
          requestChange={
            isIdentityVerificationCOFA
              ? handleRequestChangeClick
              : requestChange
          }
        />
      )}

      {isShowFundingSecurityModal && (
        <BankErrorModal
          isOpen={isShowFundingSecurityModal}
          hasBackdrop={false}
          isVisible={false}
          shouldCloseOnOverlayClick
          errorMessage={t('settingsPage.fundingAccountPage.forSecurityMessage')}
          errorTitle=""
          handleClick={goHome}
        />
      )}

      {isShowBankInformationModal && (
        <BankInformationModal
          isShowBankInformationModal={isShowBankInformationModal}
          closeBankInfoModal={closeBankInfoModal}
        />
      )}
      {isAdditionalVerificationModalOpen && (
        <IdentityVerificationModal
          isOpen={isAdditionalVerificationModalOpen}
          closeRequest={() => setIsAdditionalVerificationModalOpen(false)}
        />
      )}

      {isFastlinkModalOpen && (
        <FastlinkModal
          isFastlinkModalOpen={isFastlinkModalOpen}
          toggleFastlinkModal={toggleFastlinkModal}
          type={PROVIDER_TYPES.funding}
        />
      )}

      {isHelpCardModalOpen && (
        <HelpCardsModalAU
          isOpenHelpCards={isHelpCardModalOpen}
          currentPage={FUNDING_ACCOUNT}
          toggleHelpCard={setIsHelpCardModalOpen}
        />
      )}

      {showMainLoader && <Loader />}
    </div>
  );
}

FundingAccountView.defaultProps = {
  accountData: {},
  showLoader: false,
  isShowBanks: false,
  bankInSubmit() {},
  handleChangeBank() {},
  monitoredInstitutions: [],
  isShowBankCardModal: false,
  moreBanks: [],
  isShowWithdrawalSignIn: false,
  user: {},
  searchValue: null,
};

FundingAccountView.propTypes = {
  accountData: PropTypes.shape({
    funding: PropTypes.shape({}),
  }),
  showBanks: PropTypes.func.isRequired,
  isShowBanks: PropTypes.bool,
  signInBank: PropTypes.func.isRequired,
  isShowBankModal: PropTypes.bool.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  bank: PropTypes.shape({}).isRequired,
  bankInSubmit: PropTypes.func,
  handleChangeBank: PropTypes.func,
  credentials: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}),
  handleStopBankAccountLinking: PropTypes.func.isRequired,
  isShowBankModalAlert: PropTypes.bool.isRequired,
  bankLoginProcessWait: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  handleCloseModalError: PropTypes.func.isRequired,
  isShowBankModalError: PropTypes.bool.isRequired,
  monitoredInstitutions: PropTypes.arrayOf(PropTypes.shape({})),
  handleSubAccount: PropTypes.func.isRequired,
  isShowModalError: PropTypes.bool.isRequired,
  accountChange: PropTypes.func.isRequired,
  showBanksFromSubAccount: PropTypes.func.isRequired,
  isShowConnect: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool,
  connectAccount: PropTypes.func.isRequired,
  isShowDifferentBankModal: PropTypes.bool.isRequired,
  bankInDifferentSubmit: PropTypes.func.isRequired,
  openDifferentBank: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  bankCurrentTarget: PropTypes.string.isRequired,
  openBankCardModal: PropTypes.func.isRequired,
  isShowBankCardModal: PropTypes.bool,
  cardMonth: PropTypes.string.isRequired,
  cardYear: PropTypes.string.isRequired,
  cardCvv: PropTypes.string.isRequired,
  changeDate: PropTypes.func.isRequired,
  linkCard: PropTypes.func.isRequired,
  handleChangeCardNumber: PropTypes.func.isRequired,
  cardNumberError: PropTypes.string.isRequired,
  paymentCard: PropTypes.shape({}).isRequired,
  fundingPaymentCards: PropTypes.shape({
    payment_cards: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isChangeBankCard: PropTypes.bool.isRequired,
  spendingPaymentCards: PropTypes.shape({
    payment_cards: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  switchСardSpendingToFunding: PropTypes.func.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
  isShowPassword: PropTypes.bool.isRequired,
  showMoreBanks: PropTypes.func.isRequired,
  moreBanks: PropTypes.arrayOf(PropTypes.shape({})),
  isShowMoreBanks: PropTypes.bool.isRequired,
  isShowWithdrawalSignIn: PropTypes.bool,
  bankInSubmitWithdrawal: PropTypes.func.isRequired,
  isShowConfirmationModal: PropTypes.bool.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  accountName: PropTypes.string.isRequired,
  connectCredentials: PropTypes.shape({}).isRequired,
  connectLoader: PropTypes.bool.isRequired,
  isShowLinkFunding: PropTypes.bool.isRequired,
  linkCardForFunding: PropTypes.func.isRequired,
  selectLinkCard: PropTypes.func.isRequired,
  isFundingErrorVisible: PropTypes.bool.isRequired,
  isShowBankInformationModal: PropTypes.bool.isRequired,
  closeBankInfoModal: PropTypes.func.isRequired,
  fundingStatusCode: PropTypes.number.isRequired,
  isShowFundingSecurityModal: PropTypes.bool.isRequired,
  goHome: PropTypes.func.isRequired,
  requestChange: PropTypes.func.isRequired,
  toggleFastlinkModal: PropTypes.func.isRequired,
  isFastlinkModalOpen: PropTypes.bool.isRequired,
  isBankAccountLinkingStep: PropTypes.bool.isRequired,
  isYodleeFastlinkEnabled: PropTypes.bool.isRequired,
  searchValue: PropTypes.string,
  changeSearchValue: PropTypes.func.isRequired,
};
